/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import { INetworkIntegration, Events, EventEmitter } from './network-integration';

/**
 * Documentation:
 * https://docs.google.com/document/d/1B8siB8-dw9SOTrNJsH1xGEZF9B4ei2m-5PTSnjUoAA0/edit#heading=h.fce68ww6by1x
 * Dapi is standard interface specification for mobile rich media ads.
 */
interface IDapi {
  isDemoDapi?: boolean,
  isViewable: boolean,
  isReady: () => boolean,
  getScreenSize: () => ({ width: number, height: number }),
  openStoreUrl: (url: string) => void,
  getAudioVolume: () => number,
  addEventListener: (event: string, callback: () => void) => void,
  removeEventListener: (event: string, callback: () => void) => void,
}

const DapiEvents = {
  ready: 'ready',
  sizeChange: 'adResized',
  viewableChange: 'viewableChange',
  audioVolumeChange: 'audioVolumeChange',
};

export default class DapiIntegration implements INetworkIntegration {
  _networkSDK: IDapi | null = null;

  // declare _events: EventEmitter;
  _events: EventEmitter = new EventEmitter();

  _appInfo: any = null;

  _timeout: NodeJS.Timeout | null = null;

  public get name() { return 'ironsource'; }

  public preloader = true;

  public get appInfo(): any { return this._appInfo; }

  public onWindowLoad() {
    // @ts-ignore
    if (dapi?.isReady()) {
      this.onReady();
    } else {
      // this._networkSDK?.addEventListener(DapiEvents.ready, this.onReady.bind(this));
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-implied-eval
      setTimeout(window.monsterplayable.injected.network.onReady(), 5000);
    }
    this._events.emit('windowLoaded');
  }

  public onViewChange(event: { isViewable: boolean }) {
    if (event.isViewable) {
      this._events.emit('gamePlay');
    } else {
      this._events.emit('gamePause');
    }
  }

  public gameEnd() { console.warn('DAPI: gameEnd'); }

  public gameReady() { console.warn('DAPI: gameReady'); }

  public install() {
    // @ts-ignore
    _networkSDK.openStoreUrl(_appInfo.store.url);
  }

  public getAudioVolume() {
    return 1;
  }

  public getScreenSize() {
    return { width: window.innerWidth, height: window.innerHeight };
  }

  public sendMsg(msg: string) {
    console.warn('msg: ', msg);
  }

  public onVolumeChange() { this._events.emit('soundChange'); }

  public onReady() {
    // this._networkSDK.removeEventListener(DapiEvents.ready, this.onReady);
    // this._networkSDK.addEventListener(DapiEvents.sizeChange, this.onResize.bind(this));
    // this._networkSDK.addEventListener(DapiEvents.viewableChange, this.onViewChange.bind(this));
    // this._networkSDK.addEventListener(DapiEvents.audioVolumeChange, this.onVolumeChange.bind(this));
    this._events.emit('gameStart');
  }

  public onResize() {
    this._events.emit('screenResize');
  }

  addListener(event: string, callback: () => void) {
    this._events.addListener(event, callback);
  }

  removeListener(event: string, callback: () => void) {
    this._events.removeListener(event, callback);
  }

  MP_META = '';
  MP_SCRIPT = `
  <script>
  function getScript(e, i) {
    var n = document.createElement("script");
    n.type = "text/javascript",n.async = !0, i && (n.onload = i), n.src = e, document.head.appendChild(n) }
  function parseMessage(e) {
    var i = e.data,
    n = i.indexOf(DOLLAR_PREFIX + RECEIVE_MSG_PREFIX);
    if (-1 !== n) {
      var t = i.slice(n + 2);
      return getMessageParams(t)
    }
    return {}
  }
  function getMessageParams(e) {
    var i, n = [],
    t = e.split("/"),
    a = t.length;
    if (-1 === e.indexOf(RECEIVE_MSG_PREFIX)) {
      if (a >= 2 && a % 2 === 0)
      for (i = 0; a > i; i += 2) n[t[i]] = t.length < i + 1 ? null : decodeURIComponent(t[i + 1])
    } else {
      var o = e.split(RECEIVE_MSG_PREFIX);
      void 0 !== o[1] && (n = JSON && JSON.parse(o[1]))
    }
    return n
  }
  function getDapi(e) { var i = parseMessage(e); if (!i || i.name === GET_DAPI_URL_MSG_NAME) { var n = i.data; getScript(n, onDapiReceived) } } function invokeDapiListeners() { for (var e in dapiEventsPool) dapiEventsPool.hasOwnProperty(e) && dapi.addEventListener(e, dapiEventsPool[e]) } function onDapiReceived() { dapi = window.dapi, window.removeEventListener("message", getDapi), invokeDapiListeners() } function init() {
    window.dapi.isDemoDapi && (window.parent.postMessage
      (DOLLAR_PREFIX + SEND_MSG_PREFIX + JSON.stringify({ state: "getDapiUrl" }), "*"), window.addEventListener("message", getDapi, !1))
  } var DOLLAR_PREFIX = "$$$$",
    RECEIVE_MSG_PREFIX = "DAPI_SERVICE:", SEND_MSG_PREFIX = "DAPI_AD:", GET_DAPI_URL_MSG_NAME = "connection.getDapiUrl", dapiEventsPool = {}, dapi = window.dapi ||
    {
      isReady: function () { return !1 }, addEventListener: function (e, i) { dapiEventsPool[e] = i }, removeEventListener: function (e) { delete dapiEventsPool[e] },
      isDemoDapi: !0
    }; init();

  // window.onload = function(){
  (dapi.isReady()) ? onReadyCallback() : dapi.addEventListener("ready", onReadyCallback);
  // };
  function onReadyCallback() {
    _networkSDK = dapi;
    //No need to listen to this event anymore
    _networkSDK.removeEventListener("ready", onReadyCallback);

    //If the ad is visible start the game
    _networkSDK.isViewable() && startGame();
    //Use dapi functions
    _networkSDK.addEventListener("viewableChange", viewableChangeCallback);
    _networkSDK.addEventListener("audioVolumeChange", onVolumeChange);
    _networkSDK.addEventListener("adResized", onResize);
  }
  function startGame() {
    var screenSize = _networkSDK.getScreenSize();
    _networkSDK.getAudioVolume();
  }
  function viewableChangeCallback(e) {
    e.isViewable ? this.startGame() : pause();
  }
  function onVolumeChange() {
    _networkSDK.getAudioVolume();
  }
  function onResize() {
    _networkSDK.getScreenSize()
  }
  </script>
  `;
}
