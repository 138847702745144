import {
  Redirect, Route, Switch, useRouteMatch,
} from 'react-router-dom';
import {
  SettingOutlined,
  PlaySquareOutlined,
} from '@ant-design/icons';

import { ApplicationContextProvider } from './context/application.context';
import { IecContextProvider } from './context/iec.context';
import { CreativesContextProvider } from './context/creatives.context';
import { CreativeContextProvider } from './context/creative.context';
import { TourContextProvider } from './context/tour.context';
import { MouseContextProvider } from './context/mouse.context';

import {
  CreativesPage, IecPage, ExportPage, ApplicationsPage, ApplicationPage, PlayablePage,
} from './pages';
import { YcTabs } from '../../components/index';

import './index.scss';
import {
  PreviewCompImage_CSS,
  PreviewCompText_CSS,
  PreviewCompFlex_CSS,
  PreviewCompBorder_CSS,
  PreviewCompBackground_CSS,
  PreviewCompAnimation_CSS,
  PreviewBreak_CSS,
  PreviewLoop_CSS,
  PreviewHover_CSS,
  PreviewElement_CSS,
  PreviewCompTemplatedBackground_CSS,
} from './components/iec';
import { IecSettingsContextProvider } from './context/iec-settings.context';
import { PlayableContextProvider } from './context/playable.context';
import { useAuths } from '../../contexts';

export function ApplicationModule() {
  const uAuths = useAuths();
  const { path } = useRouteMatch();

  const elements = [
    {
      key: 'creatives',
      label: 'Creatives',
      icon: <PlaySquareOutlined />,
    },
    {
      key: 'settings',
      label: 'Settings',
      icon: <SettingOutlined />,
    },
  ];

  const displayMenu = (() => {
    const sp = window.location.pathname.split('/');
    return sp.length > 3;
  });

  const getElements = () => {
    const elems = [];
    const id = window.location.pathname.split('/')[2];
    for (const e of elements) {
      elems.push({
        ...e,
        link: `/applications/${id}/${e.key}`,
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return elems;
  };

  return (
    <div id="applications">
      { displayMenu()
        && <YcTabs elems={getElements()} />}
      <div className={`content${displayMenu() ? ' content-menu' : ''}`}>
        <ApplicationContextProvider>
          <MouseContextProvider>
            <CreativesContextProvider>
              <TourContextProvider>
                <CreativeContextProvider>
                  <IecContextProvider>
                    <PlayableContextProvider>
                      <IecSettingsContextProvider>
                        <Switch>
                          <Route exact path={`${path}`}><ApplicationsPage /></Route>
                          <Route exact path={`${path}/:appId/settings`}><ApplicationPage /></Route>
                          <Route exact path={`${path}/:appId/creatives`}><CreativesPage /></Route>
                          <Route
                            exact
                            path={`${path}/:appId/creatives/iec/:id`}
                            render={() => (
                              uAuths.canAccess('applications/iec') ? <IecPage /> : <Redirect to={`${path}`} />
                            )}
                          />
                          <Route
                            exact
                            path={`${path}/:appId/creatives/playable/:id`}
                            render={() => (
                              uAuths.canAccess('applications/playable') ? <PlayablePage /> : <Redirect to={`${path}`} />
                            )}
                          />
                          <Route
                            exact
                            path={`${path}/:appId/export/:id`}
                            render={() => (
                              !uAuths.isFreeAccount() ? <ExportPage /> : <Redirect to={`${path}`} />
                            )}
                          />
                          <Route path={path}><Redirect to={`${path}`} /></Route>
                        </Switch>
                      </IecSettingsContextProvider>
                    </PlayableContextProvider>
                  </IecContextProvider>
                </CreativeContextProvider>
              </TourContextProvider>
            </CreativesContextProvider>
          </MouseContextProvider>
        </ApplicationContextProvider>
      </div>
      <style>
        {PreviewBreak_CSS}
        {PreviewLoop_CSS}
        {PreviewHover_CSS}
        {PreviewElement_CSS}
        {PreviewCompText_CSS}
        {PreviewCompImage_CSS}
        {PreviewCompFlex_CSS}
        {PreviewCompBorder_CSS}
        {PreviewCompBackground_CSS}
        {PreviewCompAnimation_CSS}
        {PreviewCompTemplatedBackground_CSS}
      </style>
    </div>
  );
}
