/* eslint-disable jsx-a11y/media-has-caption */

import { ExportType, MCreative, MCreativeBreak } from '../../../../../modeles';
import { PreviewElement } from './preview-element';
import { PreviewCompBackground } from './preview-comp-background';
import { varElement } from '../../exports/functions';

export const PreviewBreak_CSS = `
/* BREAK */
.preview-break { 
  position: relative;
  width: 100%;
  height: 100%;
}
`;

export function PreviewBreak(props: {
  br: MCreativeBreak,
  base64?: { [key:string]: string },
  exportType?: ExportType,
  language?: string,
  iec?: MCreative,
}) {
  const getVar = () => {
    return varElement(props.br, 'break');
  };
  return (
    <div className="preview-break" id={getVar()} style={{ display: props.base64 ? 'none' : 'block' }}>
      <PreviewCompBackground background={props.br.background} />
      {props.br.elements.map((element) => (
        <PreviewElement
          exportType={props.exportType}
          element={element}
          key={element._key}
          base64={props.base64}
          language={props.language}
          iec={props.iec}
        />
      ))}
    </div>
  );
}
