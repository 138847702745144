import {
  Form, Input, Button, Result,
} from 'antd';
import { Link } from 'react-router-dom';

import * as ImagesSignup from '../../../../assets/images/sign-up';
import { authsService } from '../../../../services/auths.service';
import { useAuths } from '../../../../contexts/auths.context';

import './sign-up.page.scss';

export function SignUpPage() {
  const uAuths = useAuths();

  const onFinish = async (data: any) => {
    // @ts-ignore
    // window.lintrk('track', { conversion_id: 16234633 });
    await uAuths.signup(data);
  };

  const onResendMail = () => {
    authsService.newVerificationMail();
  };

  return (
    <div className="root-sign-up">
      <img src={ImagesSignup.Bkgcontent} className="bkgcontent" alt="monster" />
      <div className="block-bkg">
        <img src={ImagesSignup.Monster} className="monster" alt="monster" />
        <div className="block-auth">
          {!uAuths.signUp && (
          <>
            <div className="top-row">
              <span className="orange-title">Sign Up</span>
              <div className="right-part">
                <span className="left-text">Already have an account ?</span>
                <Link className="gradient-btn" to="/login">Login now</Link>
              </div>
            </div>
            <Form onFinish={(e) => { onFinish(e); }} layout="vertical">
              <div className="row">
                <Form.Item label="Company Name" className="col-12" name="name" rules={[{ required: true }]} initialValue="">
                  <Input placeholder="Company Name" type="text" />
                </Form.Item>
                <Form.Item label="Email" className="col-12" name={['user', 'email']} rules={[{ required: true }]} initialValue="">
                  <Input placeholder="Email" type="email" />
                </Form.Item>
                <Form.Item label="Last Name" className="col-6" name={['user', 'lastName']} rules={[{ required: true }]} initialValue="">
                  <Input placeholder="Last Name" type="text" />
                </Form.Item>
                <Form.Item label="First Name" className="col-6" name={['user', 'firstName']} rules={[{ required: true }]} initialValue="">
                  <Input placeholder="First Name" type="text" />
                </Form.Item>
                <Form.Item label="Password" className="col-12" name={['user', 'password']} rules={[{ required: true }]} initialValue="">
                  <Input.Password placeholder="Password" type="password" />
                </Form.Item>
                <div className="bottom-row">
                  <Button className="confirm-btn" type="primary" htmlType="submit">Sign Up</Button>
                </div>
              </div>
            </Form>
          </>
          )}
          {uAuths.signUp && (
          <Result
            status="success"
            title="Successfull Sign up!"
            subTitle="Please confirm your email address to active your account!"
            extra={[
              <a href="mailto:contact@monsterplayable.com">
                <Button type="primary">
                  Contact us!
                </Button>
              </a>,
              <Button type="primary" onClick={onResendMail}>
                Resend verification link
              </Button>,
            ]}
          />
          )}
        </div>
      </div>
    </div>
  );
}
