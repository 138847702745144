import { ExportType, MCompButton } from '../../../../../modeles';
import { varVideo } from '../../exports/functions';

export const PreviewCompButton_CSS = `
.preview-button-a { position: absolute; top: 0; left: 0; right: 0; bottom: 0; pointer-events: auto !important; }
`;

export function PreviewCompButton(props: {
  button: MCompButton,
  exportType?: ExportType,
}) {
  let onclick = 'void(0);';
  // eslint-disable-next-line no-script-url
  let href = 'javascript:void(0);';
  let typeButton = '';
  if (props.button.action === 'video-continue') {
    onclick = 'video().play();';
    onclick += '__breakFlag = false;';
    onclick += 'unMutedAllVideos();';
    onclick += 'displayParent(\'video-continue\');';
    typeButton = 'video-continue';
  } else if (props.button.action === 'app-download') {
    onclick = `
    video().pause();
    monsterplayable.install();
    __breakFlag = false;
    `;
    typeButton = 'app-download';
    if (props.button.appDownloadDisplayEndCard) {
      onclick += `    
        displayParent(endcard);
      `;
    }
  } else if (props.button.action === 'video-change') {
    onclick = `
    event.preventDefault();
    unMutedAllVideos();
    __breakFlag = false;
    video().pause();
    displayVideo('${varVideo(props.button.videoKey)}');
    displayHover('${varVideo(props.button.videoKey)}');
    `;
    typeButton = 'video-change';
  } else if (props.button.action === 'endcard-display') {
    onclick = 'video().pause();';
    onclick += 'displayParent(\'endcard\');';
    typeButton = 'endcard-display';
    if (props.button.displayEndCardRedirect) {
      onclick += `
      if(dataToSend.iec.network !== 'unityads') {
        monsterplayable.install();
      }
      `;
    }
  } else if (props.button.action === 'redirect-url') {
    onclick = 'video().pause();';
    typeButton = 'redirect-url';
    href = props.button.url || '';
  }
  if (props.exportType !== 'Export') {
    onclick += `newLog('Event: Button ${typeButton}');`;
  }
  return (
    // eslint-disable-next-line react/no-danger
    <div dangerouslySetInnerHTML={{
      __html: `
    <a class="preview-button-a"
    href="${href}"
    ontouchstart="${onclick}"
    onClick="${onclick}"></a>
    `,
    }}
    />
  );
}
