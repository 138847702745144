import { CSSProperties } from 'react';
import { MCompBorder, MCompBoxShadow } from '../../../../../modeles';
import { ColorTools } from '../../../../../tools';

export const PreviewCompBorder_CSS = `
/* BORDER */
.preview-comp-border {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
`;

export function PreviewCompBorder(props: {
  border?: MCompBorder,
  boxShadow?: MCompBoxShadow,
  children: React.ReactNode,
}) {
  const getStyle = () => {
    const style: CSSProperties = {};
    if (props.border) {
      style.borderWidth = `${props.border.size}px`;
      style.borderColor = ColorTools.mColorToString(props.border.color);
      style.overflow = props.border.overflow;
      style.borderRadius = props.border.radius;
      style.borderStyle = 'solid';
    }
    if (props.boxShadow) {
      const b = props.boxShadow;
      style.boxShadow = `${b.x}px ${b.y}px ${b.blurRadius}px ${b.spreadRadius}px ${ColorTools.mColorToString(b.color)}`;
    }
    return style;
  };
  return (
    <>
      {(props.border || props.boxShadow) && (<div className="preview-comp-border" style={getStyle()}>{props.children}</div>)}
      {(!props.border && !props.boxShadow) && props.children}
    </>
  );
}
