import {
  Resume, HomeHeader, PlayablePreview, IecPreview, WhyMp,
} from './home-components';
import { Footer } from '../../../../components/footer/footer';
import * as HomeImages from '../../../../assets/images/home';
import { VideoComponent } from './home-components/video-component/video-component';

import './home.page.scss';

export interface IGame {
  name: string,
  icon: string,
  url: string
}

export function HomePage() {
  return (
    <div id="root-home">

      <script type="text/javascript" id="hs-script-loader" async defer src="//js-eu1.hs-scripts.com/26656886.js" />

      <HomeHeader />

      <Resume />

      <div className="preview">
        <img className="background-preview wave" src={HomeImages.CenterBackground} alt="Fd Rond" />
        <PlayablePreview />
        <IecPreview />
      </div>

      <WhyMp />

      <VideoComponent />

      <Footer />

    </div>
  );
}
