import { MColor, MCompBackground, NodeType } from '../../../../../../modeles';

import { YcCard, YcColorPicker } from '../../../../../../components';
import { useIec } from '../../../../context/iec.context';

import './setting-comp-background.scss';

export function SettingCompBackground(props: {
  background: MCompBackground,
  isDeletable: boolean,
}) {
  const uIec = useIec();

  return (
    <YcCard
      title="Background"
      light
      open={props.background._open}
      onClickTitle={(val) => {
        props.background._open = val;
        uIec.refreshIec(true);
      }}
      onDelete={
        props.isDeletable
          ? () => { uIec.removeComponentNodeEdite(props.background); }
          : undefined
      }
    >
      <div className="setting-base">
        <div className="row">
          <div className="col-6">
            <label>Color</label>
            <YcColorPicker
              color={props.background.color}
              onColorChange={(color: MColor) => {
                props.background.color = color;
                uIec.refreshIec(true);
              }}
            />
          </div>
        </div>
      </div>
    </YcCard>
  );
}
