import { useEffect, useRef, useState } from 'react';
import { Button, Flex } from 'antd';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { v4 as uuid } from 'uuid';

import { usePlayable } from '../../../../context/playable.context';

import './assets-lib.scss';

interface IMesh {
  name: string,
  mesh: THREE.Mesh,
  modelIndex: string
}

export function AssetsLib(props: {
  onValidate: (mesh: IMesh) => void;
  onCancel: () => void;
}) {
  const uPlayable = usePlayable();

  const [assets, _setAssets] = useState<IMesh[]>([]);
  const [selectedMesh, _setSelectedMesh] = useState<number | null>(null);

  useEffect(() => {
    if (uPlayable.playableWindow) {
      const newList: IMesh[] = [];
      const models = uPlayable.playableWindow.monsterplayable.workflow.assets.models;
      for (const key in models) {
        if (Object.prototype.hasOwnProperty.call(models, key)) {
          const item = models[key];

          newList.push({
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            name: Object.keys(item)[0],
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            mesh: item[Object.keys(item)[0]].mesh as THREE.Mesh,
            modelIndex: key,
          });
        }
      }
      _setAssets(newList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uPlayable.playableWindow]);

  const prepareGameObjectFromThree = (mpObj: any, threeObj: any) => {
    if (!mpObj.instanceID) {
      mpObj.instanceID = uuid();
      mpObj.name = threeObj?.name;
    }
    mpObj.components = [
      {
        instanceID: uuid(),
        type: 'Transform',
        localPosition: {
          x: threeObj.position.x ?? 0,
          y: threeObj.position.x ?? 0,
          z: threeObj.position.x ?? 0,
          type: 'Vector3',
        },
      },
    ];
    if (threeObj.type === 'Mesh') {
      mpObj.components.push(
        {
          instanceID: uuid(),
          type: 'MeshFilter',
          sharedMesh_Infos: { type: 'Mesh', ...uPlayable.addModelMeshToJson(threeObj) },
        },
        {
          instanceID: uuid(),
          type: 'MeshRenderer',
          sharedMaterials: [...uPlayable.addModelMaterialsToJson(threeObj)],
        },
      );
    }
    if (threeObj?.children?.length) {
      let currIdx: number = 0;

      mpObj.children = [];
      threeObj?.children?.forEach((currThreeObj: any) => {
        if (['Group', 'Mesh'].includes(currThreeObj?.type as string)) {
          mpObj.children.push({});
          prepareGameObjectFromThree(mpObj.children[currIdx], currThreeObj);
          currIdx += 1;
        }
      });
    }
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];

    try {
      if (file && file?.name?.substring(file.name.length - 4) === '.fbx') {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          try {
            const fbxContent: ArrayBuffer = e.target.result;
            const fbxLoader = new FBXLoader();
            const threeObj = fbxLoader.parse(fbxContent, '');
            const mpObj = { name: file?.name.slice(0, -4), instanceID: uuid() };

            prepareGameObjectFromThree(mpObj, threeObj);

            uPlayable.addGameObjectToJson(mpObj);
            const newGameObjectRuntime = uPlayable.playableClasses?.GameObject?.MP_GenerateGameObjectViaJson(mpObj);
          } catch (error) {
            console.error('FBX loading:', error);
          }
        };
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        reader.readAsArrayBuffer(file);
      }
    } catch (error) {
      console.error('FBX file reading:', error);
    }
  };

  return (
    <div className="t-lib">
      <div className="d-flex gap-2">
        {
          assets.map((bg, index) => (
            <div key={index} onClick={() => { _setSelectedMesh(index); }}>
              <LittleScene assets={bg} selected={index === selectedMesh} />
            </div>
          ))
        }
        {/* <input type="file" onChange={handleFileChange} /> */}
      </div>
      <Flex gap="small" wrap="wrap" className="mt-2" justify="right">
        <Button onClick={() => { props.onCancel(); }}>Cancel</Button>
        <Button
          type="primary"
          onClick={() => {
            if (selectedMesh !== null) {
              props.onValidate(assets[selectedMesh]);
            }
          }}
        >Validate
        </Button>
      </Flex>
    </div>
  );
}

export function LittleScene(props: {
  assets: { name: string, mesh: THREE.Mesh }
  selected: boolean
}) {
  const uPlayable = usePlayable();

  const [PLAYABLE_THREE, _SET_PLAYABLE_THREE] = useState<typeof THREE | null>(null);

  const sceneContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (uPlayable.playableWindow) {
      _SET_PLAYABLE_THREE(uPlayable.playableThree.THREE as typeof THREE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uPlayable.playableWindow]);

  useEffect(() => {
    if (sceneContainer && uPlayable.playableWindow && PLAYABLE_THREE) {
      if (sceneContainer.current?.firstChild) {
        sceneContainer.current!.removeChild(sceneContainer.current.firstChild);
      }
      const scene = new PLAYABLE_THREE.Scene();
      const camera = new PLAYABLE_THREE.PerspectiveCamera(75, 200 / 200, 0.1, 1000);
      const renderer = new PLAYABLE_THREE.WebGLRenderer();
      renderer.setSize(200, 200);
      renderer.setClearColor(0x404040, 1);
      sceneContainer.current!.appendChild(renderer.domElement);
      // @ts-ignore
      const bufferGeometry = props.assets.mesh.mp_bGeometry;

      const geometriesContainer = new PLAYABLE_THREE.Object3D();
      const material = new PLAYABLE_THREE.MeshStandardMaterial({ color: 0x828282 });

      const mesh = new THREE.Mesh(bufferGeometry, material);
      mesh.castShadow = true;
      mesh.receiveShadow = true;
      geometriesContainer.add(mesh);
      const boundingBox = new THREE.Box3().setFromObject(geometriesContainer);
      const center = new THREE.Vector3();
      boundingBox.getCenter(center);
      const maxDimension = Math.max(boundingBox.max.x - boundingBox.min.x, boundingBox.max.y - boundingBox.min.y, boundingBox.max.z - boundingBox.min.z);
      const desiredSize = 1;
      const scaleFactor = desiredSize / maxDimension;
      geometriesContainer.scale.set(scaleFactor, scaleFactor, scaleFactor);
      scene.add(geometriesContainer);

      // Create a wireframe geometry based on the cube's geometry
      const wireframeGeometry = new PLAYABLE_THREE.WireframeGeometry(bufferGeometry);
      // Create a material for the wireframe
      const wireframeMaterial = new PLAYABLE_THREE.LineBasicMaterial({ color: 0x000000, linewidth: 2 });
      // Create a wireframe mesh
      const wireframe = new PLAYABLE_THREE.LineSegments(wireframeGeometry, wireframeMaterial);
      geometriesContainer.add(wireframe);

      const ambientLight = new PLAYABLE_THREE.AmbientLight(0xffffff, 1);
      scene.add(ambientLight);

      const directionalLight = new PLAYABLE_THREE.DirectionalLight(0xffffff, 0.8);
      directionalLight.castShadow = true;
      directionalLight.position.copy(camera.position);

      scene.add(directionalLight);
      camera.position.z = 1;
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.target.set(0, 0, 0);

      const animate = () => {
        requestAnimationFrame(animate);
        directionalLight.position.copy(camera.position);
        renderer.render(scene, camera);
      };
      animate();

      return () => {
        renderer.dispose();
      };
    }
    return () => { };
  }, [uPlayable.playableWindow, PLAYABLE_THREE, props.assets]); // Run effect only once on component mount

  return (
    <div className={`asset ${props.selected ? 'selected' : ''}`}>
      <label className="name">{props.assets.name}</label>
      <div className="container-asset-three" ref={sceneContainer} />
    </div>
  );
}
