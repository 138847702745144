/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Button } from 'antd';
import { useState } from 'react';

import { useAuths } from '../../../../../contexts';
import { usePlayable } from '../../../context/playable.context';
import { engineLogsService } from '../../../../../services';

import './export.scss';

export function Export() {
  const uPlayable = usePlayable();
  const uAuths = useAuths();

  const [loading, _setLoading] = useState(false);

  const onExport = () => {
    _setLoading(true);
    uPlayable.tryPlayable();
    _setLoading(false);
  };

  const onDownload = () => {
    _setLoading(true);
    uPlayable.downloadPlayable();
    _setLoading(false);
  };

  async function downloadPlayableZip(id: number) {
    const response = await engineLogsService.downloadDataFromPlayable(id);

    const link = document.createElement('a');
    link.href = response.url;
    link.setAttribute('download', 'downloaded.zip');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    if (response.script) {
      const element = document.createElement('a');
      const file = new Blob([response.script], { type: 'text/plain' });
      element.href = URL.createObjectURL(file);
      element.download = 'script.ts';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  }

  const buttonsMap: { label: string, onClick: () => void, isSuperAdmin: boolean, disabled?: boolean }[] = [
    { label: 'Try', onClick: () => onExport(), isSuperAdmin: false },
    { label: 'Download Playable', onClick: () => onDownload(), isSuperAdmin: false },
  ];
  if (uAuths.isSuperAdmin()) {
    buttonsMap.push({ label: 'D.D.E', onClick: () => uPlayable.exportJson(), isSuperAdmin: true });
    buttonsMap.push({
      label: 'D.ZIP',
      onClick: () => { downloadPlayableZip(uPlayable.playable.id); },
      isSuperAdmin: true,
      disabled: !uPlayable.playable.playableDataId,
    });
  }

  return (
    <div className="export">
      {buttonsMap?.filter(Boolean).map(({
        onClick, label, isSuperAdmin, disabled,
      }, index) => (
        <Button key={index} type="primary" danger={isSuperAdmin} onClick={onClick} loading={loading} disabled={disabled}>
          {label}
        </Button>
      ))}
    </div>
  );
}
