import {
  Form, Input, Button, Select, Popover, Switch, Skeleton,
} from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import { WarningOutlined } from '@ant-design/icons';

import { applicationsService, storeScraperService } from '../../../../services';
import { MApplication, MDocument, MTag } from '../../../../modeles';
import { MPLibrary } from '../../../../components';

import './application.page.scss';
import { useApplication } from '../../context/application.context';

export function ApplicationPage() {
  const uApplication = useApplication();

  const [isReady, _setIsReady] = useState<boolean>(false);
  const [tags, _setTags] = useState<MTag[]>([]);
  const [fontColorVisible, _setFontColorVisible] = useState<{ visible: false }>({ visible: false });
  const [androidWarning, _setAndroidWarning] = useState<boolean>(false);
  const [androidGameFound, _setAndroidGameFound] = useState<any | null>(null);
  const [appleWarning, _setAppleWarning] = useState<boolean>(false);
  const [appleGameFound, _setAppleGameFound] = useState<any | null>(null);

  useEffect(() => {
    _setIsReady(false);
    applicationsService.tags().then((data) => {
      _setTags(data || []);
      _setIsReady(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_setTags, _setIsReady]);

  useEffect(() => {
    _setAppleGameFound(null);
    _setAndroidGameFound(null);
    storeScraperService.playStore(uApplication.app?.playStore as string).then((data) => {
      _setAndroidGameFound(data);
    });
    storeScraperService.appStore(uApplication.app?.appStore as string).then((data) => {
      _setAppleGameFound(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uApplication]);

  const onFinish = (values: any) => {
    const ts = values.tags ? values.tags.map((v: string) => tags.find((t) => t.text === v)) : [];
    const c: MApplication = { ...uApplication.app, ...values, tags: ts };
    uApplication.saveApp(c);
  };

  const handleFontColorVisibleChange = (visible: any) => {
    _setFontColorVisible({ visible });
  };

  function editDefaultColor(value: any) {
    const defaultColor = value;
    uApplication.updateApp({ ...uApplication.app!, defaultColor });
  }

  async function checkAndroidWarning(e: ChangeEvent<HTMLInputElement>) {
    _setAndroidWarning(!e.target.value.includes('play.google.com'));
    _setAndroidGameFound(await storeScraperService.playStore(e.target.value as string));
  }

  async function checkAppleWarning(e: ChangeEvent<HTMLInputElement>) {
    _setAppleWarning(!e.target.value.includes('apps.apple.com'));
    _setAppleGameFound(await storeScraperService.appStore(e.target.value as string));
  }

  return (
    <div id="application" key={uApplication.app?.id}>
      {isReady && uApplication.app
        && (
          <div className="row">
            <div className="col-lg-9">
              <div className="contain-style">
                <Form onFinish={onFinish} layout="vertical">
                  <div className="row pb-4">
                    <div className="col-lg-3 col-sm-5">
                      <div className="icon-content">
                        <MPLibrary
                          type="image"
                          label="Icon"
                          typeName="icon"
                          folder="icon"
                          path="/library/upload-icon"
                          defaultValue={uApplication.app!.Icon}
                          onUpload={(doc: MDocument) => {
                            uApplication.updateApp({ ...uApplication.app!, Icon: doc });
                          }}
                          maxWeight={0.25}
                        />
                      </div>
                    </div>
                    <div className="col-lg-9 col-sm-7">
                      <div className="data-settings">
                        <h3 className="a-title">{uApplication.app!.name}</h3>
                        <div className="row">
                          <Form.Item label="Key" name="key" rules={[{ required: true }]} initialValue={uApplication.app!.key}>
                            <Input type="text" className="ke col-xl-8" disabled />
                          </Form.Item>

                          <Form.Item
                            className="col-lg-9 col-md-8"
                            label="Tags"
                            name="tags"
                            rules={[{ required: false }]}
                            initialValue={uApplication.app!.tags?.map((t) => t.text)}
                          >
                            <Select
                              mode="multiple"
                              style={{ width: '100%' }}
                              placeholder="Tags"
                              fieldNames={{ label: 'text', value: 'text', options: 'options' }}
                              options={tags}
                            />
                          </Form.Item>
                          <div className="option col-lg-3 col-md-4">
                            <label>Default color</label>
                            <Popover
                              content={(
                                <ChromePicker
                                  color={uApplication.app!.defaultColor}
                                  onChange={(e) => editDefaultColor(e.hex)}
                                  disableAlpha
                                />
                              )}
                              trigger="click"
                              open={fontColorVisible.visible}
                              onOpenChange={handleFontColorVisibleChange}
                            >
                              <div className="preview-color-picker" style={{ backgroundColor: uApplication.app!.defaultColor }} />
                            </Popover>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="store-settings">
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Item label="Play store url" name="playStore" initialValue={uApplication.app!.playStore}>
                          <Input
                            type="text"
                            status={androidWarning ? 'warning' : ''}
                            prefix={androidWarning ? <WarningOutlined /> : null}
                            onChange={(e) => { checkAndroidWarning(e); }}
                          />
                        </Form.Item>
                        {
                          androidGameFound
                            ? (
                              <div className="game-found">
                                <img src={androidGameFound.icon} alt="" />
                                <label>{androidGameFound.title}</label>
                              </div>
                            )
                            : (
                              <Skeleton avatar active paragraph={{ rows: 0 }} />
                            )
                        }
                      </div>
                      <div className="col-md-6">
                        <Form.Item label="App store url" name="appStore" initialValue={uApplication.app!.appStore}>
                          <Input
                            type="text"
                            status={appleWarning ? 'warning' : ''}
                            prefix={appleWarning ? <WarningOutlined /> : null}
                            onChange={(e) => { checkAppleWarning(e); }}
                          />
                        </Form.Item>
                        {
                          appleGameFound
                            ? (
                              <div className="game-found">
                                <img src={appleGameFound.icon} alt="" />
                                <label>{appleGameFound.title}</label>
                              </div>
                            )
                            : (
                              <Skeleton avatar active paragraph={{ rows: 0 }} />
                            )
                        }
                      </div>
                      <Form.Item className="col-md-4" label="Update URLs for all iecs" name="forceUpdate" valuePropName="checked" initialValue={false}>
                        <Switch />
                      </Form.Item>
                    </div>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">Save</Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        )}
    </div>
  );
}
