import { Tag } from 'antd';

import { MTag } from '../../modeles';

import './tags.scss';

export function YcTags(props:{
  tags?: MTag[]
}) {
  return (
    <div className="yc-tags">
      {props.tags?.map((tag, i) => (
        <Tag
          key={i}
          style={{
            marginRight: 3, color: tag.color, border: `1px solid ${tag.color}`, backgroundColor: `${tag.color}15`,
          }}
        >
          {tag.text}
        </Tag>
      ))}
    </div>
  );
}
