import { CSSProperties } from 'react';
import { MCompAnimation } from '../../../../../modeles';

export const PreviewCompAnimation_CSS = `
/* ANIMATION */
.preview-comp-animation {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
@keyframes animation-pulse {
  from {
    transform: scale(calc(1 + (var(--animScaleStart) - 1) * var(--animIntensity)));
  }
  to {
    transform: scale(calc(1 + (var(--animScaleEnd) - 1) * var(--animIntensity)));
  }
}
@keyframes animation-hithere {
  30% {
    transform: var(--animation-hithere-animScaleStart);
  }
  40%, 60% {
    transform: rotate(calc(var(--animRotateStart) * var(--animIntensity))) scale(calc(1 + (var(--animScaleStart) - 1) * var(--animIntensity)));
  }
  50% {
    transform: rotate(calc(var(--animRotateEnd) * var(--animIntensity))) scale(calc(1 + (var(--animScaleStart) - 1) * var(--animIntensity)));
  }
  70% {
    transform: rotate(0deg) scale(calc(1 + (var(--animScaleStart) - 1) * var(--animIntensity)));
  }
  100% {
    transform: scale(calc(1 + (var(--animScaleEnd) - 1) * var(--animIntensity)));
  }
}
@keyframes animation-bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(calc(var(--animTranslateY) * var(--animIntensity)));
  }
  60% {
    transform: translateY(calc(var(--animTranslateY) / 2 * var(--animIntensity)));
  }
}
@keyframes animation-spine {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@keyframes animation-circular {
  0%{
    transform:rotate(0deg)
    translate(-165px)
    rotate(0deg);
  
  }
  100%{
    transform:rotate(360deg)
    translate(-165px)
    rotate(-360deg);
  }
}
@keyframes animation-to-left {
  0% {
    transform: translateX(0);
  }
  
  50% {
    transform: translateX(100%);
  }
  
  100% {
    transform: translateX(0);
  }
}
@keyframes animation-to-right {
  0% {
    transform: translateX(0);
  }
  
  50% {
    transform: translateX(-100px);
  }
  
  100% {
    transform: translateX(0);
  }
}
`;

export function PreviewCompAnimation(props: {
  animation?: MCompAnimation,
  children: React.ReactNode,
}) {
  const getStyle = () => {
    if (props.animation) {
      const style: any = {
        '--animScaleStart': props.animation!.scaleStart,
        '--animScaleEnd': props.animation!.scaleEnd,
        '--animRotateStart': `${props.animation!.rotateStart}deg`,
        '--animRotateEnd': `${props.animation!.rotateEnd}deg`,
        '--animTranslateY': `${props.animation!.translateY}px`,
        '--animIntensity': props.animation!.intensity,
      };
      switch (props.animation.animation) {
        case 'pulse':
          style.animation = `animation-pulse ${props.animation.duration}s infinite ${props.animation.timingFunction} alternate`;
          break;
        case 'hithere':
          style.animation = `animation-hithere ${props.animation.duration}s infinite ${props.animation.timingFunction}`;
          break;
        case 'bounce':
          style.animation = `animation-bounce ${props.animation.duration}s infinite ${props.animation.timingFunction}`;
          break;
        case 'spine':
          style.animation = `animation-spine ${props.animation.duration}s infinite ${props.animation.timingFunction}`;
          break;
        case 'circular':
          style.animation = `animation-circular ${props.animation.duration}s infinite ${props.animation.timingFunction}`;
          break;
        case 'animation-to-left':
          style.animation = `animation-to-left ${props.animation.duration}s infinite ${props.animation.timingFunction}`;
          break;
        case 'animation-to-right':
          style.animation = `animation-to-right ${props.animation.duration}s infinite ${props.animation.timingFunction}`;
          break;
        default:
          break;
      }
      return style as CSSProperties;
    }
    return {};
  };
  return (
    <>
      {props.animation && (
      <div className="preview-comp-animation" style={getStyle()}>
        {props.children}
      </div>
      )}
      {!props.animation && props.children}
    </>
  );
}
