import {
  Tag, Checkbox,
} from 'antd';

import { MNetwork } from '../../../../modeles';

import './network-card.scss';

export function NetworkCard(props: {
  network: MNetwork,
  selected: boolean,
  onSelect: (network: MNetwork) => void,
}) {
  return (
    <div
      className={`network-card ${props.selected && 'selected'} ${props.network.isActive ? 'active' : 'not-active'}`}
      key={props.network.id}
      onClick={() => {
        if (props.network.isActive) {
          props.onSelect(props.network);
        }
      }}
    >
      {
        props.network.isBeta && !props.network.isDown
        && <Tag className="tag" color="orange">BETA</Tag>
      }
      {
        props.network.isDown
        && <Tag className="tag" color="red">Down</Tag>
      }
      <Checkbox disabled={!props.network.isActive} className="check" checked={props.selected} />
      <img src={props.network.logo} alt={props.network.name} className="logo" />
      {props.network.name}
    </div>
  );
}
