/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/media-has-caption */
import {
 MCreativeVideo, MCreativeBreak, ExportType, MCreativeLoop,
} from '../../../../modeles';
import { PreviewCompBackground } from '../iec';
import { varVideo, varElement } from './functions';

export const ExportVideo_CSS = `
  .preview-video { width: 100%; height: 100%; opacity: 0}
  .preview-video  video { position: absolute; width: 100%; height: 100%; }
  .preview-video-flex { width: 100%; height: 100%; position: relative; display: flex; align-items: center; justify-content: center; }
  .preview-video-contener { z-index: 1; position: relative; flex-shrink: 0; }
`;

export const ExportVideo_JS = `
  function video() {
    return document.getElementById('video-' + __videoId);
  }

  function videoById(id) {
    return document.getElementById(id);
  }

  function displayVideo(id) {

    var videos = document.getElementsByClassName('preview-video');
    for (let video of videos) {
      video.style.display = 'none';
    }
    __videoId = id;
    const video = document.getElementById(id);
    video.style.display = 'block';
    video.style.opacity = 1;
    displayParent('');
    displayHover(id)

    videoById('video-' + id).currentTime = 0;
    __lastParent = '';

    // if break start at 0 set time to 0 & wait until the element is create
    if (window[id + '_last'] && window[id + '_last'].breakZeroObj && window[id + '_last'].breakZeroObj !== 'null') {
      var isDisplay = false;
      var inte = setInterval(function() {
        let canAccess = false;
        if(!isDisplay && video && video.style && video.style.display ){
          isDisplay = true;
          try {
            if( isIos() ) {
              videoById('video-' + id).play();
            } else {
              videoById('video-' + id).currentTime = 0.01;
            }
            videoById('video-' + id).pause();
            displayParent(window[id + '_last'].breakZeroObj);
            window[id + '_last'].nb = 0;
            clearInterval(inte);
          } catch (e) {            
            isDisplay = false;
          }
        }
      }, 1);
    } else {
      videoById('video-' + id).play();
    }
  }

  function displayHover(videoId){
    var hovers = document.getElementsByClassName('hover-video');
    for (let hover of hovers) {
      hover.style.display = 'none';
    }
    if(videoId) {
      var hoverDisplayed = 'hover-' + videoId;
      if (document.getElementsByClassName(hoverDisplayed)[0]){
        document.getElementsByClassName(hoverDisplayed)[0].style.display = 'block';
      } else {
        var isHoverDisplay = false;
        var hoverInte = setInterval(function() {
          if(!isHoverDisplay && document.getElementsByClassName(hoverDisplayed)[0]
          && document.getElementsByClassName(hoverDisplayed)[0].style
          && document.getElementsByClassName(hoverDisplayed)[0].style.display ){
            isHoverDisplay = true;
            try {
              document.getElementsByClassName(hoverDisplayed)[0].style.display = 'block'
              clearInterval(hoverInte);
            } catch (e) {            
              isHoverDisplay = false;
            }
            
          }
        }, 1);
      }
    }
  }

  function displayParent(id) {
    var previewBreaks = document.getElementsByClassName('preview-break');
    var previewLoops = document.getElementsByClassName('preview-loop');
    if(__lastParent.includes('loop') && id === 'video-continue'){
      __lastParent = '';
      video().currentTime = +__lastLoopEndTime;
    }
    for (let previewBreak of previewBreaks) {
      previewBreak.style.display = 'none';
    }
    for (let previewLoop of previewLoops) {
      previewLoop.style.display = 'none';
    }
    if (id && id !== 'video-continue') {
      document.getElementById(id).style.display = 'block';
      __lastLoopEndTime = 0;
    }
    if (id === 'endcard') {
      monsterplayable.gameEnd();
    }
    iecResize();
  }

  function unMutedAllVideos() {
    Array.from(document.getElementsByClassName('video')).map((video) => {
      video.muted = eval(video.id.split('-')[1] + '_IsMuted');
    });
  }
`;

export function ExportVideo(props: {
  video: MCreativeVideo,
  base64: { [key:string]: string },
  first: boolean,
  exportType?: ExportType,
}) {
  const getVar = () => {
    return varVideo(props.video);
  };

  const startingBreak = () => {
    const zBr = props.video.breaks.find((br: MCreativeBreak) => br.time === 0);
    return zBr ? varElement(zBr, 'break') : null;
  };

  return (
    <div className="preview-video" id={getVar()}>
      <PreviewCompBackground background={props.video.background} />
      <video
        className="video"
        id={`video-${getVar()}`}
        loop={props.video.loop}
        muted
        autoPlay
        playsInline
        src={props.base64[props.video.video.url]}
      />
      <script dangerouslySetInnerHTML={{
        __html: `
          window.${getVar()}_last = { breakZeroObj: '${startingBreak()}', nb: -1 }
          const ${getVar()} = document.getElementById('video-${getVar()}');
          const video${props.video._key}_IsMuted = ${props.video.muted}

          ${!props.first ? `videoById(${getVar()}.id).pause();` : ''}
          ${props.first ? `displayVideo("${getVar()}");` : ''}

          setInterval(function () {
            if (${getVar()}.currentTime > 0.01) {
              ${props.video.breaks.map((br: MCreativeBreak) => `
                if (${getVar()}_last.nb < ${br.time}
                && ${getVar()}.currentTime >= ${br.time}
                && __lastParent !== '${varElement(br, 'break')}') {
                  __breakFlag = true;
                  __lastParent = '${varElement(br, 'break')}';
                  displayParent('${varElement(br, 'break')}');
                  ${getVar()}.pause();
                  if(${getVar()}.currentTime < ${br.time}){
                    ${getVar()}.currentTime = ${br.time} + 0.01;
                  }
                }
              `).join(' ')}
              ${props.video.loops.map((loop: MCreativeLoop) => `
                if(${getVar()}.currentTime >= ${loop.timeStart}
                && ${getVar()}.currentTime < ${loop.timeEnd}
                && document.getElementById(__videoId).id === "${getVar()}"
                && __lastParent !== '${varElement(loop, 'loop')}') {
                  displayParent('${varElement(loop, 'loop')}');
                  __lastParent = '${varElement(loop, 'loop')}';
                  __lastLoopEndTime = ${loop.timeEnd};
                }
                if (${getVar()}.currentTime >= ${loop.timeEnd} && __lastParent === '${varElement(loop, 'loop')}') {
                  ${getVar()}.currentTime = ${loop.timeStart};
                }
              `).join(' ')}
              ${getVar()}_last.nb = ${getVar()}.currentTime;
            }
          }, 1000/60);
          ${!props.video.loop
            ? `${getVar()}.onended = (event) => {
              setTimeout(() => {
                if (!__breakFlag) {
                  if(document.getElementById(__videoId).id === "${getVar()}") {
                  ${props.video.endVideoAction === 'endcard-display'
                  ? `
                   displayParent("endcard");
                   ${props.video.displayEndCardRedirect ? 'monsterplayable.install();' : ''}
                  ` : ''}
                  ${props.video.endVideoAction === 'video-change'
                    ? `
                      displayVideo('video${props.video.nextVideoKey}');
                      displayHover('video${props.video.nextVideoKey}');
                    `
                  : ''}
                  ${props.video.endVideoAction === 'endcard-display' && props.exportType !== 'Export'
                  ? "newLog('Event: video-end to endcard');" : ''}
                  ${props.video.endVideoAction === 'video-change' && props.exportType !== 'Export'
                    ? "newLog('Event: video-end to next video');" : ''}
                  }
                }
              }, "200");
            };`
            : ''
          }
          


          function _${props.video._key}() {
            const elem = document.getElementById('${getVar()}');
            elem.style.scale = getZoom();
          }

          _${props.video._key}();
          __onResize.push(() => {
            _${props.video._key}();
          });
        `,
      }}
      />
    </div>
  );
}
