/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  Button, Popover, QRCode, Spin,
} from 'antd';
import * as ReactDOMServer from 'react-dom/server';
import { renderToString } from 'react-dom/server';
import { useState } from 'react';
import { QrcodeOutlined } from '@ant-design/icons';

import { ExportType, MCreative } from '../../../../modeles';
import { creativesService } from '../../../../services';
import { FilesTools } from '../../../../tools';
import { useIec } from '../../context/iec.context';
import { useIecSettings } from '../../context/iec-settings.context';
import { getFontsUsed, getImagesUrl } from '../../utils/iec.utils';
import { EmptyExport } from '../export-network';
import { ExportIec } from '../exports/export-iec';
import { useCreative } from '../../context/creative.context';

import './export.scss';

export async function ExportHtml(iec: MCreative, exportType: ExportType, language: string) {
  const base64 : { [key:string]: string } = {};
  await Promise.all(iec.iecData.videos.map(async (video) => {
    const resp = await creativesService.urlPublic(video.video.url);
    if (resp) {
      const value = await FilesTools.getBase64FromUrl(resp);
      if (value) {
        base64[video.video.url] = value;
      }
    }
  }));
  await Promise.all(getImagesUrl(iec).map(async (imageUrl: string) => {
    const resp = await creativesService.urlPublic(imageUrl);
    if (resp) {
      const value = await FilesTools.getBase64FromUrl(resp);
      if (value) {
        base64[imageUrl] = value;
      }
    }
  }));
  return renderToString(<ExportIec exportType={exportType} iec={iec} base64={base64} language={language} />);
}

export function Export() {
  const uIec = useIec();
  const uCreative = useCreative();
  const uIecSettings = useIecSettings();

  const [loading, _setLoading] = useState(false);
  const [qrCodeUrl, _setQrCodeUrl] = useState<string>('');

  const generateContent = async (exportType: ExportType, language: string): Promise<Blob> => {
    const html = await ExportHtml(uIec.iec, exportType, language);
    const fontsName = getFontsUsed(uIec.iec);
    const fonts = await FilesTools.setBase64Fonts(
      fontsName.map((fontName) => uIecSettings.fonts.find((f) => f.name.toLocaleLowerCase() === fontName.toLocaleLowerCase())!),
    );
    const file = new Blob([
      `<!DOCTYPE html>${ReactDOMServer.renderToString(<EmptyExport iec={uIec.iec} fonts={fonts} appInfo={{}} script="" body={html} />)}`,
    ], {
      type: 'text/html',
    });
    return file as Blob;
  };

  const onTry = async () => {
    _setLoading(true);
    const element = document.createElement('a');
    element.href = URL.createObjectURL(await generateContent('Try', uCreative.currentLanguage) as Blob);
    element.target = '_blank';
    document.body.appendChild(element);
    element.click();
    setTimeout(() => {
      document.body.removeChild(element);
    }, 0);
    _setLoading(false);
  };

  const onDownload = async () => {
    _setLoading(true);
    const element = document.createElement('a');
    element.href = URL.createObjectURL(await generateContent('Download', uCreative.currentLanguage) as Blob);
    element.download = 'TEST';
    element.click();
    _setLoading(false);
  };

  const displayQrCode = async () => {
    const iec = await generateContent('Download', uCreative.currentLanguage) as Blob;
    const formData = new FormData();
    formData.append('iec', iec, 'iec.html');
    _setQrCodeUrl(await creativesService.generateQrCode(formData));
  };

  const content = (
    <Spin spinning={!qrCodeUrl}>
      <QRCode
        errorLevel="M"
        value={qrCodeUrl}
      />
    </Spin>
  );

  return (
    <div className="export">
      <Button
        type="primary"
        onClick={() => { onTry(); }}
        loading={loading}
      >
        Try
      </Button>
      <Button
        type="primary"
        onClick={() => { onDownload(); }}
        loading={loading}
      >
        Download preview
      </Button>
      <Popover content={content} title="Scan your IEC!" trigger="click">
        <Button
          type="primary"
          onClick={() => { displayQrCode(); }}
          loading={loading}
          icon={<QrcodeOutlined />}
        >
          QRcode
        </Button>
      </Popover>
    </div>
  );
}
