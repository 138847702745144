import { Modal, Button } from 'antd';
import { useState } from 'react';

import { useIec } from '../../../../context/iec.context';
import { MCompTemplatedBackground } from '../../../../../../modeles';
import { YcCard } from '../../../../../../components';
import { IcoStyle } from '../../../../../../assets/images/icons';
import { IcoGame, IcoGameB, IcoGameC } from '../../../../../../assets/images/button-templates-icons/index';

import './setting-comp-templated-background.scss';

const changeButtonStyleData = [
  { mainClassName: 'bouton-1', icon: IcoGame },
  { mainClassName: 'bouton-2' },
  { mainClassName: 'bouton-3' },
  { mainClassName: 'bouton-4' },
  { mainClassName: 'bouton-5', icon: IcoGame },
  { mainClassName: 'bouton-6' },
  { mainClassName: 'bouton-7' },
  { mainClassName: 'bouton-8' },

  { mainClassName: 'bouton-9' },
  { mainClassName: 'bouton-10' },
  { mainClassName: 'bouton-11' },
  { mainClassName: 'bouton-12' },
  { mainClassName: 'bouton-13', icon: IcoGame },
  { mainClassName: 'bouton-14' },
  { mainClassName: 'bouton-15' },
  { mainClassName: 'bouton-16' },

  { mainClassName: 'bouton-17' },
  { mainClassName: 'bouton-18' },
  { mainClassName: 'bouton-19' },
  { mainClassName: 'bouton-20' },
  { mainClassName: 'bouton-21', icon: IcoGameB },
  { mainClassName: 'bouton-22', icon: IcoGameC },
  { mainClassName: 'bouton-23' },
  { mainClassName: 'bouton-24' },

  { mainClassName: 'bouton-25', icon: IcoGame },
  { mainClassName: 'bouton-26' },
  { mainClassName: 'bouton-27' },
  { mainClassName: 'bouton-28' },
  { mainClassName: 'bouton-29' },
  { mainClassName: 'bouton-30' },
  { mainClassName: 'bouton-31' },
  { mainClassName: 'bouton-32' },

  { mainClassName: 'bouton-33' },
  { mainClassName: 'bouton-34' },
  { mainClassName: 'bouton-35' },
  { mainClassName: 'bouton-36' },
  { mainClassName: 'bouton-37' },
  { mainClassName: 'bouton-38' },
  { mainClassName: 'bouton-39' },
  { mainClassName: 'bouton-40' },
];

export function SettingCompTemplatedBackground(props: {
  templatedBackground: MCompTemplatedBackground,
}) {
  const uIec = useIec();

  const [isButtonStyleModal, _setIsButtonStyleModal] = useState(false);

  const handleChangeTemplatedBackground = (nb: number) => {
    (props.templatedBackground as any).templateNb = nb;
    uIec.refreshIec(true);
    _setIsButtonStyleModal(false);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function ChangeButtonStyleBtn() {
    return (
      <Button
        icon={(<img src={IcoStyle} alt="style-icon" />)}
        size="small"
        onClick={() => _setIsButtonStyleModal(true)}
      >
        Pick a template
      </Button>
    );
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function ChangeButtonStyleTemplate({ mainClassName, icon, onClick }: { mainClassName: string, icon: string | undefined, onClick: any }) {
    const aTagProps = { className: 'bt' };

    return (
      <div className={mainClassName} onClick={onClick}>
        <div className="shd">
          <a {...aTagProps}>
            {/* {icon && <img className="picto-style" src={icon} alt="icon" />} */}
            <span>Play now !</span>
          </a>
        </div>
      </div>
    );
  }

  return (
    <YcCard
      title="Templated Background"
      light
      open={props.templatedBackground._open}
      onClickTitle={(val) => {
        props.templatedBackground._open = val;
        uIec.refreshIec(true);
      }}
      onDelete={() => uIec.removeComponentNodeEdite(props.templatedBackground)}
    >
      <Modal
        title="Button template"
        width="75%"
        open={isButtonStyleModal}
        footer={null}
        onCancel={() => _setIsButtonStyleModal(false)}
      >
        <div className="templated-btns-modal">
          {changeButtonStyleData?.map(({ mainClassName, icon }, i: number) => (
            <ChangeButtonStyleTemplate
              key={i}
              mainClassName={mainClassName}
              icon={icon}
              onClick={() => handleChangeTemplatedBackground(i + 1)}
            />
          ))}
        </div>
      </Modal>
      <div className="setting-comp-templated-background">
        <div className="row mb-3">
          <div className="col-12">
            <ChangeButtonStyleBtn />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            Ce composant rend inutilisable certains autres
          </div>
        </div>
      </div>
    </YcCard>
  );
}
