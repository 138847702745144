import { IPermission } from '../modeles';
import { BaseService } from './base.service';

class PermissionService extends BaseService {
  PATH = 'permissions/';

  async allPermissions(): Promise<IPermission | null> {
    return this._view(`${this.PATH}all-permissions/`);
  }

  async customerPermissions(): Promise<IPermission | null> {
    return this._view(`${this.PATH}customer-permissions/`);
  }
}

export const permissionService = new PermissionService();
