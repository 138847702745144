import { useState } from 'react';
import {
  LineChart as ReLineChart, XAxis, YAxis, CartesianGrid, Legend, Tooltip as ChartTooltip, ResponsiveContainer, Line,
} from 'recharts';

import { COLORS } from '../../enums/colors.e';

import './line-chart.scss';

export function LineChart(props: {
  data: any;
  dataKey: string;
  barDataKey: Array<string>;
}) {
  const [opacity, _setOpacity] = useState<any>({});

  const handleMouseEnterOpacity = (o: any) => {
    const { dataKey } = o;
    const newOpacity = opacity;
    if (Object.keys(newOpacity as object).length > 0) {
      props.barDataKey.forEach((element) => {
        newOpacity[element] = 0.5;
      });
    } else {
      Object.keys(newOpacity as object).forEach((element) => {
        newOpacity[element] = 0.5;
      });
    }
    _setOpacity({ ...newOpacity, [dataKey]: 1 });
  };

  const handleMouseLeaveOpacity = (/* o: any */) => {
    const newOpacity = opacity;
    Object.keys(newOpacity as object).forEach((element) => {
      newOpacity[element] = 1;
    });
    _setOpacity({ ...newOpacity });
  };

  return (
    <div className="bar-chart">
      <ResponsiveContainer width="100%" height={300}>
        <ReLineChart
          data={props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <Legend onMouseEnter={handleMouseEnterOpacity} onMouseLeave={handleMouseLeaveOpacity} />
          <XAxis dataKey={props.dataKey} />
          <YAxis />
          <ChartTooltip />
          <Legend />
          {
            props.barDataKey.map((key, i) => <Line dataKey={key} key={i} stroke={COLORS[i]} strokeOpacity={opacity[key]} />)
          }
        </ReLineChart>
      </ResponsiveContainer>
    </div>
  );
}
