import { ReactElement } from 'react';
import {
  APP_WIDTH,
  ComponentType,
  MColor,
  MCompBackground,
  MCompBorder,
  MCompButton,
  MCompFlex,
  MCompImage,
  MCompOverlay,
  MCompText,
  MCompTransform,
  MCreative,
  MCreativeBreak,
  MCreativeLoop,
  MCreativeElementBase,
  MCreativeEndcard,
  MCreativeVideo,
  MNodeBase,
  generateCreativeKey,
  MCreativeHover,
  MCustomTemplate,
  MCompBlur,
} from '../../../modeles';
import { deepCopy } from '../../../tools';
import {
  loopIecNode, loopIecVideosElems, LoopCallbackParams, LoopElem,
} from './iecLoop.utils';
import { customTemplateService } from '../../../services';
import {
  IcoBlur,
  IcoBreak,
  IcoButton,
  IcoEmpty,
  IcoEmptyButton,
  IcoEndCard,
  IcoHorizontalLayout,
  IcoHover,
  IcoImage,
  IcoLoop,
  IcoOverlay,
  IcoPlay,
  IcoText,
  IcoVerticalLayout,
} from '../../../assets/images/icons';

const CreativeElementPriority = [
  'endcard',
  'video',
  'break',
  'loop',
  'layoutHorizontal',
  'layoutVertical',
  'button',
  'emptyButton',
  'overlay',
  'blur',
  'image',
  'label',
  'empty',
  'hover',
] as const;
export type CreativeElementType = typeof CreativeElementPriority[number];

const CAN_INCLUDES = (node: MNodeBase, replace: boolean = false): CreativeElementType[] => {
  const type = getTypeElement(node);
  if (type === 'endcard' && replace === true) {
    return ['endcard'];
  }
  if (type === 'video') {
    return ['break', 'hover', 'loop'];
  }
  return ['empty', 'label', 'image', 'emptyButton', 'button', 'overlay', 'blur', 'layoutHorizontal', 'layoutVertical'];
};

const getComponent = (node: MNodeBase, type: ComponentType) => {
  return node._type === 'element' ? (node as MCreativeElementBase).components.find((c) => c._type === type) : null;
};

const IecElementEditor = {
  video: {
    type: 'video',
    name: 'Video',
    icon: () => <img src={IcoPlay} alt="Icon Play" className="yc-icon" />,
    check: (node: MNodeBase) => {
      return node._type === 'video';
    },
    create: () => {
      return new MCreativeVideo();
    },
  },
  break: {
    type: 'break',
    name: 'Break',
    icon: () => <img src={IcoBreak} alt="Icon Break" className="yc-icon" />,
    check: (node: MNodeBase) => {
      return node._type === 'break';
    },
    create: () => {
      return new MCreativeBreak();
    },
  },
  loop: {
    type: 'loop',
    name: 'Loop',
    icon: () => <img src={IcoLoop} alt="Icon Loop" className="yc-icon" />,
    check: (node: MNodeBase) => {
      return node._type === 'loop';
    },
    create: () => {
      return new MCreativeLoop();
    },
  },
  endcard: {
    type: 'endcard',
    name: 'Endcard',
    icon: () => <img src={IcoEndCard} alt="Icon Endcard" className="yc-icon" />,
    check: (node: MNodeBase) => {
      return node._type === 'endcard';
    },
    create: () => {
      return new MCreativeEndcard();
    },
  },
  hover: {
    type: 'hover',
    name: 'Hover',
    icon: () => <img src={IcoHover} alt="Icon Hover" className="yc-icon" />,
    check: (node: MNodeBase) => {
      return node._type === 'hover';
    },
    create: () => {
      return new MCreativeHover();
    },
  },
  empty: {
    type: 'empty',
    name: 'Empty',
    icon: () => <img src={IcoEmpty} alt="Icon Empty" className="yc-icon" />,
    check: (node: MNodeBase) => {
      return node._type === 'element';
    },
    create: () => {
      const elem = new MCreativeElementBase();
      return elem;
    },
  },
  layoutVertical: {
    type: 'layoutVertical',
    name: 'Vertical Layout',
    icon: () => <img src={IcoVerticalLayout} alt="Icon vertical layout" className="yc-icon" />,
    check: (node: MNodeBase) => {
      return (getComponent(node, 'flex') as MCompFlex)?.flexDirection === 'row';
    },
    create: () => {
      const elem = new MCreativeElementBase();
      elem.components.push(new MCompFlex('row'));
      return elem;
    },
  },
  layoutHorizontal: {
    type: 'layoutHorizontal',
    name: 'Horizontal Layout',
    icon: () => <img src={IcoHorizontalLayout} alt="Icon Horizontal layout" className="yc-icon" />,
    check: (node: MNodeBase) => {
      return (getComponent(node, 'flex') as MCompFlex)?.flexDirection === 'column';
    },
    create: () => {
      const elem = new MCreativeElementBase();
      elem.components.push(new MCompFlex());
      return elem;
    },
  },
  label: {
    type: 'label',
    name: 'Label',
    icon: () => <img src={IcoText} alt="Icon Label" className="yc-icon" />,
    check: (node: MNodeBase) => {
      return getComponent(node, 'text');
    },
    create: () => {
      const elem = new MCreativeElementBase();
      elem.components.push(new MCompText(), new MCompBackground(new MColor(255, 255, 255, 0.8)));
      elem.transform = new MCompTransform(APP_WIDTH, APP_WIDTH / 6);
      return elem;
    },
  },
  image: {
    type: 'image',
    name: 'Image',
    icon: () => <img src={IcoImage} alt="Icon" className="yc-icon" />,
    check: (node: MNodeBase) => {
      return getComponent(node, 'image');
    },
    create: () => {
      const elem = new MCreativeElementBase();
      elem.components.push(new MCompImage());
      return elem;
    },
  },
  button: {
    type: 'button',
    name: 'Button',
    icon: () => <img src={IcoButton} alt="Icon Button" className="yc-icon" />,
    check: (node: MNodeBase) => {
      return getComponent(node, 'button');
    },
    create: () => {
      const elem = new MCreativeElementBase();
      elem.components.push(
        new MCompButton(),
        new MCompBackground(new MColor(100, 220, 60, 1)),
        new MCompBorder(10, 40, new MColor(0, 0, 0, 0.5)),
        new MCompText('Button', undefined, new MColor(255, 255, 255, 1)),
      );
      elem.transform = new MCompTransform(APP_WIDTH / 2, APP_WIDTH / 5);
      return elem;
    },
  },
  emptyButton: {
    type: 'emptyButton',
    name: 'Empty Button',
    icon: () => <img src={IcoEmptyButton} alt="Icon Empty" className="yc-icon" />,
    check: (node: MNodeBase) => {
      return getComponent(node, 'button');
    },
    create: () => {
      const elem = new MCreativeElementBase();
      elem.components.push(
        new MCompButton(),
      );
      elem.transform = new MCompTransform(APP_WIDTH / 2, APP_WIDTH / 5);
      return elem;
    },
  },
  overlay: {
    type: 'overlay',
    name: 'Overlay',
    icon: () => <img src={IcoOverlay} alt="Icon overlay" className="yc-icon" />,
    check: (node: MNodeBase) => {
      return getComponent(node, 'overlay');
    },
    create: () => {
      const elem = new MCreativeElementBase();
      elem.components.push(new MCompOverlay());
      return elem;
    },
  },
  blur: {
    type: 'blur',
    name: 'Blur',
    icon: () => <img src={IcoBlur} alt="Icon Blur" className="yc-icon" />,
    check: (node: MNodeBase) => {
      return getComponent(node, 'blur');
    },
    create: () => {
      const elem = new MCreativeElementBase();
      elem.components.push(new MCompBlur());
      return elem;
    },
  },
};

function getTypeElement(node: MNodeBase): CreativeElementType {
  return CreativeElementPriority.find((type) => IecElementEditor[type].check(node))!;
}

function getEditorElem(node: MNodeBase) {
  return IecElementEditor[getTypeElement(node)];
}

function getIconByNode(node: MNodeBase): ReactElement {
  const type = getTypeElement(node as MCreativeElementBase);
  return IecElementEditor[type].icon();
}

function getIconByType(type: CreativeElementType): ReactElement {
  return IecElementEditor[type].icon();
}

function createCreativeElement(type: CreativeElementType): MNodeBase {
  return IecElementEditor[type].create();
}

function duplicateNode(node: MNodeBase): MNodeBase {
  const copy = deepCopy<MNodeBase>(node);
  copy._key = generateCreativeKey();

  loopIecNode(copy, (params) => {
    params.node._key = generateCreativeKey();
  });

  return copy;
}

async function saveCustomTemplate(node: MNodeBase, name: string, type: string) {
  const newNode = deepCopy<MNodeBase>(node);
  const newTemplate: MCustomTemplate = {
    ...new MCustomTemplate(),
    name,
    type,
    node: newNode,
  };
  await customTemplateService.add(newTemplate);
}

async function removeCustomTemplate(elementId: number) {
  await customTemplateService.remove(elementId);
}

const getFontsUsed = (iec: MCreative) => {
  const fonts: string[] = [];
  loopIecVideosElems(iec, (params) => {
    // @ts-ignore
    if (params.node.components?.find((component) => component._type === 'text')?.fontPolice) {
      // @ts-ignore
      fonts.push(params.node.components.find((component) => component._type === 'text').fontPolice as string);
    }
  });

  return [...new Set(fonts)].filter((f: string) => f !== 'arial');
};

const getDisplayEndCardActions = (iec: MCreative): any[] => {
  const elements: string[] = [];
  loopIecVideosElems(iec, (params) => {
    // @ts-ignore
    if (params.node.components?.find((component) => component._type === 'button')) {
      // @ts-ignore
      if (params.node.components?.find((component) => component._type === 'button').action === 'endcard-display') {
        // @ts-ignore
        elements.push(params.node._key);
      }
    }
  });
  return elements;
};

const getImagesUrl = (iec: MCreative) => {
  const urls: string[] = [];
  loopIecVideosElems(iec, (params) => {
    // @ts-ignore
    if (params.node.components?.find((component) => component._type === 'image')?.file.url) {
      // @ts-ignore
      urls.push(params.node.components.find((component) => component._type === 'image').file.url as string);
    }
  });
  return [...new Set(urls)];
};

const findElemByFunc = (iec: MCreative, func : (node: LoopElem) => boolean) => {
  const elemFind: LoopCallbackParams[] = [];
  loopIecVideosElems(iec, (params) => {
    if (func(params.node)) {
      elemFind.push(params);
    }
  });
  return elemFind!;
};

const findAllTextByFunc = (iec: MCreative) => {
  const texts: string[] = [];
  loopIecVideosElems(iec, (params) => {
    // @ts-ignore
    if (params.node.components?.find((component) => component._type === 'text')) {
      // @ts-ignore
      texts.push(params.node.components?.find((component) => component._type === 'text').text as string);
    }
  });
  return [...new Set(texts)];
};

const findElem = (iec: MCreative, key: React.Key) => {
  return findElemByFunc(iec, (node) => node._key === key)[0];
};

const getTranslationJson = (iec: MCreative): any[] => {
  let newTranslations: any[] = [];
  const texts = findAllTextByFunc(iec);
  if (iec.translations.length < 1) {
    texts.forEach((text: string) => {
      newTranslations.push({
        DEFAULT: text,
      });
    });
  } else {
    newTranslations = iec.translations;
    texts.forEach((text: string) => {
      if (!newTranslations.find((elem) => elem.DEFAULT === text)) {
        newTranslations.push({
          DEFAULT: text,
        });
      }
    });
  }
  return newTranslations;
};

export {
  IecElementEditor,

  CAN_INCLUDES,
  getComponent,
  getTypeElement,
  getEditorElem,
  getIconByNode,
  getIconByType,
  createCreativeElement,
  duplicateNode,
  saveCustomTemplate,
  removeCustomTemplate,
  getFontsUsed,
  getImagesUrl,
  findElemByFunc,
  findElem,
  getDisplayEndCardActions,
  findAllTextByFunc,
  getTranslationJson,
};
