export class MColor {
  constructor(r?: number, g?: number, b?: number, a?: number) {
    if (r) { this.r = r; }
    if (g) { this.g = g; }
    if (b) { this.b = b; }
    if (a) { this.a = a; }
  }
  r: number = 1;
  g: number = 1;
  b: number = 1;
  a: number = 0;
}
