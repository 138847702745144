import { CloudDownloadOutlined } from '@ant-design/icons';
import {
  Button,
  Spin, Tag, Tooltip,
} from 'antd';
import { ColumnType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { MpTable } from '../../../../components';
import { MCreativeExport, MNetwork } from '../../../../modeles';
import { creativeExportsService } from '../../../../services';
import { getUniqueList } from '../../../../tools';

import './exports.page.scss';

export function ExportsPage() {
  const [loading, _setLoading] = useState(false);
  const [creativesExported, _setCreativesExported] = useState<MCreativeExport[]>([]);

  useEffect(() => {
    _setLoading(true);
    creativeExportsService.listing().then((data) => {
      _setCreativesExported(data || []);
      _setLoading(false);
    });
  }, []);

  async function downloadFile(id: number) {
    const url = await creativeExportsService.downloadFile(id);
    const link = document.createElement('a');
    link.href = url!;
    link.click();
  }

  const columns: ColumnType<MCreativeExport>[] = [
    {
      title: 'Id',
      key: 'id',
      dataIndex: 'id',
      render: (text: string, record: MCreativeExport) => (
        <label>{record.id}</label>
      ),
      sorter: (a: MCreativeExport, b: MCreativeExport) => a.id - b.id,
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (text: string, record: MCreativeExport) => (
        <label>{record.name}</label>
      ),
      sorter: (a: MCreativeExport, b: MCreativeExport) => a.name.localeCompare(b.name || '') || -1,
      filters: getUniqueList(creativesExported, 'name').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MCreativeExport) => record.name === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'Account Id',
      key: 'accountId',
      dataIndex: 'accountId',
      render: (text: string, record: MCreativeExport) => (
        <Link to={`/admin/accounts/account/${record.accountId}`}>
          #
          {record.accountId}
        </Link>
      ),
      sorter: (a: MCreativeExport, b: MCreativeExport) => a.accountId - b.accountId,
      filters: getUniqueList(creativesExported, 'accountId').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MCreativeExport) => record.accountId === (value as number) || false,
      filterSearch: true,
    },
    {
      title: 'Account',
      key: 'account',
      dataIndex: 'account',
      render: (text: string, record: MCreativeExport) => (
        <Link to={`/admin/accounts/account/${record.accountId}`}>
          {record.Account?.name}
        </Link>
      ),
      sorter: (a: MCreativeExport, b: MCreativeExport) => a.Account?.name.localeCompare(b.Account?.name || '') || -1,
      filters: getUniqueList(creativesExported, 'Account.name').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MCreativeExport) => record.Account?.name === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      render: (text: string, record: MCreativeExport) => (
        <label>{record.type}</label>
      ),
      sorter: (a: MCreativeExport, b: MCreativeExport) => a.type.localeCompare(b.type || '') || -1,
      filters: getUniqueList(creativesExported, 'type').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MCreativeExport) => record.type === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'Networks',
      key: 'networks',
      fixed: 'right',
      align: 'center',
      dataIndex: 'networks',
      render: (text: string, record: MCreativeExport) => (
        record.networks.map((network: MNetwork) => <Tag key={network.value}>{network.name}</Tag>)
      ),
    },
    {
      title: 'Date',
      key: 'date',
      fixed: 'right',
      align: 'center',
      dataIndex: 'date',
      render: (text: Date, record: MCreativeExport) => (
        <label>{new Date(record.createdAt!).toLocaleString() }</label>
      ),
      sorter: (a: MCreativeExport, b: MCreativeExport) => a.createdAt?.toString()?.localeCompare(b.createdAt?.toString() || '') || -1,
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      width: '100px',
      align: 'center',
      dataIndex: 'actions',
      render: (text: string, record: MCreativeExport) => (
        <div>
          <Tooltip placement="top" title="Download">
            <Button type="primary" shape="circle" icon={<CloudDownloadOutlined />} size="small" onClick={() => { downloadFile(record.id); }} />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div id="exports">
      <Spin spinning={loading}>
        <MpTable
          bordered
          urlParamsEnabled
          mpDisableTotal
          size="small"
          dataSource={creativesExported}
          columns={columns}
          rowKey="id"
          mpTableKey="exportTable"
        />
      </Spin>
    </div>
  );
}
