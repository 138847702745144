import { useEffect, useState } from 'react';
import { SettingCompInputNumber, SettingCompInputCheckbox } from '../../sub-settings';

import { usePlayable } from '../../../../context/playable.context';
import { YcCard } from '../../../../../../components';
import { ICanvasJson as JsonType } from '../../../../../../modeles/MP_Interfaces';

import './setting-comp-canvas.scss';

export function SettingCompCanvas(props: { initialData: any, parentID: string, icon?: string }) {
  const childType: string = props.initialData.type;
  const childID: string = props.initialData.instanceID;
  const parentID: string = props.parentID;

  const uPlayable = usePlayable();

  const [compTypeIndex, _setCompTypeIndex] = useState<number>();
  const [component, _setComponent] = useState<JsonType>();

  useEffect(() => {
    if (compTypeIndex === undefined) {
      _setCompTypeIndex(uPlayable.getCompTypeIndex(childType, parentID, childID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (props !== undefined && compTypeIndex !== undefined) {
      _setComponent(uPlayable.getRunTimeComponent(`${childType}[${compTypeIndex}]`) as JsonType);
    }
  }, [childType, compTypeIndex, props, uPlayable]);

  const editComponent = (propertyPath: string, value: any, update: boolean = true) => {
    if (compTypeIndex !== undefined) {
      uPlayable.updateGameObject(value, `${childType}[${compTypeIndex}]/${propertyPath}`, childID, update);
    }
  };

  return (
    <YcCard
      title="Canvas"
      icon={props.icon}
      light
      open
      warning={compTypeIndex === undefined}
      titleExtra={(
        <SettingCompInputCheckbox
          checked={component?.enabled ?? true}
          onChange={(e) => editComponent('enabled', e)}
        />
      )}
    >
      <div className="row">
        <div className="col-12">
          <SettingCompInputNumber
            disabled
            label="Sort Order"
            value={component?.sortingOrder ?? 0}
            onChange={(sortingOrder) => editComponent('sortingOrder', sortingOrder)}
          />
        </div>
      </div>
    </YcCard>
  );
}
