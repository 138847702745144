import { MCreative } from '../../../../modeles';
import { ExportIec_CSS, ExportIec_JS } from '../exports/export-iec';
import {
  PreviewBreak_CSS,
  PreviewCompAnimation_CSS,
  PreviewCompBackground_CSS,
  PreviewCompBorder_CSS,
  PreviewCompButton_CSS,
  PreviewCompFlex_CSS,
  PreviewCompImage_CSS,
  PreviewCompText_CSS,
  PreviewElement_CSS,
  PreviewEndcard_CSS,
  PreviewHover_CSS,
  PreviewCompTemplatedBackground_CSS,
} from '../iec';
import { ExportVideo_CSS, ExportVideo_JS } from '../exports/export-video';
import { EventLogs } from './event-logs';
import MolocoIntegration from '../../../../networks/moloco-integration';
import GoogleAdsIntegration from '../../../../networks/googleAds-integration';
import { Events } from '../../../../networks/network-integration';

export type EmptyExportFont = {
  name: string;
  src: string;
};

/* eslint-disable react/no-danger */
export function EmptyExport(props: {
  iec: MCreative,
  script: string,
  body: string,
  fonts: EmptyExportFont[],
  appInfo: any,
  NETWORK?: any,
  scripts?: { type: string; src: string; }[],
  metadatas?: [{ name: string, content: string }],
}) {
  const getId = (): string => {
    let iecId = 0;
    if (props.appInfo && props.appInfo.id) {
      iecId = props.appInfo.id;
    } else if (props.iec && props.iec.id) {
      iecId = props.iec.id;
    }
    return iecId.toString();
  };

  return (
    <>
      <html lang="en">
        <head>
          <>
            {
            props.fonts.map((f) => (
              <style dangerouslySetInnerHTML={{ __html: `@font-face { font-family: '${f.name}'; src: url(${f.src}); }` }} />
            ))
          }
            {
            !props.NETWORK && (
              <EventLogs />
            )
          }
            <style dangerouslySetInnerHTML={{
              __html: `
              body {
                margin: 0;
                user-select: none;
                width: 100vw;
                height: 100vh;
                overflow: hidden;
              }
              .preview-element {
                pointer-events:none;
              }

              ${ExportIec_CSS}
              ${ExportVideo_CSS}
              ${PreviewBreak_CSS}
              ${PreviewEndcard_CSS}
              ${PreviewHover_CSS}
              ${PreviewElement_CSS}

              ${PreviewCompButton_CSS}
              ${PreviewCompImage_CSS}
              ${PreviewCompBackground_CSS}
              ${PreviewCompBorder_CSS}
              ${PreviewCompText_CSS}
              ${PreviewCompImage_CSS}
              ${PreviewCompFlex_CSS}
              ${PreviewCompAnimation_CSS}
              ${PreviewCompTemplatedBackground_CSS}
            `,
            }}
            />
            <script dangerouslySetInnerHTML={{
              __html: `
              var __videoWidth = ${props.iec.iecData.width};
              var __videoHeight = ${props.iec.iecData.height};
              var __onResize = [];
              var __videoId = null;
              var __lastParent = '';
              var __lastLoopEndTime = '';
              var __breakFlag = false;
              var __iecDisplayed = false;
              var __iecEnd = false;

              function getVW() {
                return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
              }
              function getVH() {
                return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
              }
              function getZoom() {
                if (getVW() > getVH()) {
                  return ${props.iec.iecData.zoomLandscape || 1};
                }
                return ${props.iec.iecData.zoomPortrait || 1};
              };
            
              ${ExportIec_JS}
              ${ExportVideo_JS}
            `,
            }}
            />
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, user-scalable=no, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0" />
            {
              props.metadatas?.map((item, index) => (
                <meta key={index} name={item.name} content={item.content} />
              ))
            }
            {
              props.scripts?.map((item, index) => (
                <script key={index} type={item.type} src={item.src} />
              ))
            }
            <script dangerouslySetInnerHTML={{
              __html: `
                var _networkSDK = null;
                var _appInfo = ${JSON.stringify(props.appInfo)};
                var Events = ${JSON.stringify(Events)}

                function isIos() {
                  return /iPhone|iPad|iPod|Macintosh|MacIntel|Mac/i.test(navigator.userAgent);
                }

                function defineStoreUrl() {
                  if (isIos()) {
                    _appInfo.store = {
                      url: '${props.appInfo.appStore}',
                    }
                  } else {
                    _appInfo.store = {
                      url: '${props.appInfo.playStore}',
                    }
                  }
                }

                var monsterplayable = {
                  ready: function() {
                    ${props.NETWORK && props.NETWORK.gameReady.toString().replace(/^[^{]*{\s*/, '').replace(/\s*}[^}]*$/, '')}
                  },
                  install: function() {
                    defineStoreUrl();
                    iecEndApiCall();
                    ${props.NETWORK && props.NETWORK.install.toString().replace(/^[^{]*{\s*/, '').replace(/\s*}[^}]*$/, '')}
                  },
                  gameEnd: function() {
                    ${props.NETWORK && props.NETWORK.gameEnd.toString().replace(/^[^{]*{\s*/, '').replace(/\s*}[^}]*$/, '')}
                  },
                }
                
                let dataToSend = {
                  device: {
                    language: window.navigator.language,
                    languages: window.navigator.languages,
                    platform: window.navigator.platform,
                    userAgent: window.navigator.userAgent,
                  },
                  iec: {
                    iecId: '${props.appInfo.id}',
                    userId: '${props.iec.userId}',
                    creativeType: '${props.iec.type}',
                    accountId: '${props.iec.accountId}',
                    applicationId: '${props.appInfo.applicationId}',
                    network: '${props.NETWORK ? props.NETWORK.name : 'null'}',
                  },
                };

                function iecDisplayApiCall() {
                ${(props.NETWORK
                  && !(props.NETWORK instanceof GoogleAdsIntegration)
                  && !(props.NETWORK instanceof MolocoIntegration))
                ? (`
                if(!__iecDisplayed){
                  let ApiCall = new XMLHttpRequest();
                  ApiCall.open("POST", "${process.env.REACT_APP_API_ANALYTICS_URL}/iec-entries/iec-displayed");
                  ApiCall.setRequestHeader("Accept", "application/json");
                  ApiCall.setRequestHeader("Content-Type", "application/json");
                  ApiCall.send(JSON.stringify(dataToSend));
                  __iecDisplayed = true;
                }
                `) : ('')}
                  ${(!props.NETWORK)
                  ? (`
                    newLog('Event: Iec Displayed');
                  `) : ('')}
                }

                function iecEndApiCall() {
                ${(props.NETWORK
                  && !(props.NETWORK instanceof GoogleAdsIntegration)
                  && !(props.NETWORK instanceof MolocoIntegration))
                    ? (`
                    if(!__iecEnd){
                      let ApiCall = new XMLHttpRequest();
                      ApiCall.open("POST", "${process.env.REACT_APP_API_ANALYTICS_URL}/iec-entries/iec-end");
                      ApiCall.setRequestHeader("Accept", "application/json");
                      ApiCall.setRequestHeader("Content-Type", "application/json");
                      ApiCall.send(JSON.stringify(dataToSend));
                      __iecEnd = true;
                    }
                `) : ('')}
                ${(!props.NETWORK)
                      ? (`
                  newLog('Event: Iec End');
                `) : ('')}
                }

                function clickHandler(event) {
                  unMutedAllVideos();
                  window.removeEventListener('click', clickHandler);
                }
                window.addEventListener('click', clickHandler);
            `,
            }}
            />
            <script dangerouslySetInnerHTML={{ __html: props.script }} />
          </>
        </head>
        <body>
          <div dangerouslySetInnerHTML={{ __html: props.body }} />
          <script dangerouslySetInnerHTML={{ __html: 'monsterplayable.ready()' }} />
        </body>
      </html>
    </>
  );
}
