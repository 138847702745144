import {
  Form, Input, Button, Card, Space,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/pro-regular-svg-icons';
import { useState } from 'react';

import { profileService } from '../../../../services/profile.service';
import { useAuths } from '../../../../contexts';
import { MUser } from '../../../../modeles';

import './profile.page.scss';

export function ProfilePage() {
  const uAuths = useAuths();
  const [isChangingPassword, _setIsChangingPassword] = useState<boolean>(false);
  const [form] = Form.useForm();

  const onUpdatePassword = (data: any) => {
    profileService.updatePassword(data);
  };

  const onUpdateInformation = (data: MUser) => {
    profileService.updateInformations(data);
  };

  return (
    <div className="root-profile">
      <div className="row">
        <div className="col-3" />

        <div className="col-6">
          <Card title="Profile" className="profile">
            <Form onFinish={onUpdateInformation} layout="vertical">
              <div className="row">
                <Form.Item
                  className="col-12"
                  label="Email"
                  name="email"
                  initialValue={uAuths.user?.email}
                >
                  <Input type="text" disabled />
                </Form.Item>
                <Form.Item
                  className="col-6"
                  label="Last name"
                  name="lastName"
                  initialValue={uAuths.user?.lastName}
                >
                  <Input type="text" />
                </Form.Item>
                <Form.Item
                  className="col-6"
                  label="First name"
                  name="firstName"
                  initialValue={uAuths.user?.firstName}
                >
                  <Input type="text" />
                </Form.Item>
              </div>
              <Button
                className="button-save"
                type="primary"
                htmlType="submit"
                icon={<FontAwesomeIcon icon={faSave} />}
              >
                Save Profile
              </Button>
            </Form>
          </Card>

          <Card className="password">
            {
              !isChangingPassword
                ? (
                  <Button
                    type="primary"
                    onClick={() => { _setIsChangingPassword(true); }}
                  >
                    Change password
                  </Button>
                )
                : (
                  <Form onFinish={onUpdatePassword} layout="vertical" form={form}>
                    <Form.Item label="Current Password" name="currentPassword" rules={[{ required: true }]}>
                      <Input.Password type="password" />
                    </Form.Item>
                    <Form.Item label="New Password" name="newPassword" rules={[{ required: true }]}>
                      <Input.Password type="password" />
                    </Form.Item>
                    <Form.Item label="Confirm Password" name="confirmPassword" rules={[{ required: true }]}>
                      <Input.Password type="password" />
                    </Form.Item>
                    <Space direction="horizontal" className="save-password">
                      <Button
                        type="primary"
                        ghost
                        onClick={() => {
                          form.resetFields();
                          _setIsChangingPassword(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button type="primary" htmlType="submit">Change password</Button>
                    </Space>
                  </Form>
                )
            }
          </Card>
        </div>

      </div>
    </div>
  );
}
