import { Input, Select } from 'antd';

import { useIec } from '../../../../context/iec.context';
import { ButtonActionTypeValues, MCompButton } from '../../../../../../modeles';
import { YcCard } from '../../../../../../components';
import { NumberTools } from '../../../../../../tools';
import { IecSelect } from '../../../select';
import { IecSwitch } from '../../../iec-switch';

import './setting-comp-button.scss';

const SelectOption = Select.Option;

export function SettingCompButton(props: {
  button: MCompButton,
}) {
  const uIec = useIec();

  function editValue(param: string, value: any) {
    (props.button as any)[param] = value;
    uIec.refreshIec(true);
  }

  const getActionsValues = () => {
    return ButtonActionTypeValues.filter((value) => value.endcard === !!uIec.getEndcardActive());
  };

  const warning = () => {
    if (props.button.action === 'redirect-url') {
      return 'Link redirection are not allowed for all network or target, don\'t use it for Mobile Application ADS';
    }

    if (!uIec.getEndcardActive() && props.button.action === 'app-download') {
      return (
        <p>
          App download option doesn&apos;t work before endcard for Unity.<br />
          Use endcard display with the redirect instead.
        </p>
      );
    }

    if (!uIec.getEndcardActive() && props.button.action === 'endcard-display') {
      return (
        <p>
          Redirect to store doesn&apos;t work on Unity.<br />
          The end card will be display without redirection on it.
        </p>
      );
    }
    return null;
  };

  return (
    <YcCard
      title="Button"
      light
      open={props.button._open}
      onClickTitle={(val) => {
        props.button._open = val;
        uIec.refreshIec(true);
      }}
      onDelete={() => {
        uIec.removeComponentNodeEdite(props.button);
      }}
      warning={warning()}
    >
      <div className="setting-comp-button">
        <div className="row">
          <div className="col-6">
            <IecSelect label="Action" field="action" obj={props.button} values={getActionsValues()} />
          </div>
          {props.button.action === 'video-change'
            && (
            <div className="col-6">
              <label>Videos</label>
              <Select
                value={props.button.videoKey}
                onChange={(val) => {
                  props.button.videoKey = val;
                  uIec.refreshIec(true);
                }}
              >
                {uIec.iec.iecData.videos.map((video) => {
                  const index = uIec.iec.iecData.videos.findIndex((v) => v === video) + 1;
                  return (
                    <SelectOption key={video._key} value={video._key}>
                      Video[{index}] - {NumberTools.round(video.videoDuration, 2)}s
                    </SelectOption>
                  );
                })}
              </Select>
            </div>
            )}
          {props.button.action === 'endcard-display'
            && (
            <div className="col-6">
              <IecSwitch
                obj={props.button}
                label="Redirect to store"
                field="displayEndCardRedirect"
              />
            </div>
            )}
          {props.button.action === 'app-download'
            && (
            <div className="col-6">
              <IecSwitch
                obj={props.button}
                label="Open endcard"
                field="appDownloadDisplayEndCard"
              />
            </div>
            )}
          {props.button.action === 'redirect-url'
            && (
              <>
                <div className="col-6">
                  <label>URL</label>
                  <Input
                    placeholder="URL"
                    value={props.button.url || ''}
                    onChange={(e) => editValue('url', e.target.value)}
                    size="small"
                  />
                </div>
              </>
            )}
        </div>
      </div>
    </YcCard>
  );
}
