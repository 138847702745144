import { BaseService } from './base.service';

class PerformanceService extends BaseService {
  PATH = 'reporting/performance/';

  async filters(): Promise<any | null> {
    return this._view(`${this.PATH}filters/`);
  }

  async search(data: object): Promise<any | null> {
    return this._view(`${this.PATH}search`, data);
  }
}

export const performanceService = new PerformanceService();
