import { InputNumber } from 'antd';

import { useIec } from '../../context/iec.context';
import { usePlayable } from '../../context/playable.context';

import './iec-input-number.scss';

export function IecInputNumber(props: {
  obj: any,
  field: string,
  label?: string,
  step?: number,
  disabled?: boolean,
  context?: string,
  addonBefore?: React.ReactNode,
}) {
  const uIec = useIec();
  const uPlayable = usePlayable();

  function onChange(value: any) {
    props.obj[props.field] = value;
    if (props.context === 'PLA') {
      uPlayable.refreshPlayable();
    } else {
      uIec.refreshIec(true);
    }
  }

  return (
    <div className="iec-input-number">
      {props.label && <label>{props.label}</label>}
      <InputNumber
        step={props.step}
        value={props.obj[props.field]}
        onChange={(value) => onChange(value)}
        disabled={props.disabled}
        size="small"
        addonBefore={props.addonBefore}
      />
    </div>
  );
}
