/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */

import { INetworkIntegration, Events, EventEmitter } from './network-integration';

/**
 * Documentation: https://www.iab.com/wp-content/uploads/2015/08/IAB_MRAID_v2_FINAL.pdf
 * Mraid is standard interface specification for mobile rich media ads.
 */
interface IMraid {
  isViewable: boolean;
  getState: () => string; // loading, default, expanded, resized, hidden see documentation p20
  getScreenSize: () => ({ width: number, height: number });
  addEventListener: (event: string, callback: () => void) => void;
  removeEventListener: (event: string, callback: () => void) => void;
  open: (url: string) => void; // used to open url in a new window
}

const MraidEvents = {
  ready: 'ready',
  sizeChange: 'sizeChange',
  viewableChange: 'viewableChange',
};

class FakeMraid implements IMraid {
  private _events = new EventEmitter();

  public isViewable = true;

  constructor() {
    window.onresize = () => {
      this._events.emit(MraidEvents.sizeChange);
    };
  }

  getState() { return 'ready'; }

  getScreenSize() { return { width: window.innerWidth, height: window.innerHeight }; }

  addEventListener(event: string, callback: () => void) { return this._events.addListener(event, callback); }

  removeEventListener(e: string, callback: () => void) { return this._events.removeListener(e, callback); }

  open(url: string) { window.open(url, '_blank')?.focus(); }
}

export default class MraidIntegration implements INetworkIntegration {
  _networkSDK: IMraid | null = null;

  _events: EventEmitter = new EventEmitter();

  _appInfo: any = null;

  public get name() { return 'mraid'; }

  public preloader = true;

  public get appInfo(): any { return this._appInfo; }

  public onWindowLoad() {
    try {
      // @ts-ignore
      this._networkSDK = mraid;
      console.warn('mraid.js found', this._networkSDK);
    } catch {
      console.warn('mraid.js not found');
    }

    if (this._networkSDK?.getState() === 'loading') {
      this._networkSDK.addEventListener('ready', this.onReady.bind(this));
    } else {
      this.onReady();
    }
    this._networkSDK?.addEventListener('sizeChange', this.onResize.bind(this));
    this._networkSDK?.addEventListener('viewableChange', this.onViewChange.bind(this));

    this._events.emit('windowLoaded');
  }

  public onResize() {
    this._events.emit('screenResize');
  }

  public onReady() {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this._networkSDK?.removeEventListener('ready', this.onReady);
    this._events.emit('gameStart');
  }

  public onViewChange() {
    if (this._networkSDK?.isViewable) {
      this._events.emit('gamePlay');
    } else {
      this._events.emit('gamePause');
    }
  }

  public onVolumeChange() {
    this._events.emit('soundChange');
  }

  public install() {
    try {
      // @ts-ignore
      mraid.open(_appInfo.store.url);
    } catch {
      // @ts-ignore
      this._networkSDK.open(_appInfo.store.url);
    }
  }

  public gameEnd() {
  }

  public gameReady() {
  }

  public getScreenSize() {
    return { width: window.innerWidth, height: window.innerHeight };
  }

  public getAudioVolume() {
    return 1;
  }

  public sendMsg(msg: string) {
    console.warn(msg);
  }

  addListener(event: string, callback: () => void) {
    this._events.addListener(event, callback);
  }

  removeListener(event: string, callback: () => void) {
    this._events.removeListener(event, callback);
  }

  MP_META = '';
  MP_SCRIPT = '';
}
