import { useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import * as EffectComposer from 'three/examples/jsm/postprocessing/EffectComposer';
import * as RenderPass from 'three/examples/jsm/postprocessing/RenderPass';
import * as ShaderPass from 'three/examples/jsm/postprocessing/ShaderPass';
import * as OutlinePass from 'three/examples/jsm/postprocessing/OutlinePass';
import * as TransformControls from 'three/examples/jsm/controls/TransformControls';

import { usePlayable } from '../../../context/playable.context';
import { findAllParentsId, findElem } from '../../../utils/playable.utils';
import { DebugUI } from './components/debug-ui/debug-ui';
import { IGameObjectJson } from '../../../../../modeles/MP_Interfaces';
import { MPPerspectiveCamera, MPControls } from './components/mp-scene-objects';
import { DebugCamera } from './components/debug-camera';
import { MPLightHelper, MPRemoveLightHelper } from './components/mp-debug-light';
import { DebugParticles } from './components/debug-particles';

import './scene-view.scss';

type Mode = {
  desc: string;
  field: string;
};

type ModesMap = {
  translate: Mode,
  rotate: Mode,
  scale: Mode,
};

const RAD_TO_DEG = 180 / Math.PI;

export function SceneView(props: {
  displayed: boolean;
}) {
  const uPlayable = usePlayable();

  const sceneThreeContainer = useRef<HTMLDivElement>(null);
  const nodeRef = useRef<IGameObjectJson | null>(null);

  const [sourceScene, _setSourceScene] = useState<THREE.Scene | null>(null);
  const [container, _setContainer] = useState<HTMLDivElement | null>(null);

  const [PLAYABLE_THREE, _SET_PLAYABLE_THREE] = useState<typeof THREE | null>(null);
  const [EFFECT_COMPOSER, _SET_EFFECT_COMPOSER] = useState<typeof EffectComposer | null>(null);
  const [RENDER_PASS, _SET_RENDER_PASS] = useState<typeof RenderPass | null>(null);
  const [SHADER_PASS, _SET_SHADER_PASS] = useState<typeof ShaderPass | null>(null);
  const [OUTLINE_PASS, _SET_OUTLINE_PASS] = useState<typeof OutlinePass | null>(null);
  const [TRANSFORM_CONTROLS, _SET_TRANSFORM_CONTROLS] = useState<typeof TransformControls | null>(null);

  const [currentCamera, _setCurrentCamera] = useState<THREE.Camera | null>(null);
  const [currentOutlinePass, _setCurrentOutlinePass] = useState<OutlinePass.OutlinePass | null>(null);
  const [currentTransformControls, _setTransformControls] = useState<TransformControls.TransformControls | null>(null);
  const [currentLightHelper, _setCurrentLightHelper] = useState<THREE.DirectionalLightHelper | THREE.PointLightHelper | THREE.SpotLightHelper | null>(null);

  const [transformControlsSpace, _setTransformControlsSpace] = useState<'world' | 'local'>('world');

  useEffect(() => {
    if (uPlayable.playableWindow && sceneThreeContainer.current) {
      _setContainer(sceneThreeContainer.current);
      _SET_PLAYABLE_THREE(uPlayable.playableThree.THREE as typeof THREE);
      _SET_EFFECT_COMPOSER(uPlayable.playableThree.EFFECT_COMPOSER as typeof EffectComposer);
      _SET_RENDER_PASS(uPlayable.playableThree.RENDER_PASS as typeof RenderPass);
      _SET_SHADER_PASS(uPlayable.playableThree.SHADER_PASS as typeof ShaderPass);
      _SET_OUTLINE_PASS(uPlayable.playableThree.OUTLINE_PASS as typeof OutlinePass);
      _SET_TRANSFORM_CONTROLS(uPlayable.playableThree.TRANSFORM_CONTROLS as typeof TransformControls);
      _setSourceScene(uPlayable.playableClasses?.MP_GameManager?.scene as THREE.Scene);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sceneThreeContainer, uPlayable.playableWindow, uPlayable.playableClasses]);

  useEffect(() => {
    if (!sourceScene || !currentTransformControls || !currentOutlinePass) { return; }

    if (uPlayable.nodeEdit) {
      nodeRef.current = uPlayable.nodeEdit;
    } else {
      nodeRef.current = null;
      currentTransformControls.detach();
      MPRemoveLightHelper(currentLightHelper, sourceScene);
      _setCurrentLightHelper(null);
      currentOutlinePass.selectedObjects = [];
    }

    sourceScene?.traverse((elem) => {
      if (elem.type === 'BoxHelper') {
        elem.parent?.remove(elem);
      }
      MPRemoveLightHelper(currentLightHelper, sourceScene);
      _setCurrentLightHelper(null);
    });

    createOutline();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uPlayable.nodeEdit]);

  useEffect(() => {
    if (currentTransformControls) {
      if (!props.displayed) {
        currentTransformControls.reset();
        currentTransformControls.detach();
        MPRemoveLightHelper(currentLightHelper, sourceScene!);
        _setCurrentLightHelper(null);
        currentOutlinePass!.selectedObjects = [];
      } else {
        createOutline();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.displayed]);

  const mousePosition = (mouse: THREE.Vector2, event: MouseEvent) => {
    if (container) {
      // @ts-ignore
      mouse.x = ((event.layerX / container.clientWidth) * 2 - 1);
      // @ts-ignore
      mouse.y = (-(event.layerY / container.clientHeight) * 2 + 1);
    }
  };

  const findGameObjectParent = (obj: THREE.Object3D): THREE.Object3D => {
    // @ts-ignore
    if (obj.gameObject && obj.type !== 'Mesh') {
      return obj;
    }
    return findGameObjectParent(obj.parent!);
  };

  const createOutline = () => {
    sourceScene?.traverse((elem) => {
      // @ts-ignore
      if (elem.gameObject?.instanceID !== undefined && elem.gameObject?.instanceID === uPlayable.nodeEdit?.instanceID) {
        if (props.displayed
          && !(elem.parent instanceof TransformControls.TransformControls || elem.parent?.parent instanceof TransformControls.TransformControlsGizmo)) {
          if (elem.type === 'Mesh' && elem.parent) {
            currentTransformControls!.attach(elem.parent);
            currentOutlinePass!.selectedObjects = [elem];
          } else {
            currentTransformControls!.attach(elem);
            currentOutlinePass!.selectedObjects = [elem];
          }
          // @ts-ignore
          if (elem.gameObject?.mp_allComponents.find((comp) => comp.mp_type === 'Light')) {
            // @ts-ignore
            const lightType = elem.gameObject?.mp_allComponents.find((comp) => comp.mp_type === 'Light').mp_threeLight as THREE.Light;
            const lightHelper = MPLightHelper(PLAYABLE_THREE!, lightType);
            if (lightHelper) {
              sourceScene.add(lightHelper);
            }
            _setCurrentLightHelper(lightHelper);
          }
        }
      }
    });
  };

  useEffect(() => {
    if (uPlayable.playableJson) {
      let visibleElements: THREE.Object3D[] = [];
      let currentLayersDisplayed: THREE.Layers;
      let transformControls: TransformControls.TransformControls;
      let raycaster: THREE.Raycaster;

      const updateVisibleElements = () => {
        visibleElements = [];
        sourceScene?.traverse((elem) => {
          // @ts-ignore
          if (elem?.layers?.test(currentLayersDisplayed) && elem.gameObject) {
            visibleElements.push(elem);
          }
        });
      };

      const SelectObject = (
        mouse: THREE.Vector2,
        camera: THREE.PerspectiveCamera,
      ) => {
        updateVisibleElements();
        raycaster.setFromCamera(mouse, camera);
        if (raycaster) raycaster.layers = currentLayersDisplayed;
        const intersects = raycaster.intersectObjects(visibleElements);

        if (intersects.length > 0) {
          let intersectedObject = intersects[0].object;
          // @ts-ignore
          if (!intersectedObject.gameObject?.mp_components.Collider.length > 0
            && intersectedObject.parent
            // @ts-ignore
            && intersectedObject.parent.gameObject?.mp_components.Collider.length > 0) {
            intersectedObject = intersectedObject.parent;
          }

          const obj = findGameObjectParent(intersectedObject);
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          const node = findElem(uPlayable.playableJson!, obj.gameObject.instanceID);
          if (node) {
            uPlayable.setNodeEdit(node);
            // @ts-ignore
            uPlayable.setNodeOpened(findAllParentsId(obj.gameObject));
            // @ts-ignore
            nodeRef.current = node;
          }
        }
      };

      const handleFocusObject = (object: THREE.Object3D | null, controls: OrbitControls, camera: THREE.PerspectiveCamera) => {
        if (object) {
          const globalPosition = new THREE.Vector3();
          object.localToWorld(globalPosition);
          camera.position.set(globalPosition.x, globalPosition.y + 3, globalPosition.z + 5);
          controls.target.set(globalPosition.x, globalPosition.y, globalPosition.z);
        } else {
          camera.position.set(0, 20, 15);
          controls.target.set(0, 0, 0);
        }
      };

      if (
        PLAYABLE_THREE && container && sourceScene && EFFECT_COMPOSER && RENDER_PASS
        && SHADER_PASS && OUTLINE_PASS && TRANSFORM_CONTROLS
      ) {
        if (sceneThreeContainer.current?.firstChild) {
          sceneThreeContainer.current!.removeChild(sceneThreeContainer.current.firstChild);
        }

        const containerSize = {
          width: container.clientWidth as number || 10,
          height: container.clientHeight as number || 10,
        };

        const camera = MPPerspectiveCamera(PLAYABLE_THREE, containerSize);
        camera.layers.enableAll();
        _setCurrentCamera(camera);
        currentLayersDisplayed = { ...camera.layers } as THREE.Layers;
        updateVisibleElements();

        const renderer = new PLAYABLE_THREE.WebGLRenderer();
        renderer.setSize(containerSize.width as number, containerSize.height as number);
        renderer.setClearColor(0x707070, 1);
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = PLAYABLE_THREE.PCFSoftShadowMap;
        renderer.toneMapping = PLAYABLE_THREE.NoToneMapping;
        container.appendChild(renderer.domElement);

        const composer = new EFFECT_COMPOSER.EffectComposer(renderer);
        const renderPass = new RENDER_PASS.RenderPass(sourceScene, camera);
        composer.addPass(renderPass);

        const outlinePass = new OUTLINE_PASS.OutlinePass(new PLAYABLE_THREE.Vector2(containerSize.width, containerSize.height), sourceScene, camera);
        outlinePass.visibleEdgeColor.set(0xff0000);
        outlinePass.hiddenEdgeColor.set(0xff7f00);
        composer.addPass(outlinePass);
        _setCurrentOutlinePass(outlinePass);

        // TO DO: Inversion Shader X/Y
        // const hDRAndGammaShader = new SHADER_PASS.ShaderPass(HDRAndGammaShader);
        // composer.addPass(hDRAndGammaShader);
        // const flipImagePass = new SHADER_PASS.ShaderPass(FlipImageShader);
        // composer.addPass(flipImagePass);

        transformControls = new TRANSFORM_CONTROLS.TransformControls(camera, renderer.domElement);
        transformControls.layers.enableAll();
        sourceScene.add(transformControls);
        _setTransformControls(transformControls);

        const controls = MPControls(camera, renderer.domElement);

        raycaster = new PLAYABLE_THREE.Raycaster();
        const mouse = new PLAYABLE_THREE.Vector2();

        let altKeyPressed = false;
        let transformControlsPressed = false;

        container.addEventListener('mousemove', (event) => mousePosition(mouse, event as MouseEvent));
        document.addEventListener('keydown', (event) => {
          switch (event.key) {
            case 'Alt':
              altKeyPressed = true;
              controls.enableRotate = true;
              break;
            case 'Escape':
              transformControls.reset();
              transformControls.detach();
              outlinePass.selectedObjects = [];
              uPlayable.setNodeEdit(null);
              break;
            case 'w':
              transformControls.setMode('translate');
              break;
            case 'e':
              transformControls.setMode('rotate');
              break;
            case 'r':
              transformControls.setMode('scale');
              break;
            case '+':
            case '=':
            case 'num+':
              transformControls.setSize(transformControls.size + 0.1);
              break;
            case '-':
            case '_':
            case 'num-':
              transformControls.setSize(Math.max(transformControls.size - 0.1, 0.1));
              break;
            case 'x':
              transformControls.showX = !transformControls.showX;
              break;
            case 'y':
              transformControls.showY = !transformControls.showY;
              break;
            case 'z':
              transformControls.showZ = !transformControls.showZ;
              break;
            case ' ':
              transformControls.enabled = !transformControls.enabled;
              break;
            case 'ArrowUp':
              camera.position.set(camera.position.x, camera.position.y, camera.position.z - 0.5);
              break;
            case 'ArrowDown':
              camera.position.set(camera.position.x, camera.position.y, camera.position.z + 0.5);
              break;
            case 'ArrowRight':
              camera.position.set(camera.position.x + 0.5, camera.position.y, camera.position.z);
              break;
            case 'ArrowLeft':
              camera.position.set(camera.position.x - 0.5, camera.position.y, camera.position.z);
              break;
            case 'f':
              handleFocusObject(outlinePass.selectedObjects[0], controls, camera);
              break;
            case 'q':
              _setTransformControlsSpace(transformControls.space === 'local' ? 'world' : 'local');
              break;
            default:
              break;
          }
        });
        document.addEventListener('keyup', (event) => {
          if (event.key === 'Alt') {
            altKeyPressed = false;
            controls.enableRotate = false;
          }
        });
        container.addEventListener('click', () => {
          if (!altKeyPressed && !transformControlsPressed) {
            SelectObject(mouse, camera);
          }
        });
        container.addEventListener('mouseup', () => {
          if (nodeRef.current !== null && transformControls.object) {
            uPlayable.setNodeEdit(nodeRef.current);

            const modesMap: ModesMap = {
              translate: { desc: 'position', field: 'localPosition' },
              rotate: { desc: 'rotation', field: 'localEulerAngles' },
              scale: { desc: 'scale', field: 'localScale' },
            };

            const { desc, field } = modesMap[transformControls.mode];
            // @ts-ignore
            const { x, y, z } = transformControls.object[desc];

            const isRotate = transformControls.mode === 'rotate';
            const radToDegMultiplier = isRotate ? RAD_TO_DEG : 1;

            if (transformControlsPressed) {
              uPlayable.updateGameObject(
                new uPlayable.playableClasses.Vector3(x * radToDegMultiplier, y * radToDegMultiplier, z * radToDegMultiplier),
                `Transform[0]/${field}`,
                nodeRef.current.components[0].instanceID as string,
                transformControlsPressed,
                nodeRef.current.instanceID as string,
              );
            }
          }
        });
        container.addEventListener('mousemove', () => {
          transformControlsPressed = transformControls.dragging;
        });

        const animate = () => {
          if (currentLayersDisplayed.mask !== camera.layers.mask) {
            updateVisibleElements();
            currentLayersDisplayed = { ...camera.layers } as THREE.Layers;
          }

          requestAnimationFrame(animate);
          renderer.render(sourceScene, camera);
          composer.render();
        };

        animate();

        const resizeObserver = new ResizeObserver(() => {
          const width = container.clientWidth as number || 10;
          const height = container.clientHeight as number || 10;
          renderer.setSize(width, height);
          camera.aspect = width / height;
          camera.updateProjectionMatrix();
          composer.setSize(width, height);
          outlinePass.setSize(width, height);
        });

        resizeObserver.observe(container);

        return () => {
          resizeObserver.disconnect();
          renderer.dispose();
        };
      }
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PLAYABLE_THREE, container, sourceScene]);

  return (
    <div className="scene-view">
      <Spin spinning={!PLAYABLE_THREE || !container}>
        <DebugUI
          currentCamera={currentCamera}
          currentTransformControls={currentTransformControls}
          transformControlsSpace={transformControlsSpace}
          setTransformControlsSpace={(space) => { _setTransformControlsSpace(space); }}
        />
        {
          uPlayable.nodeEdit?.components.find((c) => c.type === 'Camera') && (
            <DebugCamera
              currentScene={sourceScene}
              PLAYABLE_THREE={PLAYABLE_THREE!}
            />
          )
        }
        {/* {
          (uPlayable.nodeEdit?.components.find((c) => c.type === 'ParticleSystem') && sourceScene) && (
            <DebugParticles
              currentScene={sourceScene}
              PLAYABLE_THREE={PLAYABLE_THREE!}
            />
          )
        } */}
        <div className="container-three" ref={sceneThreeContainer} />
      </Spin>
    </div>
  );
}
