import { MCompOverlay } from '../../../../../../modeles';
import { useIec } from '../../../../context/iec.context';
import { YcCard, YcColorPicker } from '../../../../../../components';
import { IecInputNumber } from '../../../input-number';

import './setting-comp-overlay.scss';

export function SettingCompOverlay(props: {
  overlay: MCompOverlay
}) {
  const uIec = useIec();

  return (
    <YcCard
      title="Overlay"
      light
      open={props.overlay._open}
      onClickTitle={(val) => {
        props.overlay._open = val;
        uIec.refreshIec(true);
      }}
      onDelete={() => {
        uIec.removeComponentNodeEdite(props.overlay);
      }}
    >
      <div className="row">
        <div className="col-4">
          <IecInputNumber label="Border Radius (%)" field="borderRadius" obj={props.overlay} step={0.1} />
        </div>
        <div className="col-4">
          <IecInputNumber label="Blur" field="blur" obj={props.overlay} />
        </div>
        <div className="col-4">
          <label>Color</label>
          <YcColorPicker
            color={props.overlay.layoutColor}
            onColorChange={(c) => {
              props.overlay.layoutColor = c;
              uIec.refreshIec(true);
            }}
          />
        </div>
      </div>

    </YcCard>
  );
}
