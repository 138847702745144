import {
  Button, Popconfirm, Spin, Switch, Table,
} from 'antd';
import { ColumnType } from 'antd/lib/table';
import { CheckOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { ReactNode, useEffect, useState } from 'react';
import { useAuths } from '../../../../../../contexts/auths.context';
import { MPlayableEngine } from '../../../../../../modeles';
import { playableEngineService } from '../../../../../../services';

import './playable-engines.scss';

export function PlayableEngines() {
  const uAuths = useAuths();
  const [playableEngines, _setPlayableEngines] = useState<MPlayableEngine[]>([]);
  const [loading, _setLoading] = useState(false);

  useEffect(() => {
    _setLoading(true);
    playableEngineService.listing().then((data) => {
      _setPlayableEngines(data || []);
      _setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const playableEnginesColumns: ColumnType<MPlayableEngine>[] = [
    {
      title: 'Version',
      key: 'version',
      render: (text: number, record: MPlayableEngine) => (
        <label>{record.version}</label>
      ),
      sorter: (a, b) => a.version.localeCompare(b.version || '') || -1,
    },
    {
      title: 'Release',
      key: 'isRelease',
      width: '6%',
      render: (text: string, record: MPlayableEngine) => (
        <Switch
          disabled={!uAuths.isSuperAdmin()}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          defaultChecked={record.isRelease}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={(e) => {
            record.isRelease = e;
            playableEngineService.isRelease(record.id, e);
          }}
        />
      ),
      sorter: (a: MPlayableEngine, b: MPlayableEngine) => (a.isRelease || b.isRelease ? 1 : 0),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text: string, record: MPlayableEngine) => (
        <div className="actions d-flex justify-content-around">
          {uAuths.isAdmin()
            && (
              <Popconfirm title="Sure to delete?" onConfirm={() => deleteEngine(record.id)}>
                <Button type="primary" shape="circle" icon={<DeleteOutlined />} size="small" danger />
              </Popconfirm>
            )}
        </div>
      ),
    },
    {
      title: 'Date',
      key: 'date',
      render: (text: number, record: MPlayableEngine) => (
        <label>{new Date(record.createdAt!).toLocaleString()}</label>
      ),
    },
  ];

  const deleteEngine = (id: number) => {
    const dataSource = [...playableEngines];
    _setPlayableEngines(dataSource.filter((item) => item.id !== id));
    playableEngineService.remove(id);
  };

  return (
    <div id="playable-engines">
      {uAuths.canAccess('mpAdmin/sdkEngine')
        && (
          <div className="row">
            <div className="col-12">
              <Spin spinning={loading}>
                <Table bordered size="small" dataSource={playableEngines} columns={playableEnginesColumns} rowKey="id" />
              </Spin>
            </div>
          </div>
        )}
    </div>
  );
}
