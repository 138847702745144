import { CSSProperties } from 'react';
import { MCompText, MCompTextShadow, MCompTextStroke } from '../../../../../modeles';
import { ColorTools } from '../../../../../tools';

// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires, @typescript-eslint/no-unsafe-argument
// eslint-disable-next-line import/no-duplicates
export const PreviewCompText_CSS = `
/* TEXT */
.preview-comp-text {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.preview-comp-text .preview-comp-text-content {
  display: table-cell;
}
`;

export function PreviewCompText(props : {
  text: MCompText,
  textShadow?: MCompTextShadow,
  textStroke?: MCompTextStroke,
}) {
  const getStyle = () => {
    const style: CSSProperties = {
      fontSize: `${props.text.fontSize}px`,
      fontFamily: props.text.fontPolice,
      fontWeight: props.text.fontWeight,
      color: ColorTools.mColorToString(props.text.fontColor),
      fontStyle: props.text.fontStyle,
      textDecoration: props.text.textDecoration,
      textAlign: props.text.textAlign,
      verticalAlign: props.text.verticalAlign,
    };
    if (props.textShadow) {
      const shadow = props.textShadow;
      style.textShadow = `${shadow.x}px ${shadow.y}px ${shadow.blurRadius}px ${ColorTools.mColorToString(shadow.color)}`;
    }
    if (props.textStroke) {
      const textStroke = props.textStroke;
      style.WebkitTextStroke = `${textStroke.size}px ${ColorTools.mColorToString(textStroke.color)}`;
    }
    return style;
  };

  return (
    <div className="preview-comp-text">
      <div className="preview-comp-text-content" style={getStyle()}>
        {props.text.text}
      </div>
    </div>
  );
}
