/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/media-has-caption */
import {
  MCreativeVideo, APP_WIDTH, MCreative, ExportType,
} from '../../../../modeles';
import {
  PreviewEndcard, PreviewBreak, PreviewHover, PreviewLoop,
} from '../iec';
import { ExportVideo } from './export-video';

export const ExportIec_CSS = `
.preview-iec { position: absolute; width: 100%; height: 100%; }
.preview-iec-flex { position: absolute; left: 0; right: 0; top: 0; bottom: 0; display: flex; align-items: center; justify-content: center; }
.preview-iec-contener { position: relative; flex-shrink: 0; overflow: hidden; }
`;

export const ExportIec_JS = `
  function iecResize() {
    const size = ${APP_WIDTH};
    const eContent = document.getElementById("preview-iec-contener");
    if (eContent) {
      const dW = getVW();
      const dH = getVH();
      const vW = __videoWidth;
      const vH = __videoHeight;
      const wR = vW / dW;
      const hR = vH / dH;
      if (wR < hR) {
        const ratio = (dH / size) * (vW / vH);
        const height = dH / ratio;
        const width = vW * ((dH / vH) / ratio);
        eContent.style.width = width + "px";
        eContent.style.height = height + "px";
        eContent.style.transform = "scale(" + (ratio * getZoom()) + ")";
      } else {
        const ratio = dW / size;
        const width = dW / ratio;
        const height = vH * ((dW / vW) / ratio);
        eContent.style.width = width + "px";
        eContent.style.height = height + "px";
        eContent.style.transform = "scale(" + (ratio * getZoom()) + ")";
      }  
    }
  }
  addEventListener('resize', (event) => {
    iecResize();
    for (const resize of __onResize) {
      if (getVW() > getVH()) {
        resize('landscape');
      } else {
        resize('portrait');
      }
    }
  });
`;

export function ExportIec(props: {
  iec: MCreative,
  base64: { [key:string]: string },
  exportType?: ExportType,
  language?: string,
}) {
  return (
    <div className="preview-iec">
      {props.iec.iecData.videos.map(
        (video, index) => (
          <ExportVideo
            exportType={props.exportType}
            video={video}
            base64={props.base64}
            first={index === 0}
            key={video._key}
          />
        ),
      )}
      <div className="preview-iec-flex">
        <div className="preview-iec-contener" id="preview-iec-contener">
          { props.iec.iecData.videos.map((video: MCreativeVideo) => {
            const elements = video.breaks.map((br) => (
              <PreviewBreak
                exportType={props.exportType}
                br={br}
                key={br._key}
                base64={props.base64}
                language={props.language}
                iec={props.iec}
              />
            ));
            elements.push(
              ...video.loops.map((lo) => (
                <PreviewLoop
                  exportType={props.exportType}
                  loop={lo}
                  key={lo._key}
                  base64={props.base64}
                  language={props.language}
                  iec={props.iec}
                />
              )),
            );
            if (video.hover) {
              elements.push(<PreviewHover
                exportType={props.exportType}
                videoId={video._key}
                hover={video.hover}
                base64={props.base64}
                language={props.language}
                iec={props.iec}
              />);
            }
            return elements;
          }) }
          {props.iec.iecData.hover ? (
            <PreviewHover
              exportType={props.exportType}
              hover={props.iec.iecData.hover}
              base64={props.base64}
              language={props.language}
              iec={props.iec}
            />
          ) : ''}
          <PreviewEndcard
            exportType={props.exportType}
            endcard={props.iec.iecData.endcard}
            base64={props.base64}
            language={props.language}
            iec={props.iec}
          />
        </div>
      </div>
      <script dangerouslySetInnerHTML={{
        __html: `
        iecResize();
      `,
      }}
      />
    </div>
  );
}
