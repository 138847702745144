import { MAccount } from '../modeles';
import { BaseService } from './base.service';

class AccountService extends BaseService {
  PATH = 'account/';

  async view(): Promise<MAccount | null> {
    return this._view(`${this.PATH}view`);
  }

  async edit(id: number, account: MAccount): Promise<MAccount | null> {
    return this._edit(`${this.PATH}edit/`, account);
  }

  async exportFormat(): Promise<string> {
    return await this._view(`${this.PATH}export-format`) || '';
  }
}

export const accountService = new AccountService();
