import {
  MCreativeBreak, MCreativeLoop, MCreativeVideo, MCreativeEndcard, MCreativeElementBase, MCreativeHover,
} from '../../../../modeles';
import {
  SettingVideo, SettingBreak, SettingLoop, SettingEndcard, SettingHover,
} from '../iec';

import { useIec } from '../../context/iec.context';
import { getEditorElem } from '../../utils/iec.utils';

import { SettingBase } from '../iec/settings/setting-base';
import './setting.scss';

export function Setting() {
  const uIec = useIec();
  const node = uIec.getNodeEdite()!;

  if (node) {
    const elemEditor = getEditorElem(node);

    if (node._type === 'video') { return <SettingVideo video={node as MCreativeVideo} />; }
    if (node._type === 'break') { return <SettingBreak br={node as MCreativeBreak} />; }
    if (node._type === 'loop') { return <SettingLoop loop={node as MCreativeLoop} />; }
    if (node._type === 'endcard') { return <SettingEndcard endcard={node as MCreativeEndcard} />; }
    if (node._type === 'hover') { return <SettingHover hover={node as MCreativeHover} />; }
    if (node._type === 'element') {
      return (
        <SettingBase
          title={`${elemEditor.name} ${node._displayName ? `- ${node._displayName}` : ''}`}
          icon={elemEditor.icon()}
          element={node as MCreativeElementBase}
        ><span className="title-effect">Add Components</span>
        </SettingBase>
      );
    }
  }
  return null;
}
