import { useEffect, useState } from 'react';
import {
  Button, Input, Modal, Space,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';

import { YcCard } from '../../../../../../components';
import { IMeshFilterJson } from '../../../../../../modeles/MP_Interfaces';
import { usePlayable } from '../../../../context/playable.context';
import { AssetsLib } from './assets-lib';

import './setting-comp-mesh-filter.scss';

export function SettingCompMeshFilter(props: {
  initialData: any,
  parentID: string,
  icon?: string,
}) {
  const uPlayable = usePlayable();

  const [component, _setComponent] = useState<IMeshFilterJson>();
  const [compTypeIndex, _setCompTypeIndex] = useState<number | undefined>(undefined);
  const [modelsModalIsOpen, _setModelsModalIsOpen] = useState<boolean>(false);

  const childType: string = props.initialData.type;
  const childID: string = props.initialData.instanceID;
  const parentID: string = props.parentID;

  useEffect(() => {
    _setCompTypeIndex(uPlayable.getCompTypeIndex(childType, parentID, childID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (compTypeIndex !== undefined) {
      const comp = uPlayable.getRunTimeComponent(`${childType}[${compTypeIndex}]`) as IMeshFilterJson;
      _setComponent(comp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, compTypeIndex]);

  const updateMesh = (mesh: { name: string, mesh: THREE.Mesh, modelIndex: string }) => {
    // @ts-ignore
    component.name = mesh.name;
    // @ts-ignore
    component.mesh = mesh.mesh;
    // @ts-ignore
    component.modelIndex = mesh.modelIndex;
    _setModelsModalIsOpen(false);

    uPlayable.updateGameObject(component, `${childType}[${compTypeIndex}]/sharedMesh_Infos`, childID);
    // _setComponent(uPlayable.getRunTimeComponent(`${childType}[${compTypeIndex}]`) as ITransformJson);
  };

  return (
    <YcCard title="Mesh Filter" icon={props.icon} light open>
      <div className="row">
        <div className="col-12">
          <div className="row mb-1 d-flex align-items-center">
            <div className="col-5">
              <label>Mesh</label>
            </div>
            <div className="col-7">
              <Space direction="horizontal">
                <Input
                  // @ts-ignore
                  value={component?.name || 'null'}
                  contentEditable={false}
                  disabled
                />
                <Button
                  icon={<FontAwesomeIcon icon={faEdit} title="Text align left" />}
                  onClick={() => {
                    _setModelsModalIsOpen(true);
                  }}
                />
              </Space>
            </div>
          </div>
        </div>
      </div>
      <Modal title="Basic Modal" width={1000} open={modelsModalIsOpen} footer={null} onCancel={() => _setModelsModalIsOpen(false)}>
        <AssetsLib onValidate={(mesh) => { updateMesh(mesh); }} onCancel={() => _setModelsModalIsOpen(false)} />
      </Modal>
    </YcCard>
  );
}
