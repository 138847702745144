import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button, Tooltip, Popconfirm, Spin,
} from 'antd';
import { AndroidFilled, AppleFilled } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/pro-regular-svg-icons';

import { MApplication, MTag } from '../../../../modeles';
import { accountService, applicationsService } from '../../../../services';
import { YcTags, MpTable, MpColumnType } from '../../../../components';
import { useAuths } from '../../../../contexts/auths.context';

import './applications.page.scss';

export function ApplicationsListing(props: {
  searchValue: string,
}) {
  const uAuths = useAuths();

  const [applications, _setApplications] = useState<MApplication[]>([]);
  const [applicationsDisplayed, _setApplicationsDisplayed] = useState<MApplication[]>([]);
  const [loading, _setLoading] = useState(false);
  const [tags, _setTags] = useState<MTag[]>([]);

  useEffect(() => {
    _setLoading(true);
    accountService.view().then((data) => {
      _setTags(data?.tags || []);
    });
    applicationsService.listing().then((data) => {
      _setApplications(data || []);
      _setApplicationsDisplayed(data || []);
      _setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    _setApplicationsDisplayed(
      applications.filter(
        (application) => application.name
          .toLowerCase()
          .includes(props.searchValue.toLowerCase() as string) || application.key.toUpperCase().includes(props.searchValue.toUpperCase() as string),
      ),
    );
  }, [applications, props.searchValue]);

  const columns = (): MpColumnType<MApplication>[] => {
    const result: MpColumnType<MApplication>[] = [
      {
        title: 'Name',
        key: 'name',
        mpCanNotHide: true,
        mpSort: 'string',
        render: (text: string, record: MApplication) => (
          <Link className="link-to-iecs" to={`/applications/${record.id}/creatives`}>
            {
              record.Icon?.url
                ? <img className="application-icon" src={record.Icon?.url} alt={record.name} />
                : <div className="no-application-icon">{record.name[0]}</div>
            }
            {record.name}
          </Link>
        ),
      },
      {
        title: 'Key',
        key: 'key',
        mpCanNotHide: true,
        mpSort: 'string',
      },
      {
        title: 'Store',
        key: 'store',
        fixed: 'right',
        width: '100px',
        align: 'center',
        render: (text: string, record: MApplication) => (
          <div className="d-flex justify-content-around">
            {record.playStore && <AndroidFilled />}
            {record.appStore && <AppleFilled />}
          </div>
        ),
        sorter: (a, b) => (a.Iecs?.filter((iec) => iec.type === 'IEC').length || 0) - (b.Iecs?.filter((iec) => iec.type === 'IEC').length || 0),
      },
      {
        title: 'Tags',
        key: 'tags',
        fixed: 'right',
        width: '100px',
        align: 'center',
        render: (text: string, record: MApplication) => (
          <YcTags tags={record.tags} />
        ),
        filters: tags.map((element) => ({
          text: element.text,
          value: element.text,
        })),
        onFilter: (value: any, record) => record.tags?.some((e) => e.text === value) || false,
        filterSearch: true,
      },
      {
        title: 'IECs',
        key: 'iecs',
        fixed: 'right',
        width: '100px',
        align: 'center',
        render: (text: string, record: MApplication) => (
          <label>{record?.Iecs?.filter((iec) => iec.type === 'IEC').length}</label>
        ),
        sorter: (a, b) => (a.Iecs?.filter((iec) => iec.type === 'IEC').length || 0) - (b.Iecs?.filter((iec) => iec.type === 'IEC').length || 0),
      },
    ];
    if (uAuths.canAccess('applications/playable')) {
      result.push(
        {
          title: 'Playables',
          key: 'playables',
          fixed: 'right',
          width: '100px',
          align: 'center',
          render: (text: string, record: MApplication) => (
            <label>{record?.Iecs?.filter((iec) => iec.type === 'PLA').length}</label>
          ),
          sorter: (a, b) => (a.Iecs?.filter((iec) => iec.type === 'PLA').length || 0) - (b.Iecs?.filter((iec) => iec.type === 'PLA').length || 0),
        },
      );
    }
    result.push(
      {
        title: 'Actions',
        key: 'actions',
        fixed: 'right',
        width: '100px',
        align: 'center',
        mpCanNotHide: true,
        render: (text: string, record: MApplication) => (
          <div className="actions">
            <Link to={`applications/${record.id}/settings`}>
              <Tooltip placement="top" title="Edit application">
                <Button type="primary" shape="circle" icon={<FontAwesomeIcon icon={faEdit} title="Text align left" />} size="small" />
              </Tooltip>
            </Link>
            {uAuths.isAdmin()
              && (
                <Tooltip placement="topRight" title="Remove application">
                  <>
                    <Popconfirm title="Sure to delete?" onConfirm={() => { adminDelete(record.id); }}>
                      <Button type="primary" shape="circle" icon={<FontAwesomeIcon icon={faTrash} title="Text align left" />} size="small" danger />
                    </Popconfirm>
                  </>
                </Tooltip>
              )}
            {uAuths.isSuperAdmin()
              && (
                <Tooltip placement="topRight" title="! Admin delete !">
                  <>
                    <Popconfirm title="Sure to delete?" onConfirm={() => { superAdminDelete(record.id); }}>
                      <Button type="dashed" shape="circle" icon={<FontAwesomeIcon icon={faTrash} title="Text align left" />} size="small" danger />
                    </Popconfirm>
                  </>
                </Tooltip>
              )}
          </div>
        ),
      },
    );
    return result;
  };

  const adminDelete = async (id: number) => {
    const dataSource = [...applicationsDisplayed];
    _setApplications(dataSource.filter((item) => item.id !== id));
    _setApplicationsDisplayed(dataSource.filter((item) => item.id !== id));
    await applicationsService.deleteByDate(id);
  };

  const superAdminDelete = async (id: number) => {
    const dataSource = [...applicationsDisplayed];
    _setApplications(dataSource.filter((item) => item.id !== id));
    _setApplicationsDisplayed(dataSource.filter((item) => item.id !== id));
    await applicationsService.remove(id);
  };

  return (
    <Spin spinning={loading}>
      <MpTable
        bordered
        size="small"
        dataSource={applicationsDisplayed}
        columns={columns()}
        rowKey="id"
        mpTableKey="applications"
        urlParamsEnabled
      />
    </Spin>
  );
}
