import {
  Redirect, Route, Switch, useRouteMatch,
} from 'react-router-dom';

// styles
import './index.scss';

// pages
import { SetupPage } from './pages';

export function SetupModule() {
  const { path } = useRouteMatch();

  return (
    <div id="setup">
      <Switch>
        <Route exact path={`${path}`}><SetupPage /></Route>
        <Route path={path}><Redirect to={`${path}`} /></Route>
      </Switch>
    </div>
  );
}
