import { BaseService } from './base.service';
import { MAccount, MApplication, MTag } from '../modeles';

class ApplicationsService extends BaseService {
  PATH = 'applications/';

  async listing(): Promise<MApplication[] | null> {
    return this._listing(`${this.PATH}listing`);
  }

  async sideListing(): Promise<MApplication[] | null> {
    return this._listing(`${this.PATH}side-listing`);
  }

  async add(iec: MApplication): Promise<MApplication | null> {
    return this._add(`${this.PATH}add`, iec);
  }

  async view(id: number): Promise<MApplication | null> {
    return this._view(`${this.PATH}view/${id}`);
  }

  async edit(id: number, app: MApplication): Promise<MApplication | null> {
    return this._edit(`${this.PATH}edit/${id}`, app);
  }

  async remove(id: number): Promise<MApplication | null> {
    return this._delete(`admin/${this.PATH}delete/${id}`);
  }

  async deleteByDate(id: number): Promise<MApplication | null> {
    return this._delete(`${this.PATH}delete/${id}`);
  }

  async tags(): Promise<MTag[] | null> {
    return this._view(`${this.PATH}tags`).then((res: any) => res.tags as MTag[]);
  }
}

export const applicationsService = new ApplicationsService();
