import { BaseService } from './base.service';
import { MAsset } from '../modeles';

class AssetService extends BaseService {
  PATH = 'asset/';

  async globalAssets(): Promise<MAsset[] | null> {
    return this._listing(`${this.PATH}global-assets`);
  }

  async listing(): Promise<MAsset[] | null> {
    return this._listing(`${this.PATH}listing`);
  }

  async types(): Promise<string[] | null> {
    return this._listing(`${this.PATH}types`);
  }

  async add(asset: MAsset): Promise<MAsset | null> {
    return this._add(`${this.PATH}add`, asset);
  }

  async remove(id: number): Promise<MAsset | null> {
    return this._delete(`${this.PATH}delete/${id}`);
  }

  // SUPER ADMIN ASSETS //

  async superListing(): Promise<MAsset[] | null> {
    return this._listing(`admin/${this.PATH}listing`);
  }

  async superAdd(asset: MAsset): Promise<MAsset | null> {
    return this._add(`admin/${this.PATH}add-asset`, asset);
  }

  async SuperEdit(asset: MAsset): Promise<MAsset | null> {
    return this._edit(`admin/${this.PATH}edit/${asset.id}`, asset);
  }

  async SuperRemove(id: number): Promise<MAsset | null> {
    return this._delete(`admin/${this.PATH}delete/${id}`);
  }
}

export const assetService = new AssetService();
