/* eslint-disable react/no-danger */
import {
  Button, Input, Popover, Spin, Table,
} from 'antd';
import { ColumnType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck, faCloudArrowDown, faGear, faTriangleExclamation, faXmark,
} from '@fortawesome/pro-regular-svg-icons';

import { MEngineLogs } from '../../../../modeles';
import { engineLogsService } from '../../../../services';
import { getUniqueList } from '../../../../tools';

import './engine-logs.page.scss';

export function EngineLogsPage() {
  const location = useLocation();

  const [engineLogs, _setEngineLogs] = useState<MEngineLogs[]>([]);
  const [displayedEngineLogs, _setDisplayedEngineLogs] = useState<MEngineLogs[]>([]);
  const [isReady, _setIsReady] = useState<boolean>(false);
  const [search, _setSearch] = useState<string>('');

  const queryParameters = new URLSearchParams(location.search);

  useEffect(() => {
    engineLogsService.listing().then((data) => {
      _setEngineLogs(data || []);
      _setDisplayedEngineLogs(data || []);

      if (queryParameters.has('search')) {
        _setSearch(queryParameters.get('search') || '');
      }

      _setIsReady(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    _setDisplayedEngineLogs(
      engineLogs.filter(
        (engineLog) => engineLog.code!
          .toLowerCase()
          .includes(search.toLowerCase()) || engineLog.code?.toLowerCase() === search.toLowerCase(),
      ),
    );
  }, [engineLogs, search]);

  const columns: ColumnType<MEngineLogs>[] = [
    {
      title: 'Status',
      key: 'status',
      width: 80,
      align: 'center',
      render: (engineLog: MEngineLogs) => {
        switch (engineLog.status) {
          case 'succeeded':
            return <FontAwesomeIcon icon={faCheck} color="green" size="xl" />;
          case 'failed':
            return <FontAwesomeIcon icon={faXmark} color="red" size="xl" />;
          case 'pending':
          case 'running':
            return <FontAwesomeIcon icon={faGear} color="orange" size="xl" className="rotate" />;
          default:
            return <FontAwesomeIcon icon={faTriangleExclamation} color="orange" size="xl" />;
        }
      },
      sorter: (a: MEngineLogs, b: MEngineLogs) => a.status!.localeCompare(b.status || '') || -1,
      filters: getUniqueList(engineLogs, 'status').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MEngineLogs) => record.status === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'ID',
      key: 'id',
      render: (engineLog: MEngineLogs) => (
        <label>{engineLog.id}</label>
      ),
      sorter: (a: MEngineLogs, b: MEngineLogs) => a.id - b.id,
      filters: getUniqueList(engineLogs, 'id').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MEngineLogs) => record.id.toString() === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'Code',
      key: 'code',
      render: (engineLog: MEngineLogs) => (
        <label>{engineLog.code}</label>
      ),
      sorter: {
        compare: (a: MEngineLogs, b: MEngineLogs) => a.code!.localeCompare(b.code || '') || -1,
      },
      filters: getUniqueList(engineLogs, 'code').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MEngineLogs) => record.code === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'Application (id)',
      key: 'application',
      render: (engineLog: MEngineLogs) => (
        <Link to={`/applications/${engineLog.Application?.id}/creatives`}>
          <label>{engineLog.Application?.name} ({engineLog.Application?.id})</label>
        </Link>
      ),
      sorter: {
        compare: (a: MEngineLogs, b: MEngineLogs) => a.Application?.name!.localeCompare(b.Application?.name || '') || -1,
      },
      filters: getUniqueList(engineLogs, 'Application.name').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MEngineLogs) => record.Application?.name === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'Playable (id)',
      key: 'playable',
      render: (engineLog: MEngineLogs) => (
        <Link to={`/applications/${engineLog.Application?.id}/creatives/playable/${engineLog.Playable?.id}`}>
          <label>{engineLog.Playable?.name} ({engineLog.Playable?.id})</label>
        </Link>
      ),
      sorter: {
        compare: (a: MEngineLogs, b: MEngineLogs) => a.Playable?.name!.localeCompare(b.Playable?.name || '') || -1,
      },
      filters: getUniqueList(engineLogs, 'Playable.name').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MEngineLogs) => record.Playable?.name === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'SDK Version',
      key: 'sdkVersion',
      render: (engineLog: MEngineLogs) => (
        <label>{engineLog.sdkVersion}</label>
      ),
      sorter: {
        compare: (a: MEngineLogs, b: MEngineLogs) => a.sdkVersion!.localeCompare(b.sdkVersion || '') || -1,
      },
      filters: getUniqueList(engineLogs, 'sdkVersion').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MEngineLogs) => record.sdkVersion === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'Engine Version',
      key: 'engineVersion',
      render: (engineLog: MEngineLogs) => (
        <label>{engineLog.engineVersion}</label>
      ),
      sorter: {
        compare: (a: MEngineLogs, b: MEngineLogs) => a.engineVersion!.localeCompare(b.engineVersion || '') || -1,
      },
      filters: getUniqueList(engineLogs, 'engineVersion').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MEngineLogs) => record.engineVersion === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'Account',
      key: 'account',
      render: (engineLog: MEngineLogs) => (
        <Link to={`/admin/accounts/account/${engineLog.accountId}`}>{engineLog.Account?.name}</Link>
      ),
      sorter: (a: MEngineLogs, b: MEngineLogs) => a.Account?.name.localeCompare(b.Account?.name || '') || -1,
      filters: getUniqueList(engineLogs, 'Account.name').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MEngineLogs) => record.Account?.name === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'User',
      key: 'user',
      render: (engineLog: MEngineLogs) => (
        <Link to={`#${engineLog.userId}`}>{engineLog.User?.email}</Link>
      ),
      sorter: {
        compare: (a: MEngineLogs, b: MEngineLogs) => a.User!.id - b.User!.id,
      },
      filters: getUniqueList(engineLogs, 'User.email').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MEngineLogs) => record.User!.id === (value as number) || false,
      filterSearch: true,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (engineLog: MEngineLogs) => (
        <div className="d-flex justify-content-around">
          <Popover content="Download">
            <Button
              type="primary"
              shape="circle"
              icon={<FontAwesomeIcon icon={faCloudArrowDown} />}
              size="small"
              onClick={() => { download(engineLog.id); }}
            />
          </Popover>
        </div>
      ),
    },
    {
      title: 'Creation date',
      key: 'createdAt',
      render: (engineLog: MEngineLogs) => (
        <label>{new Date(engineLog.createdAt!).toLocaleString()}</label>
      ),
      sorter: (a: MEngineLogs, b: MEngineLogs) => a.createdAt?.toString()?.localeCompare(b.createdAt?.toString() || '') || -1,
      defaultSortOrder: 'descend',
    },
  ];

  async function download(id: number) {
    const response = await engineLogsService.downloadData(id);

    const link = document.createElement('a');
    link.href = response.url;
    link.setAttribute('download', 'downloaded.zip');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    if (response.script) {
      const element = document.createElement('a');
      const file = new Blob([response.script], { type: 'text/plain' });
      element.href = URL.createObjectURL(file);
      element.download = 'script.ts';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  }

  const displayLog = (logs: any) => {
    return (
      <div className="logs">
        <pre dangerouslySetInnerHTML={{ __html: logs }} />
      </div>
    );
  };

  return (
    <div id="engine-logs">
      <Spin spinning={!isReady}>
        <Input
          placeholder="Search"
          onChange={(e) => { _setSearch(e.target.value as string); }}
          value={search}
          style={{ width: '30%', marginBottom: '20px' }}
        />
        <Table
          bordered
          size="small"
          dataSource={displayedEngineLogs}
          columns={columns}
          expandable={{
            expandedRowRender: (record) => displayLog(record.logs),
            rowExpandable: (record) => record.logs !== null,
          }}
          rowKey="id"
          pagination={{ defaultPageSize: 20, pageSizeOptions: [10, 20, 50, 100], showSizeChanger: true }}
        />
      </Spin>
    </div>
  );
}
