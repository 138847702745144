import {
  useState, ChangeEvent,
  useRef, useEffect,
} from 'react';
import { Button } from 'antd';

import './comp-image.scss';

export function SettingCompImage(props: {
  label: string,
  uploadLabel?: string,
  disabled?: boolean,
  onChange?: (data: any) => void,
  value?: string,
}) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [selectedImage, setSelectedImage] = useState<{ data: string | ArrayBuffer | null, name: string }>();

  useEffect(() => {
    if (!props.value) {
      setSelectedImage(undefined);
    }
  }, [props.value]);

  useEffect(() => {
    if (selectedImage && props.onChange) { props.onChange(selectedImage); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImage]);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setSelectedImage({ data: reader.result, name: file.name });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOpenInput = () => {
    if (fileInputRef.current) { fileInputRef.current.click(); }
  };

  return (
    <div className="comp-image-container row">
      <div className="col-5">
        <label>{props.label}</label>
      </div>
      <div className="col-7" onClick={handleOpenInput}>
        <input type="file" accept="image/*" onChange={handleImageChange} ref={fileInputRef} />
        {
          selectedImage
            ? (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <img src={selectedImage.data as string} height={50} alt="src" />
            )
            : (
              <Button disabled={props.disabled} style={{ width: '100%' }}>
                {props?.uploadLabel || 'Upload'}
              </Button>
            )
        }
      </div>
    </div>
  );
}
