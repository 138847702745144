import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import * as HomeImages from '../../../../../../assets/images/home';
import { GamesCard, InformationCard, PreviewCard } from '../sub-components';

import './iec-preview.scss';
import { IGame } from '../../home.page';

export function IecPreview() {
  const icons = [
    HomeImages.IcoVideo,
    HomeImages.IcoRules,
    HomeImages.IcoSmartphone,
  ];
  const content = (
    <p>
      Let your users interact directly on an ad !
      From recording videos of your game,
      the Interactive End Card permits the user to interact on the ad with clicks,
      and redirection through the Store from an End Card.
    </p>
  );
  const games: IGame[] = [
    {
      name: 'Ninja hands',
      icon: 'https://yso-playable-prod-front.s3.eu-west-3.amazonaws.com/home_assets/iec_game_1_icon.png',
      url: 'https://yso-playable-prod-front.s3.eu-west-3.amazonaws.com/home_assets/game_1_iec.html',
    },
    {
      name: 'Retro Battle',
      icon: 'https://yso-playable-prod-front.s3.eu-west-3.amazonaws.com/home_assets/iec_game_2_icon.png',
      url: 'https://yso-playable-prod-front.s3.eu-west-3.amazonaws.com/home_assets/game_2_iec.html',
    },
    {
      name: 'Hide N Seek 3D!',
      icon: 'https://yso-playable-prod-front.s3.eu-west-3.amazonaws.com/home_assets/iec_game_3_icon.png',
      url: 'https://yso-playable-prod-front.s3.eu-west-3.amazonaws.com/home_assets/game_3_iec.html',
    },
  ];
  const [selectedIec, _setSelectedIec] = useState<IGame | null>(games[0]);
  const [previewKey, _setPreviewKey] = useState<number>(0);
  const isSmallScreen = useMediaQuery({ maxWidth: 1024 });

  return (
    <div id="iec-preview">
      <div className="content row">
        <div className="details col-12 col-lg-7">
          <h2>Try our Interactive End Card !</h2>

          <GamesCard
            isSmallScreen={isSmallScreen}
            games={games}
            selectedGame={selectedIec}
            selectGame={(game) => { _setSelectedIec(game); }}
            refresh={() => { _setPreviewKey(previewKey + 1); }}
          />

          <InformationCard path="/iec-presentation" icons={icons} left={false} content={content} />
        </div>
        <div className="col-5 preview-card">
          <PreviewCard key={previewKey} game={selectedIec} />
        </div>
      </div>
    </div>
  );
}
