import {
  Form, Input, Button, Tooltip,
} from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove } from '@fortawesome/pro-regular-svg-icons';
import { useState } from 'react';

import * as ImagesSignup from '../../../../assets/images/sign-up';
import { useAuths } from '../../../../contexts/auths.context';

import '../../index.scss';
import './login.page.scss';

export function LoginPage() {
  const uAuths = useAuths();

  const [refresh, _setRefresh] = useState<number>(0);

  const onFinish = (data: any) => {
    uAuths.login(data);
  };

  return (
    <div className="root-login">
      <img src={ImagesSignup.Bkgcontent} className="bkgcontent" alt="monster" />
      <div className="block-bkg">
        <img src={ImagesSignup.Monster} className="monster" alt="monster" />
        <div className="block-auth">
          <Form onFinish={onFinish} layout="vertical">
            <div className="top-row">
              <span className="orange-title">Login</span>
              <div className="right-part">
                <span className="left-text">No account ?</span>
                <Link className="gradient-btn" to="/sign-up">Sign Up now</Link>
              </div>
            </div>
            <Form.Item label="Email" name="email" rules={[{ required: true }]} initialValue="">
              <Input placeholder="Email" type="email" />
            </Form.Item>
            <Form.Item label="Password" name="password" rules={[{ required: true }]} initialValue="">
              <Input.Password placeholder="Password" type="password" />
            </Form.Item>
            <div className="bottom-row">
              <Link className="forgot-text" to="/forgot-password">Forgot your password</Link>
              <Button className="confirm-btn" type="primary" htmlType="submit">Login</Button>
            </div>
          </Form>
        </div>
        {uAuths.getTokens().tokens?.length > 0
          && (
          <div className="block-tokens">
            <div className="tokens" key={refresh}>
              { uAuths.getTokens().tokens.map((token, index) => (
                <Tooltip key={index} title={token.account}>
                  <div className="token">
                    <Button shape="circle" size="large" onClick={() => { uAuths.setToken(token.token); }}>
                      {token.firstName[0].toUpperCase()}.{token.lastName[0].toUpperCase()}
                    </Button>
                    <Button
                      shape="circle"
                      size="small"
                      danger
                      icon={<FontAwesomeIcon icon={faRemove} title="Font weight" />}
                      onClick={() => { uAuths.removeToken(token); _setRefresh(refresh + 1); }}
                      className="remove-token"
                    />
                  </div>
                </Tooltip>
              ))}
            </div>
          </div>
          )}
      </div>
    </div>
  );
}
