import THREE from 'three';

export function MPLightHelper(
  PLAYABLE_THREE: typeof THREE,
  light: THREE.Light,
): THREE.PointLightHelper | THREE.DirectionalLightHelper | THREE.SpotLightHelper | null {
  const lightType = light.constructor.name;
  switch (lightType) {
    case 'DirectionalLight':
      return DirectionalLightHelper(PLAYABLE_THREE, light as THREE.DirectionalLight);
    case 'PointLight':
      return PointLightHelper(PLAYABLE_THREE, light as THREE.PointLight);
    case 'SpotLight':
      return SpotLightHelper(PLAYABLE_THREE, light as THREE.SpotLight);
    case 'AmbientLight':
    case 'HemisphereLight':
    case 'RectAreaLight':
    case 'LightProbe':
    default:
      console.info(`Light helper of type ${lightType} is not implemented yet`);
      return null;
      break;
  }
}

export const MPRemoveLightHelper = (
  lightHelper: THREE.PointLightHelper | THREE.DirectionalLightHelper | THREE.SpotLightHelper | null,
  scene: THREE.Scene,
) => {
  if (lightHelper) {
    scene.remove(lightHelper);
    lightHelper.dispose();
  }
};

const DirectionalLightHelper = (PLAYABLE_THREE: typeof THREE, light: THREE.DirectionalLight): THREE.DirectionalLightHelper => {
  const directionalLightHelper = new PLAYABLE_THREE.DirectionalLightHelper(light, 1);
  return directionalLightHelper;
};

const PointLightHelper = (PLAYABLE_THREE: typeof THREE, light: THREE.PointLight): THREE.PointLightHelper => {
  const pointLightHelper = new PLAYABLE_THREE.PointLightHelper(light, 1);
  return pointLightHelper;
};

const SpotLightHelper = (PLAYABLE_THREE: typeof THREE, light: THREE.SpotLight): THREE.SpotLightHelper => {
  const spotLightHelper = new PLAYABLE_THREE.SpotLightHelper(light);
  return spotLightHelper;
};
