export class NumberTools {
  static round(num: number, nbAfterComma: number) {
    if (num === undefined) {
      return undefined;
    }
    if (num !== 0) {
      const pow = 10 ** nbAfterComma;
      return Math.round(num * pow) / pow;
    }
    return 0;
  }

  static roundPrice(num: number, nbAfterComma: number) {
    const p = NumberTools.round(num, nbAfterComma);
    if (p !== undefined) {
      return `$${p}`;
    }
    return undefined;
  }
  static roundPercentage(num: number, nbAfterComma: number) {
    const p = NumberTools.round(num, nbAfterComma);
    if (p !== undefined) {
      return `${p}%`;
    }
    return undefined;
  }
}
