import {
  Input, InputNumber,
  Switch, Checkbox,
  Collapse,
  CollapseProps,
} from 'antd';
import { useEffect, useRef, useState } from 'react';

const { TextArea } = Input;

export function SettingCompTextArea(props: {
  value: string,
  label: string,
  onChange: (value: string) => void,
  rows?: number,
}) {
  return (
    <div className="row mb-1 d-flex align-items-flex-start">
      <div className="col-5">
        <label>{props.label}</label>
      </div>
      <div className="col-7">
        <TextArea
          value={props.value ?? ''}
          onChange={(e) => props.onChange(e.target.value as string)}
          rows={props.rows ?? 1}
          size="small"
        />
      </div>
    </div>
  );
}

export function SettingCompInputText(props: {
  value: string,
  label: string,
  onChange: (value: string) => void,
  disabled?: boolean,
}) {
  return (
    <div className="row mb-1 d-flex align-items-center">
      <div className="col-5">
        <label>{props.label}</label>
      </div>
      <div className="col-7">
        <Input
          disabled={props.disabled}
          value={props.value ?? ''}
          onChange={(e) => props.onChange(e.target.value as string)}
          size="small"
        />
      </div>
    </div>
  );
}

export function SettingCompInputNumber(props: {
  value: number,
  label: string,
  onChange: (value: number) => void,
  disabled?: boolean,
  reduceSpace?: boolean,
}) {
  const fieldRef = useRef<HTMLInputElement>(null);

  const handleSelectContent = () => {
    if (fieldRef.current !== null) {
      fieldRef.current.select();
    }
  };

  return (
    <div className="row mb-1 d-flex align-items-center">
      <div className={`col-${props?.reduceSpace ? 2 : 5}`}>
        <label>{props.label}</label>
      </div>
      <div className={`col-${props?.reduceSpace ? 10 : 7}`}>
        <InputNumber
          ref={fieldRef}
          disabled={props?.disabled}
          value={props.value}
          onChange={(e) => e != null && props.onChange(e as number)}
          onFocus={handleSelectContent}
          size="small"
          controls={false}
        />
      </div>
    </div>
  );
}

export function SettingCompInputBoolean(props: {
  checked?: boolean,
  label?: string,
  onChange: (value: boolean) => void,
  disabled?: boolean,
}) {
  return (
    <div className="row mb-1" onClick={(e) => e?.stopPropagation()}>
      {
        props.label && (
          <div className="col-5">
            <label>{props.label}</label>
          </div>
        )
      }
      <div className="col-7">
        <Switch
          disabled={props.disabled}
          checked={props.checked}
          onChange={() => props.onChange(!props.checked)}
        />
      </div>
    </div>
  );
}

export function SettingCompInputCheckbox(props: {
  checked?: boolean,
  label?: string,
  onChange: (value: boolean | undefined) => void,
  disabled?: boolean,
}) {
  return (
    <div className="row" onClick={(e) => e?.stopPropagation()}>
      {
        props.label && (
          <div className="col-5">
            <label>{props.label}</label>
          </div>
        )
      }
      <div className="col-7">
        <Checkbox
          disabled={props.disabled}
          checked={props.checked}
          onChange={() => props.onChange(!props.checked)}
        />
      </div>
    </div>
  );
}

export function SettingCompArrayInputText(props: {
  label: string,
  value: string[]
  onChange: (value: string[]) => void,
}) {
  const [items, _setItems] = useState<CollapseProps['items']>([]);

  useEffect(() => {
    _setItems([{
      key: 1,
      label: `${props.label} (${props.value.length})`,
      children: props.value.map((item, index) => (
        <SettingCompInputText
          key={index}
          label={`Element ${index}`}
          value={item}
          onChange={(e) => { onChange(e, index); }}
        />
      )),
    }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.label, props.value]);

  const onChange = (value: string, index: number) => {
    const newValue = [...props.value];
    newValue[index] = value;
    props.onChange(newValue);
  };

  return (
    <div className="row mb-1 d-flex align-items-center">
      <Collapse ghost items={items} />
    </div>
  );
}

export function SettingCompArrayInputNumber(props: {
  label: string,
  value: number[]
  onChange: (value: number[]) => void,
}) {
  const [items, _setItems] = useState<CollapseProps['items']>([]);

  useEffect(() => {
    _setItems([{
      key: 1,
      label: `${props.label} (${props.value.length})`,
      children: props.value.map((item, index) => (
        <SettingCompInputNumber
          key={index}
          label={`Element ${index}`}
          value={item}
          onChange={(e) => { onChange(e, index); }}
        />
      )),
    }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.label, props.value]);

  const onChange = (value: number, index: number) => {
    const newValue = [...props.value];
    newValue[index] = value;
    props.onChange(newValue);
  };

  return (
    <div className="row mb-1 d-flex align-items-center">
      <Collapse ghost items={items} />
    </div>
  );
}
