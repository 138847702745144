import { BaseService } from './base.service';
import { MDocumentation } from '../modeles';

class DocumentationService extends BaseService {
  PATH = 'documentation/';

  async listing(): Promise<MDocumentation[] | null> {
    return this._listing(`${this.PATH}listing`);
  }

  async add(iec: MDocumentation): Promise<MDocumentation | null> {
    return this._add(`${this.PATH}add`, iec);
  }

  async view(id: number): Promise<MDocumentation | null> {
    return this._view(`${this.PATH}view/${id}`);
  }

  async lastDocumentation(): Promise<MDocumentation | null> {
    return this._view(`${this.PATH}last-documentation`);
  }

  async edit(id: number, app: MDocumentation): Promise<MDocumentation | null> {
    return this._edit(`${this.PATH}edit/${id}`, app);
  }

  async remove(id: number): Promise<MDocumentation | null> {
    return this._delete(`${this.PATH}delete/${id}`);
  }
}

export const documentationService = new DocumentationService();
