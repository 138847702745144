import { BaseService } from '../base.service';
import { MApplication } from '../../modeles';

class AdminApplicationsService extends BaseService {
  PATH = 'admin/applications/';

  async listing(): Promise<MApplication[] | null> {
    return this._listing(`${this.PATH}listing`);
  }

  async listingByAccount(accountId: number): Promise<MApplication[] | null> {
    return this._listing(`${this.PATH}listing-by-account/${accountId}`);
  }
}

export const adminApplicationsService = new AdminApplicationsService();
