import { useState } from 'react';

import { YcTitle } from '../../../../components';
import { CreativeList, PerformanceFilters, PieChart } from '../../components';
import { performanceService } from '../../../../services';

import './performance.page.scss';

export interface Total {
  count: number,
  displayed: number,
  redirect: number,
}

export interface ResultLine {
  id: number;
  date?: string;
  platform?: string;
  applicationKey?: string;
  creative?: string;
  user?: string;
  countryCode?: string;
  network?: string;
  creativeType?: string;
  adName?: string;
  isTop?: boolean;
  count: number,
  displayed: number,
  redirect: number,
  schema?: any;
}

export interface Result {
  is: string[],
  total: Total,
  results: ResultLine[],
}

export function PerformancePage() {
  const [loading, _setLoading] = useState<boolean>(false);
  const [results, _setResults] = useState<Result | null>(null);

  const onSearch = (data: any) => {
    _setLoading(true);
    performanceService.search(data as object).then((res) => {
      if (res) {
        res.results = res.results.map((e: ResultLine) => ({
          ...e,
        }));
        _setResults({
          ...res,
          is: data.is ? data.is.split(',') : [],
        } as unknown as Result);
        if (data.is?.split(',').length >= 1 && data.is?.split(',').length < 3 && data.is.includes('granularity')) {
          const newList: any[] = [];
          const newListKey: any[] = [];
          let breakpoint = data.is?.split(',')[0] === 'granularity' ? data.is?.split(',')[1] : data.is?.split(',')[0];
          if (breakpoint === 'applicationId') breakpoint = 'applicationKey';
          if (breakpoint === 'creativeId') breakpoint = 'creativeName';
          if (breakpoint === 'userId') breakpoint = 'userName';
          if (breakpoint === 'country') breakpoint = 'countryCode';
          res.results.forEach((element: any) => {
            if (!newListKey?.includes(element[breakpoint])) {
              newListKey.push(element[breakpoint]);
            }
            if (newList.find((item: any) => item.date === element.date)) {
              const e = newList.find((item: any) => item.date === element.date);
              // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
              e[element[breakpoint]] = (newList.find((item: any) => item.date === element.date)[breakpoint] || 0) + element.displayed;
            } else {
              newList.push({ date: element.date, [element[breakpoint] || 'displayed']: element.displayed });
            }
          });
          if (newListKey.length === 1 && newListKey[0] === undefined) {
            newListKey[0] = 'displayed';
          }
        }
      }
      _setLoading(false);
    });
  };

  const getChartData = (object: string): { data: { name: string, value: number }[], total: number } => {
    const data: { name: string, value: number }[] = [];
    const topResult = results?.results.filter((result) => result.isTop) || [];

    if (object !== 'actions') {
      topResult.forEach((element) => {
        if (element.schema.raw) {
          if (data.some((d) => d.name === (element.schema.raw[object]).toString())) {
            data.find((d) => d.name === (element.schema.raw[object]).toString())!.value += 1;
          } else {
            data.push({ name: (element.schema.raw[object]).toString(), value: 1 });
          }
        }
      });
    } else if (object === 'actions') {
      topResult.forEach((element) => {
        if (element.schema.raw) {
          let actions = element.schema.raw.breaks;
          actions += element.schema.raw.loops;

          if (data.some((d) => d.name === actions.toString())) {
            data.find((d) => d.name === actions.toString())!.value += 1;
          } else {
            data.push({ name: actions.toString(), value: 1 });
          }
        }
      });
    }
    return { data, total: topResult.length };
  };

  return (
    <div id="performance">
      <YcTitle label="Performance" />
      <div className="row">
        <div className="col-12 mb-3">
          <PerformanceFilters service={performanceService} onSearch={onSearch} />
        </div>
        {results && (
        <>
          <div className="col-12 mb-3 charts">
            <PieChart label="Video" data={getChartData('videos')} />
            <PieChart label="Action" data={getChartData('actions')} />
            <PieChart label="Global Hover" data={getChartData('globalHover')} />
            <PieChart label="End Card" data={getChartData('endcard')} />
          </div>
          <div className="col-12 mb-3">
            <CreativeList loading={loading} results={results} />
          </div>
        </>
        )}
      </div>
    </div>
  );
}
