import { Card, Popover } from 'antd';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown, faChevronRight, faTrash, faReply, faTriangleExclamation, faLock, faUnlock,
} from '@fortawesome/pro-regular-svg-icons';

// styles
import './card.scss';

export function YcCard({
  canOpenAndClose = true,
  ...props
}: {
  title: string | React.ReactNode,
  children: React.ReactNode,
  icon?: React.ReactNode,
  titleExtra?: React.ReactNode,
  open: boolean,
  light?: boolean,
  active?: boolean,
  canEdit?: boolean,
  canOpenAndClose?: boolean,
  onClickTitle?: (open: boolean) => void,
  onDelete?: () => void,
  onBack?: () => void,
  warning?: string | React.ReactNode,
}) {
  const [open, _setOpen] = useState(props.open);
  const [isEditing, _setIsEditing] = useState(false);

  useEffect(() => {
    _setOpen(props.open);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  const onClick = () => {
    if (canOpenAndClose) {
      const b = !open;
      _setOpen(b);
      if (props.onClickTitle) {
        props.onClickTitle(b);
      }
    }
  };

  return (
    <div className={`yc-card${open ? ' open' : ''}${canOpenAndClose ? ' clickable' : ''}${props.active ? ' active' : ''}${props.light ? ' light' : ''}`}>
      <Card
        size="small"
        title={(
          <div onClick={onClick} className="title">
            {props.icon && <span className="icon">{props.icon}</span>}
            <span>{props.title}</span>
            <span className="title-extra">{props.titleExtra}</span>
          </div>
      )}
        extra={(
          <div className="icons">
            {props.warning
            && (
              <Popover content={props.warning} title="Warning">
                <FontAwesomeIcon className="icon warning" icon={faTriangleExclamation} />
              </Popover>
            )}
            {props.onDelete && <span onClick={() => { props.onDelete!(); }}><FontAwesomeIcon className="icon" onClick={onClick} icon={faTrash} /></span>}
            {props.onBack && <span onClick={() => { props.onBack!(); }}><FontAwesomeIcon className="icon" onClick={onClick} icon={faReply} /></span>}
            {props.canEdit && (
            <span onClick={() => {
              _setIsEditing(!isEditing);
            }}
            ><FontAwesomeIcon className="icon" onClick={onClick} icon={isEditing ? faUnlock : faLock} />
            </span>
            )}
            {!!canOpenAndClose && !!open && <FontAwesomeIcon className="icon" onClick={onClick} icon={faChevronDown} />}
            {!!canOpenAndClose && !open && <FontAwesomeIcon className="icon" onClick={onClick} icon={faChevronRight} />}
          </div>
      )}
      >
        {props.children}
        {props.canEdit && isEditing === false && <div className="lock" />}
      </Card>
    </div>
  );
}
