import { BaseService } from '../base.service';
import { MCreative } from '../../modeles';

class ClientManagerCreativesService extends BaseService {
  PATH = 'client-manager/creatives/';

  async addIec(iec: MCreative): Promise<MCreative | null> {
    return this._add(`${this.PATH}add-iec`, iec);
  }
}

export const clientManagerCreativesService = new ClientManagerCreativesService();
