import { MUser } from '../../modeles';
import { BaseService } from '../base.service';

class AdminUsersService extends BaseService {
  PATH = 'admin/users/';

  async listing(): Promise<MUser[] | null> {
    return this._view(`${this.PATH}listing`);
  }

  async view(id: number): Promise<MUser | null> {
    return this._view(`${this.PATH}view/${id}`);
  }

  async edit(id: number, app: MUser): Promise<MUser | null> {
    return this._edit(`${this.PATH}super-edit/${id}`, app);
  }

  async isActive(id: number, isActive: boolean): Promise<MUser[]> {
    return await this._listing(`${this.PATH}super-is-active/${id}/${isActive}`) || [];
  }

  async isClientManager(id: number, isClientManager: boolean): Promise<MUser[]> {
    return await this._listing(`${this.PATH}super-is-client-manager/${id}/${isClientManager}`) || [];
  }

  async isAdmin(id: number, isAdmin: boolean): Promise<MUser[]> {
    return this._listing(`${this.PATH}super-is-admin/${id}/${isAdmin}`);
  }

  async sendNewPassword(id: number): Promise<MUser[] | null> {
    return await this._listing(`${this.PATH}send-new-password/${id}`) || [];
  }
}

export const adminUsersService = new AdminUsersService();
