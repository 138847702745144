/* eslint-disable react/no-danger */
import {
  useCallback,
  useEffect, useRef, useState,
} from 'react';
import { FullscreenOutlined, ReloadOutlined } from '@ant-design/icons';

import {
  AppScale,
  ComponentType,
  MCompBackground,
  MCompBase,
  MCompBorder,
  MCompBoxShadow,
  MCompTextStroke,
  MCompFlex,
  MCompImage,
  MCompOverlay,
  MCompBlur,
  MCompText,
  MCreativeElementBase,
  MCompAnimation,
  MCompButton,
  ExportType,
  MCreative,
  MCompTextShadow,
  MCompTemplatedBackground,
} from '../../../../../../modeles';
import { useIec } from '../../../../context/iec.context';
import { PreviewCompBackground } from '../preview-comp-background';
import { PreviewCompAnimation } from '../preview-comp-animation';
import { PreviewCompBorder } from '../preview-comp-border';
import { PreviewCompImage } from '../preview-comp-image';
import { PreviewCompText } from '../preview-comp-text';
import { PreviewCompOverlay } from '../preview-comp-overlay';
import { PreviewCompBlur } from '../preview-comp-blur';
import { PreviewCompFlex } from '../preview-comp-flex';
import { PreviewCompButton } from '../preview-comp-button';
import { PreviewCompTemplatedBackground } from '../preview-comp-templated-background';
import { useMove } from '../../../../context/mouse.context';

import './preview-element.scss';

export const PreviewElement_CSS = `
.preview-element {
  position: absolute;
}
`;

export function PreviewElement(props: {
  element: MCreativeElementBase,
  base64?: { [key:string]: string },
  exportType?: ExportType,
  language?: string,
  iec?: MCreative,
}) {
  const uIec = useIec();
  const uMove = useMove();
  const rElem = useRef<HTMLDivElement>(null);
  const R2D = 180 / Math.PI;

  const [elem, _setElem] = useState(props.element);
  const [isRotate, _setIsRotate] = useState(false);
  const [isMove, _setIsMove] = useState(false);
  const [isResize, _setIsResize] = useState(false);
  const [startAngle, _setStartAngle] = useState(0);
  const [lastAngle, _setLastAngle] = useState(0);
  const [rotateCenter, _setRotateCenter] = useState({ x: 0, y: 0 });

  const clearAndSave = () => {
    if (isMove || isResize || isRotate) {
      _setIsMove(false);
      _setIsResize(false);
      _setIsRotate(false);
      _setLastAngle(elem.transform.rotate);
      props.element.transform = { ...elem.transform };
      uIec.refreshIec(true);
    }
  };

  const isActive = () => {
    return props.element._edite as boolean;
  };

  const toggleElement = (element: MCreativeElementBase) => {
    uIec.setNodeEdite(element);
  };

  const getTr = useCallback(() => {
    if (elem.transform.isTransformDifferent) {
      if (uIec.getPhoneOrientation() === 'landscape') {
        return elem.transform.landscape;
      }
      if (uIec.getPhoneOrientation() === 'portrait') {
        return elem.transform.portrait;
      }
    }
    return elem.transform;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elem]);

  useEffect(() => {
    if (!props.base64 && uMove.eventMove) {
      if (isMove) {
        getTr().x += uMove.eventMove.movementX * AppScale();
        getTr().y += uMove.eventMove.movementY * AppScale();
        elem.transform.x = getTr().x;
        elem.transform.y = getTr().y;
        _setElem({ ...elem });
      }
      if (isRotate) {
        const d = R2D * Math.atan2(uMove.eventMove.clientY - rotateCenter.y, uMove.eventMove.clientX - rotateCenter.x);
        const rotation = d - startAngle;
        elem.transform.rotate = lastAngle + rotation;
        getTr().rotate = elem.transform.rotate;
        _setElem({ ...elem });
      }
      if (isResize) {
        const r = (elem.transform.rotate + 360) % 360;
        const t = r * (Math.PI / 180);
        const x1 = uMove.eventMove.nativeEvent.movementX * AppScale();
        const y1 = uMove.eventMove.nativeEvent.movementY * AppScale();
        const x = x1 * Math.cos(t) + y1 * Math.sin(t);
        const y = y1 * Math.cos(t) - x1 * Math.sin(t);
        getTr().width += x;
        getTr().height += y;
        getTr().x += (x1 - x) / 2;
        getTr().y += (y1 - y) / 2;
        elem.transform.width = getTr().width;
        elem.transform.height = getTr().height;
        elem.transform.x = getTr().x;
        elem.transform.y = getTr().y;
        _setElem({ ...elem });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uMove.eventMove]);

  useEffect(() => {
    if (!props.base64) {
      clearAndSave();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uMove.nbUp]);

  useEffect(() => {
    const keydown = (event: KeyboardEvent) => {
      const localName : string = (event.target as Element).localName;
      if (props.element._edite && ['input'].includes(localName) === false) {
        if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.code as string)) {
          let add = 1;
          if (event.shiftKey) {
            add = 10;
          }
          if (event.altKey) {
            if (event.code === 'ArrowUp') { getTr().height -= add; }
            if (event.code === 'ArrowDown') { getTr().height += add; }
            if (event.code === 'ArrowLeft') { getTr().width -= add; }
            if (event.code === 'ArrowRight') { getTr().width += add; }
          } else {
            if (event.code === 'ArrowUp') { getTr().y -= add; }
            if (event.code === 'ArrowDown') { getTr().y += add; }
            if (event.code === 'ArrowLeft') { getTr().x -= add; }
            if (event.code === 'ArrowRight') { getTr().x += add; }
          }
          props.element.transform = { ...elem.transform };
          uIec.refreshIec(true);
          event.preventDefault();
        } else if (['Escape'].includes(event.code as string)) {
          uIec.setNodeEdite(uIec.getBreakActive());
        }
      }
    };
    document.addEventListener('keydown', keydown);
    _setElem(props.element);
    return () => {
      document.removeEventListener('keydown', keydown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.element]);

  const getParentSize = (field: string) : number => {
    if (rElem.current && rElem.current!.parentElement) {
      return (rElem.current!.parentElement! as any)[field] as number;
    }
    return -1;
  };

  const posRight = () : boolean => {
    const t = getTr();
    return t.width + t.x - getParentSize('clientWidth') === 0;
  };

  const posBottom = () : boolean => {
    const t = getTr();
    return t.height + t.y - getParentSize('clientHeight') === 0;
  };

  const posMiddle = () : boolean => {
    const t = getTr();
    return Math.abs(getParentSize('clientHeight') / 2 - (t.y + t.height / 2)) < 1;
  };
  const posCenter = () : boolean => {
    const t = getTr();
    return Math.abs(getParentSize('clientWidth') / 2 - (t.x + t.width / 2)) < 1;
  };

  const compFind = (type: ComponentType) => { return props.element.components.find((comp) => comp._type === type) as MCompBase; };
  const compText = () => {
    const currentIec = props.iec ? props.iec : uIec.iec;
    if (!props.language || props.language === 'ZZ') {
      return compFind('text') as MCompText;
    }
    const text = compFind('text') as MCompText;
    if (!text) {
      return text as MCompText;
    }
    const translation = currentIec.translations.find((translate) => text.text === translate.DEFAULT);
    const textTranslate = translation[props.language]?.length > 0 ? translation[props.language] : translation.DEFAULT;
    return { ...text, text: textTranslate } as MCompText;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  const compBorder = () => { return compFind('border') as MCompBorder; };
  const compBackground = () => { return compFind('background') as MCompBackground; };
  const compImage = () => { return compFind('image') as MCompImage; };
  const compOverlay = () => { return compFind('overlay') as MCompOverlay; };
  const compBlur = () => { return compFind('blur') as MCompBlur; };
  const compButton = () => { return compFind('button') as MCompButton; };
  const compBoxShadow = () => { return compFind('box-shadow') as MCompBoxShadow; };
  const compTextShadow = () => { return compFind('text-shadow') as MCompTextShadow; };
  const compTextStroke = () => { return compFind('text-stroke') as MCompTextStroke; };
  const compAnimation = () => { return compFind('animation') as MCompAnimation; };
  const compFlex = () => { return compFind('flex') as MCompFlex; };
  const compTemplatedBackground = () => { return compFind('templated-background') as MCompTemplatedBackground; };

  const getPointerEvents = (): 'none' | 'auto' => {
    if (props.base64) {
      return 'none';
    }
    return props.element._isLocked ? 'none' : 'auto';
  };

  const getDisplayStyle = (): 'none' | 'initial' => {
    if (props.base64) {
      return 'initial';
    }
    return props.element._isHidden ? 'none' : 'initial';
  };

  return (
    <div
      ref={rElem}
      className="preview-element"
      id={props.element._key}
      style={{
        width: `${getTr().width}px`,
        height: `${getTr().height}px`,
        left: `${getTr().x}px`,
        top: `${getTr().y}px`,
        transform: `rotate(${getTr().rotate}deg)`,
        zIndex: (isRotate || isMove || isResize) ? 1 : 0,
        pointerEvents: `${getPointerEvents()}`,
        display: `${getDisplayStyle()}`,
      }}
      onMouseDown={(e) => {
        if (!props.base64) {
          e.stopPropagation();
          _setIsMove(true);
          toggleElement(props.element);
        }
      }}
      onMouseUp={() => {
        if (!props.base64) {
          clearAndSave();
        }
      }}
    >
      <PreviewCompAnimation animation={compAnimation()}>
        <PreviewCompBorder border={compBorder()} boxShadow={compBoxShadow()}>
          {compBackground() && <PreviewCompBackground background={compBackground()} />}
          {compImage() && <PreviewCompImage base64={props.base64} image={compImage()} />}
          {compText() && <PreviewCompText text={compText()!} textShadow={compTextShadow()} textStroke={compTextStroke()} />}
          {compOverlay() && <PreviewCompOverlay overlay={compOverlay()!} />}
          {compBlur() && <PreviewCompBlur blur={compBlur()!} />}
          <PreviewCompFlex flex={compFlex()}>
            {props.element.elements.map(
              (element) => <PreviewElement exportType={props.exportType} element={element} key={element._key} base64={props.base64} />,
            )}
          </PreviewCompFlex>
          {props.base64 && compButton() && <PreviewCompButton exportType={props.exportType} button={compButton()!} />}
          {compTemplatedBackground() && <PreviewCompTemplatedBackground templatedBackground={compTemplatedBackground()!} />}
        </PreviewCompBorder>
      </PreviewCompAnimation>

      {/* ================================================================================================================================================ */}
      {!props.base64 && isActive()
        && (
        <>
          {posCenter() && <div className="pos-center" />}
          {posMiddle() && <div className="pos-middle" />}
          <div className="bordered">&nbsp;</div>
          {getTr().y === 0 && <div className="pos-top" />}
          {getTr().x === 0 && <div className="pos-left" />}
          {posRight() && <div className="pos-right" />}
          {posBottom() && <div className="pos-bottom" />}
          {/* ROTATION */}
          <div
            className="e-b-rotate"
            onMouseDown={(e) => {
              e.stopPropagation();
              const bb = e.currentTarget.parentElement?.getBoundingClientRect();
              const center = {
                // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                x: bb!.left + (bb!.width / 2),
                // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                y: bb!.top + (bb!.height / 2),
              };
              _setRotateCenter(center);
              _setStartAngle(R2D * Math.atan2(e.clientY - center.y, e.clientX - center.x));
              _setIsRotate(true);
            }}
            onMouseUp={() => {
              clearAndSave();
            }}
          >
            <div className="e-b-content"><ReloadOutlined /></div>
          </div>
          {/* RESIZE */}
          <div
            className="e-b-resize"
            onMouseDown={(e) => {
              _setIsResize(true);
              e.stopPropagation();
            }}
            onMouseUp={() => { clearAndSave(); }}
          >
            <div className="e-b-content"><FullscreenOutlined /></div>
          </div>
        </>
        )}
      {props.base64 && props.element.transform.isTransformDifferent && (
      <script dangerouslySetInnerHTML={
        {
          __html: `
          function _${props.element._key}() {
            const elem = document.getElementById('${props.element._key}');
            if (getVW() > getVH()) {
              elem.style.width = '${props.element.transform.landscape.width}px';
              elem.style.height = '${props.element.transform.landscape.height}px';
              elem.style.left = '${props.element.transform.landscape.x}px';
              elem.style.top = '${props.element.transform.landscape.y}px';
            } else {
              elem.style.width = '${props.element.transform.portrait.width}px';
              elem.style.height = '${props.element.transform.portrait.height}px';
              elem.style.left = '${props.element.transform.portrait.x}px';
              elem.style.top = '${props.element.transform.portrait.y}px';
            }
          }
          _${props.element._key}();
          __onResize.push(() => {
            _${props.element._key}();
          });
            `,
        }
      }
      />
      )}
    </div>
  );
}
