/* eslint-disable import/no-cycle */
import { BaseService, ITokens } from './base.service';
import { UserAuth } from '../contexts/auths.context';
import { MUser } from '../modeles';

class AuthsService extends BaseService {
  PATH = 'auths/';

  async signup(data: any): Promise<UserAuth | null> {
    const response = await this.post(`${this.PATH}signup`, { ...data, isGoogleAd: localStorage.getItem('isGoogleAd') as string });
    if (response?.token) {
      this.setToken(response.token as string);
      return response.user as UserAuth;
    }
    if (response?.data.token) {
      this.setToken(response?.data.token as string);
    }
    return null;
  }

  async login(data: any): Promise<UserAuth | null> {
    const response = await this.post(`${this.PATH}login`, data);
    if (response?.token) {
      this.setToken(response.token as string);
      this.addToken(response.token as string, response.user as MUser);
      return response.user as UserAuth;
    }
    return null;
  }

  async loginAsUser(userId: number, asSuperAdmin: boolean): Promise<UserAuth | null> {
    const response = await this.get(`admin/${this.PATH}login-as-user/${userId}?asSuperAdmin=${asSuperAdmin}`, {});
    if (response?.token) {
      this.setToken(response.token as string);
      return response.user as UserAuth;
    }
    return null;
  }

  async autologin(): Promise<UserAuth | null> {
    if (this.getToken()) {
      const response = await this.get(`${this.PATH}autologin`, {});
      if (response?.token) {
        this.setToken(response.token as string);
        return response.user as UserAuth;
      }
    }
    return null;
  }

  async logout(): Promise<boolean> {
    await this.get(`${this.PATH}logout`, {});
    this.delToken();
    return true;
  }

  async forgotPassword(email: string): Promise<true | []> {
    return await this._getSuccess(`${this.PATH}forgot-password/${email}`) || [];
  }

  async resetPassword(data: any): Promise<boolean> {
    if (await this.post(`${this.PATH}reset-password`, data)) {
      return true;
    }
    return false;
  }

  async validateAccount(token: string): Promise<boolean> {
    return await this._getSuccess(`${this.PATH}active-account/${token}`) || false;
  }

  async newVerificationMail(token: string = this.getToken()): Promise<boolean> {
    return await this._getSuccess(`${this.PATH}new-verification-mail/${token}`) || false;
  }
}

export const authsService = new AuthsService();
