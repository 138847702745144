import { MDocument } from './document.m';
import { MCreative } from './creative.m';
import { MNetwork } from './network.m';
import { MAccount } from './account.m';

export class MCreativeExport {
  id: number = 0;
  name: string = '';
  creativeId: number = 0;
  accountId: number = 0;
  type: string = '';
  exportId: number = 0;
  networks: MNetwork[] = [];
  Creative?: MCreative;
  Export?: MDocument;
  createdAt?: Date;
  platform?: string;
  Account?: MAccount;
  deletedAt?: Date;
}
