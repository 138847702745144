import { YcTitle } from '../../../../components';
import { PlayableEngines, PlayableSdk } from './components';

import './sdk-engine.page.scss';

export function SdkEnginePage() {
  return (
    <div id="sdk-engine">
      <YcTitle label="SDK / Engines" />
      <div className="row">
        <div className="col-7">
          <PlayableSdk />
        </div>
        <div className="col-5">
          <PlayableEngines />
        </div>
      </div>
    </div>
  );
}
