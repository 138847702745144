/* eslint-disable jsx-a11y/media-has-caption */
import { useRef } from 'react';
import { APP_WIDTH } from '../../../../../../modeles';
import { useIec } from '../../../../context/iec.context';
import { PreviewBreak } from '../preview-break';
import { PreviewLoop } from '../preview-loop';
import { PreviewEndcard } from '../preview-endcard';
import { PreviewHover } from '../preview-hover';
import { PreviewVideo } from '../preview-video';

import './preview-iec.scss';
import { useCreative } from '../../../../context/creative.context';

export type ScreenModeType = 'fill-width' | 'fill-height' | 'fill-both';

export function PreviewIec() {
  const rContent = useRef<HTMLDivElement>(null);
  const uIec = useIec();
  const uCreative = useCreative();

  const screenMode = () : ScreenModeType => {
    if (rContent.current) {
      const dW = rContent.current!.clientWidth as number;
      const dH = rContent.current!.clientHeight as number;
      const vW = uIec.iec.iecData.width;
      const vH = uIec.iec.iecData.height;
      const wR = vW / dW;
      const hR = vH / dH;
      if (wR > hR) {
        return 'fill-width';
      }
      if (wR < hR) {
        return 'fill-height';
      }
    }
    return 'fill-both';
  };

  const getZoom = () => {
    if (uIec.getPhoneOrientation() === 'landscape') {
      return uIec.iec.iecData.zoomLandscape;
    }
    return uIec.iec.iecData.zoomPortrait;
  };

  const getStyleContent = () : React.CSSProperties => {
    if (rContent.current) {
      const dW = rContent.current!.clientWidth as number;
      const dH = rContent.current!.clientHeight as number;
      const vW = uIec.iec.iecData.width;
      const vH = uIec.iec.iecData.height;
      const mode = screenMode();
      uIec.setScreenMode(mode);
      if (mode === 'fill-height') {
        const ratio = (dH / APP_WIDTH) * (vW / vH);
        const height = dH / ratio;
        const width = vW * ((dH / vH) / ratio);
        return {
          width: `${width}px`,
          height: `${height}px`,
          transform: `scale(${ratio * getZoom()})`,
        };
      }
      const ratio = dW / APP_WIDTH;
      const width = dW / ratio;
      const height = vH * ((dW / vW) / ratio);
      return {
        width: `${width}px`,
        height: `${height}px`,
        transform: `scale(${ratio * getZoom()})`,
      };
    }
    return {
      width: '100%',
      height: '100%',
    };
  };

  return (
    <div className="preview-iec" ref={rContent}>
      {uIec.getVideoActive() ? <PreviewVideo video={uIec.getVideoActive()!} /> : ''}
      <div className="preview-iec-flex">
        <div className="preview-iec-contener" style={getStyleContent()}>
          {uIec.getBreakActive() ? <PreviewBreak br={uIec.getBreakActive()!} language={uCreative.currentLanguage} /> : ''}
          {uIec.getLoopActive() ? <PreviewLoop loop={uIec.getLoopActive()!} language={uCreative.currentLanguage} /> : ''}
          {(uIec.getVideoActive() && uIec.getVideoActive()!.hover)
            ? <PreviewHover isEditable={uIec.getVideoHoverActive()?._key === uIec.getVideoActive()!.hover!._key} hover={uIec.getVideoActive()!.hover!} />
            : ''}
          {!uIec.getVideoActive() && uIec.getHoverActive()
            ? <PreviewHover isEditable hover={uIec.getHoverActive()!} language={uCreative.currentLanguage} />
            : ''}
          {uIec.getEndcardActive() ? <PreviewEndcard endcard={uIec.getEndcardActive()!} language={uCreative.currentLanguage} /> : ''}
        </div>
      </div>
    </div>
  );
}
