import { MCompOverlay } from '../../../../../modeles';
import { ColorTools } from '../../../../../tools';

export function PreviewCompOverlay(props : {
  overlay: MCompOverlay,
}) {
  return (
    <div
      style={{
        filter: `blur(${props.overlay.blur}px)`,
        boxShadow: `0 0 0 calc(max(300vw, 300vh)) ${ColorTools.mColorToString(props.overlay.layoutColor)}`,
        borderRadius: `${props.overlay.borderRadius}%`,
        height: '100%',
      }}
    />
  );
}
