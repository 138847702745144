import { BaseService } from './base.service';
import { MCustomTemplate } from '../modeles';

class CustomTemplateService extends BaseService {
  PATH = 'iec-node-saved/';

  async listing(): Promise<MCustomTemplate[] | []> {
    return this._listing(`${this.PATH}listing`);
  }

  async add(node: MCustomTemplate): Promise<MCustomTemplate | null> {
    return this._add(`${this.PATH}add`, node);
  }

  async edit(node: MCustomTemplate): Promise<MCustomTemplate | null> {
    return this._edit(`${this.PATH}edit/${node.id}`, node);
  }

  async remove(id: number): Promise<MCustomTemplate | null> {
    return this._delete(`${this.PATH}delete/${id}`);
  }
}

export const customTemplateService = new CustomTemplateService();
