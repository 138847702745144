import { useEffect, useState } from 'react';
import {
  Button,
  Input,
  Space,
  Image,
  Modal,
  Upload,
  UploadProps,
  message,
  UploadFile,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus } from '@fortawesome/pro-regular-svg-icons';

import { usePlayable } from '../../../../context/playable.context';
import { YcCard } from '../../../../../../components';
import { IImageJson as JsonType, ISpriteJson } from '../../../../../../modeles/MP_Interfaces';
import { SettingCompColor, SettingCompInputCheckbox } from '../../sub-settings';

import './setting-comp-image.scss';

export function SettingCompImage(props: { initialData: any, parentID: string, icon?: string }) {
  const childType: string = props.initialData.type;
  const childID: string = props.initialData.instanceID;
  const parentID: string = props.parentID;

  const uPlayable = usePlayable();

  const [compTypeIndex, _setCompTypeIndex] = useState<number>();
  const [component, _setComponent] = useState<JsonType>();

  useEffect(() => {
    if (compTypeIndex === undefined) {
      _setCompTypeIndex(uPlayable.getCompTypeIndex(childType, parentID, childID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (props !== undefined && compTypeIndex !== undefined) {
      _setComponent(uPlayable.getRunTimeComponent(`${childType}[${compTypeIndex}]`) as JsonType);
    }
  }, [childType, compTypeIndex, props, uPlayable]);

  const editComponent = (propertyPath: string, value: any, update: boolean = true) => {
    if (compTypeIndex !== undefined) {
      uPlayable.updateGameObject(value, `${childType}[${compTypeIndex}]/${propertyPath}`, childID, update);
    }
  };

  const editSprite = (sprite: any, isCustom: boolean) => {
    editComponent('sprite', sprite, false);
    editComponent('sprite_Infos', { id: sprite.instanceID });

    if (isCustom) {
      uPlayable.addModelTextureToJson(sprite.texture);
      uPlayable.addModelSpriteToJson(sprite);
    }
  };

  return (
    <YcCard
      title="Image"
      icon={props.icon}
      light
      open
      warning={compTypeIndex === undefined}
      titleExtra={(
        <SettingCompInputCheckbox
          checked={component?.enabled}
          onChange={(e) => editComponent('enabled', e)}
        />
      )}
    >
      <div className="row setting-comp-image">
        <div className="col-12">
          <div className="col-12 d-flex align-items-center">
            <SettingCompSprite
              label="Source Image"
              // @ts-ignore
              sprite={component?.sprite as ISpriteJson}
              // TO REWORK
              onSpriteChange={(sprite, isCustom = false) => { editSprite(sprite, isCustom); }}
            />
          </div>
          <SettingCompColor
            defaultType="white"
            label="Color"
            color={component?.color}
            onChange={(e, updateJson) => editComponent('color', e, updateJson)}
          />
          <div className="image-displayed">
            {/* @ts-ignore */}
            <Image width={150} height={150} src={component?.sprite.texture?.mp_img64} />
          </div>
        </div>
      </div>
    </YcCard>
  );
}

export function SettingCompSprite(props: {
  sprite: ISpriteJson,
  label: string,
  onSpriteChange: (sprite: any, custom?: boolean) => void,
}) {
  const uPlayable = usePlayable();

  const [spriteModalIsOpen, _setSpriteModalIsOpen] = useState<boolean>(false);

  const editSprite = (spriteId: string) => {
    const sprite = uPlayable.playableWindow.monsterplayable.workflow.resourceLoader.GetSpriteById(spriteId);
    props.onSpriteChange(sprite);
  };

  return (
    <div className="row col-12">
      <div className="col-5">
        <label>{props.label}</label>
      </div>
      <div className="col-7">
        <Space direction="horizontal">
          <Input
            disabled
            value={props.sprite?.name}
          />
          {/* @ts-ignore */}
          <Button onClick={() => _setSpriteModalIsOpen(true)} icon={<FontAwesomeIcon icon={faEdit} />} />
        </Space>
      </div>
      <Modal title="Sprites" width={1000} open={spriteModalIsOpen} footer={null} onCancel={() => _setSpriteModalIsOpen(false)}>
        <SpritesModal
          currentSprite={props.sprite?.instanceID || ''}
          onSpriteSelected={(spriteId) => { editSprite(spriteId); }}
          onCustomSpriteAdded={(sprite) => { props.onSpriteChange(sprite, true); }}
        />
      </Modal>
    </div>
  );
}

export function SpritesModal(props: {
  currentSprite: string,
  onSpriteSelected: (spriteId: string) => void
  onCustomSpriteAdded: (sprite: any) => void
}) {
  const uPlayable = usePlayable();

  const [sprites, _setSprites] = useState<any | null>(null);
  const [fileList, _setFileList] = useState<UploadFile<any>[]>([]);

  useEffect(() => {
    _setSprites(uPlayable.playableWindow.monsterplayable.workflow.assets.sprites);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentSprite]);

  const getBase64 = (file: Blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadProps: UploadProps = {
    name: 'file',
    fileList,
    onChange: ({ fileList: e }) => handleChange(e),
    beforeUpload: (file) => {
      const isValid = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
      if (!isValid) {
        message.error(`${file.name} is not a image file`);
        return false;
      }
      return true;
    },
  };

  const handleSelectImg = async (file: UploadFile) => {
    try {
      const src = await getBase64(file.originFileObj!);

      uPlayable.playableClasses.Texture2D.LoadSpriteFront({ url: src, name: file.name.split('.')[0] }, (sprite: any) => {
        props.onCustomSpriteAdded(sprite);
      });
    } catch (error) {
      message.error('File conversion failed');
    }
  };

  const handleChange = (list: UploadFile[]) => {
    handleSelectImg(list[0]);
    _setFileList([]);
  };

  return (
    <div className="sprites-container">
      <div className="mb-2">
        <Upload {...uploadProps}>
          <Button type="primary" icon={<FontAwesomeIcon icon={faPlus} />} iconPosition="end">New Sprite</Button>
        </Upload>
      </div>

      {
        sprites
        && (
          <div className="sprites">
            {
              Object.keys(sprites as { [key: string]: any }).map((spriteId) => {
                return (
                  <div
                    className={`${props.currentSprite === spriteId ? 'selected' : ''} sprite`}
                    key={spriteId}
                    onClick={() => { props.onSpriteSelected(spriteId); }}
                  >
                    <Image
                      preview={false}
                      src={sprites[spriteId].texture.mp_img64}
                      className="image"
                    />
                    <p className="label">
                      {
                        sprites[spriteId].texture.name
                      }
                    </p>
                  </div>
                );
              })
            }
          </div>
        )
      }

    </div>
  );
}
