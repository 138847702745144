import { useEffect, useState } from 'react';
import { Alert, Spin } from 'antd';

import { usePlayable } from '../../../context/playable.context';

import './logs-view.scss';

interface ILogsView {
  type: 'success' | 'info' | 'warning' | 'error' | undefined;
  message: string;
}

enum Type {
  'log' = 'info',
  'warn' = 'warning',
  'error' = 'error',
}

export function LogsView() {
  const uPlayable = usePlayable();

  const [logs, _setLogs] = useState<ILogsView[]>([{ type: 'info', message: 'Connection to playable logs' }]);
  const [isReady, _setIsReady] = useState<boolean>(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const cleanLogs: ILogsView[] = uPlayable.playableWindow?.capturedLogs?.map((element: { type: string; message: string }) => ({
        type: Type[element.type as keyof typeof Type],
        message: element.message,
      })) || [];

      _setLogs(cleanLogs);
      _setIsReady(true);
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    _setIsReady(false);
    _setLogs([{ type: 'info', message: 'Connection to playable logs' }]);
  }, [uPlayable.playableIsPlaying]);

  return (
    <div className="logs-view">
      <Spin spinning={!isReady}>
        {
          logs.map((log, index) => (
            <Alert message={log.message} type={log.type} showIcon key={index} className="mb-2" />
          ))
        }
      </Spin>
    </div>
  );
}
