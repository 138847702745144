import {
  Button, Form, Input, Select, Upload, message,
} from 'antd';
import {
  RcFile, UploadChangeParam, UploadFile, UploadProps,
} from 'antd/es/upload';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';

import { MAsset, MDocument } from '../../../../modeles';
import { assetService } from '../../../../services/asset.service';

import './upload-asset.scss';

const SelectOption = Select.Option;

export function UploadAsset(props: {
  modalOk: (doc: MDocument) => Promise<void>,
  modalCancel: () => void,
  defaultType: string,
  maxWeight: number,
}) {
  const [isLoading, _setIsLoading] = useState<boolean>(false);
  const [asset, _setAsset] = useState<MAsset>(new MAsset());
  const [types, _setTypes] = useState<string[]>([]);
  const [newType, _setNewType] = useState<string>();
  const [form] = Form.useForm();

  useEffect(() => {
    if (!form.getFieldValue('name')) {
      form.setFieldValue('name', asset.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset]);

  useEffect(() => {
    assetService.types().then((data) => {
      if (data) {
        data = [...new Set([...data, props.defaultType])];
        _setTypes(data || []);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetModal = () => {
    _setAsset(new MAsset());
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG/GIF file!');
    }
    const weight = file.size / 1024 / 1024 < props.maxWeight;
    if (!weight) {
      message.error(`Image must smaller than ${props.maxWeight < 1 ? `${props.maxWeight * 1000}KB` : `${props.maxWeight}MB`}!`);
    }
    return isJpgOrPng && weight;
  };

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      _setIsLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      _setIsLoading(false);
      _setAsset({
        ...asset,
        name: asset.name ? asset.name : info.file.response.data.originalFileName.split('.')[0],
        Document: info.file.response.data as MDocument,
        documentId: info.file.response.data.id,
      });
    }
  };

  const submitAsset = async (values: any) => {
    const newAsset = await assetService.add({
      name: values.name,
      documentId: asset.documentId,
      isPrivate: true,
      isFree: true,
      type: values.type,
    } as MAsset);

    if (newAsset && newAsset.Document) {
      props.modalOk(newAsset.Document);
      resetModal();
    }
  };

  const cancel = () => {
    props.modalCancel();
    resetModal();
  };

  const uploadButton = (
    <div>
      {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const valideNewType = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (newType) {
        _setTypes([...new Set([...types, newType])]);
      }
      form.setFieldValue('type', newType);
    }
  };

  return (
    <div id="upload-asset">
      <Form className="row" id="formCreate" onFinish={(e) => { submitAsset(e); }} form={form} layout="vertical">
        <Form.Item className="col-6" label="Name" name="name" rules={[{ required: true }]} initialValue={asset.name}>
          <Input disabled={asset.id !== 0} type="text" placeholder="My click" />
        </Form.Item>
        <Form.Item className="col-6" label="Type" name="type" rules={[{ required: true }]} initialValue={asset.type || props.defaultType}>
          <Select showSearch onSearch={(e) => { _setNewType(e); }} onKeyDown={(e) => { valideNewType(e); }}>
            {types.map((type) => (
              <SelectOption key={type} value={type}>
                {type}
              </SelectOption>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          className="col-12"
          label={`Asset (Image must smaller than ${props.maxWeight < 1 ? `${props.maxWeight * 1000}KB` : `${props.maxWeight}MB`}!)`}
          name="asset"
          rules={[{ required: true }]}
          initialValue={asset.Document?.url}
        >
          <Upload
            disabled={asset.id !== 0}
            name="asset"
            listType="picture-card"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            headers={{
              authorization: localStorage.getItem('user-token') || '',
            }}
            action={`${process.env.REACT_APP_API_URL}/asset/upload-asset`}
            maxCount={1}
          >
            {
              asset.id === 0 && (asset.Document?.originalFileName
                ? <img src={asset.Document.url} alt="asset" style={{ width: '100%' }} />
                : uploadButton)
            }
            {
              asset.id !== 0 && (<img src={asset.Document?.url} alt="asset" style={{ width: '100%' }} />)
            }
          </Upload>
        </Form.Item>
        <Form.Item className="col-12">
          <Button onClick={cancel}>Cancel</Button>
          <Button type="primary" htmlType="submit">Submit</Button>
        </Form.Item>
      </Form>
    </div>
  );
}
