import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Form, Input, Button, Result, Spin,
} from 'antd';
import * as ImagesSignup from '../../../../assets/images/sign-up';

import { authsService } from '../../../../services';

import './reset-password.page.scss';

export function ResetPasswordPage() {
  const [passwordChanged, _setPasswordChanged] = useState<boolean>(false);
  const [token, _setToken] = useState<string>('');
  const [loading, _setLoading] = useState<boolean>(false);

  const uHistory = useHistory();
  const uParams: any = useParams();

  useEffect(() => {
    if (uParams.token) {
      _setToken(uParams.token as string);
    } else {
      uHistory.push('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values: any) => {
    _setLoading(true);
    authsService.resetPassword({ ...values, token }).then((bool) => {
      if (bool) {
        _setPasswordChanged(true);
      }
      _setLoading(false);
    });
  };

  return (
    <div id="root-forgot-password">
      <img src={ImagesSignup.Bkgcontent} className="bkgcontent" alt="monster" />
      <div className="block-bkg">
        <img src={ImagesSignup.Monster} className="monster" alt="monster" />
        <div className="block-auth">
          <h1>Reset password</h1>
          <Spin spinning={loading}>
            <div className="block">
              {!passwordChanged
                ? (
                  <Form onFinish={onFinish} layout="vertical">
                    <Form.Item label="New password" name="newPassword" rules={[{ required: true }]}>
                      <Input.Password placeholder="password" type="password" />
                    </Form.Item>
                    <Form.Item label="Confirm password" name="confirmPassword" rules={[{ required: true }]}>
                      <Input.Password placeholder="password" type="password" />
                    </Form.Item>
                    <Form.Item style={{ marginBottom: '0px' }}>
                      <Button htmlType="submit" type="primary">Send</Button>
                    </Form.Item>
                  </Form>
                )
                : (
                  <Result
                    status="success"
                    title="You successfully restore your password!"
                    extra={[
                      <Link to="/login">
                        <Button type="primary" key="console">
                          Login
                        </Button>
                      </Link>,
                    ]}
                  />
                )}
            </div>
          </Spin>
        </div>
      </div>
    </div>
  );
}
