import { message } from 'antd';
import { MCustomFont } from '../modeles';

export class FilesTools {
  static async getFileInfos(file: File): Promise<{ width: number, height: number, sizeMb: number }> {
    return new Promise((resolve) => {
      const fr = new FileReader();
      fr.onload = () => {
        const img = new Image();
        img.onload = () => {
          resolve({
            width: img.width,
            height: img.height,
            sizeMb: file.size / 1000 / 1000,
          });
        };
        img.src = `${fr.result?.toString() as string}`;
      };
      fr.readAsDataURL(file);
    });
  }

  static async checkFileInfos(file: File, types: string[], sizes: Array<Array<number>> = [], maxSizeMb = 1000000): Promise<boolean> {
    if (types.includes(file.type as string) === false) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.error(`Image need to be ${types.join(', ')}`);
    } else {
      const infos = await FilesTools.getFileInfos(file);
      if (infos.sizeMb <= maxSizeMb) {
        if (!sizes || sizes.length === 0 || sizes.find((s) => s[0] === infos.width && s[1] === infos.height)) {
          return true;
        }
        const s: any = [...sizes];
        for (const i in s) {
          if (s[i]) {
            s[i] = s[i].join('x');
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-floating-promises, @typescript-eslint/restrict-template-expressions
        message.error(`Image size need to be ${s.join(', ')}`);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(`Image size max ${maxSizeMb}MB`);
      }
    }
    return false;
  }

  static async getBase64FromUrl(url: string): Promise<string | null> {
    try {
      const data = await fetch(url);
      const blob = await data.blob();
      return await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          if (typeof base64data === 'string') {
            resolve(base64data as string);
          } else {
            resolve(null);
          }
        };
      });
    } catch (e) {
      console.log('getBase64FromUrl', e);
    }
    return null;
  }

  static async getSizeFromUrl(url: string): Promise<number> {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        return 0;
      }

      const blob = await response.blob();
      const sizeInBytes = blob.size;

      return sizeInBytes as number;
    } catch (error) {
      return 0;
    }
  }

  static async setBase64Fonts(fonts: MCustomFont[]) {
    const embedFonts: { name: string, src: string }[] = await Promise.all(fonts.map(async (font) => {
      const b64 = await FilesTools.getBase64FromUrl(font.Document?.url || '') as string;
      return {
        name: font.name,
        src: b64,
      };
    }));
    return embedFonts;
  }
}
