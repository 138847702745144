import { useEffect, useState } from 'react';

import { usePlayable } from '../../../../context/playable.context';
import { YcCard } from '../../../../../../components';
// import { ICanvasRendererJson as JsonType } from '../../../../../../modeles/MP_Interfaces';
import { SettingCompInputBoolean } from '../../sub-settings';

import './setting-comp-canvas-renderer.scss';

export function SettingCompCanvasRenderer(props: {
  initialData: any,
  parentID: string,
  icon?: string,
}) {
  const childType: string = props.initialData.type;
  const childID: string = props.initialData.instanceID;
  const parentID: string = props.parentID;

  const uPlayable = usePlayable();

  const [compTypeIndex, _setCompTypeIndex] = useState<number>();
  // const [component, _setComponent] = useState<JsonType>();

  useEffect(() => {
    if (compTypeIndex === undefined) {
      _setCompTypeIndex(uPlayable.getCompTypeIndex(childType, parentID, childID));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  // useEffect(() => {
  //   if (props !== undefined && compTypeIndex !== undefined) {
  //     _setComponent(uPlayable.getRunTimeComponent(`${childType}[${compTypeIndex}]`) as JsonType);
  //   }
  // }, [childType, compTypeIndex, props, uPlayable]);

  // const editComponent = (propertyPath: string, value: any, update: boolean = true) => {
  //   if (compTypeIndex !== undefined) {
  //     uPlayable.updateGameObject(value, `${childType}[${compTypeIndex}].${propertyPath}`, childID, update);
  //   }
  // };

  return (
    <YcCard title="Canvas Renderer" icon={props.icon} light open warning={compTypeIndex === undefined}>
      <div className="row">
        <div className="col-12">
          <SettingCompInputBoolean
            disabled
            label="Cull Transparent Mesh"
            checked
            onChange={() => null}
          />
        </div>
      </div>
    </YcCard>
  );
}
