import { useEffect, useState } from 'react';

import { usePlayable } from '../../../../context/playable.context';
import { YcCard } from '../../../../../../components';
import { ICameraJson as JsonType } from '../../../../../../modeles/MP_Interfaces';
import {
  SettingCompColor, SettingCompInputNumber, SettingCompFieldsGroup,
  SettingCompSelect, SettingCompSlider, SettingCompInputCheckbox,
} from '../../sub-settings';

import './setting-comp-camera.scss';

const projectionChoices: { label: string, value: boolean }[] = [
  { label: 'Perspective', value: false },
  { label: 'Orthographic', value: true },
];

export function SettingCompCamera(props: { initialData: any, parentID: string, icon?: string }) {
  const childType: string = props.initialData.type;
  const childID: string = props.initialData.instanceID;
  const parentID: string = props.parentID;

  const uPlayable = usePlayable();

  const [compTypeIndex, _setCompTypeIndex] = useState<number>();
  const [component, _setComponent] = useState<JsonType>();

  useEffect(() => {
    if (compTypeIndex === undefined) {
      _setCompTypeIndex(uPlayable.getCompTypeIndex(childType, parentID, childID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (props !== undefined && compTypeIndex !== undefined) {
      _setComponent(uPlayable.getRunTimeComponent(`${childType}[${compTypeIndex}]`) as JsonType);
    }
  }, [childType, compTypeIndex, props, uPlayable]);

  const editComponent = (propertyPath: string, value: any, update: boolean = true) => {
    if (compTypeIndex !== undefined) {
      uPlayable.updateGameObject(value, `${childType}[${compTypeIndex}]/${propertyPath}`, childID, update);
    }
  };

  return (
    <YcCard
      title="Camera"
      icon={props.icon}
      light
      open
      warning={compTypeIndex === undefined}
      titleExtra={(
        <SettingCompInputCheckbox
          checked={component?.enabled ?? true}
          onChange={(e) => editComponent('enabled', e)}
        />
      )}
    >
      <div className="row">
        <div className="col-12">
          <SettingCompColor
            defaultType="sky"
            label="Background"
            noAlpha
            color={component?.backgroundColor}
            onChange={(color, updateJson) => editComponent('backgroundColor', color, updateJson)}
          />
          <SettingCompSelect
            label="Projection"
            value={component?.orthographic ?? false}
            options={projectionChoices}
            onChange={(e) => editComponent('orthographic', e)}
          />
        </div>
        <div className="col-12">
          {
            component?.orthographic && (
              <SettingCompInputNumber
                label="Orthograpic size"
                value={component?.orthographicSize ?? 5}
                onChange={(e) => editComponent('orthographicSize', e)}
              />
            )
          }
          {
            !component?.orthographic && (
              <SettingCompSlider
                max={179}
                min={1}
                step={0.1}
                label="Field of View"
                value={component?.fieldOfView ?? 60}
                onChange={(e, update) => editComponent('fieldOfView', e, update)}
              />
            )
          }
          <SettingCompFieldsGroup
            label="Clipping Planes"
            type="number"
            fields={[
              {
                label: 'Near',
                value: component?.nearClipPlane ?? 0.3,
                field: 'nearClipPlane',
              },
              {
                label: 'Far',
                value: component?.farClipPlane ?? 1000,
                field: 'farClipPlane',
              },
            ]}
            onChange={(field, e) => editComponent(field, e)}
          />
          <SettingCompInputNumber
            disabled
            label="Aspect"
            value={component?.aspect ?? 0}
            onChange={(e) => editComponent('aspect', e)}
          />
        </div>
      </div>
    </YcCard>
  );
}
