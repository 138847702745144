import { MCompImage } from '../../../../../modeles';

export const PreviewCompImage_CSS = `
/* IMAGE */
.preview-comp-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.preview-comp-image img {
  width: 100%;
  height: 100%;
  pointer-events: none;
}
`;

export function PreviewCompImage(props : {
  image: MCompImage,
  base64?: { [key:string]: string },
}) {
  const getUrl = () : string => {
    if (props.base64) {
      return props.base64[props.image.file.url];
    }
    return props.image.file.url;
  };
  return (
    <div className="preview-comp-image">
      {props.image.file.url && <img src={getUrl()} alt="" style={{ objectFit: props.image.objectFit, transform: props.image.objectFlip }} />}
    </div>
  );
}
