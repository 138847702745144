import { MCreative } from '../../../../modeles';
import { EmptyExport, EmptyExportFont } from './empty';

/* eslint-disable jsx-a11y/html-has-lang */
export function Tiktok(props: {
  iec: MCreative,
  body: string,
  fonts: EmptyExportFont[],
  appInfo: any,
  NETWORK: any,
}) {
  return (
    <EmptyExport
      iec={props.iec}
      body={props.body}
      fonts={props.fonts}
      appInfo={props.appInfo}
      NETWORK={props.NETWORK}
      scripts={[
        { type: 'text/javascript', src: 'https://sf16-muse-va.ibytedtos.com/obj/union-fe-nc-i18n/playable/sdk/playable-sdk.js' },
      ]}
      script={`
        try {
          _networkSDK = playableSDK;
        } catch (e) {
          console.warn('playableSDK not found')
        }
        dataToSend.iec.network = 'tiktok';

        function iecDisplayApiCallOneShot() {
          window.removeEventListener('click', iecDisplayApiCallOneShot);
          iecDisplayApiCall();
        }
        window.addEventListener('click', iecDisplayApiCallOneShot);
      `}
    />
  );
}
