import { MCreative } from '../../../../modeles';
import { EmptyExport, EmptyExportFont } from './empty';

/* eslint-disable jsx-a11y/html-has-lang */
export function Moloco(props: {
  iec: MCreative,
  body: string,
  fonts: EmptyExportFont[],
  appInfo: any,
  NETWORK: any,
}) {
  return (
    <EmptyExport
      iec={props.iec}
      body={props.body}
      fonts={props.fonts}
      appInfo={props.appInfo}
      NETWORK={props.NETWORK}
      metadatas={[
        { name: 'ad.orientation', content: 'portrait,landscape' },
      ]}
      scripts={[
        { type: 'text/javascript', src: 'https://tpc.googlesyndication.com/pagead/gadgets/html5/api/exitapi.js' },
        { type: 'text/javascript', src: 'https://sf16-muse-va.ibytedtos.com/obj/union-fe-nc-i18n/playable/sdk/playable-sdk.js' },
      ]}
      script={`
        dataToSend.iec.network = 'moloco';

        ${props.NETWORK.onWindowLoad()}
      `}
    />
  );
}
