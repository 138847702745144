import { Image } from 'antd';

import './step-three.scss';
import { Setup1, Setup2, Setup3 } from '../../../../../../assets/images/installation-images';

export function StepThree() {
  const steps = [
    {
      title: '1) Select Tools/Monster Playable',
      image: Setup1,
    },
    {
      title: '2) Login with your Monster Playable account',
      image: Setup2,
    },
    {
      title: '3) Select the game your are working on',
      image: Setup3,
    },
  ];

  return (
    <div id="step-three">
      <div className="d-flex justify-content-around">
        {
          steps.map((step, index) => (
            <div className="d-block text-center" key={index}>
              <p>{step.title}</p>
              <Image
                width={200}
                height={200}
                style={{ objectFit: 'contain' }}
                src={step.image}
              />
            </div>
          ))
        }
      </div>
    </div>
  );
}
