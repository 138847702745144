import { useEffect, useState } from 'react';
import {
  Button, Dropdown, MenuProps, Switch, Tooltip,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowsUpDownLeftRight, faRotate, faUpRightAndDownLeftFromCenter, faGlobe, faCube, faLayerGroup,
} from '@fortawesome/pro-regular-svg-icons';
import * as TransformControls from 'three/examples/jsm/controls/TransformControls';

import './debug-ui.scss';

const CONTROL_BTNS_TOOLTIP_DELAY = 0.6;

export function DebugUI(props: {
  currentCamera: THREE.Camera | null
  currentTransformControls: TransformControls.TransformControls | null
  transformControlsSpace: 'world' | 'local'
  setTransformControlsSpace: (space: 'world' | 'local') => void
}) {
  const [showAllLayers, _setShowAllLayers] = useState<boolean>(false);
  const [openLayerDropdown, _setOpenLayerDropdown] = useState<boolean>(false);

  const controlsOptions = [
    {
      title: 'Translate (W)',
      value: 'translate',
      icon: faArrowsUpDownLeftRight,
    },
    {
      title: 'Rotate (E)',
      value: 'rotate',
      icon: faRotate,
    },
    {
      title: 'Scale (R)',
      value: 'scale',
      icon: faUpRightAndDownLeftFromCenter,
    },
  ];

  const listLayersSelected: { key: number, selected: boolean }[] = [];
  for (let i = 0; i <= 31; i += 1) {
    listLayersSelected.push({ key: i, selected: true });
  }
  const layersOption: MenuProps['items'] = [];
  for (let i = 0; i <= 31; i += 1) {
    layersOption.push(
      {
        key: i,
        label: (
          <div className="d-flex gap-1 align-items-center">
            <Switch
              defaultChecked={listLayersSelected.find((item) => item.key === i)?.selected}
              onChange={(check) => {
                listLayersSelected.find((item) => item.key === i)!.selected = check;
                layerSelectedChange();
              }}
              size="small"
            />
            <span>Layer {i}</span>
          </div>
        ),
      },
    );
  }

  useEffect(() => {
    if (props.currentTransformControls) {
      props.currentTransformControls.space = props.transformControlsSpace;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.transformControlsSpace]);

  const layerSelectedChange = () => {
    props.currentCamera?.layers.disableAll();
    listLayersSelected.filter((layer) => layer.selected === true).forEach((layer) => {
      props.currentCamera?.layers.enable(layer.key);
    });
  };

  const setShowAllLayers = () => {
    _setShowAllLayers(!showAllLayers);
    if (!showAllLayers) {
      props.currentCamera?.layers.enableAll();
    } else {
      props.currentCamera?.layers.set(0);
    }
  };

  return (
    <div className="debug-ui">
      <div className="controls-options">
        {controlsOptions?.map((btn, i) => (
          <Tooltip
            key={i}
            mouseEnterDelay={CONTROL_BTNS_TOOLTIP_DELAY}
            title={btn.title}
            placement="right"
          >
            <div>
              <Button onClick={() => props.currentTransformControls?.setMode(btn.value as 'translate' | 'rotate' | 'scale')}>
                <FontAwesomeIcon icon={btn?.icon} />
              </Button>
            </div>
          </Tooltip>
        ))}
      </div>
      <div className="layer-selector">
        <Tooltip
          mouseEnterDelay={CONTROL_BTNS_TOOLTIP_DELAY}
          title="Layers"
          placement="top"
        >
          <div>
            <Dropdown
              menu={{ items: layersOption, onClick: () => { } }}
              placement="bottomRight"
              open={openLayerDropdown}
              onOpenChange={() => { }}
            >
              <Button onClick={() => { _setOpenLayerDropdown(!openLayerDropdown); }}>
                <FontAwesomeIcon icon={faLayerGroup} />
              </Button>
            </Dropdown>
          </div>
        </Tooltip>
        {/* <Tooltip
          mouseEnterDelay={CONTROL_BTNS_TOOLTIP_DELAY}
          title={showAllLayers ? 'Hide objects' : 'Show hidden objects'}
          placement="top"
        >
          <Button onClick={() => setShowAllLayers()} disabled>
            <FontAwesomeIcon icon={showAllLayers ? faEye : faEyeSlash} />
          </Button>
        </Tooltip> */}
        <Tooltip
          mouseEnterDelay={CONTROL_BTNS_TOOLTIP_DELAY}
          title={props.transformControlsSpace === 'local' ? 'Local space' : 'World space'}
          placement="top"
        >
          <div>
            <Button onClick={() => {
              if (props.currentTransformControls) {
                props.setTransformControlsSpace(props.transformControlsSpace === 'local' ? 'world' : 'local');
              }
            }}
            >
              <FontAwesomeIcon icon={props.transformControlsSpace === 'local' ? faCube : faGlobe} />
            </Button>
          </div>
        </Tooltip>
      </div>
    </div>
  );
}
