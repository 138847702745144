import { BaseService } from './base.service';
import { MCreative } from '../modeles';

class CreativesService extends BaseService {
  PATH = 'creatives/';

  async listing(): Promise<MCreative[] | null> {
    return this._listing(`${this.PATH}listing`);
  }

  async listingAll(): Promise<MCreative[] | null> {
    return this._listing(`admin/${this.PATH}listing-all`);
  }

  async listingByApplication(applicationId: number): Promise<MCreative[] | null> {
    return this._listing(`${this.PATH}listing-by-application/${applicationId}`);
  }

  async add(iec: MCreative): Promise<MCreative | null> {
    return this._add(`${this.PATH}add`, iec);
  }

  async view(id: number): Promise<MCreative | null> {
    return this._view<MCreative | null>(`${this.PATH}view/${id}`);
  }

  async edit(id: number, app: MCreative): Promise<MCreative | null> {
    return this._edit(`${this.PATH}edit/${id}`, app);
  }

  async urlPublic(url: string): Promise<string | null> {
    const resp = await this.post(`${this.PATH}public-url/`, { url }) as Promise<string | null>;
    if (resp != null) {
      return (resp as any).data as string;
    }
    return null;
  }

  async deleteByDate(id: number): Promise<MCreative | null> {
    return this._delete(`${this.PATH}delete/${id}`);
  }

  async remove(id: number): Promise<MCreative | null> {
    return this._delete(`admin/${this.PATH}delete/${id}`);
  }

  async nextCreative(data: { applicationId: number, type: string }): Promise<any | null> {
    return this._edit(`${this.PATH}next-creative/`, data);
  }

  async versionExist(data: { applicationId: number, type: string, version: number }): Promise<any | null> {
    return this._edit(`${this.PATH}version-exist/`, data);
  }

  async generateQrCode(formData: FormData): Promise<any | null> {
    return this._edit(`${this.PATH}generate-qr-code/`, formData);
  }

  async exportView(id: number): Promise<MCreative | null> {
    return this._view<MCreative | null>(`${this.PATH}export-view/${id}`);
  }
}

export const creativesService = new CreativesService();
