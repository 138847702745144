import { Link } from 'react-router-dom';
import { PlusOutlined, ExportOutlined, RocketOutlined } from '@ant-design/icons';

// styles
import './title.scss';
import { Button, Tooltip } from 'antd';

export function YcTitle(props: {
  links?: [{
    link: string,
    label: string,
  }],
  export?: string,
  idToCreatives?: number | null,
  label: string,
  addUrl?: string,
}) {
  return (
    <h1 className="c-title">
      <div>
        {props.links && props.links.map((link, index) => (
          <span key={index}>
            <Link to={link.link}>{link.label}</Link>
            {' '}
            &gt;&nbsp;
          </span>
        ))}
        {!props.label && <>New</>}
        {props.label && props.label}
        {!!props.addUrl
          && (
          <>
            &nbsp;&nbsp;
            <Tooltip placement="top" title={`New ${props.label}`}><Link type="primary" to={props.addUrl} className="icon"><PlusOutlined /></Link></Tooltip>
          </>
          )}
      </div>
      <div>
        {
          props.export && (
          <Link to={props.export}>
            <Tooltip placement="top" title="Export creative">
              <Button type="primary" shape="circle" icon={<ExportOutlined />} size="large" />
            </Tooltip>
          </Link>
          )
        }
        {
          props.idToCreatives
          && (
          <Link to={`/applications/${props.idToCreatives}/creatives`}>
            <Tooltip placement="top" title="Creative">
              <Button type="primary" shape="circle" icon={<RocketOutlined />} size="large" />
            </Tooltip>
          </Link>
          )
        }
      </div>
    </h1>
  );
}
