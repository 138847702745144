import { useEffect, useState } from 'react';

import { usePlayable } from '../../../../context/playable.context';
import { YcCard } from '../../../../../../components';
import { ICapsuleColliderJson as JsonType } from '../../../../../../modeles/MP_Interfaces';
import {
  SettingCompInputBoolean,
  SettingCompInputCheckbox,
  SettingCompInputNumber,
  SettingCompSelect,
  SettingCompVector3,
} from '../../sub-settings';

import './setting-comp-capsule-collider.scss';

const directionOptions: { label: string, value: number }[] = [
  { label: 'X-Axis', value: 0 },
  { label: 'Y-Axis', value: 1 },
  { label: 'Z-Axis', value: 2 },
];

export function SettingCompCapsuleCollider(props: { initialData: any, parentID: string, icon?: string }) {
  const childType: string = props.initialData.type;
  const childID: string = props.initialData.instanceID;
  const parentID: string = props.parentID;

  const uPlayable = usePlayable();

  const [compTypeIndex, _setCompTypeIndex] = useState<number>();
  const [component, _setComponent] = useState<JsonType>();

  useEffect(() => {
    if (compTypeIndex === undefined) {
      _setCompTypeIndex(uPlayable.getCompTypeIndex(childType, parentID, childID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (props !== undefined && compTypeIndex !== undefined) {
      _setComponent(uPlayable.getRunTimeComponent(`${childType}[${compTypeIndex}]`) as JsonType);
    }
  }, [childType, compTypeIndex, props, uPlayable]);

  const editComponent = (propertyPath: string, value: any, update: boolean = true) => {
    if (compTypeIndex !== undefined) {
      uPlayable.updateGameObject(value, `${childType}[${compTypeIndex}]/${propertyPath}`, childID, update);
    }
  };

  return (
    <YcCard
      title="Capsule Collider"
      icon={props.icon}
      light
      open
      warning={compTypeIndex === undefined}
      titleExtra={(
        <SettingCompInputCheckbox
          checked={component?.enabled ?? true}
          onChange={(e) => editComponent('enabled', e)}
        />
      )}
    >
      <div className="row">
        <div className="col-12">
          <SettingCompInputBoolean
            label="Is Trigger"
            checked={component?.isTrigger ?? false}
            onChange={(value) => editComponent('isTrigger', value)}
          />
          <SettingCompVector3
            defaultType="0s"
            label="Center"
            vector={component?.center}
            onChange={(vector) => editComponent('center', vector)}
          />
          <SettingCompInputNumber
            label="Radius"
            value={component?.radius ?? 0.5}
            onChange={(radius) => editComponent('radius', radius)}
          />
          <SettingCompInputNumber
            label="Height"
            value={component?.height ?? 1}
            onChange={(height) => editComponent('height', height)}
          />
          <SettingCompSelect
            label="Direction"
            value={component?.direction ?? 1}
            options={directionOptions}
            onChange={(direction) => editComponent('direction', direction)}
          />
        </div>
      </div>
    </YcCard>
  );
}
