import {
  Redirect, Route, RouteProps, Switch, useRouteMatch,
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuilding,
  faUsers,
  faGear,
  faLineColumns,
  faFileInvoiceDollar,
  faBook,
  faFileExport,
  faPuzzlePiece,
  faImage,
  faRocket,
  faBug,
  faDollarSign,
} from '@fortawesome/pro-regular-svg-icons';

import { YcTabs } from '../../components';

// Pages
import {
  AccountPage,
  AccountsPage,
  EngineLogsPage,
  InvoicesGeneratorPage,
  SdkEnginePage,
  DocumentationsPage,
  ExportsPage,
  CreativesPage,
  AssetsPage,
  UsersPage,
  ApplicationsPage,
  BuildErrorPage,
  CreativesListingPage,
} from './pages';
import { YcTabsElems } from '../../components/tabs/tabs';
import { useAuths } from '../../contexts';

// Styles
import './index.scss';

function YcAdminRoute({
  YcComponent,
  canAccess = true,
  ...rest
}: RouteProps<string> & { YcComponent: any, canAccess?: boolean }) {
  return (
    <Route
      {...rest}
      exact
      render={(props) => (
        <>
          {(canAccess) && <YcComponent {...props} />}
          {(!canAccess) && <Redirect to={{ pathname: '/', state: { from: props.location } }} />}
        </>
      )}
    />
  );
}

export function AdminModule() {
  const uAuths = useAuths();
  const { path } = useRouteMatch();

  const elementDisabled = (endPath: string): boolean => {
    return !(uAuths.canAccess(`mpAdmin/${endPath}`) || uAuths.isSuperAdmin());
  };

  const elements: YcTabsElems[] = [
    {
      link: '/admin/accounts',
      label: 'Accounts',
      icon: <FontAwesomeIcon icon={faBuilding} className="icon" />,
      disabled: elementDisabled('accounts'),
    },
    {
      link: '/admin/users',
      label: 'Users',
      icon: <FontAwesomeIcon icon={faUsers} className="icon" />,
      disabled: elementDisabled('users'),
    },
    {
      link: '/admin/applications',
      label: 'Applications',
      icon: <FontAwesomeIcon icon={faRocket} className="icon" />,
      disabled: elementDisabled('applications'),
    },
    {
      link: '/admin/sdk-engine',
      label: 'SDK / Engine',
      icon: <FontAwesomeIcon icon={faGear} className="icon" />,
      disabled: elementDisabled('sdkEngine'),
    },
    {
      link: '/admin/engine-logs',
      label: 'Engine Runner logs',
      icon: <FontAwesomeIcon icon={faLineColumns} className="icon" />,
      disabled: elementDisabled('engineLogs'),
    },
    {
      link: '/admin/build-error',
      label: 'Parser Error',
      icon: <FontAwesomeIcon icon={faBug} className="icon" />,
      disabled: elementDisabled('buildError'),
    },
    {
      link: '/admin/invoices-generator',
      label: 'Invoices Generator',
      icon: <FontAwesomeIcon icon={faFileInvoiceDollar} className="icon" />,
      disabled: elementDisabled('invoices'),
    },
    {
      link: '/admin/documentations',
      label: 'Documentations',
      icon: <FontAwesomeIcon icon={faBook} className="icon" />,
      disabled: elementDisabled('documentations'),
    },
    {
      link: '/admin/exports',
      label: 'Exports',
      icon: <FontAwesomeIcon icon={faFileExport} className="icon" />,
      disabled: elementDisabled('exports'),
    },
    {
      link: '/admin/creatives',
      label: 'Creatives',
      icon: <FontAwesomeIcon icon={faPuzzlePiece} className="icon" />,
      disabled: elementDisabled('creatives'),
    },
    {
      link: '/admin/assets',
      label: 'Assets',
      icon: <FontAwesomeIcon icon={faImage} className="icon" />,
      disabled: elementDisabled('assets'),
    },
    {
      link: '/admin/creatives-listing',
      label: 'Creatives Listing',
      icon: <FontAwesomeIcon icon={faDollarSign} className="icon" />,
      disabled: elementDisabled('creativesListing'),
    },
  ];

  return (
    <div id="admin">
      <div className="mb-3">
        <YcTabs elems={elements} />
      </div>
      <Switch>
        <YcAdminRoute exact path={`${path}/accounts`} canAccess={!elementDisabled('accounts')} YcComponent={AccountsPage} />
        <YcAdminRoute exact path={`${path}/users`} canAccess={!elementDisabled('users')} YcComponent={UsersPage} />
        <YcAdminRoute exact path={`${path}/applications`} canAccess={!elementDisabled('applications')} YcComponent={ApplicationsPage} />
        <YcAdminRoute exact path={`${path}/accounts/account/:id`} canAccess={!elementDisabled('accounts')} YcComponent={AccountPage} />
        <YcAdminRoute exact path={`${path}/sdk-engine`} canAccess={!elementDisabled('sdkEngine')} YcComponent={SdkEnginePage} />
        <YcAdminRoute exact path={`${path}/engine-logs`} canAccess={!elementDisabled('engineLogs')} YcComponent={EngineLogsPage} />
        <YcAdminRoute exact path={`${path}/build-error`} canAccess={!elementDisabled('buildError')} YcComponent={BuildErrorPage} />
        <YcAdminRoute exact path={`${path}/invoices-generator`} canAccess={!elementDisabled('invoices')} YcComponent={InvoicesGeneratorPage} />
        <YcAdminRoute exact path={`${path}/documentations`} canAccess={!elementDisabled('documentations')} YcComponent={DocumentationsPage} />
        <YcAdminRoute exact path={`${path}/exports`} canAccess={!elementDisabled('exports')} YcComponent={ExportsPage} />
        <YcAdminRoute exact path={`${path}/creatives`} canAccess={!elementDisabled('creatives')} YcComponent={CreativesPage} />
        <YcAdminRoute exact path={`${path}/assets`} canAccess={!elementDisabled('assets')} YcComponent={AssetsPage} />
        <YcAdminRoute exact path={`${path}/creatives-listing`} canAccess={!elementDisabled('creativesListing')} YcComponent={CreativesListingPage} />
        {
          !elementDisabled('accounts')
            ? (
              <Route path={path}><Redirect to={`${path}/accounts`} /></Route>
            )
            : (
              <Route path={path}><Redirect to={`${path}/`} /></Route>
            )
        }

      </Switch>
    </div>
  );
}
