export function DescriptionPreview() {
  return (
    <div>
      <p>This part is the IEC simulation render and try.<br />
        On the top you can change the simulation resolution,<br />
        and the bottom some option to test your creation
      </p>
      <ul>
        <li>Try: Run it in a specific page</li>
        <li>Download: A file to test</li>
        <li>QRcode: Scan it with your mobile</li>
      </ul>
    </div>
  );
}
