/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable import/no-cycle */
import { INetworkIntegration, Events, EventEmitter } from './network-integration';

export interface INetworkHandler {
  onresize: () => void,
  gameStart: () => void,
  gameClose: () => void,
  install?: () => void,
  gameEnd?: () => void,
  gameReady?: () => void,
  HttpAPI?: {
    sendPoint: (msg: string) => void
  },
  innerWidth: number,
  innerHeight: number,
}

export default class GoogleAdsIntegration implements INetworkIntegration {
  _networkSDK: INetworkHandler | null = null;

  _events: EventEmitter = new EventEmitter();

  public get name() { return 'googleads'; }

  _appInfo: any = null;

  public preloader = true;

  public get appInfo(): any { return this._appInfo; }

  public onWindowLoad() {
    this._networkSDK = null;
    try {
      // @ts-ignore
      this._networkSDK = ExitApi;
    } catch (e) {
      console.warn('ExitApi not found');
    }
    this._events.emit('windowLoaded');
    this._events.emit('gameStart');
  }

  public onReady() {
    this._events.emit('gameStart');
  }

  public onResize() {
    this._events.emit('screenResize');
  }

  public onViewChange(isViewable: boolean) {
    if (isViewable) {
      this._events.emit('gamePlay');
    } else {
      this._events.emit('gamePause');
    }
  }

  public onVolumeChange() {
    this._events.emit('soundChange');
  }

  public install() {
    try {
      // @ts-ignore
      ExitApi.exit();
    } catch {
      // @ts-ignore
      this._networkSDK.exit();
    }
  }

  public gameEnd() {
    // @ts-ignore
    if (this._networkSDK?.gameEnd) this._networkSDK.gameEnd();
  }

  public gameReady() {
    // @ts-ignore
    if (this._networkSDK?.gameReady) this._networkSDK.gameReady();
  }

  public getScreenSize() { return { width: window.innerWidth, height: window.innerHeight }; }

  public getAudioVolume() {
    return 1;
  }

  public sendMsg(msg: string) {
    if (this._networkSDK?.HttpAPI && this._networkSDK.HttpAPI.sendPoint) {
      this._networkSDK.HttpAPI.sendPoint(msg);
    }
  }

  addListener(event: string, callback: () => void) {
    this._events.addListener(event, callback);
  }

  removeListener(event: string, callback: () => void) {
    this._events.removeListener(event, callback);
  }

  MP_META = '<meta name="ad.orientation" content="portrait,landscape">';
  MP_SCRIPT = '<script type="text/javascript" src="https://tpc.googlesyndication.com/pagead/gadgets/html5/api/exitapi.js"></script>';
}
