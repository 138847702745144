import {
  InputNumber,
} from 'antd';

import './number-check.scss';

export function YcNumberCheckPage(props: {
  label: string,
  value: number | null,
  onChange?: (value: number | null) => void,
}) {
  return (
    <div className="c-field-check">
      <div className="left">
        {props.label}
      </div>
      <InputNumber
        value={props.value}
        onChange={(e: number | null) => { if (props.onChange) { props.onChange(e); } }}
      />
    </div>
  );
}
