import { useState } from 'react';
import { Button, Popconfirm, Popover } from 'antd';
import {
  DeleteOutlined as DeleteIcon,
  CopyOutlined as DuplicateIcon,
  EllipsisOutlined as EllipsisIcon,
} from '@ant-design/icons';

import { usePlayable } from '../../../context/playable.context';
import { IcoGeneric } from '../../../../../assets/images/playable';

export function ElemTitle({ node }: { node: any }) {
  const uPlayable = usePlayable();

  const [isMoreActionsPopover, _setIsMoreActionsPopover] = useState<boolean>(false);

  const stopPropagation = (e: any) => { e.stopPropagation(); };

  const onDelete = (e: any) => {
    e.stopPropagation();

    const elem = uPlayable.playableClasses.MP_GameManager.scene.gameObjects
      .find((gameObject: any) => gameObject.instanceID === node.instanceID);

    uPlayable.playableClasses.Object.DestroyImmediate(elem);
    uPlayable.refreshPlayable();
    uPlayable.deleteGameObject(elem.instanceID as string);
  };

  const onDuplicate = (e: any) => {
    e.stopPropagation();

    const elem = uPlayable.playableClasses.MP_GameManager.scene.gameObjects
      .find((gameObject: any) => gameObject.instanceID === node.instanceID);

    const newGameObject = uPlayable.playableClasses.Object.Instantiate('GameObject', elem, elem.transform.parent);

    uPlayable.duplicateGameObject(newGameObject);
    uPlayable.refreshPlayable();

    _setIsMoreActionsPopover(false);
  };

  const popoverMoreActions = () => {
    return (
      <div className="more-node-options">
        <Button className="node-option" type="text" onClick={onDuplicate} icon={<DuplicateIcon />} size="small">
          Duplicate
        </Button>
      </div>
    );
  };

  const title: string[] = [node.name];

  const visible: boolean = node.activeSelf !== undefined ? node.activeSelf : true;

  return (
    <div className="line">
      <div className={`title ${visible ? '' : 'hidden'}`}>
        <img src={IcoGeneric} alt="generic-icon" className="yc-icon" />
        <span>{title}</span>
      </div>
      <div className="actions">
        <span>
          <Popconfirm title="Sure to delete?" onConfirm={onDelete} onCancel={stopPropagation}>
            <Button
              type="text"
              shape="circle"
              icon={<DeleteIcon />}
              size="small"
              danger
            />
          </Popconfirm>
        </span>
        <span>
          <Popover
            content={popoverMoreActions}
            trigger="click"
            placement="bottomRight"
            open={isMoreActionsPopover}
            onOpenChange={(e: boolean) => _setIsMoreActionsPopover(e)}
          >
            <Button
              type="text"
              shape="circle"
              onClick={stopPropagation}
              icon={<EllipsisIcon />}
              size="small"
            />
          </Popover>
        </span>
      </div>
    </div>
  );
}
