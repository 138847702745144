/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable import/no-cycle */
import { INetworkIntegration, EventEmitter } from './network-integration';

export interface INetworkHandler {
  onresize: () => void,
  gameStart: () => void,
  gameClose: () => void,
  install?: () => void,
  gameEnd?: () => void,
  gameReady?: () => void,
  HttpAPI?: {
    sendPoint: (msg: string) => void
  },
  innerWidth: number,
  innerHeight: number,
}

export default class MintegralIntegration implements INetworkIntegration {
  _networkSDK: INetworkHandler | null = null;

  _events: EventEmitter = new EventEmitter();

  _appInfo: any = null;

  public get name() { return 'mintegral'; }

  public preloader = false;

  public get appInfo(): any { return this._appInfo; }

  public onWindowLoad() {
    // @ts-ignore
    _networkSDK = window as unknown as INetworkHandler;
    // @ts-ignore
    _networkSDK.onresize = () => { this._events.emit('gameStart'); };
    // @ts-ignore
    _networkSDK.gameStart = () => { this._events.emit('gamePlay'); };
    // @ts-ignore
    _networkSDK.gameClose = () => { this._events.emit('gamePause'); };
    this._events.emit('windowLoaded');
    this.onReady();
  }

  public onReady() {
    this._events.emit('gameStart');
  }

  public onResize() {
    this._events.emit('screenResize');
  }

  public onViewChange(isViewable: boolean) {
    if (isViewable) {
      this._events.emit('gamePlay');
    } else {
      this._events.emit('gamePause');
    }
  }

  public onVolumeChange() {
    this._events.emit('soundChange');
  }

  public install() {
    // @ts-ignore
    if (_networkSDK?.install) _networkSDK.install();
  }

  public gameEnd() {
    // @ts-ignore
    if (_networkSDK?.gameEnd) _networkSDK.gameEnd();
  }

  public gameReady() {
    // @ts-ignore
    if (_networkSDK?.gameReady) _networkSDK.gameReady();
  }

  public getScreenSize() { return { width: window.innerWidth, height: window.innerHeight }; }

  public getAudioVolume() {
    return 1;
  }

  public sendMsg(msg: string) {
    // @ts-ignore
    if (_networkSDK?.HttpAPI && _networkSDK.HttpAPI.sendPoint) {
      // @ts-ignore
      _networkSDK.HttpAPI.sendPoint(msg);
    }
  }

  addListener(event: string, callback: () => void) {
    this._events.addListener(event, callback);
  }

  removeListener(event: string, callback: () => void) {
    this._events.removeListener(event, callback);
  }

  MP_META = '<meta name="viewport" content="width=device-width,user-scalable=no,initial-scale=1.0, minimum-scale=1.0,maximum-scale=1.0"/>';
  MP_SCRIPT = '';
}
