import { useEffect, useState } from 'react';

import { usePlayable } from '../../../../context/playable.context';
import { YcCard } from '../../../../../../components';
import { IButtonJson as JsonType } from '../../../../../../modeles/MP_Interfaces';
import { SettingCompInputBoolean, SettingCompInputCheckbox, SettingCompSelect } from '../../sub-settings';

import './setting-comp-button.scss';

const transitionOptions: { label: string, value: number }[] = [
  { label: 'None', value: 0 },
  { label: 'Color Tint', value: 1 },
  { label: 'Sprite Swap', value: 2 },
  { label: 'Animation', value: 3 },
];

export function SettingCompButton(props: { initialData: any, parentID: string, icon?: string }) {
  const childType: string = props.initialData.type;
  const childID: string = props.initialData.instanceID;
  const parentID: string = props.parentID;

  const uPlayable = usePlayable();

  const [compTypeIndex, _setCompTypeIndex] = useState<number>();
  const [component, _setComponent] = useState<JsonType>();

  useEffect(() => {
    if (compTypeIndex === undefined) {
      _setCompTypeIndex(uPlayable.getCompTypeIndex(childType, parentID, childID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (props !== undefined && compTypeIndex !== undefined) {
      _setComponent(uPlayable.getRunTimeComponent(`${childType}[${compTypeIndex}]`) as JsonType);
    }
  }, [childType, compTypeIndex, props, uPlayable]);

  const editComponent = (propertyPath: string, value: any, update: boolean = true) => {
    if (compTypeIndex !== undefined) {
      uPlayable.updateGameObject(value, `${childType}[${compTypeIndex}]/${propertyPath}`, childID, update);
    }
  };

  return (
    <YcCard
      title="Button"
      icon={props.icon}
      light
      open
      warning={compTypeIndex === undefined}
      titleExtra={(
        <SettingCompInputCheckbox
          checked={component?.enabled ?? true}
          onChange={(e) => editComponent('enabled', e)}
        />
      )}
    >
      <div className="row">
        <div className="col-12">
          <SettingCompInputBoolean
            label="Interactable"
            checked={component?.interactable ?? true}
            onChange={(e) => editComponent('interactable', e)}
          />
          <SettingCompSelect
            disabled
            label="Transition"
            value={component?.transition ?? 1}
            options={transitionOptions}
            onChange={(e) => editComponent('transition', e)}
          />
        </div>
      </div>
    </YcCard>
  );
}
