import packageJson from '../../package.json';
import { MCreative } from '../modeles';

const frontVersion = packageJson.version;

function gererateRandomString(nbChar = 64) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < nbChar; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function capitalize(str: string): any {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
}

function deepCopy<T>(object: T) {
  return JSON.parse(JSON.stringify(object)) as T;
}

function iecIsEditable(creative: MCreative) {
  if (creative.type === 'PLA') {
    return true;
  }
  const iecEngineVersion = creative.iecEngineVersion;
  if (creative.iecEngineVersion !== frontVersion) {
    if (iecEngineVersion?.split('.')[0] !== frontVersion.split('.')[0]
      || iecEngineVersion?.split('.')[1] !== frontVersion.split('.')[1]) {
      return false;
    }
    return true;
  }

  return true;
}

function isMonthly(date: Date) {
  const currentDate = new Date();
  return (date.getMonth() === currentDate.getMonth())
    && (date.getFullYear() === currentDate.getFullYear());
}

function getUniqueList(object: any, path: string): any[] {
  return object
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument
    ? [...new Set(object.map((item) => path.split('.').reduce((obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : []), item)))]
    : [];
}

function getBooleanFilter() {
  return [
    {
      text: 'True',
      value: true,
    },
    {
      text: 'False',
      value: false,
    },
  ];
}

const antFormNormFile = (e: any) => {
  if (Array.isArray(e)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return e;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return e?.fileList;
};

export {
  gererateRandomString,
  capitalize,
  deepCopy,
  iecIsEditable,
  isMonthly,
  getUniqueList,
  getBooleanFilter,
  antFormNormFile,
};
