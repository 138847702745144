/* eslint-disable react/no-danger  */
export function EventLogs() {
  return (
    <>
      <style dangerouslySetInnerHTML={{
        __html: `
          .logs{
            position: fixed;
            z-index:10000;
          }

          .event-log{
            font-size: 20px;
            background-color: rgba(10, 212, 208, 0.1);
            border: solid 1px;
            color: rgb(10, 212, 208);
            padding: 5px 10px;
            margin: 5px;
            border-radius: 12px;
          }
          `,
      }}
      />
      <script dangerouslySetInnerHTML={{
        __html: `
          document.addEventListener('DOMContentLoaded', function() {
            var divLogs = document.createElement('div');
            divLogs.id = 'divLogs';
            divLogs.className = 'logs';
            document.body.appendChild(divLogs);
          }, false);

          function newLog(message, color = '10, 212, 208', timer = 2) {
            var div = document.createElement('div');
            div.innerHTML = message;
            div.className = 'event-log';
            div.style.color = 'rgb(' + color + ')';
            div.style.backgroundColor = 'rgba(' + color + ', 0.1)';
            document.getElementById('divLogs').appendChild(div);

            setTimeout(function(){
              div.remove();
            }, timer * 1000)
          }
        `,
      }}
      />
    </>
  );
}
