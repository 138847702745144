import { useEffect, useState } from 'react';

import { usePlayable } from '../../../../context/playable.context';
import { YcCard } from '../../../../../../components';
import { IRigidbodyJson as JsonType } from '../../../../../../modeles/MP_Interfaces';
import { SettingCompInputBoolean, SettingCompInputNumber } from '../../sub-settings';

import './setting-comp-rigidbody.scss';

export function SettingCompRigidbody(props: { initialData: any, parentID: string, icon?: string }) {
  const childType: string = props.initialData.type;
  const childID: string = props.initialData.instanceID;
  const parentID: string = props.parentID;

  const uPlayable = usePlayable();

  const [compTypeIndex, _setCompTypeIndex] = useState<number>();
  const [component, _setComponent] = useState<JsonType>();

  useEffect(() => {
    if (compTypeIndex === undefined) {
      _setCompTypeIndex(uPlayable.getCompTypeIndex(childType, parentID, childID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (props !== undefined && compTypeIndex !== undefined) {
      _setComponent(uPlayable.getRunTimeComponent(`${childType}[${compTypeIndex}]`) as JsonType);
    }
  }, [childType, compTypeIndex, props, uPlayable]);

  const editComponent = (propertyPath: string, value: any, update: boolean = true) => {
    if (compTypeIndex !== undefined) {
      uPlayable.updateGameObject(value, `${childType}[${compTypeIndex}]/${propertyPath}`, childID, update);
    }
  };

  return (
    <YcCard title="Rigidbody" icon={props.icon} light open warning={compTypeIndex === undefined}>
      <div className="row">
        <div className="col-12">
          <SettingCompInputNumber
            label="Mass"
            value={component?.mass ?? 1}
            onChange={(e) => editComponent('mass', e)}
          />
          <SettingCompInputNumber
            label="Drag"
            value={component?.drag ?? 0}
            onChange={(e) => editComponent('drag', e)}
          />
          <SettingCompInputNumber
            label="Angular Drag"
            value={component?.angularDrag ?? 0.05}
            onChange={(e) => editComponent('angularDrag', e)}
          />
        </div>
        <div className="col-12">
          <SettingCompInputBoolean
            label="Use Gravity"
            checked={component?.useGravity ?? true}
            onChange={(e) => editComponent('useGravity', e)}
          />
        </div>
        <div className="col-12">
          <SettingCompInputBoolean
            label="Is Kinematic"
            checked={component?.isKinematic ?? false}
            onChange={(e) => editComponent('isKinematic', e)}
          />
        </div>
      </div>
    </YcCard>
  );
}
