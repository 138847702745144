/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useState } from 'react';
import { Modal, Tabs } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
} from '@fortawesome/pro-regular-svg-icons';

import { LibraryAccount, LibraryAssets } from './sub-components';
import { VideoUploader } from '..';
import { MDocument } from '../../modeles';
import { UploadAsset } from './sub-components/upload-asset';

import './library.scss';

const { TabPane } = Tabs;

type YcLibrary = {
  defaultValue: MDocument | undefined;
  width?: number | undefined;
  height?: number | undefined;
  typeName: string;
  path: string;
  label: string;
  type: 'image' | 'video';
  onUpload: (doc: MDocument) => Promise<void> | void;
  maxSizeInMB?: number | undefined;
  folder: string;
  maxWeight?: number;
};

export function MPLibrary(props: YcLibrary) {
  const [icon, _setIcon] = useState<MDocument | undefined>(props.defaultValue);
  const [isModalVisible, _setIsModalVisible] = useState<boolean>(false);
  const [documentSelected, _setDocumentSelected] = useState<MDocument | null>(null);
  const [confirmLoading, _setConfirmLoading] = useState(false);

  const divButton = (
    <>
      <FontAwesomeIcon icon={faPlus} />
      <div style={{ marginTop: 8 }}>{props.label}</div>
    </>
  );

  useEffect(() => {
    _setIcon(props.defaultValue);
  }, [props.defaultValue]);

  const modalOk = async () => {
    _setConfirmLoading(true);
    if (documentSelected) {
      _setIcon(documentSelected);
      await props.onUpload(documentSelected);
    }
    _setIsModalVisible(false);
    _setConfirmLoading(false);
  };

  const modalCancel = () => {
    _setIsModalVisible(false);
  };

  const editDocumentSelected = (doc: MDocument) => {
    _setDocumentSelected(doc);
  };

  const editDocumentSelectedByUpload = async (doc: MDocument) => {
    _setDocumentSelected(doc);
    _setConfirmLoading(true);
    if (doc) {
      _setIcon(doc);
      await props.onUpload(doc);
    }
    _setIsModalVisible(false);
    _setConfirmLoading(false);
  };

  return (
    <div
      className="library-component"
    >
      <div className="icon" onClick={() => _setIsModalVisible(!isModalVisible)}>
        {props.type === 'image' && (icon?.url ? <img src={icon?.url} className="asset-size" alt="avatar" /> : divButton)}
        {props.type === 'video' && (icon?.url ? <video src={`${icon?.url}#t=1,2`} className="asset-size" /> : divButton)}
      </div>
      <Modal
        title="Choose a file"
        open={isModalVisible}
        footer={null}
        onCancel={modalCancel}
        confirmLoading={confirmLoading}
        width={1160}
        centered
        className="yc-choose-file"
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Upload" key="1">
            {
              props.type === 'video'
                ? (
                  <VideoUploader onUpload={editDocumentSelected} modalCancel={modalCancel} modalOk={modalOk} />
                )
                : (
                  <UploadAsset
                    defaultType={props.typeName}
                    modalCancel={modalCancel}
                    modalOk={editDocumentSelectedByUpload}
                    maxWeight={props.maxWeight ? props.maxWeight : 2}
                  />
                )
            }
          </TabPane>
          <TabPane tab="Your library" key="2" disabled={props.type === 'video'}>
            <LibraryAccount
              key={icon?.id}
              modalCancel={modalCancel}
              onDocumentSelected={editDocumentSelected}
              documentSelected={documentSelected}
              modalOk={modalOk}
            />
          </TabPane>
          <TabPane tab="Assets" key="3" disabled={props.type === 'video'}>
            <LibraryAssets modalCancel={modalCancel} onAssetSelected={editDocumentSelected} documentSelected={documentSelected} modalOk={modalOk} />
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  );
}
