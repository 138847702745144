import { useEffect, useState } from 'react';

import { usePlayable } from '../../../../context/playable.context';
import { YcCard } from '../../../../../../components';
import { ITextJson as JsonType } from '../../../../../../modeles/MP_Interfaces';
import {
  SettingCompColor, SettingCompInputNumber,
  SettingCompInputBoolean, SettingButtonGroup,
  SettingCompInputCheckbox, SettingCompTextArea,
  SettingButtonGroupStr,
} from '../../sub-settings';

import {
  IcoLeft, IcoCenter, IcoRight,
  IcoTop, IcoMiddle, IcoBottom,
} from '../../../../../../assets/images/textAlignments';
import './setting-comp-text.scss';

type GroupButton = {
  hoverText: string,
  value: number,
  label?: string,
  icon?: string,
};

const fontStylesBtns: GroupButton[] = [
  { hoverText: 'Bold', value: 1, label: 'B' },
  { hoverText: 'Italic', value: 2, label: 'I' },
];

const textAlignmentMap = {
  UpperLeft: 0,
  UpperCenter: 1,
  UpperRight: 2,
  MiddleLeft: 3,
  MiddleCenter: 4,
  MiddleRight: 5,
  LowerLeft: 6,
  LowerCenter: 7,
  LowerRight: 8,
};

type GroupButtonStr = {
  value: string,
  label?: string,
  icon: string,
};

const horizontalBtnsStr: GroupButtonStr[] = [
  { value: 'Left', icon: IcoLeft },
  { value: 'Center', icon: IcoCenter },
  { value: 'Right', icon: IcoRight },
];

const verticalBtnsStr: GroupButtonStr[] = [
  { value: 'Upper', icon: IcoTop },
  { value: 'Middle', icon: IcoMiddle },
  { value: 'Lower', icon: IcoBottom },
];

export function SettingCompText(props: { initialData: any, parentID: string, icon?: string }) {
  const childType: string = props.initialData.type;
  const childID: string = props.initialData.instanceID;
  const parentID: string = props.parentID;

  const uPlayable = usePlayable();

  const [compTypeIndex, _setCompTypeIndex] = useState<number>();
  const [component, _setComponent] = useState<JsonType>();

  const [alignmentInfosStr, _setAlignmentInfosStr] = useState({ horizontal: 'Left', vertical: 'Upper' });

  useEffect(() => {
    if (compTypeIndex === undefined) {
      _setCompTypeIndex(uPlayable.getCompTypeIndex(childType, parentID, childID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (props !== undefined && compTypeIndex !== undefined) {
      _setComponent(uPlayable.getRunTimeComponent(`${childType}[${compTypeIndex}]`) as JsonType);
    }
  }, [childType, compTypeIndex, props, uPlayable]);

  // Specific
  useEffect(() => {
    if (component !== undefined) {
      // @ts-ignore
      const alignment = Object.keys(textAlignmentMap).find((e) => textAlignmentMap[e] === props.initialData.alignment);
      const split = alignment?.split(/(?=[A-Z])/);

      _setAlignmentInfosStr({ horizontal: split?.[1] ?? 'Left', vertical: split?.[0] ?? 'Right' });
    }
  }, [component, props.initialData.alignment]);

  const editComponent = (propertyPath: string, value: any, update: boolean = true) => {
    if (compTypeIndex !== undefined) {
      uPlayable.updateGameObject(value, `${childType}[${compTypeIndex}]/${propertyPath}`, childID, update);
    }
  };

  // Specific
  const handleAlignmentChangeStr = (str: string, isHorizontal: boolean = true) => {
    const fieldToUpdate = isHorizontal ? 'horizontal' : 'vertical';
    let newKey: string = '';

    if (isHorizontal) {
      newKey = alignmentInfosStr.vertical + str;
    } else {
      newKey = str + alignmentInfosStr.horizontal;
    }

    // @ts-ignore
    editComponent('alignment', textAlignmentMap[newKey]);
    _setAlignmentInfosStr((old) => ({ ...old, [fieldToUpdate]: str }));
  };

  return (
    <YcCard
      title="Text"
      icon={props.icon}
      light
      open
      warning={compTypeIndex === undefined}
      titleExtra={(
        <SettingCompInputCheckbox
          checked={component?.enabled ?? true}
          onChange={(bool) => editComponent('enabled', bool)}
        />
      )}
    >
      <div className="row">
        <div className="col-12">
          <SettingCompTextArea
            label="Text"
            value={component?.text ?? ''}
            onChange={(text: string) => editComponent('text', text)}
            rows={2}
          />
          <SettingButtonGroup
            canMultiple
            label="Font Style"
            btns={fontStylesBtns}
            onClick={(e) => editComponent('fontStyle', e)}
            value={component?.fontStyle ?? 0}
          />
          <SettingCompInputNumber
            disabled={component?.resizeTextForBestFit ?? true}
            label="Font Size"
            value={component?.fontSize ?? 14}
            onChange={(e) => editComponent('fontSize', e)}
          />
          <SettingButtonGroupStr
            label="Horizontal Alignment"
            btns={horizontalBtnsStr}
            onClick={(e: string) => handleAlignmentChangeStr(e)}
            value={alignmentInfosStr.horizontal}
          />
          <SettingButtonGroupStr
            label="Vertical Alignment"
            btns={verticalBtnsStr}
            onClick={(e: string) => handleAlignmentChangeStr(e, false)}
            value={alignmentInfosStr.vertical}
          />
          <SettingCompInputBoolean
            label="Best Fit"
            checked={component?.resizeTextForBestFit ?? false}
            onChange={(e) => editComponent('resizeTextForBestFit', e)}
          />
          {
            component?.resizeTextForBestFit && (
              <>
                <SettingCompInputNumber
                  label="Min Size"
                  value={component?.resizeTextMinSize ?? 10}
                  onChange={(e) => editComponent('resizeTextMinSize', e)}
                />
                <SettingCompInputNumber
                  label="Max Size"
                  value={component?.resizeTextMaxSize ?? 40}
                  onChange={(e) => editComponent('resizeTextMaxSize', e)}
                />
              </>
            )
          }
          <SettingCompColor
            defaultType="grey"
            label="Color"
            color={component?.color}
            onChange={(color, updateJson) => editComponent('color', color, updateJson)}
          />
        </div>
      </div>
    </YcCard>
  );
}
