/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useRef } from 'react';

import { MCreativeVideo } from '../../../../../../modeles';
import { useIec } from '../../../../context/iec.context';
import { PreviewCompBackground } from '../preview-comp-background';

import './preview-video.scss';

export function PreviewVideo(props: {
  video: MCreativeVideo,
}) {
  const rVideo = useRef<HTMLVideoElement>(null);

  const uIec = useIec();

  function loopVideo(video: HTMLVideoElement, start: number, end: number) {
    if (video.currentTime >= end) {
      video.currentTime = start;
    }
  }

  useEffect(() => {
    if (rVideo.current) {
      rVideo.current.ontimeupdate = null;
      if (uIec.getBreakActive()) {
        rVideo.current.pause();
        rVideo.current.currentTime = uIec.getBreakActive()!.time || 0;
      }
      if (uIec.getLoopActive()) {
        if (uIec.getLoopActive()!.displayedTime) {
          rVideo.current.pause();
          rVideo.current.currentTime = uIec.getLoopActive()!.displayedTime || 0;
        } else {
          rVideo.current.currentTime = uIec.getLoopActive()!.timeStart || 0;
          rVideo.current.ontimeupdate = () => loopVideo(rVideo.current!, uIec.getLoopActive()!.timeStart, uIec.getLoopActive()!.timeEnd);
          rVideo.current.play();
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uIec.iec]);

  const getScale = () => {
    if (uIec.getPhoneOrientation() === 'landscape') {
      return uIec.iec.iecData.zoomLandscape;
    }
    return uIec.iec.iecData.zoomPortrait;
  };

  return (
    <div className="preview-video">
      <PreviewCompBackground background={props.video.background} />
      <video
        style={{
          transform: `scale(${getScale()})`,
        }}
        ref={rVideo}
        src={props.video.video.url}
        onLoadedData={(v) => {
          if (!uIec.iec.iecData.width || !uIec.iec.iecData.height) {
            uIec.iec.iecData.width = (v.target as any).videoWidth;
            uIec.iec.iecData.height = (v.target as any).videoHeight;
          }
          props.video.videoDuration = v.currentTarget.duration;
          uIec.refreshIec(false);
        }}
      />
    </div>
  );
}
