import { useState, useEffect } from 'react';
import {
  Button, Collapse, CollapseProps, Upload,
} from 'antd';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';

import { YcCard } from '../../../../../../components';
import {
  SettingCompInputNumber,
  SettingCompInputBoolean,
  SettingCompSelect,
  SettingCompInputText,
  SettingCompInputCheckbox,
  SettingCompColor,
  SettingCompVector3,
  SettingCompVector2,
  SettingCompImage,
} from '../../sub-settings';
import { usePlayable } from '../../../../context/playable.context';
import { IColorJson } from '../../../../../../modeles/MP_Interfaces';

import './setting-comp-script.scss';
import { SettingCompAudioSource } from '../setting-comp-audio-source';
import { SettingCompTransform } from '../setting-comp-transform';
import { SettingInputScript } from './setting-input-script';

export interface IVariable {
  name: string,
  type: string,
  value: string,
  isRef?: boolean,
  componentId?: string,
}

export function SettingCompScript(props: { initialData: any, parentID: string, icon?: string }) {
  const childType: string = props.initialData.mp_path;
  const childID: string = props.initialData.instanceID;
  const parentID: string = props.parentID;

  const uPlayable = usePlayable();

  const [compTypeIndex, _setCompTypeIndex] = useState<number>();
  const [component, _setComponent] = useState<any>();

  useEffect(() => {
    if (compTypeIndex === undefined) {
      _setCompTypeIndex(uPlayable.getCompTypeIndex(childType, parentID, childID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (props !== undefined && compTypeIndex !== undefined) {
      _setComponent(uPlayable.getRunTimeComponent(`${childType}[${compTypeIndex}]`) as any);
    }
  }, [childType, compTypeIndex, props, uPlayable]);

  const editComponent = (propertyPath: string, value: any, isColor?: boolean) => {
    if (isColor) {
      const colorValue = `${value.r}|||${value.g}|||${value.b}|||${value.a}`;
      uPlayable.updateGameObject(colorValue, `${childType}[${compTypeIndex}]/${propertyPath}`, childID, true);
    }
    if (compTypeIndex !== undefined) {
      uPlayable.updateGameObject(value, `${childType}[${compTypeIndex}]/${propertyPath}`, childID);
    }
  };

  return (
    <YcCard
      title={`${props?.initialData?.mp_path} (Script)`}
      icon={props.icon}
      light
      open
      warning={compTypeIndex === undefined}
      titleExtra={(
        <SettingCompInputCheckbox
          checked={component?.enabled ?? true}
          onChange={(e) => editComponent('enabled', e)}
        />
      )}
    >
      <div className="row">
        <div className="col-12">
          {
            component?.mp_variables?.map((variable: IVariable, index: number) => (
              <SettingInputScript
                key={index}
                componentRunTime={component}
                variable={variable}
                index={index}
                editComponent={(path: string, value: any, isColor: boolean) => {
                  editComponent(path, value, isColor);
                }}
              />
            ))
          }
        </div>
      </div>
      <div className="btns">
        <Button
          disabled
          shape="circle"
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          icon={<DownloadOutlined />}
          size="large"
          onClick={() => null}
        />
        <Upload>
          <Button
            disabled
            shape="circle"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            icon={<UploadOutlined />}
            size="large"
            onClick={() => null}
          />
        </Upload>
      </div>
    </YcCard>
  );
}
