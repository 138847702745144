import { useState, useEffect } from 'react';
import { Select } from 'antd';

import { usePlayable } from '../../../context/playable.context';

interface ISelectOption {
  label: string;
  value: any;
}

export function SettingCompSelect(props: {
  label: string,
  value: any,
  options: ISelectOption[],
  onChange: (value: any) => void,
  disabled?: boolean,
}) {
  const [value, _setValue] = useState<any>();

  useEffect(() => { _setValue(props?.value); }, [props.value]);

  const editValue = (newValue: any) => {
    _setValue(newValue);
    props.onChange(newValue);
  };

  return (
    <div className="row mb-1">
      <div className="col-5">
        <label>{props?.label}</label>
      </div>
      <div className="col-7">
        <Select
          disabled={props?.disabled}
          value={value || props.value}
          options={props?.options}
          size="small"
          onChange={(e: any) => editValue(e)}
        />
      </div>
    </div>
  );
}

export function SettingCompSelectLayerMask(props: {
  label: string,
  value: any,
  onChange: (value: any) => void,
  disabled?: boolean,
}) {
  const uPlayable = usePlayable();
  const [value, _setValue] = useState<any>();

  useEffect(() => { _setValue(props?.value); }, [props.value]);

  const editValue = (newValue: any) => {
    _setValue(newValue);
    props.onChange(newValue);
  };

  return (
    <div className="row mb-1">
      <div className="col-5">
        <label>{props?.label}</label>
      </div>
      <div className="col-7">
        <Select
          disabled={props?.disabled}
          value={value || props.value}
          options={uPlayable.playableClasses.LayerMask_Class.mp_layerToLayerName.map((layer: { layerName: string, layer: string }) => {
            return { label: layer.layerName, value: layer.layer };
          })}
          size="small"
          onChange={(e: any) => editValue(e)}
        />
      </div>
    </div>
  );
}
