/* eslint-disable no-unreachable-loop */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Popover } from 'antd';
import { useState } from 'react';
import { ChromePicker } from 'react-color';

import { MColor } from '../../modeles';
import { ColorTools } from '../../tools';

import './color-picker.scss';

export function YcColorPicker(props: {
  color: MColor,
  onColorChange: (color: MColor) => void
}) {
  const [backgroundColorVisible, _setbackgroundColorVisible] = useState(false);

  return (
    <Popover
      content={(
        <ChromePicker
          color={props.color}
          onChange={(e) => {
            const cn = new MColor(e.rgb.r, e.rgb.g, e.rgb.b, e.rgb.a);
            const c = props.color;
            if ((c.r !== cn.r || c.g !== cn.g || c.b !== cn.b) && cn.a === 0) {
              cn.a = 1;
            }
            props.onColorChange(cn);
          }}
        />
      )}
      trigger="click"
      open={backgroundColorVisible}
      onOpenChange={(e) => { _setbackgroundColorVisible(e); }}
    >
      <div className="preview-color-picker" style={{ backgroundColor: ColorTools.mColorToString(props.color) }} />
    </Popover>
  );
}
