import { Link, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button, Input } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { MApplication } from '../../modeles';
import { applicationsService } from '../../services';
import { IconApp } from '../icon-app';

import './side-applications.scss';

export function SideApplications() {
  const [applications, _setApplications] = useState<MApplication[]>([]);
  const [applicationsDisplayed, _setApplicationsDisplayed] = useState<MApplication[]>([]);
  const [application, _setApplication] = useState<MApplication>(new MApplication());
  const [appId, _setAppId] = useState<number>();

  const uHistory = useHistory();

  useEffect(() => {
    applicationsService.sideListing().then((data) => {
      _setApplications([...(data || [])]);
      _setApplicationsDisplayed(data || []);
      _setApplication(data?.find((app) => app.id === getAppId()) || new MApplication());
    });
    uHistory.listen((history) => {
      const id = parseInt(history.pathname.split('/')[2], 10);
      _setAppId(id);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const a = applications.find((app) => app.id === appId);
    if (a) {
      _setApplication(a);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId]);

  const onSearch = (value: string) => {
    _setApplicationsDisplayed(
      applications.filter(
        (appF) => appF.name.toLowerCase().includes(value.toLowerCase()) || appF.key.toUpperCase().includes(value.toUpperCase()),
      ),
    );
  };

  const getAppId = () => +window.location.pathname.split('/')[2] || null;

  return (
    <div id="side-applications-component">
      {application.id !== 0
        && (
          <div className="main-app">
            <Link to={`/applications/${application.id}/settings`}>
              <Button className="edit" icon={<EditOutlined />} size="small" />
            </Link>
            <IconApp app={application} />
          </div>
        )}
      <Input className="search-bar" placeholder="Filter" onChange={(e) => onSearch(e.target.value as string)} />
      <div className="applications">
        {
          applicationsDisplayed.map((app) => <IconApp app={app} selected={app.id === application.id} key={app.id} />)
        }
      </div>
    </div>
  );
}
