/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useMemo, useState } from 'react';

export interface MouseContextType {
  eventMove: React.MouseEvent<HTMLDivElement, MouseEvent> | null,
  nbDown: number,
  nbUp: number,
  setIsElementSelected: React.Dispatch<React.SetStateAction<boolean>>,
  isElementSelected: boolean,
}

const MouseContext = React.createContext<MouseContextType>({
  eventMove: null,
  nbDown: 0,
  nbUp: 0,
  setIsElementSelected: () => {},
  isElementSelected: false,
});

export function MouseContextProvider({ children }: { children: React.ReactNode }) {
  const [eventMove, _setEventMove] = useState<React.MouseEvent<HTMLDivElement, MouseEvent> | null>(null);
  const [nbDown, _setNbDown] = useState(0);
  const [nbUp, _setNbUp] = useState(1);
  const [isElementSelected, _setIsElementSelected] = useState(false);

  const value = useMemo(() => ({
    eventMove,
    nbDown,
    nbUp,
    isElementSelected,
    setIsElementSelected: _setIsElementSelected,
  }), [
    eventMove,
    nbDown,
    nbUp,
    isElementSelected,
    _setIsElementSelected,
  ]);
  return (
    <MouseContext.Provider value={value}>
      <div
        onMouseMove={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          _setEventMove(e);
        }}
        onMouseDown={() => {
          _setNbDown(nbDown + 1);
        }}
        onMouseUp={() => {
          _setNbUp(nbUp + 1);
        }}
      >
        {children}
      </div>
    </MouseContext.Provider>
  );
}

export function useMove() {
  const context = React.useContext(MouseContext);
  if (!context) throw new Error('No MouseContext provider found!');
  return context;
}
