import _ from 'lodash';
import saveAs from 'file-saver';

import { MCreativeExport } from '../modeles';
import { creativeExportsService } from '../services';

export function generateExportName(
  items: {
    crea_name?: string,
    app_name?: string,
    network?: string,
    key?: string,
    type?: string,
    version?: string,
    platform?: string,
  },
  exportFormatString: string,
) {
  let text: string = exportFormatString;
  Object.keys(items).forEach((item: string) => {
    // @ts-ignore
    text = text.replace(`{${item.toUpperCase()}}`, `${items[item] as string || ''}`);
  });
  while (text[text.length - 1] === '_' || text[0] === '_') {
    if (text[text.length - 1] === '_') {
      text = text.slice(0, -1);
    }
    if (text[0] === '_') {
      text = text.substring(1);
    }
  }
  text = text.replaceAll('__', '_');
  return text;
}

export function htmlDecode(input: any) {
  let text = _.unescape(input as string);
  text = text.replaceAll('&#x27;', '\'');
  return text;
}

export function getZipLanguageFile(translations: string[], index: number): string {
  const path = '';
  if (translations.length > 1) {
    return `${translations[index] === 'ZZ' ? 'DEFAULT' : translations[index]}/`;
  }
  return path;
}

export function exportPlatform(platform: string) {
  if (platform === 'ios') {
    return 'IOS';
  } if (platform === 'android') {
    return 'AND';
  }
  return '';
}

export async function addCreativeExports(newCreativeExport: MCreativeExport, content: Blob, platform: string, name: string) {
  await creativeExportsService.add(newCreativeExport);
  saveAs(content, `${name}.zip`);
}
