import { Link } from 'react-router-dom';
import * as HomeImages from '../../../../../../assets/images/home';

import { useAuths } from '../../../../../../contexts';

import './home-header.scss';

export function HomeHeader() {
  const uAuths = useAuths();
  const ycIsLogin = uAuths.isLogin();

  return (
    <div id="home-header">
      <img className="header" src={HomeImages.ImgHeader} alt="Img Header" />
      <div className="content row">
        <div className="col-12 col-lg-12 col-xl-7 align-self-center">
          <img className="icon" src={HomeImages.IconMonsterPlayableOrange} alt="Ico Monster Playable" />
          <h1 className="title"><p>Create your own Playable Ads</p> <p>and Interactive End Card (IEC)</p> <p>with Unity</p></h1>
          {
            !ycIsLogin
              ? <Link className="button" to="/login">Request a demo</Link>
              : <Link className="button" to="/applications">Your Applications</Link>
          }
        </div>
      </div>
    </div>
  );
}
