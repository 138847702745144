import { AssetVisual, ExportVisual, easilyVisual } from '../../../../../../assets/images/home';

import './why-mp.scss';

interface ICard {
  title: string;
  description: string;
  image: string;
  bgColor: string;
}

export function WhyMp() {
  const cards: ICard[] = [
    {
      title: 'Easily UI',
      description: 'Take advantage of a direct selection interface by easily selecting the elements of your game that you wish to modify.',
      image: easilyVisual,
      bgColor: '#50BCFF',
    },
    {
      title: 'Lots of export options',
      description: 'Easily export your project to your favorite networks. Meta, Google, App lovin, Mintegral, Unity.',
      image: ExportVisual,
      bgColor: '#6F9BFF',
    },
    {
      title: 'Assets Library',
      description: 'Take advantage of a library of unique and animated assets to enrich your ads.',
      image: AssetVisual,
      bgColor: '#00F4BB',
    },
  ];

  return (
    <div id="why-mp">
      <h2>Why use Monster Playable ?</h2>

      <div className="row justify-content-center">
        {
          cards.map((card, index) => (
            <div className="col-12 col-sm-6 col-lg-4 mb-5" key={index}>
              <UseCard card={card} />
            </div>
          ))
        }
      </div>
    </div>
  );
}

export function UseCard(props: {
  card: ICard,
}) {
  return (
    <div id="use-card">
      <div className="image" style={{ backgroundColor: props.card.bgColor }}>
        <img src={props.card.image} alt={props.card.title} />
      </div>
      <div className="content">
        <h3>
          {props.card.title}
        </h3>
        <p>
          {props.card.description}
        </p>
      </div>
    </div>
  );
}
