import { MCompBackground } from '../../../../../modeles';
import { ColorTools } from '../../../../../tools';

export const PreviewCompBackground_CSS = `
/* BACKGROUND */
.preview-comp-background {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
`;

export function PreviewCompBackground(props: {
  background: MCompBackground,
}) {
  return (
    <div
      className="preview-comp-background"
      style={{
        backgroundColor: ColorTools.mColorToString(props.background.color),
      }}
    />
  );
}
