import { BaseService } from './base.service';

class StoreScraperService extends BaseService {
  PATH = 'store-scraper/';

  async playStore(url: string): Promise<any> {
    const match = url?.match(/\/details\?id=([^&]+)/) || null;
    const appId = match ? match[1] : null;

    if (appId) {
      return this._view(`${this.PATH}play-store/${appId}`);
    }
    return { title: 'URL not valide', icon: '' };
  }

  async appStore(url: string): Promise<any> {
    const appId = (url?.split('/')?.pop() as string)?.split('id')[1] || null;
    if (appId) {
      return this._view(`${this.PATH}app-store/${appId}`);
    }
    return { title: 'URL not valide', icon: '' };
  }
}

export const storeScraperService = new StoreScraperService();
