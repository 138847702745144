import { useIec } from '../../../../context/iec.context';
import { MColor, MCompBorder, OverflowTypeValues } from '../../../../../../modeles';
import { YcCard, YcColorPicker } from '../../../../../../components';
import { IecInputNumber } from '../../../input-number';
import { IecSelect } from '../../../select';

import './setting-comp-border.scss';

export function SettingCompBorder(props: {
  border: MCompBorder
}) {
  const uIec = useIec();

  return (
    <YcCard
      title="Border"
      light
      open={props.border._open}
      onClickTitle={(val) => {
        props.border._open = val;
        uIec.refreshIec(true);
      }}
      onDelete={() => {
        uIec.removeComponentNodeEdite(props.border);
      }}
    >
      <div className="row">
        <div className="col-3">
          <IecInputNumber label="Size" field="size" obj={props.border} />
        </div>
        <div className="col-3">
          <IecInputNumber label="Radius" field="radius" obj={props.border} />
        </div>
        <div className="col-3">
          <label>Color</label>
          <YcColorPicker
            color={props.border.color}
            onColorChange={(color: MColor) => {
              props.border.color = color;
              uIec.refreshIec(true);
            }}
          />
        </div>
        <div className="col-3">
          <IecSelect label="Overflow" field="overflow" obj={props.border} values={OverflowTypeValues} />
        </div>
      </div>
    </YcCard>
  );
}
