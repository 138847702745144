import { Link } from 'react-router-dom';
import { Button, Spin } from 'antd';
import { useMemo, useState } from 'react';
import { AimOutlined } from '@ant-design/icons';

import { LineChart, YcTitle } from '../../../../components';
import { MpTable, MpColumnType } from '../../../../components/table';
import { reportingService } from '../../../../services';
import { ReportingFilter } from '../../components/reporting-filters';

import './reporting.page.scss';

interface Total {
  count: number,
  displayed: number,
  redirect: number,
}

interface ResultLine {
  id: number;
  date?: string;
  platform?: string;
  applicationKey?: string;
  creative?: string;
  user?: string;
  countryCode?: string;
  network?: string;
  creativeType?: string;
  adName?: string;
  count: number,
  displayed: number,
  redirect: number,
}

interface Result {
  is: string[],
  total: Total,
  results: ResultLine[],
}

export function ReportingPage() {
  const [results, _setResults] = useState<Result | null>(null);
  const [loading, _setLoading] = useState<boolean>(false);
  const [chartData, _setChartData] = useState<Array<any>>([]);
  const [chartDataKey, _setChartDataKey] = useState<Array<any>>([]);
  const [specificToAdd, _setSpecificToAdd] = useState<any>([]);

  const onSearch = (data: any) => {
    _setLoading(true);
    _setChartData([]);
    reportingService.search(data as object).then((res) => {
      _setLoading(false);
      if (res) {
        res.results = res.results.map((e: ResultLine) => ({
          ...e,
        }));
        _setResults({
          ...res,
          is: data.is ? data.is.split(',') : [],
        } as unknown as Result);
        if (data.is?.split(',').length >= 1 && data.is?.split(',').length < 3 && data.is.includes('granularity')) {
          const newList: any[] = [];
          const newListKey: any[] = [];
          let breakpoint = data.is?.split(',')[0] === 'granularity' ? data.is?.split(',')[1] : data.is?.split(',')[0];
          if (breakpoint === 'applicationId') breakpoint = 'applicationKey';
          if (breakpoint === 'creativeId') breakpoint = 'creativeName';
          if (breakpoint === 'userId') breakpoint = 'userName';
          if (breakpoint === 'country') breakpoint = 'countryCode';
          res.results.forEach((element: any) => {
            if (!newListKey?.includes(element[breakpoint])) {
              newListKey.push(element[breakpoint]);
            }
            if (newList.find((item: any) => item.date === element.date)) {
              const e = newList.find((item: any) => item.date === element.date);
              // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
              e[element[breakpoint]] = (newList.find((item: any) => item.date === element.date)[breakpoint] || 0) + element.displayed;
            } else {
              newList.push({ date: element.date, [element[breakpoint] || 'displayed']: element.displayed });
            }
          });
          if (newListKey.length === 1 && newListKey[0] === undefined) {
            newListKey[0] = 'displayed';
          }
          _setChartData(newList.sort((a: any, b: any) => a.date - b.date));
          _setChartDataKey(newListKey);
        }
      }
      _setLoading(false);
    });
  };

  const columDate: MpColumnType<ResultLine> = {
    title: 'Date',
    key: 'date',
    dataIndex: 'date',
    mpSort: 'string',
    mpCanNotHide: true,
  };

  const columPlatform: MpColumnType<ResultLine> = {
    title: 'Platform',
    key: 'platform',
    dataIndex: 'platform',
    mpSort: 'string',
    mpCanNotHide: true,
    render: (text: string, record: any) => (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>{record.platform}</span>
        <Button
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={() => _setSpecificToAdd({
            ...record,
            category: 'platforms',
            fieldToCompare: 'value',
            compareWith: 'platform',
          })}
        >
          <AimOutlined />
        </Button>
      </div>
    ),
  };

  const columApplication: MpColumnType<ResultLine> = {
    title: 'Application',
    key: 'applicationKey',
    dataIndex: 'applicationKey',
    mpSort: 'string',
    mpCanNotHide: true,
    render: (text: string, record: any) => (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Link to={`/applications/${record.applicationId}/creatives`}>
          {record.applicationKey}
        </Link>
        <Button
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={() => _setSpecificToAdd({
            ...record,
            category: 'applications',
            fieldToCompare: 'label',
            compareWith: 'applicationKey',
          })}
        >
          <AimOutlined />
        </Button>
      </div>
    ),
  };

  const columCreative: MpColumnType<ResultLine> = {
    title: 'Creative',
    key: 'creativeName',
    dataIndex: 'creativeName',
    mpSort: 'string',
    mpCanNotHide: true,
    render: (text: string, record: any) => (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Link to={`/applications/${record.applicationId}/creatives/iec/${record.creativeId}`}>
          {record.creativeName}
        </Link>
        <Button
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={() => _setSpecificToAdd({
            ...record,
            category: 'creatives',
            fieldToCompare: 'value',
            compareWith: 'creativeId',
          })}
        >
          <AimOutlined />
        </Button>
      </div>
    ),
  };

  const columUser: MpColumnType<ResultLine> = {
    title: 'User',
    key: 'userName',
    dataIndex: 'userName',
    mpSort: 'string',
    mpCanNotHide: true,
  };

  const columCreativeType: MpColumnType<ResultLine> = {
    title: 'Creative Type',
    key: 'creativeType',
    dataIndex: 'creativeType',
    mpSort: 'string',
    mpCanNotHide: true,
  };

  const columNetwork: MpColumnType<ResultLine> = {
    title: 'Network',
    key: 'network',
    dataIndex: 'network',
    mpSort: 'string',
    mpCanNotHide: true,
    render: (text: string, record: any) => (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>{record.network}</span>
        <Button
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={() => _setSpecificToAdd({
            ...record,
            category: 'networks',
            fieldToCompare: 'value',
            compareWith: 'network',
          })}
        >
          <AimOutlined />
        </Button>
      </div>
    ),
  };

  const columCountry: MpColumnType<ResultLine> = {
    title: 'Country',
    key: 'countryCode',
    dataIndex: 'countryCode',
    mpSort: 'string',
    mpCanNotHide: true,
    render: (text: string, record: any) => (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>{record.countryCode}</span>
        <Button
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={() => _setSpecificToAdd({
            ...record,
            category: 'countries',
            fieldToCompare: 'value',
            compareWith: 'countryCode',
          })}
        >
          <AimOutlined />
        </Button>
      </div>
    ),
  };

  const columns: MpColumnType<ResultLine>[] = [
    {
      title: 'Impression',
      dataIndex: 'displayed',
      key: 'displayed',
      mpSort: 'number',
      render: (text: string, record: any) => (
        <label>{record.displayed.toLocaleString()}</label>
      ),
    },
    {
      title: 'Redirect',
      dataIndex: 'redirect',
      key: 'redirect',
      mpSort: 'number',
      render: (text: string, record: any) => (
        <label>{record.redirect.toLocaleString()}</label>
      ),
    },
    {
      title: 'Conversion',
      dataIndex: 'conversion',
      key: 'conversion',
      mpSort: 'number',
      render: (text: string, record: any) => (
        <label>{`${((+record.redirect * 100) / +record.displayed).toFixed(3)}%`}</label>
      ),
    },
  ];

  const columnsMemo = useMemo(() => {
    let cols: MpColumnType<ResultLine>[] = [...columns];
    let bCols: MpColumnType<ResultLine>[] = [];

    if (results?.is.includes('creativeType')) {
      bCols = [columCreativeType, ...bCols];
    }
    if (results?.is.includes('country')) {
      bCols = [columCountry, ...bCols];
    }
    if (results?.is.includes('network')) {
      bCols = [columNetwork, ...bCols];
    }
    if (results?.is.includes('applicationId')) {
      bCols = [columApplication, ...bCols];
    }
    if (results?.is.includes('creativeId')) {
      bCols = [columCreative, ...bCols];
    }
    if (results?.is.includes('userId')) {
      bCols = [columUser, ...bCols];
    }
    if (results?.is.includes('platform')) {
      bCols = [columPlatform, ...bCols];
    }
    if (results?.is.includes('granularity')) {
      bCols = [columDate, ...bCols];
    }
    if (bCols.length) {
      cols = [{
        title: 'Breakdown',
        dataIndex: 'breakdown',
        key: 'breakdown',
        mpCanNotHide: true,
        children: bCols,
      }, ...cols];
    }
    return cols;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results?.is]);

  const summarys = results ? [
    { key: 'generique', value: undefined, colSpan: results.is.length },
    { key: 'displayed', value: results.total.displayed.toLocaleString() || undefined },
    { key: 'redirect', value: results.total.redirect.toLocaleString() || undefined },
    { key: 'conversion', value: `${((+results.total.redirect * 100) / +results.total.displayed).toFixed(3)}%` },
  ] : undefined;

  return (
    <div id="reporting-advertising">
      <YcTitle label="Reporting" />
      <Spin spinning={loading}>
        <div className="mb-3">
          <ReportingFilter
            service={reportingService}
            onSearch={onSearch}
            specificToAdd={specificToAdd}
            specificToAddSetter={_setSpecificToAdd}
          />
        </div>
        {!!chartData[0] && <LineChart data={chartData} dataKey="date" barDataKey={chartDataKey} />}
        {!!results && (
          <MpTable
            bordered
            size="small"
            dataSource={results.results}
            columns={columnsMemo}
            mpSummarys={summarys}
            mpTableKey="reporting-advertising"
            rowKey="id"
            horizontalScrollEnabled
          />
        )}
      </Spin>
    </div>
  );
}
