import { BaseService } from './base.service';
import { MCustomFont } from '../modeles';

class CustomFontService extends BaseService {
  PATH = 'custom-font/';

  async listing(): Promise<MCustomFont[] | null> {
    return this._listing(`${this.PATH}listing`);
  }

  async add(font: MCustomFont): Promise<MCustomFont | null> {
    return this._add(`${this.PATH}add`, font);
  }

  async remove(id: number): Promise<MCustomFont | null> {
    return this._delete(`${this.PATH}delete/${id}`);
  }
}

export const customFontService = new CustomFontService();
