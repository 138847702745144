import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';

import { MApplication } from '../../modeles';

import './icon-app.scss';

export function IconApp({ size = 52, ...props }: {
  app: MApplication,
  selected?: boolean,
  size?: number,
}) {
  const dynamicLink = (id: string | number) => {
    let endUrl = '/';
    for (let index = 3; index < window.location.pathname.split('/').length; index += 1) {
      endUrl += window.location.pathname.split('/')[index];
    }
    if (endUrl.includes('iec') || endUrl.includes('export') || endUrl.includes('playable')) {
      endUrl = '/creatives';
    }
    return `/applications/${id}${endUrl !== '/' ? endUrl : ''}`;
  };

  return (
    <div className="icon-app">
      <Tooltip title={props.app.name} placement="right">
        <Link className="link-to-iecs" to={() => dynamicLink(props.app.id)}>
          <div
            className={`application-icon ${props.selected ? 'selected' : ''}`}
            style={{
              width: `${size}px`,
              height: `${size}px`,
            }}
          >
            {
              props.app.Icon?.url
                ? <img className="icon" src={props.app.Icon?.url} alt={props.app.name} />
                : <span className="no-icon">{props.app.name[0]}</span>
            }
          </div>
        </Link>
      </Tooltip>
    </div>
  );
}
