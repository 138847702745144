import { useEffect, useState } from 'react';

import { usePlayable } from '../../../../context/playable.context';
import { YcCard } from '../../../../../../components';
import { IParticleSystemJson as JsonType } from '../../../../../../modeles/MP_Interfaces';
import { SettingCompInputBoolean, SettingCompInputNumber } from '../../sub-settings/comp-input';
import { SettingCompColor } from '../../sub-settings';

import './setting-comp-particle-system.scss';

export function SettingCompParticleSystem(props: { initialData: any, parentID: string, icon?: string }) {
  const childType: string = props.initialData.type;
  const childID: string = props.initialData.instanceID;
  const parentID: string = props.parentID;

  const uPlayable = usePlayable();

  const [compTypeIndex, _setCompTypeIndex] = useState<number>();
  const [component, _setComponent] = useState<JsonType>();

  useEffect(() => {
    if (compTypeIndex === undefined) {
      _setCompTypeIndex(uPlayable.getCompTypeIndex(childType, parentID, childID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (props !== undefined && compTypeIndex !== undefined) {
      _setComponent(uPlayable.getRunTimeComponent(`${childType}[${compTypeIndex}]`) as JsonType);
    }
  }, [childType, compTypeIndex, props, uPlayable]);

  const editComponent = (propertyPath: string, value: any, update: boolean = true) => {
    if (compTypeIndex !== undefined) {
      uPlayable.updateGameObject(value, `${childType}[${compTypeIndex}]/${propertyPath}`, childID, update);
    }
  };

  return (
    <YcCard title="Particle System" icon={props.icon} light open warning={compTypeIndex === undefined}>
      <div className="row">
        <div className="col-12">
          <SettingCompInputNumber
            label="Duration"
            value={component?.main?.duration ?? 5}
            onChange={(e) => editComponent('main/duration', e)}
          />
          <SettingCompInputBoolean
            label="Looping"
            checked={component?.main?.loop}
            onChange={(e) => editComponent('main/loop', e)}
          />
          <SettingCompColor
            defaultType="white"
            label="Start Color"
            // @ts-ignore
            color={component?.main?.startColor?.color}
            onChange={(e, updateJson) => editComponent('main/startColor/colorMax', e, updateJson)}
          />
          <SettingCompInputNumber
            label="Max Particles"
            // @ts-ignore
            value={component?.main?.maxParticles ?? 1000}
            onChange={(e) => editComponent('main/maxParticles', e)}
          />
        </div>
      </div>
    </YcCard>
  );
}
