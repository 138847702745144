import { useEffect, useState } from 'react';
import { Button } from 'antd';

import { YcCard } from '../../../../../../components';
import { usePlayable } from '../../../../context/playable.context';
import { SettingCompColor } from '../comp-color';
import { SettingCompSlider } from '../comp-slider';
import { SettingCompInputText } from '../comp-input';
import { SettingCompSelect } from '../comp-select';
import { SettingCompImage } from '../comp-image/comp-image';

import './sub-setting-comp-materials.scss';

const emissionFlagOptions: { label: string, value: number }[] = [
  { label: 'None', value: 0 },
  { label: 'Realtime', value: 1 },
  { label: 'Baked', value: 2 },
  { label: 'Emissive Is Black', value: 3 },
  { label: 'Any', value: 4 },
];

const path = 'MeshRenderer[0]/sharedMaterials';

export function SubSettingCompMaterials(props: { materialId: string, index: number, childID: string }) {
  const uPlayable = usePlayable();

  const [localData, _setLocalData] = useState<any>();

  // console.log('materialId: ', props.materialId);
  // console.log('index: ', props.index);
  // console.log('childID: ', props.childID);

  useEffect(() => {
    if (props.materialId !== undefined) {
      // @ts-ignore
      _setLocalData(uPlayable.playableJson.assets.materials[props.materialId]);
    }
  }, [props.materialId, uPlayable?.playableJson?.assets?.materials]);

  const editMaterial = (propertyPath: string, value: any, updateJson: boolean = true) => {
    uPlayable.updateGameObject(value, `${path}[${props.index}]${propertyPath}`, props.childID, updateJson);
  };

  const handleDuplicateMaterial = () => {
    const newMaterialId = uPlayable.duplicateMaterial(props.materialId);

    uPlayable.updateGameObject([newMaterialId], path, props.childID);
  };

  return (
    <div className="sub-setting-comp-materials">
      {
        localData && (
          <YcCard title={`${localData?.name} (Material)`} light open>
            <SettingCompInputText
              label="Name"
              value={localData?.name}
              onChange={(e: string) => editMaterial('/name', e)}
            />
            <SettingCompImage
              label="Image"
              value={localData.mainTextureAssetName}
              uploadLabel="Upload an image"
              onChange={(data) => editMaterial('/mainTextureAssetName', data)}
            />
            <SettingCompColor
              defaultType="white"
              color={localData?.color}
              label="Color"
              onChange={(color, updateJson) => editMaterial('/color', color, updateJson)}
            />
            <SettingCompSlider
              min={0}
              max={1}
              step={0.1}
              value={localData?.metalness ?? 0}
              label="Metalness"
              onChange={(e, update) => editMaterial('/metalness', e, update)}
            />
            <SettingCompSlider
              disabled
              min={0}
              max={1}
              step={0.1}
              value={localData?.glossiness ?? 0.5}
              label="Smoothness"
              onChange={(e, update) => editMaterial('/glossiness', e, update)}
            />
            <SettingCompColor
              disabled
              defaultType="white"
              color={localData?.emission}
              label="Emission"
              onChange={(color, updateJson) => editMaterial('/emission', color, updateJson)}
            />
            <SettingCompSelect
              disabled
              label="Emission Flag"
              value={localData?.emissionFlag}
              options={emissionFlagOptions}
              onChange={(e) => editMaterial('/emissionFlag', e)}
            />
            <Button className="bottom-btn" onClick={handleDuplicateMaterial}>
              <span>Duplicate material</span>
            </Button>
          </YcCard>
        )
      }
    </div>
  );
}
