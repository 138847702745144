import './card-creation.scss';

export interface ICardCreation {
  title: string;
  description: string;
  icon: string;
  image: string;
  bgColor: string;
}

export function CardCreation(
  props:{
    card: ICardCreation;
    number: number;
  },
) {
  return (
    <div className="card-creation mb-5">

      <span className="number-step">{props.number}</span>

      <div className="content row">
        <div className="col-md-7">
          <div className="content-step">
            <img className="ico-step" src={props.card.icon} alt="icon step" />
            <h2 className="title-card">{props.card.title}</h2>
            <p className="description">{props.card.description}</p>
          </div>
        </div>

        <div style={{ backgroundColor: props.card.bgColor }} className="keyvisual-style col-md-5">

          <img className="img-step" src={props.card.image} alt="visual step" />

        </div>
      </div>
    </div>
  );
}
