import { IconMonsterPlayable } from '../../../../../../../assets/images/home';
import { IGame } from '../../../home.page';

import './preview-card.scss';

export function PreviewCard(props: {
  game: IGame | null,
}) {
  return (
    <div id="preview-card">
      <div className={`content ${props.game?.url ? 'no-padding' : ''}`}>
        {
          props.game?.url
            ? (<iframe src={props.game.url} title="Game url" className="url" />)
            : (<img src={IconMonsterPlayable} alt="Monster Playable Icon" />)
        }
      </div>
    </div>
  );
}
