import * as NetworksLogo from '../assets/networks-logo';

export class MNetwork {
  id?: number = 0;
  name: string = '';
  value: string = '';
  isActive: boolean = false;
  logo?: string;
  isBeta: boolean = false;
  isDown: boolean = false;
}

export const networks: MNetwork[] = [
  {
    id: 1, name: 'Mintegral', value: 'mintegral', isActive: true, logo: NetworksLogo.Mintegral, isBeta: false, isDown: false,
  },
  {
    id: 2, name: 'Applovin', value: 'applovin', isActive: true, logo: NetworksLogo.AppLovin, isBeta: false, isDown: false,
  },
  {
    id: 3, name: 'Unity ADS', value: 'unityads', isActive: true, logo: NetworksLogo.UnityAds, isBeta: false, isDown: false,
  },
  {
    id: 4, name: 'IronSource', value: 'ironsource', isActive: true, logo: NetworksLogo.IronSource, isBeta: false, isDown: false,
  },
  {
    id: 5, name: 'YsoNetwork', value: 'ysonetwork', isActive: true, logo: NetworksLogo.YsoNetwork, isBeta: false, isDown: false,
  },
  {
    id: 6, name: 'Google Ads', value: 'googleads', isActive: true, logo: NetworksLogo.GoogleAds, isBeta: true, isDown: false,
  },
  {
    id: 7, name: 'Digital Turbine', value: 'digitalturbine', isActive: true, logo: NetworksLogo.DigitalTurbine, isBeta: true, isDown: false,
  },
  {
    id: 8, name: 'Meta', value: 'meta', isActive: true, logo: NetworksLogo.Meta, isBeta: false, isDown: false,
  },
  {
    id: 9, name: 'TikTok', value: 'tiktok', isActive: true, logo: NetworksLogo.Tiktok, isBeta: true, isDown: false,
  },
  {
    id: 10, name: 'Moloco - HTML', value: 'moloco_html', isActive: false, logo: NetworksLogo.Moloco, isBeta: true, isDown: true,
  },
  {
    id: 11, name: 'Moloco - TXT', value: 'moloco_txt', isActive: false, logo: NetworksLogo.Moloco, isBeta: true, isDown: true,
  },
];
