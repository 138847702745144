import { NavLink } from 'react-router-dom';

// styles
import './tabs.scss';

export interface YcTabsElems {
  link: string,
  label: string,
  icon?: React.ReactNode,
  disabled?: boolean,
}

export function YcTabs(props: {
  elems: YcTabsElems[],
}) {
  return (
    <div className="tabs">
      <ul className="list-unstyled">
        {props.elems.map((elem) => {
          if (!elem.disabled) {
            return (
              <li key={elem.link}>
                <NavLink activeClassName="active" exact={false} to={elem.link}>
                  {!!elem.icon && elem.icon}
                  {elem.label}
                </NavLink>
              </li>
            );
          }
          return null;
        })}
      </ul>
    </div>
  );
}
