import {
  EMPTY, LOOP, DOUBLE_CLICK, DOUBLE_CHOICE,
} from '../../../../../../assets/images/template';
import {
  MColor,
  MCompBackground,
  MCompBorder,
  MCompButton,
  MCompText,
  MCreative,
  MCreativeBreak,
  MCreativeElementBase,
  MCreativeHover,
  MCreativeVideo,
} from '../../../../../../modeles';

export const TEMPLATES = [
  {
    title: 'Empty',
    key: 'empty',
    description: 'Start from scratch',
    src: EMPTY,
  },
  {
    title: 'Two Click',
    key: 'twoClick',
    description: 'A simple template to start!',
    src: DOUBLE_CLICK,
    iec: '/two-click.html',
  },
  {
    title: 'Simple Loop',
    key: 'loop',
    description: 'A simple loop',
    src: LOOP,
    iec: '/simple-loop.html',
  },
  {
    title: 'Double choice',
    key: 'doubleChoice',
    description: 'A multiple choise',
    src: DOUBLE_CHOICE,
    iec: '/double-choice.html',
  },
];

export const setSelectedTemplate = (iec: MCreative, templateKey: string) => {
  switch (templateKey) {
    case 'empty':
      emptyTemplate(iec);
      break;

    case 'twoClick':
      doubleClickTemplate(iec);
      break;

    case 'loop':
      loopTemplate(iec);
      break;

    case 'doubleChoice':
      doubleChoiceTemplate(iec);
      break;

    default:
      emptyTemplate(iec);
      break;
  }
};
export const emptyTemplate = (iec: MCreative) => {
  const newBreak = new MCreativeBreak();
  iec.iecData.videos[0].breaks.push(newBreak);
};

export const doubleClickTemplate = (iec: MCreative) => {
  for (let i = 0; i < 2; i += 1) {
    const newBreak = new MCreativeBreak(i);
    const newButton = new MCreativeElementBase();
    newButton.components.push(
      new MCompButton(i === 1 ? 'endcard-display' : 'video-continue'),
      new MCompBackground(new MColor(100, 220, 60, 1)),
      new MCompBorder(10, 40, new MColor(0, 0, 0, 0.5)),
      new MCompText('Click', undefined, new MColor(255, 255, 255, 1)),
    );
    newBreak.elements.push(newButton);
    iec.iecData.videos[0].breaks.push(newBreak);
    iec.iecData.endcard.background = new MCompBackground(new MColor(0, 0, 0, 0.5));
  }
};

export const loopTemplate = (iec: MCreative) => {
  const newHover = new MCreativeHover();
  const newButton = new MCreativeElementBase();
  newButton.components.push(
    new MCompButton('endcard-display'),
    new MCompBackground(new MColor(100, 220, 60, 1)),
    new MCompBorder(10, 40, new MColor(0, 0, 0, 0.5)),
    new MCompText('Click', undefined, new MColor(255, 255, 255, 1)),
  );
  newHover.elements.push(newButton);
  iec.iecData.videos[0].hover = newHover;
  iec.iecData.videos[0].loop = true;
  iec.iecData.endcard.background = new MCompBackground(new MColor(0, 0, 0, 0.5));
};

export const doubleChoiceTemplate = (iec: MCreative) => {
  for (let i = 0; i < 2; i += 1) {
    const newVideo = new MCreativeVideo();
    iec.iecData.videos.push(newVideo);
  }

  const previewBreak = new MCreativeBreak();

  for (let i = 1; i < 3; i += 1) {
    const video = iec.iecData.videos[i];

    const previewComponentButton = new MCompButton('video-change');
    previewComponentButton.videoKey = video._key;

    const previewVideoButton = new MCreativeElementBase();
    previewVideoButton.components.push(previewComponentButton);

    previewVideoButton.components.push(
      new MCompBackground(new MColor(100, 220, 60, 1)),
      new MCompBorder(10, 40, new MColor(0, 0, 0, 0.5)),
      new MCompText(`Choice ${i}`, undefined, new MColor(255, 255, 255, 1)),
    );

    previewBreak.elements.push(previewVideoButton);
  }

  iec.iecData.videos[0].breaks.push(previewBreak);
};

export const complexTemplate = (iec: MCreative) => {
  // console.log(iec);
};
