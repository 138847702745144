import type { TreeProps, DataNode } from 'antd/es/tree';
import { Spin, Tree } from 'antd';
import { useEffect, useState } from 'react';

import { findElem } from '../../../utils/playable.utils';
import { YcCard } from '../../../../../components';
import { ElemTitle } from './elem-list-title';
import { usePlayable } from '../../../context/playable.context';
import { IData, IGameObjectJson } from '../../../../../modeles/MP_Interfaces';
import { IcoTree } from '../../../../../assets/images/icons';

import './elem-list.scss';

export function PlayableListElem(props: TreeProps) {
  const uPlayable = usePlayable();
  const [treeList, _setTreeList] = useState<DataNode[]>([]);

  useEffect(() => {
    if (uPlayable.playableJson && uPlayable.playableWindow.monsterplayable) {
      _setTreeList(playableToTreeList(uPlayable.playableJson));
    } else {
      _setTreeList([]);
    }
  }, [uPlayable, uPlayable.playableJson]);

  const nodeSelected = uPlayable.getNodeEdit();
  const selectedKeys = nodeSelected ? [nodeSelected.instanceID] : [];
  const expandedKeys = uPlayable.getAllOpenKeys();

  const onExpand = (expandedK: React.Key[], info: any) => {
    uPlayable.setNodeOpened([info.node.key as string], info.expanded as boolean);
  };

  const onSelect = (keys: React.Key[]/* , info: any */) => {
    if (keys && keys.length && uPlayable.playableJson) {
      const node = findElem(uPlayable.playableJson, keys[0]);
      if (node) {
        uPlayable.setNodeEdit(node);
      }
    }
  };

  const sceneName = uPlayable?.playableJson?.scene?.name;

  return (
    <YcCard
      title="Hierarchy"
      icon={<img src={IcoTree} alt="Icon Tree" className="yc-icon" />}
      open
    >
      <Spin spinning={treeList.length < 1}>
        <div className="playable-elem-list">
          <Tree
            className="iec-elem-list"
            blockNode
            defaultExpandAll
            expandedKeys={expandedKeys}
            onExpand={onExpand}
            onSelect={onSelect}
            treeData={treeList || undefined}
            selectedKeys={selectedKeys}
            {...props}
          />
        </div>
      </Spin>
    </YcCard>
  );
}

const playableToTreeList = (dataJson: IData) => {
  const parseElems = (elements: IGameObjectJson[]): DataNode[] => {
    const ret: DataNode[] = [];
    for (let i = 0; i < elements.length; i += 1) {
      const e = elements[i];
      const elemP: DataNode = {
        title: <ElemTitle node={e} key={e.instanceID} />,
        key: e.instanceID,
      };
      if (e.children && e.children.length) {
        elemP.children = parseElems(e.children);
      }
      ret.push(elemP);
    }
    return ret;
  };
  return parseElems(dataJson.scene.gameObjects);
};
