export const objByArrPath = (obj: any, path: any): any => {
  try {
    let result = obj;

    for (const step of path) {
      if (Array.isArray(result) && typeof step === 'number') {
        result = result[step];
      } else if (step in result) {
        result = result[step];
      } else {
        throw new Error(`Error with "${step}"`);
      }
    }
    return result;
  } catch (e: any) {
    console.error(e);
    return undefined;
  }
};

export const updateObjectByArrPath = (object: any, path: any, data: any): any => {
  let current = object;

  for (let i = 0; i < path.length - 1; i += 1) {
    const key = path[i];
    if (key in current) {
      current = current[key];
    } else {
      current[key] = {};
      current = current[key];
    }
  }

  const lastKey = path[path.length - 1];
  current[lastKey] = data;
  return object;
};

export const deleteGameObjectByID = (objects: any, targetID: string) => {
  for (let i = 0; i < objects.length; i += 1) {
    if (objects[i].instanceID === targetID) {
      objects.splice(i, 1);
      return true;
    }

    if (objects[i].children) {
      const childRemoved = deleteGameObjectByID(objects[i].children, targetID);

      if (childRemoved) { return true; }
    }
  }

  return false;
};

const updateComponentsID = (comps: any, componentsToCopy: any) => {
  const newComps: any[] = [];

  componentsToCopy.forEach((newComp: any, i: number) => {
    newComps.push({
      ...comps[i],
      instanceID: newComp.instanceID,
    });
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return newComps;
};

const getGameObjectValues = (gameObject: any, newGameObject: any, isRoot: boolean = true): any => {
  return {
    ...gameObject,
    children: gameObject?.children?.map((child: any, j: number) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return duplicateGameObjectByID(child, newGameObject.mp_children[j], false);
    }),
    components: updateComponentsID(gameObject.components, newGameObject.mp_allComponents),
    instanceID: newGameObject.instanceID,
    name: `${gameObject.name}${isRoot ? '_copy' : ''}`,
  };
};

// eslint-disable-next-line consistent-return
export const duplicateGameObjectByID = (objects: any, newGameObject: any, isFirstIteration: boolean = true): any => {
  if (isFirstIteration) {
    for (let i = 0; i < objects.length; i += 1) {
      if (objects[i].instanceID === newGameObject?.sourceID) {
        objects.push(getGameObjectValues(objects[i], newGameObject));
      }
    }
  } else if (objects?.instanceID === newGameObject?.sourceID) {
    return getGameObjectValues(objects, newGameObject, false);
  }
};

export const generateAssetId = (obj: any): string => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const idArr = Object.keys(obj);

  if (idArr.length === 0) { return '0'; }

  // Setting the default new id to the last one + 1
  let newId: number = parseInt(idArr[idArr.length - 1], 10) + 1;

  // Incrementing until id's unique
  while (idArr[newId] !== undefined) { newId += 1; }

  return newId.toString();
};

export const essenceOfStr = (str: string): string => {
  return str.replace(/\d/g, '').replace(/\s/g, '');
};
