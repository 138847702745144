import { Collapse, Image } from 'antd';

import './step-four.scss';
import { Header, Settings, Build } from '../../../../../../assets/images/installation-images';

const { Panel } = Collapse;

export function StepFour() {
  return (
    <div id="step-four">
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Header" key="1">
          <div className="row">
            <div className="col-5">
              <Image height={50} src={Header} />
            </div>
            <div className="col-7">
              <p>
                <b>Header:</b>
                {' '}
                You can move to the website, navigate through the plugin, and disconnect.
              </p>
            </div>
          </div>
        </Panel>
        <Panel header="Settings" key="2">
          <div className="row">
            <div className="col-3">
              <Image width={300} src={Settings} />
            </div>
            <div className="col-5">
              <ul>
                <li>
                  <b>Global settings</b>
                  <ol>
                    <u>Creative name:</u>
                    {' '}
                    The name you want to use for the playable
                  </ol>
                  <ol>
                    <u>Application name:</u>
                    {' '}
                    Selected in the previous view
                  </ol>
                  <ol>
                    <u>Playable version:</u>
                    {' '}
                    Auto selected but editable
                  </ol>
                </li>
              </ul>
            </div>
          </div>
        </Panel>
        <Panel header="Build" key="3">
          <div className="row">
            <div className="col-5">
              <Image height={100} src={Build} />
            </div>
            <div className="col-7">
              <p>
                <b>Build:</b>
                {' '}
                Build the playable !
              </p>
            </div>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
}
