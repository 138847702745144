import {
  Form, Input, Button, Result,
} from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { SmileOutlined } from '@ant-design/icons';

import * as ImagesSignup from '../../../../assets/images/sign-up';
import { authsService } from '../../../../services';

import './forgot-password.page.scss';

export function ForgotPasswordPage() {
  const [email, _setEmail] = useState<string>('');

  const onFinish = (values: any) => {
    authsService.forgotPassword(values.email as string).then((/* data */) => {
      _setEmail(values.email as string);
    });
  };

  return (
    <div id="root-forgot-password">
      <img src={ImagesSignup.Bkgcontent} className="bkgcontent" alt="monster" />
      <div className="block-bkg">
        <img src={ImagesSignup.Monster} className="monster" alt="monster" />
        <div className="block-auth">
          <h1>Forgot password</h1>
          {!email
            ? (
              <Form onFinish={onFinish} layout="vertical">
                <Form.Item label="Email" name="email" rules={[{ required: true }]}>
                  <Input placeholder="Email" type="email" />
                </Form.Item>
                <Form.Item style={{ marginBottom: '0px' }}>
                  <Button htmlType="submit" type="primary">Send</Button>
                  <Link className="pl-3" to="/login">Login</Link>
                </Form.Item>
              </Form>
            )
            : (
              <Result
                icon={<SmileOutlined />}
                title={`If your account exists, we will send you an email at ${email}`}
              />
            )}
        </div>
      </div>
    </div>
  );
}
