import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Input } from 'antd';

import { IecsListPage, PlayablesListPage } from './creative-lists';
import { creativesService } from '../../../../services';
import { useAuths } from '../../../../contexts/auths.context';
import { CreativesContextType, useCreatives } from '../../context/creatives.context';

import './creatives.page.scss';

export function CreativesPage() {
  const uAuths = useAuths();
  const uHistory = useHistory();
  const uParams: any = useParams();
  const uCreatives: CreativesContextType = useCreatives();
  const [filter, _setFilter] = useState<string>('');

  useEffect(() => {
    uCreatives.setIsReady(false);
    if (uParams.appId) {
      creativesService.listingByApplication(uParams.appId as number).then((data) => {
        uCreatives.setCreatives(data || []);
        uCreatives.setIsReady(true);
      });
    } else {
      uHistory.push('/application');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uParams.appId]);

  const onSearch = (value: string) => {
    _setFilter(value.toLowerCase());
  };

  return (
    <div id="creatives-creatives">
      <div className="col-lg-4 mb-2">
        <Input placeholder="Search" onChange={(e) => { onSearch(e.target.value as string); }} />
      </div>
      <div className="row">
        { uAuths.canAccess('applications/iec')
          && (
          <div className={(uAuths.canAccess('applications/playable') && uAuths.canAccess('applications/iec')) ? 'col-xl-6 mb-5' : 'col-lg-12'}>
            <IecsListPage filter={filter} />
          </div>
          )}
        { uAuths.canAccess('applications/playable')
          && (
            <div className="col-xl-6 col-lg-12">
              <PlayablesListPage filter={filter} />
            </div>
          )}
      </div>
    </div>
  );
}
