import { Image } from 'antd';

import { IconMonsterPlayableOrange } from '../../../../../../assets/images/home';
import { usePlayable } from '../../../../context/playable.context';

import './assets-texture.scss';

export function AssetsTexture(props: {
  textures: { [key: string]: any },
}) {
  const uPlayable = usePlayable();

  const getImage = (id: string): string => {
    const image = uPlayable.playableWindow.monsterplayable?.workflow.resourceLoader?.GetTextureByID(id)?.mp_img64;
    return image as string || '';
  };

  return (
    <div className="assets-texture">
      {
        Object.keys(props.textures).map((texture) => {
          return (
            <div className="texture" key={texture}>
              <Image
                src={getImage(texture)}
                fallback={IconMonsterPlayableOrange}
                className="image"
              />
              <p className="label">
                {props.textures[texture].name}
              </p>
            </div>
          );
        })
      }
    </div>
  );
}
