/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  useCallback, useMemo, useState,
} from 'react';

import {
} from '@ant-design/icons';

import {
  MCompBase,
  MCreative, MCreativeBreak, MCreativeLoop, MCreativeElementBase, MCreativeEndcard, MCreativeVideo, MNodeBase, MCreativeHover,
} from '../../../modeles';
import { loopIecVideosElems } from '../utils/iecLoop.utils';
import {
  findElemByFunc, findElem, getDisplayEndCardActions, getTranslationJson,
} from '../utils/iec.utils';
import { ScreenModeType } from '../components/iec';
import { FLAGS } from '../../../tools';

export type OrientationType = 'portrait' | 'landscape';

export interface IecContextType {
  iec: MCreative,
  warning: string | React.ReactNode,

  refreshIec: (save?: boolean) => void;
  historyBack: () => void;
  removeComponentNodeEdite: (comp: MCompBase) => void,
  refreshAllText: () => void,

  getVideoActive: () => MCreativeVideo | undefined,
  getBreakActive: () => MCreativeBreak | undefined,
  getLoopActive: () => MCreativeLoop | undefined,
  getVideoHoverActive: () => MCreativeHover | undefined,
  getEndcardActive: () => MCreativeEndcard | undefined,
  getHoverActive: () => MCreativeHover | undefined,
  getNodeEdite: () => MNodeBase | undefined,
  getScreenMode: () => ScreenModeType | undefined,
  getPhoneOrientation: () => OrientationType,
  getTourVisibility: () => boolean,
  getAllOpenKeys: () => string[],
  getEnabledLanguages: () => { [code: string]: string };

  setIec: (iec: MCreative | null) => void;
  setNodeEdite: (node: MNodeBase | undefined) => void,
  setScreenMode: (screenMode: ScreenModeType) => void,
  setTourVisibility: (visibility: boolean) => void,

}

const IecContext = React.createContext<IecContextType>({
  iec: new MCreative(),
  warning: null,

  refreshIec: (/* save: boolean */) => {},
  historyBack: () => {},
  removeComponentNodeEdite: () => {},
  refreshAllText: () => {},

  getVideoActive: () => { return undefined; },
  getBreakActive: () => { return undefined; },
  getLoopActive: () => { return undefined; },
  getVideoHoverActive: () => { return undefined; },
  getEndcardActive: () => { return undefined; },
  getHoverActive: () => { return undefined; },
  getNodeEdite: () => { return undefined; },
  getAllOpenKeys: () => [],
  getScreenMode: () => { return undefined; },
  getPhoneOrientation: () => { return 'portrait'; },
  getTourVisibility: () => { return false; },
  getEnabledLanguages: () => { return { ZZ: 'DEFAULT' }; },

  setNodeEdite: (/* node: MNodeBase */) => {},
  setIec: (/* iec: MCreative | null */) => {},
  setScreenMode: (/* orientation: ScreenModeType */) => {},
  setTourVisibility: () => {},
});

export function IecContextProvider({ children }: { children: React.ReactNode }) {
  const [iec, _setIec] = useState<MCreative>(new MCreative());
  const [warning, _setWarning] = useState<string | React.ReactNode>(null);
  const [iecLast, _setIecLast] = useState<MCreative | null>(null);
  const [histories, _setHistories] = useState<MCreative[]>([]);
  const [screenMode, _setScreenMode] = useState<ScreenModeType | undefined>(undefined);
  const [tourIsVisible, _setTourIsVisible] = useState<boolean>(true);

  const _duplicate = (i: MCreative) => {
    return JSON.parse(JSON.stringify(i)) as MCreative;
  };

  const setIec = useCallback((data: MCreative | null) => {
    const newData = data || new MCreative();
    newData.iecData.videos = newData.iecData.videos.map((video) => {
      return { ...JSON.parse(JSON.stringify(new MCreativeVideo())), ...JSON.parse(JSON.stringify(video)) } as MCreativeVideo;
    });
    _setIec(JSON.parse(JSON.stringify(newData)) as MCreative);
    _setIecLast(_duplicate(newData));
  }, []);

  const setWarning = useCallback(() => {
    if (!getDisplayEndCardActions(iec)[0]) {
      _setWarning('No button "display end card" set!');
    } else {
      _setWarning(false);
    }
  }, [iec]);

  const refreshIec = useCallback((save?: boolean) => {
    _setIec(_duplicate(iec));
    if (save && iecLast && JSON.stringify(iecLast) !== JSON.stringify(histories[histories.length - 1])) {
      _setHistories([...histories, iecLast]);
      _setIecLast(_duplicate(iec));
    }
    setWarning();
  }, [iec, _setIec, histories, _setHistories, iecLast, setWarning]);

  const historyBack = useCallback(() : void => {
    if (histories.length > 0) {
      const i = histories.pop()!;
      _setHistories(histories);
      _setIecLast(_duplicate(i));
      _setIec(i);
    }
  }, [histories]);

  const getNodeEdite = useCallback(() => {
    const elem = findElemByFunc(iec, (node) => !!node._edite)[0];
    return elem ? elem.node : undefined;
  }, [iec]);

  const setNodeEdite = useCallback((node: MNodeBase | undefined) => {
    loopIecVideosElems(iec, (params) => {
      params.node._edite = !!(node && params.node._key === node._key);
      if (params.node._edite) {
        params.node._open = true;
      }
    });

    refreshIec();
  }, [iec, refreshIec]);

  const getVideoActive = useCallback(() => {
    const nodeEdit = getNodeEdite();
    if (nodeEdit) {
      const { pathNode } = findElem(iec, (nodeEdit as MNodeBase)._key);
      if (Array.isArray(pathNode) && pathNode.length > 0) {
        if (pathNode[0]._type === 'video') {
          return pathNode[0] as MCreativeVideo;
        }
      }
    }

    return undefined;
  }, [iec, getNodeEdite]);

  const getBreakActive = useCallback(() => {
    const nodeEdit = getNodeEdite();
    if (nodeEdit) {
      const { pathNode } = findElem(iec, (nodeEdit as MNodeBase)._key);
      return pathNode.reverse().find((node) => node._type === 'break') as MCreativeBreak | undefined;
    }

    return undefined;
  }, [iec, getNodeEdite]);

  const getLoopActive = useCallback(() => {
    const nodeEdit = getNodeEdite();
    if (nodeEdit) {
      const { pathNode } = findElem(iec, (nodeEdit as MNodeBase)._key);
      return pathNode.reverse().find((node) => node._type === 'loop') as MCreativeLoop | undefined;
    }

    return undefined;
  }, [iec, getNodeEdite]);

  const getVideoHoverActive = useCallback(() => {
    const nodeEdit = getNodeEdite();
    if (nodeEdit) {
      const { pathNode } = findElem(iec, (nodeEdit as MNodeBase)._key);
      return pathNode.reverse().find((node) => node._type === 'hover') as MCreativeBreak | undefined;
    }

    return undefined;
  }, [iec, getNodeEdite]);

  const getEndcardActive = useCallback(() => {
    const nodeEdit = getNodeEdite();
    if (nodeEdit) {
      const { pathNode } = findElem(iec, (nodeEdit as MNodeBase)._key);
      if (Array.isArray(pathNode) && pathNode.length > 0) {
        if (pathNode[0]._type === 'endcard') {
          return pathNode[0] as MCreativeEndcard;
        }
      }
    }
    return undefined;
  }, [iec, getNodeEdite]);

  const getHoverActive = useCallback(() => {
    const nodeEdit = getNodeEdite();
    if (nodeEdit) {
      const { pathNode } = findElem(iec, (nodeEdit as MNodeBase)._key);
      if (Array.isArray(pathNode) && pathNode.length > 0) {
        if (pathNode[0]._type === 'hover') {
          return pathNode[0] as MCreativeHover;
        }
      }
    }
    return undefined;
  }, [iec, getNodeEdite]);

  const getAllOpenKeys = useCallback(() => {
    return findElemByFunc(iec, (node) => !!node._open).map((elem) => elem.node._key);
  }, [iec]);

  const removeComponentNodeEdite = useCallback((comp: MCompBase) => {
    const comps = (getNodeEdite() as MCreativeElementBase).components;
    const index = comps.findIndex((c) => c === comp);
    comps.splice(index, 1);
    refreshIec();
  }, [getNodeEdite, refreshIec]);

  const setScreenMode = useCallback((sm: ScreenModeType) => {
    _setScreenMode(sm);
  }, [_setScreenMode]);

  const getScreenMode = useCallback(() : ScreenModeType | undefined => {
    return screenMode;
  }, [screenMode]);

  const getPhoneOrientation = useCallback(() : OrientationType => {
    if (iec.iecData._phoneWidth > iec.iecData._phoneHeight) {
      return 'landscape';
    }
    return 'portrait';
  }, [iec]);

  const getTourVisibility = useCallback(() : boolean => {
    return tourIsVisible;
  }, [tourIsVisible]);

  const setTourVisibility = useCallback((visibility: boolean) => {
    _setTourIsVisible(visibility);
  }, [_setTourIsVisible]);

  const refreshAllText = useCallback(() => {
    const translations = getTranslationJson(iec);
    _setIec({ ...iec, translations });
  }, [iec]);

  const getEnabledLanguages = useCallback(():{ [code: string]: string } => {
    const enabled: string[] = [];
    iec.translations.forEach((translation) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      Object.keys(translation).forEach((key) => {
        enabled.push(key);
      });
    });
    const clean = [...new Set(enabled), 'ZZ'];
    const lang: { [code: string]: string } = {};
    Object.keys(FLAGS).forEach((flag) => {
      if (clean.includes(flag)) {
        lang[flag] = FLAGS[flag];
      }
    });
    return lang;
  }, [iec]);

  const value = useMemo(() => ({
    iec,
    warning,
    setIec,
    refreshIec,
    getVideoActive,
    getBreakActive,
    getLoopActive,
    getVideoHoverActive,
    getEndcardActive,
    getHoverActive,
    getNodeEdite,
    getEnabledLanguages,
    setNodeEdite,
    removeComponentNodeEdite,
    getAllOpenKeys,
    setScreenMode,
    getScreenMode,
    getPhoneOrientation,
    historyBack,
    getTourVisibility,
    setTourVisibility,
    refreshAllText,
  }), [
    iec,
    warning,
    setIec,
    refreshIec,
    getVideoActive,
    getBreakActive,
    getLoopActive,
    getVideoHoverActive,
    getEndcardActive,
    getHoverActive,
    getNodeEdite,
    getEnabledLanguages,
    setNodeEdite,
    removeComponentNodeEdite,
    getAllOpenKeys,
    setScreenMode,
    getScreenMode,
    getPhoneOrientation,
    historyBack,
    getTourVisibility,
    setTourVisibility,
    refreshAllText,
  ]);

  return (
    <IecContext.Provider value={value}>
      {children}
    </IecContext.Provider>
  );
}

export function useIec() {
  const context = React.useContext(IecContext);
  if (!context) throw new Error('No AuthsContext provider found!');
  return context;
}
