import { Link, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button, Input } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { MAccount, MApplication } from '../../modeles';
import { clientManagerApplicationsService } from '../../services';
import { IconApp } from '../icon-app';
import { accountColor, ColorTools } from '../../tools';

import './side-applications-managed.scss';

export function SideApplicationsManaged() {
  const [accounts, _setAccounts] = useState<MAccount[]>([]);
  const [accountsDisplayed, _setAccountsDisplayed] = useState<MAccount[]>([]);
  const [application, _setApplication] = useState<MApplication>(new MApplication());
  const [appId, _setAppId] = useState<number>();
  const [accountSelected, _setAccountSelected] = useState<number>(0);

  const uHistory = useHistory();

  useEffect(() => {
    clientManagerApplicationsService.sideListingManaged().then((acs) => {
      if (acs) {
        _setAccounts([...(acs || [])]);
        _setAccountsDisplayed(acs || []);
        _setApplication(accounts
          .flatMap((account) => account.Applications || [])
          .find((app) => app.id === getAppId()) || new MApplication());
      }
    });
    uHistory.listen((history) => {
      const id = parseInt(history.pathname.split('/')[2], 10);
      _setAppId(id);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const a = accounts
      .flatMap((account) => account.Applications || [])
      .find((app) => app.id === appId);
    if (a) {
      _setApplication(a);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId, accounts]);

  const onSearch = (value: string) => {
    _setAccountsDisplayed(
      accounts.map((acc) => ({
        ...acc,
        Applications: acc.Applications!.filter(
          (appF) => appF.name.toLowerCase().includes(value.toLowerCase())
            || appF.key.toUpperCase().includes(value.toUpperCase()),
        ),
      })),
    );
  };

  const getAppId = () => +window.location.pathname.split('/')[2] || null;

  const selectAccount = (id: number) => {
    if (accountSelected === id) {
      _setAccountSelected(0);
    } else {
      _setAccountSelected(id);
    }
  };

  return (
    <div id="side-applications-managed-component">
      {
        application.id !== 0
        && (
          <div className="main-app">
            <Link to={`/applications/${application.id}/settings`}>
              <Button className="edit" icon={<EditOutlined />} size="small" />
            </Link>
            <IconApp app={application} />
          </div>
        )
      }
      <Input className="search-bar" placeholder="Filter" onChange={(e) => onSearch(e.target.value as string)} />
      <div className="icon-list">
        {
          accountsDisplayed.map((account, index) => (
            <div
              className="account"
              style={{
                backgroundColor: `rgba(${accountColor[index + 1]}, 0.15)`,
                opacity: !!account.Applications && account.Applications!.length > 0 ? '1' : '0.3',
              }}
              key={index}
            >
              <div onClick={() => { selectAccount(account.id); }}>
                <label className="account-title" style={{ backgroundColor: `rgb(${accountColor[index + 1]})` }}>{account.name}</label>
              </div>
              <div className="app-list" style={{ display: accountSelected === account.id ? 'block' : 'none' }}>
                {
                  account.Applications?.map((app) => (
                    <IconApp app={app} selected={app.id === application.id} key={app.id} />
                  ))
                }
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
}
