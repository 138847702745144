import { BaseService } from './base.service';
import { MCreativeExport } from '../modeles';

class CreativeExportsService extends BaseService {
  PATH = 'creative-exports/';

  async listing(): Promise<MCreativeExport[] | null> {
    return this._listing(`${this.PATH}all`);
  }

  async exportsByIec(creativeId: number): Promise<MCreativeExport[] | null> {
    return this._listing(`${this.PATH}${creativeId}`);
  }

  async add(creativeExport: MCreativeExport): Promise<MCreativeExport | null> {
    return this._add(`${this.PATH}add`, creativeExport);
  }

  async uploadExport(exportFile: any): Promise<any | null> {
    return this.post(`${this.PATH}upload-export`, exportFile) as Promise<any | null>;
  }

  async uploadExportMoloco(exportFile: any): Promise<any | null> {
    return this.post(`${this.PATH}upload-export-moloco`, exportFile) as Promise<any | null>;
  }

  async remove(id: number): Promise<MCreativeExport | null> {
    return this._delete(`${this.PATH}delete/${id}`);
  }

  async downloadFile(id: number): Promise<string | null> {
    return this._view(`${this.PATH}download-file/${id}`);
  }

  async generatePlayable(id: number, networks: Array<string>): Promise<any | null> {
    return this.post(`${this.PATH}generate-playable`, { creativeId: id, networks }) as Promise<any | null>;
  }

  async buildEnded(creativeId: number, network: string): Promise<any | null> {
    return this.post(`${this.PATH}build-ended`, { creativeId, network }) as Promise<any | null>;
  }
}

export const creativeExportsService = new CreativeExportsService();
