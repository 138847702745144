import { MCompBlur } from '../../../../../modeles';

export function PreviewCompBlur(props : {
  blur: MCompBlur,
}) {
  return (
    <div
      style={{
        backdropFilter: `blur(${props.blur.blur}px)`,
        WebkitBackdropFilter: `blur(${props.blur.blur}px)`,
        height: '100%',
      }}
    />
  );
}
