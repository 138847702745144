import { Button, DatePicker, Space } from 'antd';
import { FilterConfirmProps, FilterDropdownProps } from 'antd/es/table/interface';
import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import './tab-date-filter.scss';

const { RangePicker } = DatePicker;

export function MpTabDateFilter(props: {
  setSelectedKeys: (selectedKeys: React.Key[]) => void,
  selectedKeys: React.Key[],
  confirm: (param?: FilterConfirmProps) => void,
  clearFilters?: () => void,

}) {
  const [searchRange, _setSearchRange] = useState<[Dayjs | null, Dayjs | null]>();

  useEffect(() => {
    if (props.selectedKeys[0]) {
      _setSearchRange((props.selectedKeys[0] as string).split('/').map((timestamp: string) => dayjs(+timestamp)) as [Dayjs | null, Dayjs | null]);
    }
  }, [props.selectedKeys]);

  const handleSearch = (
    confirm: FilterDropdownProps['confirm'],
  ) => {
    if (searchRange && !searchRange?.includes(null)) {
      props.setSelectedKeys([`${searchRange![0]!.valueOf()}/${searchRange![1]!.valueOf()}`] || []);
    } else {
      props.setSelectedKeys([]);
    }
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    _setSearchRange([null, null]);
  };

  return (
    <div
      className="mp-tab-date-filter"
      style={{
        padding: 8, display: 'flex', flexDirection: 'column', gap: 5,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <RangePicker
        value={searchRange}
        onChange={(e) => { _setSearchRange(e as [Dayjs | null, Dayjs | null]); }}
      />

      <Space>
        <Button
          type="link"
          onClick={() => props.clearFilters && handleReset(props.clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
        <Button
          type="primary"
          onClick={() => handleSearch(props.confirm)}
          size="small"
          style={{ width: 90 }}
        >
          OK
        </Button>
      </Space>
    </div>
  );
}
