import { useEffect, useState } from 'react';

import { usePlayable } from '../../../../context/playable.context';
import { YcCard } from '../../../../../../components';
import { ILightJson as JsonType } from '../../../../../../modeles/MP_Interfaces';
import {
  SettingCompColor, SettingCompInputCheckbox, SettingCompInputNumber,
  SettingCompSelect, SettingCompSlider,
} from '../../sub-settings';

import './setting-comp-light.scss';

const lightTypeOptions: { label: string, value: number }[] = [
  { label: 'Spot', value: 0 },
  { label: 'Directional', value: 1 },
  { label: 'Point', value: 2 },
];

const lightShadowsOptions: { label: string, value: number }[] = [
  { label: 'No Shadows', value: 0 },
  { label: 'Hard Shadows', value: 1 },
  { label: 'Soft Shadows', value: 2 },
];

const shadowResolutionOptions: { label: string, value: number }[] = [
  { label: 'Use Quality Settings', value: 0 },
  { label: 'Low Resolution', value: 1 },
  { label: 'Medium Resolution', value: 2 },
  { label: 'High Resolution', value: 3 },
  { label: 'Very High Resolution', value: 4 },
];

export function SettingCompLight(props: { initialData: any, parentID: string, icon?: string }) {
  const childType: string = props.initialData.type;
  const childID: string = props.initialData.instanceID;
  const parentID: string = props.parentID;

  const uPlayable = usePlayable();

  const [compTypeIndex, _setCompTypeIndex] = useState<number>();
  const [component, _setComponent] = useState<JsonType>();

  useEffect(() => {
    if (compTypeIndex === undefined) {
      _setCompTypeIndex(uPlayable.getCompTypeIndex(childType, parentID, childID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (props !== undefined && compTypeIndex !== undefined) {
      _setComponent(uPlayable.getRunTimeComponent(`${childType}[${compTypeIndex}]`) as JsonType);
    }
  }, [childType, compTypeIndex, props, uPlayable]);

  const editComponent = (propertyPath: string, value: any, update: boolean = true) => {
    if (compTypeIndex !== undefined) {
      uPlayable.updateGameObject(value, `${childType}[${compTypeIndex}]/${propertyPath}`, childID, update);
    }
  };

  return (
    <YcCard
      title="Light"
      icon={props.icon}
      light
      open
      warning={compTypeIndex === undefined}
      titleExtra={(
        <SettingCompInputCheckbox
          checked={component?.enabled ?? true}
          onChange={(e) => editComponent('enabled', e)}
        />
      )}
    >
      <div className="row">
        <div className="col-12">
          <SettingCompSelect
            label="Type"
            value={component?.lightType ?? 0}
            options={lightTypeOptions}
            onChange={(value) => editComponent('lightType', value)}
          />
          {
            component?.lightType !== 1 && (
              <SettingCompInputNumber
                label="Range"
                value={component?.range ?? 10}
                onChange={(value) => editComponent('range', value)}
              />
            )
          }
          {
            component?.lightType === 0 && (
              <SettingCompSlider
                label="Spot Angle"
                min={1}
                max={179}
                value={component?.spotAngle ?? 30}
                onChange={(e, update) => editComponent('spotAngle', e, update)}
              />
            )
          }
          <SettingCompColor
            defaultType="white"
            label="Color"
            color={component?.color}
            onChange={(color, updateJson) => editComponent('color', color, updateJson)}
          />
          <SettingCompInputNumber
            label="Intensity"
            value={component?.intensity ?? 1}
            onChange={(value) => editComponent('intensity', value)}
          />
          <SettingCompSelect
            label="Shadow Type"
            value={component?.shadows ?? 0}
            options={lightShadowsOptions}
            onChange={(value) => editComponent('shadows', value)}
          />
          {
            component?.lightType !== 0 && (
              <>
                <SettingCompSlider
                  disabled
                  label="Strength"
                  min={0}
                  max={1}
                  step={0.01}
                  value={component?.shadowStrength ?? 1}
                  onChange={(value, update) => editComponent('shadowStrength', value, update)}
                />
                <SettingCompSelect
                  disabled
                  label="Resolution"
                  value={component?.shadowResolution ?? 0}
                  options={shadowResolutionOptions}
                  onChange={(value) => editComponent('shadowResolution', value)}
                />
                <SettingCompSlider
                  disabled
                  label="Normal Bias"
                  min={0}
                  max={3}
                  step={0.01}
                  value={component?.shadowNormalBias ?? 0.4}
                  onChange={(value, update) => editComponent('shadowNormalBias', value, update)}
                />
                <SettingCompSlider
                  disabled
                  label="Near Plane"
                  min={0.1}
                  max={10}
                  step={0.01}
                  value={component?.shadowNearPlane ?? 0.2}
                  onChange={(value, update) => editComponent('shadowNearPlane', value, update)}
                />
              </>
            )
          }
        </div>
      </div>
    </YcCard>
  );
}
