import { BaseService } from './base.service';
import { IPermission, MUser } from '../modeles';

class UsersService extends BaseService {
  PATH = 'users/';

  async listing(): Promise<MUser[] | null> {
    return this._listing(`${this.PATH}listing`);
  }

  async add(user: MUser): Promise<MUser | null> {
    return this._add(`${this.PATH}add`, user);
  }

  async view(id: number): Promise<MUser | null> {
    return this._view(`${this.PATH}view/${id}`);
  }

  async edit(id: number, app: MUser): Promise<MUser | null> {
    return this._edit(`${this.PATH}edit/${id}`, app);
  }

  async isAdmin(id: number, isAdmin: boolean): Promise<MUser[]> {
    return this._listing(`${this.PATH}is-admin/${id}/${isAdmin}`);
  }

  async isActive(id: number, isActive: boolean): Promise<MUser[]> {
    return await this._listing(`${this.PATH}is-active/${id}/${isActive}`) || [];
  }

  async sendNewPassword(id: number): Promise<MUser[] | null> {
    return await this._listing(`${this.PATH}send-new-password/${id}`) || [];
  }

  async updateTours(tours: any) {
    return await this._edit(`${this.PATH}update-tours`, { tours }) || [];
  }

  // OTHER SERVICES //

  async defaultPermission(): Promise<IPermission | null> {
    return this._view(`${this.PATH}default-permissions`);
  }
}

export const usersService = new UsersService();
