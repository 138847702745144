import { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import * as TransformControls from 'three/examples/jsm/controls/TransformControls';

import { usePlayable } from '../../../../../context/playable.context';

import './debug-camera.scss';

export function DebugCamera(props: {
  currentScene: THREE.Scene | null,
  PLAYABLE_THREE: typeof THREE
}) {
  const uPlayable = usePlayable();
  const previewContainer = useRef<HTMLDivElement>(null);

  const [currentCameraHelper, _setCurrentCameraHelper] = useState<THREE.CameraHelper | null>();

  useEffect(() => {
    return () => {
      if (currentCameraHelper) {
        props.currentScene?.remove(currentCameraHelper);
        currentCameraHelper.dispose();
        _setCurrentCameraHelper(null);
      }
    };
  }, [currentCameraHelper, props.currentScene]);

  useEffect(() => {
    if (previewContainer.current?.firstChild) {
      previewContainer.current!.removeChild(previewContainer.current.firstChild);
    }

    if (props.currentScene) {
      if (uPlayable.nodeEdit?.components.find((c) => c.type === 'Camera') && props.currentScene) {
        const camera = uPlayable.getRuntimeGameObject(uPlayable.nodeEdit!.instanceID).mp_allComponents[1]?.mp_camera;

        if (camera) {
          const previewRenderer = new props.PLAYABLE_THREE.WebGLRenderer();
          previewRenderer.setSize(150, 150);
          previewRenderer.setClearColor(0x707070, 1);
          previewRenderer.shadowMap.enabled = true;
          previewRenderer.shadowMap.type = THREE.PCFSoftShadowMap;
          previewRenderer.toneMapping = THREE.NoToneMapping;
          previewContainer.current?.appendChild(previewRenderer.domElement);

          if (!currentCameraHelper) {
            const cameraPerspectiveHelper = new props.PLAYABLE_THREE.CameraHelper(camera as THREE.Camera);
            props.currentScene.add(cameraPerspectiveHelper);
            _setCurrentCameraHelper(cameraPerspectiveHelper);
          } else {
            currentCameraHelper.attach(camera as THREE.PerspectiveCamera);
          }

          const animate = () => {
            requestAnimationFrame(animate);
            if (props.currentScene && camera) {
              previewRenderer.render(props.currentScene, camera as THREE.Camera);
            }
          };

          animate();

          return () => {
            previewRenderer.dispose();
          };
        }
      }
    }
    return () => {

    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentScene, uPlayable.nodeEdit]);

  return (
    <div className="debug-camera" ref={previewContainer} />
  );
}
