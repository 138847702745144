import { BaseService } from '../base.service';
import { MCreative } from '../../modeles';

class AdminCreativesService extends BaseService {
  PATH = 'admin/creatives/';

  async listing(): Promise<MCreative[] | null> {
    return this._listing(`${this.PATH}listing`);
  }

  async listingByApplication(applicationId: number): Promise<MCreative[] | null> {
    return this._listing(`${this.PATH}listing-by-application/${applicationId}`);
  }
}

export const adminCreativesService = new AdminCreativesService();
