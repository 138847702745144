import qs from 'qs';
import dayjs from 'dayjs';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Form, Spin } from 'antd';
import { useEffect, useState } from 'react';

import { YcFieldCheckPage, FCItem, YcDatePicker } from '../../../../components';
import { useAuths } from '../../../../contexts/auths.context';

import './reporting-filter.component.scss';

interface Filters {
  platforms: FCItem[];
  applications: FCItem[];
  creatives: FCItem[];
  users: FCItem[];
  creativeTypes: FCItem[];
  countries: FCItem[];
  networks: FCItem[];
  granularities: FCItem[];

  isGranularity: boolean;
  isPlatform: boolean;
  isApplication: boolean;
  isCreative: boolean;
  isUser: boolean;
  isCreativeType: boolean;
  isCountry: boolean;
  isNetwork: boolean;
}

export function ReportingFilter(props: {
  onSearch: (data: any) => void;
  service: any,
  specificToAdd?: any,
  specificToAddSetter?: any,
}) {
  const uLocation = useLocation();
  const uHistory = useHistory();
  const uAuths = useAuths();

  const [filters, _setFilters] = useState<Filters | null>(null);
  const [applicationKeys, _setApplicationKeys] = useState<string[]>([]);
  const [dates, _setDates] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([dayjs(), dayjs()]);
  const [loading, _setLoading] = useState<boolean>(false);

  const SetParamsFilter = (items: FCItem[], paramsString: string) => {
    if (paramsString) {
      const params = paramsString.split(',');
      items.forEach((item) => {
        if (params.includes(`${item.value}-Y`)) {
          item.checked = true;
        } else if (params.includes(`${item.value}-N`)) {
          item.checked = false;
        }
      });
    }
  };

  useEffect(() => {
    _setLoading(true);
    const search = new URLSearchParams(uLocation.search);
    if (search.has('dateStart')) {
      dates[0] = dayjs(search.get('dateStart'));
    }
    if (search.has('dateEnd')) {
      dates[1] = dayjs(search.get('dateEnd'));
    }
    props.service.filters().then((data: Filters) => {
      _setLoading(false);
      if (data) {
        SetParamsFilter(data.granularities, search.get('granularities')!);
        SetParamsFilter(data.applications, search.get('applications')!);
        SetParamsFilter(data.creatives, search.get('creatives')!);
        SetParamsFilter(data.users, search.get('users')!);
        SetParamsFilter(data.countries, search.get('countries')!);
        SetParamsFilter(data.networks, search.get('networks')!);
        SetParamsFilter(data.platforms, search.get('platforms')!);
        SetParamsFilter(data.creativeTypes, search.get('creativeTypes')!);
        if (search.has('is')) {
          const is = search.get('is')!.split(',');
          data.isGranularity = is.includes('granularity');
          data.isApplication = is.includes('applicationId');
          data.isCreative = is.includes('creativeId');
          data.isUser = is.includes('userId');
          data.isCountry = is.includes('country');
          data.isNetwork = is.includes('network');
          data.isPlatform = is.includes('platform');
          data.isCreativeType = is.includes('creativeType');
        }
        _setFilters(data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!props?.specificToAdd || !props.specificToAddSetter || !filters) { return; }

    const { category, fieldToCompare, compareWith } = props.specificToAdd;
    // @ts-ignore
    const updatedFilters = filters?.[category]?.map((filter: any) => {
      if (filter?.[fieldToCompare] === props.specificToAdd?.[compareWith]) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return { ...filter, checked: true };
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return filter;
    });
    props.specificToAddSetter(undefined);
    // @ts-ignore
    _setFilters((old) => ({ ...old, [category]: updatedFilters }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const getParamsFilter = (items: FCItem[]) => {
    const results = items.filter((item) => item.checked !== undefined);
    return results.map((item) => `${item.value}-${item.checked ? 'Y' : 'N'}`).join() || undefined;
  };
  const getParamsIs = () => {
    const is: string[] = [];
    if (filters?.isGranularity) { is.push('granularity'); }
    if (filters?.isApplication) { is.push('applicationId'); }
    if (filters?.isCreative) { is.push('creativeId'); }
    if (filters?.isUser) { is.push('userId'); }
    if (filters?.isCreativeType) { is.push('creativeType'); }
    if (filters?.isCountry) { is.push('country'); }
    if (filters?.isNetwork) { is.push('network'); }
    if (filters?.isPlatform) { is.push('platform'); }
    if (is.length) {
      return is.join(',');
    }
    return undefined;
  };

  const getParams = (stringify: boolean) => {
    const b = {
      dateStart: dayjs(dates[0]).format('YYYY-MM-DD'),
      dateEnd: dayjs(dates[1]).format('YYYY-MM-DD'),
      granularity: filters?.isGranularity ? filters.granularities.find((g) => g.checked === true)?.value : undefined,
      applications: getParamsFilter(filters!.applications),
      creatives: getParamsFilter(filters!.creatives),
      users: getParamsFilter(filters!.users),
      creativeTypes: getParamsFilter(filters!.creativeTypes),
      countries: getParamsFilter(filters!.countries),
      networks: getParamsFilter(filters!.networks),
      platforms: getParamsFilter(filters!.platforms),
      is: getParamsIs(),
    };
    if (stringify) {
      return qs.stringify(b, { encode: false });
    }
    return b;
  };

  const setApplicationKeys = () => {
    const keys = filters?.applications.filter((app) => app.checked).map((app) => {
      return app.value as string;
    });
    _setApplicationKeys(keys || []);
  };

  return (
    <div id="reporting-filter">
      <Spin spinning={loading}>
        {!!filters
          && (
            <Form>
              <Form.Item>
                <YcDatePicker
                  value={dates}
                  onChange={(e) => {
                    if (e && e.length >= 2) {
                      _setDates([e[0]!, e[1]!]);
                    }
                  }}
                />
              </Form.Item>
              <Form.Item>
                <YcFieldCheckPage
                  is={filters.isGranularity}
                  label="Granularities"
                  items={filters.granularities}
                  onChange={(granularities, isGranularity): void => {
                    _setFilters({ ...filters, granularities, isGranularity });
                  }}
                />
              </Form.Item>
              <Form.Item>
                <YcFieldCheckPage
                  is={filters.isPlatform}
                  label="Platforms"
                  items={filters.platforms}
                  mode="multiple"
                  onChange={(platforms, isPlatform): void => {
                    _setFilters({ ...filters, platforms, isPlatform });
                  }}
                />
              </Form.Item>
              <Form.Item>
                <YcFieldCheckPage
                  is={filters.isApplication}
                  label="Applications"
                  items={filters.applications}
                  mode="multiple"
                  onChange={(applications, isApplication): void => {
                    _setFilters({ ...filters, applications, isApplication });
                    setApplicationKeys();
                  }}
                />
              </Form.Item>
              <Form.Item>
                <YcFieldCheckPage
                  is={filters.isCreative}
                  label="Creatives"
                  items={
                    applicationKeys.length > 0
                      ? filters.creatives.filter((creative) => applicationKeys.includes(creative.appKey || ''))
                      : filters.creatives
                  }
                  mode="multiple"
                  onChange={(creatives, isCreative): void => {
                    _setFilters({ ...filters, creatives, isCreative });
                  }}
                />
              </Form.Item>
              <Form.Item>
                <YcFieldCheckPage
                  is={filters.isUser}
                  label="Users"
                  items={filters.users}
                  mode="multiple"
                  onChange={(users, isUser): void => {
                    _setFilters({ ...filters, users, isUser });
                  }}
                />
              </Form.Item>
              <Form.Item>
                <YcFieldCheckPage
                  is={filters.isNetwork}
                  label="Networks"
                  items={filters.networks}
                  mode="multiple"
                  onChange={(networks, isNetwork): void => {
                    _setFilters({ ...filters, networks, isNetwork });
                  }}
                />
              </Form.Item>
              <Form.Item>
                <YcFieldCheckPage
                  is={filters.isCountry}
                  label="Countries"
                  items={filters.countries}
                  mode="multiple"
                  onChange={(countries, isCountry): void => {
                    _setFilters({ ...filters, countries, isCountry });
                  }}
                />
              </Form.Item>
              {(uAuths.canAccess('applications/playable') && uAuths.canAccess('applications/iec'))
                && (
                  <Form.Item>
                    <YcFieldCheckPage
                      is={filters.isCreativeType}
                      label="Creative Types"
                      items={filters.creativeTypes}
                      mode="multiple"
                      onChange={(creativeTypes, isCreativeType): void => {
                        _setFilters({ ...filters, creativeTypes, isCreativeType });
                      }}
                    />
                  </Form.Item>
                )}
              <Form.Item>
                <Button
                  type="primary"
                  onClick={() => {
                    uHistory.push({
                      search: getParams(true) as string,
                    });
                    props.onSearch(getParams(false));
                  }}
                >
                  Search
                </Button>
              </Form.Item>
            </Form>
          )}
      </Spin>
    </div>
  );
}
