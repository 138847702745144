/* eslint-disable max-len */
import {
  ReactNode, useEffect, useRef, useState,
} from 'react';
import {
  Button, Divider, Input, Modal, Popconfirm, Popover, Tooltip,
} from 'antd';
import {
  DeleteOutlined as DeleteIcon,
  PlusOutlined as AddIcon,
  CopyOutlined as DuplicateIcon,
  SaveOutlined,
  EllipsisOutlined,
  LockOutlined,
  UnlockOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faFileImport,
} from '@fortawesome/pro-regular-svg-icons';

import { useIec } from '../../../context/iec.context';
import { NumberTools } from '../../../../../tools';
import './elem-list.scss';
import {
  MCreativeBreak, MCreativeLoop, MCreativeElementBase, MCreativeVideo, MNodeBase, MCreativeIecData, MCreativeHover, MCustomTemplate, MCreativeEndcard, MCompButton,
} from '../../../../../modeles';
import {
  getEditorElem, CAN_INCLUDES, IecElementEditor, duplicateNode, findElem, saveCustomTemplate, removeCustomTemplate, getTypeElement,
} from '../../../utils/iec.utils';
import { customTemplateService } from '../../../../../services';
import { TemplatesModal } from '../templates-modal';

export function ElemTitle({ node }: { node: MNodeBase }) {
  const uIec = useIec();
  const disabledAddButon = node._type === 'video' && !(node as MCreativeVideo).video.url;

  const [isEditingDisplayName, _setIsEditingDisplayName] = useState<boolean>(false);
  const [newDisplayName, _setNewDisplayName] = useState<string>('');
  const displayNameInputRef = useRef(null);

  const [isModalNewTemplateOpen, _setIsModalNewTemplateOpen] = useState<boolean>(false);
  const [newTemplateName, _setNewTemplateName] = useState<string>('');
  const [iecNodesSaved, _setIecNodesSaved] = useState<MCustomTemplate[]>([]);
  const [selectedTemplate, _setSelectedTemplate] = useState<MCustomTemplate | null>(null);

  // useEffect(() => {
  //   refreshCustomIecNodes();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (displayNameInputRef.current) {
      // @ts-ignore
      displayNameInputRef.current.focus();
    }
  }, [displayNameInputRef, isEditingDisplayName]);

  // const refreshCustomIecNodes = () => {
  //   customTemplateService.listing().then((data) => {
  //     _setIecNodesSaved(data.filter(
  //       (template) => CAN_INCLUDES(node).find((type: string) => type === template.type),
  //     ));
  //   });
  // };

  const getCustomTemplates = (replace: boolean = false) => {
    customTemplateService.listing().then((data) => {
      _setIecNodesSaved(data.filter(
        (template) => CAN_INCLUDES(node, replace).find((type: string) => type === template.type),
      ));
    });
  };

  const stopPropagation = (e: any) => {
    e.stopPropagation();
  };

  const onDelete = (e: any) => {
    e.stopPropagation();
    const nodeSelect = uIec.getNodeEdite();
    const elemToRemove = findElem(uIec.iec, node._key);
    elemToRemove.parentList.splice(elemToRemove.index, 1);
    if (nodeSelect && nodeSelect._type === 'hover' && nodeSelect._key === elemToRemove.node._key) {
      delete (elemToRemove.parentNode as MCreativeVideo).hover;
    }
    if (nodeSelect && nodeSelect._key === elemToRemove.node._key) {
      const newElemSelected = elemToRemove.parentList[Math.max(elemToRemove.index - 1, 0)] || elemToRemove.parentNode;
      uIec.setNodeEdite(newElemSelected);
    }
    uIec.refreshIec(true);
  };

  const onDuplicate = (e: any) => {
    e.stopPropagation();
    const copy = duplicateNode(node);
    const elemWhereToPut = findElem(uIec.iec, node._key);
    if (node._type === 'video') {
      (elemWhereToPut.parentNode as any as MCreativeIecData).videos.push(copy as MCreativeVideo);
    } else if (node._type === 'break') {
      (elemWhereToPut.parentNode as MCreativeVideo).breaks.push(copy as MCreativeBreak);
    } else if (node._type === 'loop') {
      (elemWhereToPut.parentNode as MCreativeVideo).loops.push(copy as MCreativeLoop);
    } else if (node._type === 'element') {
      (elemWhereToPut.parentNode as MCreativeElementBase).elements.push(copy as MCreativeElementBase);
    }
    uIec.refreshIec(true);
  };

  const addCustomTemplate = (e: any, customNode: MNodeBase) => {
    e.stopPropagation();
    const copy = duplicateNode(customNode);
    if (node._type === 'video') {
      if (copy._type === 'break') {
        (node as MCreativeVideo).breaks.push(copy as MCreativeBreak);
      } else if (copy._type === 'loop') {
        (node as MCreativeVideo).loops.push(copy as MCreativeLoop);
      } else if (copy._type === 'hover') {
        (node as MCreativeVideo).hover = (copy as MCreativeHover);
      }
    } else if (node._type === 'break' || node._type === 'element') {
      (node as MCreativeBreak).elements.push(copy as MCreativeElementBase);
    } else if (node._type === 'loop') {
      (node as MCreativeLoop).elements.push(copy as MCreativeElementBase);
    } else if (node._type === 'hover') {
      (node as MCreativeHover).elements.push(copy as MCreativeElementBase);
    } else if (node._type === 'endcard') {
      (node as MCreativeEndcard).elements.push(copy as MCreativeElementBase);
    }
    uIec.refreshIec(true);
  };

  const replaceByCustomElement = (e: any, customNode: MNodeBase) => {
    e.stopPropagation();
    const copy = duplicateNode(customNode);
    if (node._type === 'endcard') {
      (node as MCreativeEndcard).elements = [];
      (node as MCreativeEndcard).elements.push(...(copy as MCreativeBreak).elements);
      (node as MCreativeEndcard).background = (copy as MCreativeBreak).background;
    }
    if (node._type === 'hover') {
      (node as MCreativeHover).elements = [];
      (node as MCreativeHover).elements.push(...(copy as MCreativeHover).elements);
      (node as MCreativeHover).background = (copy as MCreativeHover).background;
    }
    uIec.refreshIec(true);
  };

  const onSaveNode = async (e: any) => {
    e.stopPropagation();
    onCloseModalNewTemplate();
    if (node._type === 'break'
    || node._type === 'loop'
    || node._type === 'hover'
    || node._type === 'element'
    || node._type === 'endcard') {
      await saveCustomTemplate(node, newTemplateName, getTypeElement(node));
    }
    uIec.refreshIec(true);
    _setNewTemplateName('');
  };

  const onRemoveCustomTemplate = async (templateId: number) => {
    await removeCustomTemplate(templateId);
    _setIecNodesSaved(iecNodesSaved.filter((template) => template.id !== templateId));
  };

  const onCloseModalNewTemplate = () => {
    _setIsModalNewTemplateOpen(false);
    _setNewTemplateName('');
  };

  const addItem = (newElem: MNodeBase | MCreativeElementBase) => (e: any) => {
    e.stopPropagation();
    if (node._type === 'video') {
      if (newElem._type === 'hover') {
        (node as MCreativeVideo).hover = newElem! as MCreativeHover;
      } else if (newElem._type === 'break') {
        (node as MCreativeVideo).breaks.push(newElem! as MCreativeBreak);
      } else if (newElem._type === 'loop') {
        (node as MCreativeVideo).loops.push(newElem! as MCreativeLoop);
      }
    } else if (node._type === 'break') {
      (node as MCreativeBreak).elements.push(newElem! as MCreativeElementBase);
    } else if (node._type === 'loop') {
      (node as MCreativeLoop).elements.push(newElem! as MCreativeElementBase);
    } else if (node._type === 'hover') {
      (node as MCreativeHover).elements.push(newElem! as MCreativeElementBase);
    } else if (node._type === 'endcard') {
      const buttonComponent = (newElem as MCreativeElementBase).components.find(
        (component) => component._type === 'button',
      ) as MCompButton | null;
      if (buttonComponent) {
        buttonComponent.action = 'app-download';
      }
      (node as MCreativeElementBase).elements.push(newElem! as MCreativeElementBase);
    } else {
      (node as MCreativeElementBase).elements.push(newElem! as MCreativeElementBase);
    }
    uIec.setNodeEdite(newElem!);
    uIec.refreshIec(true);
  };

  const buttonToAdd = CAN_INCLUDES(node).map((stringType) => {
    const elemEditor = IecElementEditor[stringType];
    let enable = true;
    if (node._type === 'video' && elemEditor.type === 'hover' && (node as MCreativeVideo).hover) {
      enable = false;
    }
    return (
      <div className="add-item" key={elemEditor.type}>
        <Button type="text" onClick={enable ? addItem(elemEditor.create()) : () => {}} size="small" disabled={!enable}>
          <span className="add-item-icon">{elemEditor.icon()}</span> {elemEditor.name}
        </Button>
      </div>
    );
  });

  const addPopoverContent = () => {
    return (
      <>
        <Divider plain style={{ marginTop: '0' }}>Classic</Divider>
        <div className="iec-elem-list-popover">
          {buttonToAdd.map((e: ReactNode) => e)}
        </div>
        <Divider plain />
        <Button
          icon={<FontAwesomeIcon icon={faPlus} title="add" />}
          type="primary"
          onClick={() => { getCustomTemplates(); }}
        >Add customs
        </Button>
      </>
    );
  };

  const popoverMoreActions = () => {
    return (
      <div className="more-node-options">
        {(!['endcard', 'hover'].includes(node._type))
          && (
            <Button className="node-option" type="text" onClick={onDuplicate} icon={<DuplicateIcon />} size="small">
              Duplicate
            </Button>
          )}
        {(!['video'].includes(node._type))
          && (
            <Button className="node-option" type="text" onClick={() => _setIsModalNewTemplateOpen(true)} icon={<SaveOutlined />} size="small">
              Save Template
            </Button>
          )}
        {(['endcard', 'hover'].includes(node._type))
          && (
          <Button
            icon={<FontAwesomeIcon icon={faFileImport} title="add" />}
            className="node-option"
            type="text"
            shape="circle"
            size="small"
            onClick={() => { getCustomTemplates(true); }}
          >
            Load & Replace
          </Button>
          )}
      </div>
    );
  };

  const editBooleanCascade = (value: boolean, property: '_isHidden' | '_isLocked') => {
    node[property] = value;
    if (node._type === 'element') {
      (node as MCreativeElementBase).elements.forEach((element) => {
        booleanElement(element, value, property);
      });
    }
    if (node._type === 'video') {
      (node as MCreativeVideo).breaks.forEach((element) => {
        booleanElement(element, value, property);
      });
      (node as MCreativeVideo).loops.forEach((element) => {
        booleanElement(element, value, property);
      });
    }
    if (node._type === 'loop') {
      (node as MCreativeLoop).elements.forEach((element) => {
        booleanElement(element, value, property);
      });
    }
    if (node._type === 'break') {
      (node as MCreativeBreak).elements.forEach((element) => {
        booleanElement(element, value, property);
      });
    }
    if (node._type === 'hover') {
      (node as MCreativeHover).elements.forEach((element) => {
        booleanElement(element, value, property);
      });
    }
    uIec.refreshIec(true);
  };

  const booleanElement = (child: MNodeBase, value: boolean, property: '_isHidden' | '_isLocked') => {
    child[property] = value;
    if (child._type === 'element') {
      (child as MCreativeElementBase).elements.forEach((element) => {
        booleanElement(element, value, property);
      });
    }
    if (child._type === 'break') {
      (child as MCreativeBreak).elements.forEach((element) => {
        booleanElement(element, value, property);
      });
    }
    if (child._type === 'break') {
      (child as MCreativeHover).elements.forEach((element) => {
        booleanElement(element, value, property);
      });
    }
    if (node._type === 'loop') {
      (node as MCreativeLoop).elements.forEach((element) => {
        booleanElement(element, value, property);
      });
    }
  };

  const displayNameHandleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      _setIsEditingDisplayName(false);
      if (newDisplayName) {
        node._displayName = newDisplayName;
        uIec.refreshIec(true);
      }
    } else if (event.key === 'Escape' || event.keyCode === 27) {
      event.preventDefault();
      _setIsEditingDisplayName(false);
    }
  };

  const elemEditor = getEditorElem(node);

  let title: string[];

  if (node._displayName) {
    title = [node._displayName];
  } else {
    title = [elemEditor.name];
  }

  if (node._type === 'break') {
    title.push(` - ${(node as MCreativeBreak).time}s`);
  }
  if (node._type === 'loop') {
    title.push(` - ${(node as MCreativeLoop).timeStart}s -> ${(node as MCreativeLoop).timeEnd}s`);
  }
  if (node._type === 'video') {
    const duration = NumberTools.round((node as MCreativeVideo).videoDuration, 2);
    const index = uIec.iec.iecData.videos.findIndex((v) => v === node) + 1;
    title.push(`[${index}] - ${duration}s ${(node as MCreativeVideo).videoName ? `- ${(node as MCreativeVideo).videoName}` : ''}`);
  }

  return (
    <div className={`line ${node._edite ? 'selected' : ''}`}>
      <div
        className="title"
        onDoubleClick={() => {
          _setIsEditingDisplayName(true);
        }}
      >
        {elemEditor.icon()}
        {
          isEditingDisplayName
            ? (
              <Input
                ref={displayNameInputRef}
                placeholder={title.toString()}
                onKeyDown={displayNameHandleKeyDown}
                onBlur={() => { _setIsEditingDisplayName(false); }}
                value={newDisplayName}
                onChange={(e) => { _setNewDisplayName(e.target.value as string); }}
              />
            )
            : (title)
        }
      </div>
      <div className="actions">
        {!disabledAddButon
        && (
        <span>
          <Popover
            content={addPopoverContent}
            trigger="click"
            placement="bottomRight"
          >
            <Button type="text" shape="circle" onClick={stopPropagation} icon={<AddIcon />} size="small" />
          </Popover>
        </span>
        )}
        {(node._isHidden)
          ? (
            <span>
              <Tooltip title="Hidden calc">
                <Button type="text" danger shape="circle" onClick={() => editBooleanCascade(false, '_isHidden')} icon={<EyeInvisibleOutlined />} size="small" />
              </Tooltip>
            </span>
          )
          : (
            <span>
              <Tooltip title="Showed calc">
                <Button type="text" shape="circle" onClick={() => editBooleanCascade(true, '_isHidden')} icon={<EyeOutlined />} size="small" />
              </Tooltip>
            </span>
          )}
        {(node._isLocked)
          ? (
            <span>
              <Tooltip title="Unlock calc">
                <Button type="text" danger shape="circle" onClick={() => editBooleanCascade(false, '_isLocked')} icon={<LockOutlined />} size="small" />
              </Tooltip>
            </span>
          )
          : (
            <span>
              <Tooltip title="Lock calc">
                <Button type="text" shape="circle" onClick={() => editBooleanCascade(true, '_isLocked')} icon={<UnlockOutlined />} size="small" />
              </Tooltip>
            </span>
          )}
        {(!['endcard'].includes(node._type))
            && (
            <span>
              <Popconfirm title="Sure to delete?" onConfirm={onDelete} onCancel={stopPropagation}>
                <Button type="text" shape="circle" icon={<DeleteIcon />} size="small" danger />
              </Popconfirm>
            </span>
            )}
        <span>
          <Popover
            content={popoverMoreActions}
            trigger="click"
            placement="bottomRight"
          >
            <Button type="text" shape="circle" onClick={stopPropagation} icon={<EllipsisOutlined />} size="small" />
          </Popover>
        </span>
      </div>
      <Modal title="Named your template" open={isModalNewTemplateOpen} onOk={(e) => { onSaveNode(e); }} onCancel={onCloseModalNewTemplate}>
        <Input placeholder="Template name" onChange={(e) => _setNewTemplateName(e.target.value as string)} />
      </Modal>
      <Modal
        title="Templates"
        width={930}
        open={iecNodesSaved.length > 0}
        onCancel={() => { _setIecNodesSaved([]); }}
        onOk={(e) => {
          if (selectedTemplate) {
            if (node._type === 'endcard' && selectedTemplate.type === 'endcard') {
              replaceByCustomElement(e, selectedTemplate.node);
            } else {
              addCustomTemplate(e, selectedTemplate.node);
            }
            _setIecNodesSaved([]);
          }
        }}
      >
        <TemplatesModal
          templates={iecNodesSaved}
          selectedTemplate={(template) => { _setSelectedTemplate(template); }}
          removeTemplate={(template) => { onRemoveCustomTemplate(template.id); }}
        />
      </Modal>
    </div>
  );
}
