import { Link } from 'react-router-dom';
import {
  CardCreation, CardTry, Footer, ICardCreation, ICardTry,
} from '../../../../components';
import * as HomeImages from '../../../../assets/images/home';

import './playable-presentation.page.scss';

export function PlayablePresentation() {
  const cards: ICardCreation[] = [
    {
      title: 'Develop the playable ads with Unity',
      description: 'Isolate any part of your game as you see fit for export to our platform.',
      icon: HomeImages.IcoDev,
      image: HomeImages.ImgDev,
      bgColor: '#31D7FF',
    },

    {
      title: 'Integrate our Unity SDK',
      description: 'Our SDK will allow you to configure what you want to export from your Unity project',
      icon: HomeImages.IcoSdk,
      image: HomeImages.ImgSdk,
      bgColor: '#CBC1FF',
    },

    {
      title: 'Export your playable and customize it',
      description: 'Edit your Playable directly on Monster Playable. Benefit from a range of diverse assets and styles.',
      icon: HomeImages.IcoCustom,
      image: HomeImages.ImgPlbcustom,
      bgColor: '#FFCE80',
    },

    {
      title: 'Export it to all famous networks: Meta, Applovin, Mintegral…',
      description: 'Export your Playable to a multitude of platforms!',
      icon: HomeImages.IcoExport,
      image: HomeImages.ImgExport,
      bgColor: '#e4a3ff',
    },

    {
      title: 'Check the performances',
      description: 'Check the results / performances Iterate it as much as you want.',
      icon: HomeImages.IcoPerf,
      image: HomeImages.ImgPerf,
      bgColor: '#02adac',
    },
  ];

  const cardstry: ICardTry[] = [
    {
      title: 'Beaver builder',
      description: 'Help the beaver !',
      icon: 'https://yso-playable-prod-front.s3.eu-west-3.amazonaws.com/home_assets/pla_game_1_icon.png',
      url: 'https://yso-playable-prod-front.s3.eu-west-3.amazonaws.com/home_assets/game_1_pla.html',
    },

    {
      title: 'Riot Z',
      description: 'Devour people !',
      icon: 'https://yso-playable-prod-front.s3.eu-west-3.amazonaws.com/home_assets/pla_game_2_icon.png',
      url: 'https://yso-playable-prod-front.s3.eu-west-3.amazonaws.com/home_assets/game_2_pla.html',
    },

    {
      title: 'Basket Attack',
      description: 'Attack to score the basket',
      icon: 'https://yso-playable-prod-front.s3.eu-west-3.amazonaws.com/home_assets/pla_game_3_icon.png',
      url: 'https://yso-playable-prod-front.s3.eu-west-3.amazonaws.com/home_assets/game_3_pla.html',
    },

  ];

  return (

    <div id="root-home">
      <div id="playable-presentation">

        <div className="iec-header-contain">

          <div className="iec-header">
            <div className="content row">
              <div className="col-12 col-md-6 align-self-center">

                <img className="icon" src={HomeImages.IcoGame} alt="iec icon" />
                <h1 className="title">Playable Ads</h1>
                <p className="sub-title mb-5">Real playable with Unity</p>

                <div className="content-header">
                  <h2 className="market-title">A fully customizable playable Ads</h2>
                  <p><Link className="button-a" to="/login">Request a demo</Link></p>
                  <p><a className="button-b" href="#try">Try Playables</a></p>
                </div>

              </div>
              <div className="col-12 col-sm-6 align-self-center">
                <img className="iec-img" src={HomeImages.PlbVisual} alt="iec img" />
              </div>
            </div>
          </div>
        </div>

        <div className="iec-contain">

          <img className="fd-rond" src={HomeImages.FdRond} alt="Fd Rond" />
          <img className="fd-rond-pink" src={HomeImages.FdRondPink} alt="Fd Rond" />
          <img className="fd-rond-pink-b" src={HomeImages.FdRondPink} alt="Fd Rond" />

          <div className="create-playable">
            <div className="content row">
              <div className="col-12 align-self-center">
                <h2 className="title">How to create a Playable ?</h2>
                <p className="sub-title mb-5">with Monster Playable</p>
                {cards.map((card, index) => (
                  <CardCreation number={index + 1} card={card} />
                ))}
              </div>
            </div>
          </div>

          <div id="try">
            <div className="try-playable">
              <h2 className="title mb-4">Try Playables</h2>
              <div className="content row">
                {cardstry.map((card) => (
                  <CardTry cardtry={card} />
                ))}
              </div>
            </div>
          </div>
        </div>

        <Footer />

      </div>
    </div>
  );
}
