import {
  MUser, MUserApi, MAddress, MCreative, MApplication, MCreativeExport, IPermission,
} from '.';

export class MAccountTenjin {
  isActive: boolean = false;
  token: string = '';
}

export interface MTag {
  text: string,
  color: string,
}

export class MAccount {
  id: number = 0;
  addressId?: number | null = null;
  name: string = '';
  tags?: MTag[] = [];
  isFree?: boolean = true;
  isActive: boolean = true;
  mailValidated: boolean = false;
  exportFormat?: string;
  isValide?: boolean;
  createdAt?: Date;
  Address?: MAddress;
  UsersApis?: MUserApi[];
  Users?: MUser[];
  Applications?: MApplication[];
  Iec?: MCreative[];
  CreativeExport?: MCreativeExport[];
  description?: string;
  isGoogleAd: boolean = false;
  totalImpressions: number = 0;
  hubspotId?: string;
  permissions?: IPermission;
  isManaged: boolean = false;
}
