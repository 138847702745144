import { useIec } from '../../../../context/iec.context';
import { AnimationTypeValues, MCompAnimation, TimingFunctionTypeValues } from '../../../../../../modeles';
import { YcCard } from '../../../../../../components';
import { IecInputNumber } from '../../../input-number';
import { IecSelect } from '../../../select';

import './setting-comp-animation.scss';

export function SettingCompAnimation(props: {
  animation: MCompAnimation
}) {
  const uIec = useIec();

  const isScale = () => {
    return ['pulse', 'hithere'].includes(props.animation.animation);
  };

  const isRotate = () => {
    return ['hithere', 'spine'].includes(props.animation.animation);
  };

  const isTranslateY = () => {
    return ['bounce'].includes(props.animation.animation);
  };

  return (
    <YcCard
      title="Animation"
      light
      open={props.animation._open}
      onClickTitle={(val) => {
        props.animation._open = val;
        uIec.refreshIec(true);
      }}
      onDelete={() => {
        uIec.removeComponentNodeEdite(props.animation);
      }}
    >
      <div className="row">
        <div className="col-4 input-separate">
          <IecSelect label="Animation" field="animation" obj={props.animation} values={AnimationTypeValues} />
        </div>
        <div className="col-4 input-separate">
          <IecInputNumber label="Duration" field="duration" obj={props.animation} step={0.1} />
        </div>
        <div className="col-4 input-separate">
          <IecInputNumber label="Intensity" field="intensity" obj={props.animation} step={0.1} />
        </div>
        <YcCard
          title="Advance"
          open={props.animation._openAdvance}
          onClickTitle={(val) => {
            props.animation._openAdvance = val;
            uIec.refreshIec(true);
          }}
        >
          <div className="row m-2">
            <div className="col-4 input-separate">
              <IecSelect label="Timing Function" field="timingFunction" obj={props.animation} values={TimingFunctionTypeValues} />
            </div>
            <div className="clear" />
            <div className="col-3 input-separate">
              <IecInputNumber label="Scale Start" field="scaleStart" obj={props.animation} step={0.1} disabled={!isScale()} />
            </div>
            <div className="col-3 input-separate">
              <IecInputNumber label="Scale End" field="scaleEnd" obj={props.animation} step={0.1} disabled={!isScale()} />
            </div>
            <div className="col-3 input-separate">
              <IecInputNumber label="Rotate Start" field="rotateStart" obj={props.animation} disabled={!isRotate()} />
            </div>
            <div className="col-3 input-separate">
              <IecInputNumber label="Rotate End" field="rotateEnd" obj={props.animation} disabled={!isRotate()} />
            </div>
            <div className="col-3">
              <IecInputNumber label="Translate Y" field="translateY" obj={props.animation} disabled={!isTranslateY()} />
            </div>
          </div>
        </YcCard>
      </div>
    </YcCard>
  );
}
