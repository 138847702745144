import { Button, Table } from 'antd';
import { useEffect, useState } from 'react';
import { ColumnType } from 'antd/lib/table';

import { invoicesService } from '../../../../services';
import { MInvoice } from '../../../../modeles';

import './invoices.page.scss';

export function InvoicesPage() {
  const [invoices, _setInvoices] = useState<MInvoice[]>([]);

  const columns: ColumnType<MInvoice>[] = [
    {
      title: 'ID',
      key: 'id',
      render: (text: string, invoice: MInvoice) => (
        <>
          #
          {invoice.id}
        </>
      ),
    },
    {
      title: 'Date',
      key: 'month',
      render: (text: string, invoice: MInvoice) => (invoice.month),
    },
    {
      title: 'Amount',
      key: 'amount',
      render: (text: string, user: MInvoice) => (
        <>
          {user.amount}
          $
        </>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: string, invoice: MInvoice) => (
        <>
          {invoice.isPaid === false
            && (
            <Button onClick={() => {
              invoicesService.pay(invoice.month).then((bool) => {
                if (bool) {
                  invoice.isPaid = true;
                  _setInvoices([...invoices]);
                }
              });
            }}
            >
              Pay it
            </Button>
            )}
          {invoice.isPaid === true
            && <a href="www.hotmail.com">Download</a>}
        </>
      ),
    },
  ];

  useEffect(() => {
    invoicesService.listing().then((data) => {
      _setInvoices(data || []);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="invoices-page">
      <Table bordered size="small" dataSource={invoices} columns={columns} rowKey="id" />
    </div>
  );
}
