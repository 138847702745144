import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import * as HomeImages from '../../../../../../assets/images/home';
import { IGame } from '../../home.page';
import { GamesCard, InformationCard, PreviewCard } from '../sub-components';

import './playable-preview.scss';

export function PlayablePreview() {
  const icons = [
    HomeImages.IcoGame,
    HomeImages.IcoRulesB,
    HomeImages.IcoSmartphoneB,
  ];
  const content = (
    <p>
      The playable ad represents <strong>the best performing creative</strong> on the market!<br /><br />
      As a demo of your application, it represents an
      immersive experience that engages the user and therefore permits to gain in user acquisition.
    </p>
  );
  const games: IGame[] = [
    {
      name: 'Beaver builder',
      icon: 'https://yso-playable-prod-front.s3.eu-west-3.amazonaws.com/home_assets/pla_game_1_icon.png',
      url: 'https://yso-playable-prod-front.s3.eu-west-3.amazonaws.com/home_assets/game_1_pla.html',
    },
    {
      name: 'Riot Z',
      icon: 'https://yso-playable-prod-front.s3.eu-west-3.amazonaws.com/home_assets/pla_game_2_icon.png',
      url: 'https://yso-playable-prod-front.s3.eu-west-3.amazonaws.com/home_assets/game_2_pla.html',
    },
    {
      name: 'Basket Attack',
      icon: 'https://yso-playable-prod-front.s3.eu-west-3.amazonaws.com/home_assets/pla_game_3_icon.png',
      url: 'https://yso-playable-prod-front.s3.eu-west-3.amazonaws.com/home_assets/game_3_pla.html',
    },
  ];
  const [selectedPlayable, _setSelectedPlayable] = useState<IGame | null>(games[0]);
  const [previewKey, _setPreviewKey] = useState<number>(0);
  const isSmallScreen = useMediaQuery({ maxWidth: 1024 });

  return (
    <div id="playable-preview">
      <div className="content row">
        <div className="col-5 preview-card">
          {
            !isSmallScreen && (
            <PreviewCard key={previewKey} game={selectedPlayable} />
            )
          }
        </div>
        <div className="details col-12 col-lg-7">
          <h2>Try Playables Ads</h2>
          <GamesCard
            isSmallScreen={isSmallScreen}
            games={games}
            selectedGame={selectedPlayable}
            selectGame={(game) => { _setSelectedPlayable(game); }}
            refresh={() => { _setPreviewKey(previewKey + 1); }}
          />
          <InformationCard path="/playable-presentation" icons={icons} left content={content} />
        </div>
      </div>
    </div>
  );
}
