import { DeleteOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import {
  Button, Card, Popconfirm, Typography, Input, Spin,
} from 'antd';
import { useEffect, useState } from 'react';
import Meta from 'antd/lib/card/Meta';

import { MAsset, MDocument } from '../../../../modeles';
import { assetService } from '../../../../services/asset.service';

import './library-account.scss';

export function LibraryAccount(props: {
  onDocumentSelected: (doc: MDocument) => void,
  modalOk: () => Promise<void>,
  modalCancel: () => void,
  documentSelected: MDocument | null,
}) {
  const [loading, _setLoading] = useState(false);
  const [assets, _setAssets] = useState<MAsset[]>([]);
  const [assetTypes, _setAssetTypes] = useState<string[]>([]);
  const [typeSelected, _setTypeSelected] = useState<string | null>(null);
  const [assetsDisplayed, _setAssetsDisplayed] = useState<MAsset[]>([]);

  useEffect(() => {
    _setLoading(true);
    assetService.listing().then((data) => {
      _setAssets(data || []);
      _setAssetTypes([...new Set(data?.map((item) => item.type))]);
      _setLoading(false);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    _setAssetsDisplayed(assets.filter((d) => d.type === typeSelected));
    _setAssetTypes([...new Set(assets?.map((item) => item.type))]);
  }, [assets, typeSelected]);

  const selectAsset = (asset: MAsset) => {
    props.onDocumentSelected({ ...asset.Document!, id: asset.documentId });
  };

  const deleteAsset = (asset: MAsset) => {
    assetService.remove(asset.id);
    _setAssets(assets.filter((a) => a.id !== asset.id));
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase() as string;
    _setAssetsDisplayed(assets.filter((d) => d.type === typeSelected && d.name.toLowerCase().includes(value)));
  };

  return (
    <div className="library-account-component">
      <Spin spinning={loading}>
        <div className="library-assets-component d-flex flex-wrap gap-3">
          {
          !typeSelected && (
            assetTypes.map((type: string, index: number) => (
              <Card
                key={index}
                hoverable
                style={{ width: 120 }}
                cover={<img className="image-card" alt={type} src={assets.filter((asset) => asset.type === type)[0].Document!.url} />}
                onClick={() => { _setTypeSelected(type); }}
              >
                <Meta title={type} />
              </Card>
            ))
          )
        }
        </div>
        {
          typeSelected && (
            <>
              <div className="mb-3">
                <Button type="text" onClick={() => _setTypeSelected(null)}>
                  <ArrowLeftOutlined />
                </Button>
                <Typography.Text strong>{typeSelected}</Typography.Text>
                <Input placeholder="Search" onChange={onSearch} style={{ width: 200, marginLeft: 20 }} />
              </div>
              <div className="library-assets-component d-flex flex-wrap gap-3">
                {
                  assetsDisplayed
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((asset: MAsset) => (
                      <Card
                        key={asset.id}
                        className={`card ${props.documentSelected?.id === asset.documentId ? 'border border-2 border-primary' : ''}`}
                        hoverable
                        style={{ width: 120 }}
                        cover={<img className="image-card" alt={asset.name} src={asset.Document?.url} />}
                        onClick={() => { selectAsset(asset); }}
                      >
                        <Meta title={asset.name} />
                        <Popconfirm
                          title="Are you sure to delete this?"
                          onConfirm={() => deleteAsset(asset)}
                          onCancel={() => {}}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button icon={<DeleteOutlined />} size="small" type="primary" danger shape="circle" className="delete" />
                        </Popconfirm>
                      </Card>
                    ))
                }
              </div>
            </>
          )
        }
      </Spin>
      <div className="actions">
        <Button loading={loading} onClick={() => { props.modalCancel(); }}>Cancel</Button>
        <Button loading={loading} type="primary" onClick={() => { props.modalOk(); }}>Validate</Button>
      </div>
    </div>
  );
}
