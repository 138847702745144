import { MDocument } from './document.m';

export class MAsset {
  id: number = 0;
  documentId: number = 0;
  name: string = '';
  type: string = '';
  isPrivate: boolean = false;
  isFree: boolean = true;
  createdAt: Date = new Date();
  Document?: MDocument;
}
