import './privacy-policies.scss';

export function PrivacyPolicies() {
  return (
    <div id="privacy-policies">
      <p>
        These Terms of Use (the “Terms”) is the legal agreement between
        you (the “User” or “you”) and Monster Playable. (“Monster
        Playable”, “us” or “our” or “we” or “Company”) for your use of our
        website available at monsterplayable.com and any of its
        subdomains (the “Site”).
      </p>

      <p>
        If you would like to review our terms of use for other products and
        services, please use the following
        links
      </p>

      <p>
        <strong>
          BY ACCESSING AND/OR USING THE SITE, YOU
          ACKNOWLEDGE THAT YOU HAVE READ AND THAT YOU
          AGREE TO BE BOUND BY THESE TERMS INCLUDING THE
          TERMS OF OUR PRIVACY POLICY, AS AVAILABLE ON THE
          SITE
        </strong>
      </p>

      <p>
        <strong>
          IF YOU DO NOT AGREE TO THESE TERMS, DO NOT CONNECT
          TO, ACCESS OR USE THE SITE IN ANY MANNER
          WHATSOEVER.
        </strong>
      </p>

      <p>
        <strong>The Site</strong> is available only to individuals who possess the legal
        authority to enter into these Terms and to form a binding
        agreement under any applicable law.
      </p>

      <p>
        THE SITE. The Site provides you with information regarding
        Monster Playable, including without limitation Monster Playable
        products and services, and any other content available therein
        such as data, materials, contact information, designs, and graphic
        user interface (collectively, the “Content”). In addition, the Site
        provides you the ability to contact us.
      </p>

      <p>
        <strong>
          TO THE EXTENT LEGALLY PERMISSIBLE ALL RIGHTS IN AND
          TO THE CONTENT AVAILABLE ON THE SITE ARE RESERVED
          TO MONSTER PLAYABLE. TO THE EXTENT LEGALLY
          PERMISSIBLE, THE SITE AND THE CONTENT AVAILABLE
          THEREIN ARE PROVIDED ON AN “AS IS” BASIS. MONSTER
          PLAYABLE WILL NOT BE LIABLE FOR ANY DAMAGES OR
          LOSS INCURRED BY YOU OR ANY OTHER PERSON AS A
          RESULT OF OR IN CONNECTION WITH YOUR USE OF THE
          SITE AND/OR THE CONTENT AVAILABLE THEREIN.
        </strong>
      </p>

      <p>
        <strong>
          YOUR USE OF THE SITE, AND/OR THE CONTENT AVAILABLE
          THEREIN IS ENTIRELY AT YOUR OWN RISK.
        </strong>
      </p>

      <p>
        <strong>CONTACT FORM.</strong> In order to contact us using the Site, you will
        need to fill out and complete the Contact Form available therein.
        The Contact Form requires the User&apos;s full name and e-mail
        address, phone number and company name.
      </p>

      <p>
        <strong>RESTRICTIONS ON USE.</strong> There are certain conducts which are
        strictly prohibited on the Site. Please read the following
        restrictions carefully. Your failure to comply with the provisions set
        forth may result herein (at Monster Playable&apos;s sole discretion) in
        the termination of your access to the Site and may also expose
        you to civil and/or criminal liability.
      </p>

      <p>
        <strong>Unless otherwise explicitly permitted under these Terms
          or in writing by Monster Playable, you may not (and you
          may not permit anyone to):
        </strong>
        (a) use the Site and/or the Content
        for any illegal, immoral, unlawful and/or unauthorized purposes;
        (b) use the Site and/or Content for non-personal or commercial
        purposes; (c) remove or disassociate, from the Content and/or the
        Site any restrictions and signs indicating proprietary rights of
        Monster Playable or its licensors, including but not limited to any
        proprietary notices contained in such materials (such as ©,™, or
        ®); (d) interfere with or violate Users&apos; rights to privacy and other
        rights, or harvest or collect personally identifiable information
        about Users without their express consent, whether manually or
        with the use of any robot, spider, crawler, any search or retrieval
        application, or use other manual or automatic device, process or
        method to access the Site and retrieve, index and/or data-mine
        information; (e) interfere with or disrupt the operation of the Site
        or the servers or networks that host the Site, or disobey any laws,
        regulations, requirements, procedures, or policies of such servers
        or networks; (f) falsely state or otherwise misrepresent your
        affiliation with any person or entity, or express or imply that the
        Company endorses you, your site, your business or any statement
        you make, or present false or inaccurate information about the
        Site; (g) take any action that imposes, or may impose, an
        unreasonable or disproportionately large load on our platform
        infrastructure, as determined by us; (h) bypass any measures we
        may use to prevent or restrict access to the Site; (i) copy, modify,
        alter, adapt, make available, translate, port, reverse engineer,
        decompile, or disassemble any portion of the Content made
        accessible by Monster Playable on or through the Site, or publicly
        display, reproduce, create derivative works from, perform,
        distribute, or otherwise use such Content; (j) copy, distribute,
        display, execute publicly, make available to the public, reduce to
        human readable form, decompile, disassemble, adapt, sublicense,
        make any commercial use, sell, rent, transfer, lend, process,
        compile, reverse engineer, combine with other software, translate,
        modify or create derivative works of any material that is subject to
        Monster Playable&apos;s proprietary rights, including Monster Playable&apos;s
        Intellectual Property (as such term is defined below), in any way
        or by any means; (k) make any use of the Content on any other
        site or networked computer environment for any purpose without
        Monster Playable&apos;s prior written consent; (l) create a browser or
        border environment around Monster Playable Content (no frames
        or inline linking is allowed); (m) sell, license, or exploit for any
        commercial purposes any use of or access to the Site and/or
        Content; (n) frame or mirror any part of the Site without Monster
        Playable&apos;s prior express written authorization; (o) create a
        database by systematically downloading and storing all or any of
        the Content from the Site; (p) transmit or otherwise make
        available in connection with the Site any virus, worm, Trojan
        Horse, time bomb, web bug, spyware, or any other computer
        code, file, or program that may or is intended to damage or hijack
        the operation of any hardware, software, or telecommunications
        equipment, or any other actually or potentially harmful,
        disruptive, or invasive code or component; (q) use the Site for any
        purpose for which the Site is not intended; and/or (r) infringe
        and/or violate any of the Terms.
      </p>

      <p>
        <strong>TITLE AND OWNERSHIP.</strong> You acknowledge and agree that the
        Site, including any revisions, corrections, modifications,
        enhancements and/or upgrades thereto, accompanying materials,
        and any copies you are permitted to make under these Terms are
        owned by the Company or its licensors, and are protected under
        copyright laws and treaties. You further acknowledge and agree
        that all right, title, and interest in and to the Site, including
        associated intellectual property rights
        <strong>(“Intellectual Property”)</strong>
        (including, without limitation, any patents (registered
        or pending), copyrights, trade secrets, designs, or trademarks),
        evidenced by or embodied in and/or attached or connected or
        related to the Site, are and shall remain owned solely by the
        Company and/or its licensors. These Terms does not convey to
        you any interest in or to the Site, but only a limited, revocable
        right of use in accordance with these Terms. Nothing in these
        Terms constitutes a waiver of our intellectual property rights
        under any law.
      </p>

      <p>
        To the extent you provide any feedbacks to Monster
        Playable (“Feedback”), Monster Playable shall have an
        exclusive, royalty-free, fully paid up, worldwide, perpetual and
        irrevocable license to incorporate the Feedback into any Monster
        Playable current or future products, technologies or services and
        use same for any purpose all without further compensation to you
        and without your approval. You agree that all such Feedback shall
        be deemed to be non-confidential.
      </p>

      <p>
        Further, you warrant that your Feedback is not subject to any
        license terms that would purport to require Monster Playable to
        comply with any additional obligations with respect to any
        Monster Playable current or future products, technologies or
        services that incorporate any Feedback.
      </p>

      <p>
        <strong>TRADEMARKS</strong> “Monster Playable” Monster Playable ®, Monster
        Playable`&apos;`s marks and logos and all other proprietary identifiers
        used by the Company (“Company Trademarks”) are all
        trademarks and/or trade names of the Company, whether or not
        registered. All other trademarks, service marks, trade names and
        logos which may appear on the Site belong to their respective
        owners (“Third Party Marks”). No right, license, or interest to
        the Company Trademarks and the Third-Party Marks is granted
        hereunder, and you agree that no such right, license, or interest
        may be asserted by you with respect thereto and therefore you
        will avoid using any of those marks, except as permitted herein.
      </p>

      <p>
        <strong>COMMUNICATIONS</strong> You agree and acknowledge that we may
        send you from time to time, by mail, e-mail, or any other contact
        form you may provide us with (including your phone number for
        calls or text messages), information about our products and
        services, newsletters, offers for open vacancies, promotional
        messages, and materials from Monster Playable or its partners. If
        you wish not to receive such communications - please just notify
        us at any time.
      </p>

      <p>
        <strong>CHANGES TO THE SITE AND/OR TERMS.</strong> We may, at our sole
        discretion, change, modify, add to, or delete any of the terms and
        conditions of these Terms, and/or the Content and/or the Site at
        any time, without prior written notice to you. In the event of any
        material changes to the Terms, we will make reasonable efforts to
        post a clear notice on the Site and/or will attempt to send you an
        e-mail (to the extent that you provided us with such e-mail
        address) regarding such change. Your continued use of the Site,
        following any change to these Terms, constitutes your complete
        and irrevocable acceptance of any such change. If any
        modification to these Terms is not acceptable to you, your only
        recourse is to cease using the Site.
      </p>

      <p>
        <strong>WARRANTY DISCLAIMER.</strong> THE SITE IS PROVIDED “AS IS”,
        WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT
        PERMITTED BY APPLICABLE LAW, THE COMPANY DISCLAIMS ALL
        WARRANTIES, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED
        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
        PURPOSE, AND NON-INFRINGEMENT. THE ENTIRE RISK ARISING
        OUT OF THE USE OR PERFORMANCE OF THE SITE REMAINS WITH
        YOU. THE COMPANY FURTHER DOES NOT REPRESENT OR
        WARRANT THAT THE SITE WILL ALWAYS BE AVAILABLE,
        ACCESSIBLE, FUNCTIONAL, UNINTERRUPTED, SECURE, ACCURATE,
        COMPLETE AND ERROR-FREE.
      </p>

      <p>
        <strong>EXCLUSION OF CONSEQUENTIAL DAMAGES.</strong> UNDER NO
        CIRCUMSTANCES AND UNDER NO LEGAL THEORY (TORT,
        CONTRACT, OR OTHERWISE), SHALL THE COMPANY OR ITS
        LICENSORS BE LIABLE TO YOU OR ANY OTHER PERSON FOR ANY
        INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES
        OF ANY TYPE INCLUDING, WITHOUT LIMITATION, DAMAGES FOR
        LOSS OF GOODWILL, BUSINESS INTERRUPTION, COMPUTER
        FAILURE OR MALFUNCTION, OR ANY AND ALL OTHER COMMERCIAL
        DAMAGES OR LOSSES. Some jurisdictions do not allow the
        exclusion or limitation of incidental or consequential damages, so
        this limitation and exclusion may not apply to you.
      </p>

      <p>
        <strong>LIMITATION OF LIABILITY.</strong> YOU ACKNOWLEDGE AND AGREE
        THAT IN NO EVENT SHALL THE COMPANY HAVE ANY LIABILITY
        WHATSOEVER, WHETHER IN CONTRACT, TORT OR ANY OTHER
        THEORY OF LIABILITY, AND WHETHER OR NOT THE POSSIBILITY OF
        SUCH DAMAGES OR LOSSES HAS BEEN NOTIFIED TO THE
        COMPANY, IN CONNECTION WITH OR ARISING FROM YOUR USE OF
        THE SITE. YOUR ONLY RIGHT OR REMEDY WITH RESPECT TO ANY
        DISSATISFACTION WITH THE SITE IS TO IMMEDIATELY CEASE USE
        OF THE SITE.
      </p>

      <p>
        THE COMPANY&apos;S TOTAL LIABILITY TO YOU UNDER OR IN
        CONNECTION WITH YOUR USE OF THE SITE SHALL NOT EXCEED
        ONE U.S DOLLARS (UScontent.00).
      </p>

      <p>
        <strong>INDEMNITY.</strong> You shall indemnify, defend, and hold us and our
        directors, officers, and employees from and against all claims,
        suites, costs, damages, losses, liability, and expenses, including
        reasonable attorneys fees and other legal expenses, arising from
        or incurred as a result of your use of the Site, or your violation of
        these Terms.
      </p>

      <p>
        <strong>LINKS TO THIRD PARTY SITES.</strong> Certain links provided herein
        permit our Users to leave the Site and enter third party sites or
        services. These linked sites and services are provided solely as a
        convenience to you. These linked sites and services are not under
        the control of Monster Playable and it is not responsible for the
        availability of such external sites or services and does not endorse
        and is not responsible or liable for any content including but not
        limited to content advertising, products or other information on or
        available from such linked sites and services or any link contained
        in linked sites or service. In addition, Monster Playable is not
        responsible or liable for such linked sites and services privacy
        practices and/or any other practices. Your access to, use of and
        reliance upon any such sites, services and content and your
        dealings with such third parties are at your sole risk and expense.
        We reserve the right to remove any link at any time. You further
        acknowledge and agree that we shall not be responsible or liable,
        directly, or indirectly, for any damage or loss caused or alleged to
        be caused, by or in connection with use of or reliance on any
        services, content, products, or other materials available on or
        through such linked sites or resource. Most of such linked sites
        and services provide legal documents, including terms of use and
        privacy policy, governing the use thereof. It is always advisable,
        and we encourage you to read such documents carefully before
        using those sites and services, inter alia, in order to know what
        kind of information about you is being collected.
      </p>

      <p>
        <strong>TERMINATION.</strong>
        At any time, we may discontinue your use of the Site, at our sole
        discretion, with or without any reason or prior notice, in addition
        to any other remedies that may be available to Monster Playable
        under any applicable law.
      </p>

      <p>
        <strong>MISCELLANEOUS.</strong> If any provision of these Terms is held to be
        unenforceable, such provision shall be reformed only to the extent
        necessary to make it enforceable and such decision shall not
        affect the enforceability of such provision under other
        circumstances, or of the remaining provisions hereof under all
        circumstances. These Terms do not, and shall not be construed to
        create any relationship, partnership, joint venture, employer-
        employee, agency, or franchisor-franchisee relationship between
        the parties hereto. No waiver by either party of any breach or
        default hereunder will be deemed to be a waiver of any preceding
        or subsequent breach or default. Any heading, caption or section
        title contained herein is inserted only as a matter of convenience,
        and in no way defines or explains any section or provision hereof.
        YOU ACKNOWLEDGE AND AGREE THAT ANY CAUSE OF ACTION
        THAT YOU MAY HAVE ARISING OUT OF OR RELATED TO THE SITE
        MUST BE FILED WITHIN ONE (1) YEAR AFTER SUCH CLAIM AROSE.
        OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
        If any provision hereof is adjudged by any court of competent
        jurisdiction to be unenforceable, that provision shall be limited or
        eliminated to the minimum extent necessary so that these Terms
        shall otherwise remain in full force and effect while most nearly
        adhering to the intent expressed herein. You may not assign or
        transfer these Terms (including all rights and obligations
        hereunder) without our prior written consent and any attempt to
        do so in violation of the foregoing shall be void. We may assign or
        transfer these Terms without restriction or notification. No
        amendment hereof will be binding unless in writing and signed by
        Monster Playable. The parties agree that all correspondence
        relating to these Terms shall be written in the English language.
      </p>

      <p>
        <strong>LAW AND JURISDICTION.</strong> These Terms will be governed by,
        construed, and enforced in accordance with the laws of France,
        without regard to its conflicts of law principles or provisions. The
        application of the United Nations Convention on Contracts for the
        International Sale of Goods and the Uniform Computer Information
        Transactions Act is specifically excluded of these Terms. Any
        disputes arising out of or in connection with these Terms shall be
        finally exclusively settled under the Rules of Arbitration of the
        International Chamber of Commerce (the “ICC Rules”) by one
        arbitrator appointed in accordance with the ICC Rules (the
        “Arbitrator”). The arbitration shall take place in Paris, France and
        shall be conducted in the English Language. The arbitration shall
        be conducted on a confidential basis. The award passed by the
        Arbitrator shall be final and binding. Nothing contained herein
        shall prevent the Company from applying to any court of law in
        order to obtain injunctions, equitable relief or any equivalent
        remedy, against you, in order to restrain the breach of any
        restrictive covenants pursuant to these Terms. The arbitration
        award shall be enforceable in any court of competent jurisdiction.
        Any motion to enforce or vacate an arbitration award under this
        agreement shall be kept confidential to the maximum extent
        possible.
      </p>

      <p>
        <strong>CONTACT US.</strong> If you have any questions, complaints and/or
        claims, you may contact us at contact@monsterplayable.com.
      </p>
    </div>
  );
}
