import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

import './index.css';
import { App } from './modules/app';
import reportWebVitals from './reportWebVitals';
import { AuthsContextProvider } from './contexts';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const root = createRoot(document.getElementById('root') as Element | DocumentFragment);

function setFavicon() {
  const favicon = document.querySelector("link[rel='icon']");

  if (process.env.NODE_ENV === 'development') {
    // @ts-ignore
    favicon.href = '/favicon-dev.png';
  }
}

setFavicon();

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthsContextProvider>
        <App />
      </AuthsContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

reportWebVitals();
