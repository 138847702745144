import './video-component.scss';

export function VideoComponent() {
  return (
    <div id="video-component">
      <div className="content">
        <h2>Monster Playable how it work?</h2>
        <div className="video-responsive">
          <iframe
            className="video"
            src="https://www.youtube.com/embed/czFuskvSKVU?si=Jshm09VyAPCP0tYd"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
      </div>
    </div>
  );
}
