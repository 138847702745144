import { useEffect, useState } from 'react';

import { usePlayable } from '../../../../context/playable.context';
import { YcCard } from '../../../../../../components';
import { IAnimatorJson as JsonType } from '../../../../../../modeles/MP_Interfaces';
import { SettingCompInputCheckbox, SettingCompInputNumber } from '../../sub-settings';

import './setting-comp-animator.scss';

export function SettingCompAnimator(props: { initialData: any, parentID: string, icon?: string }) {
  const childType: string = props.initialData.type;
  const childID: string = props.initialData.instanceID;
  const parentID: string = props.parentID;

  // const id = props.initialData.runtimeAnimatorController_Infos.id;

  const uPlayable = usePlayable();

  const [compTypeIndex, _setCompTypeIndex] = useState<number>();
  const [component, _setComponent] = useState<JsonType>();

  useEffect(() => {
    if (compTypeIndex === undefined) {
      _setCompTypeIndex(uPlayable.getCompTypeIndex(childType, parentID, childID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (props !== undefined && compTypeIndex !== undefined) {
      _setComponent(uPlayable.getRunTimeComponent(`${childType}[${compTypeIndex}]`) as JsonType);
    }
  }, [childType, compTypeIndex, props, uPlayable]);

  const editComponent = (propertyPath: string, value: any, update: boolean = true) => {
    if (compTypeIndex !== undefined) {
      uPlayable.updateGameObject(value, `${childType}[${compTypeIndex}]/${propertyPath}`, childID, update);
    }
  };

  return (
    <YcCard
      title="Animator"
      icon={props.icon}
      light
      open={false}
      warning={compTypeIndex === undefined}
      titleExtra={(
        <SettingCompInputCheckbox
          checked={component?.enabled ?? true}
          onChange={(e) => editComponent('enabled', e)}
        />
      )}
    >
      <span>Animator</span>
      {/* <span>Layers</span>
      {localData?.layers?.map((layer: any, i: number) => (
        <YcCard key={i} title={layer?.name} light open>
          <div className="row mb-2">
            <SettingCompInputNumber
              disabled
              label="Default weight"
              value={layer?.defaultWeight || 0}
              onChange={() => null}
            />
            <YcCard key={i} title="States" light open>
              {layer?.stateMachine?.states?.map((state: any, j: number) => (
                <div className="row" key={j}>
                  <span>{state.name}</span>
                  <SettingCompInputNumber
                    disabled
                    label="Speed"
                    value={state.speed ?? 1}
                    onChange={() => null}
                  />
                </div>
              ))}
            </YcCard>
          </div>
        </YcCard>
      ))}
      <span>Parameters</span>
      {localData?.parameters?.map((param: any, i: number) => (
        <YcCard key={i} title={param?.name} light open>
          <SettingCompInputNumber
            disabled
            label="Parameter type"
            value={param?.typeParam ?? 0}
            onChange={() => null}
          />
        </YcCard>
      ))} */}
    </YcCard>
  );
}
