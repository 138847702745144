import { MEngineLogs } from '../modeles';
import { BaseService } from './base.service';

class EngineLogsService extends BaseService {
  PATH = 'engine-logs/';

  async listing(): Promise<MEngineLogs[] | null> {
    return this._listing(`${this.PATH}listing`);
  }

  async downloadData(id: number): Promise<any | null> {
    return this._view(`${this.PATH}download-data/${id}`);
  }

  async downloadDataFromPlayable(id: number): Promise<any | null> {
    return this._view(`${this.PATH}download-data-from-playable/${id}`);
  }
}

export const engineLogsService = new EngineLogsService();
