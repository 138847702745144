import { BaseService } from './base.service';
import { MPlayableEngine, MTag } from '../modeles';

class PlayableEngineService extends BaseService {
  PATH = 'playable-engine/';

  async listing(): Promise<MPlayableEngine[] | null> {
    return this._listing(`${this.PATH}listing`);
  }

  async compatiblesEngines(version: string): Promise<MPlayableEngine[] | null> {
    return this._listing(`${this.PATH}compatibles-engines/${version}`);
  }

  async add(iec: MPlayableEngine): Promise<MPlayableEngine | null> {
    return this._add(`${this.PATH}add`, iec);
  }

  async view(id: number): Promise<MPlayableEngine | null> {
    return this._view(`${this.PATH}view/${id}`);
  }

  async edit(id: number, app: MPlayableEngine): Promise<MPlayableEngine | null> {
    return this._edit(`${this.PATH}edit/${id}`, app);
  }

  async remove(id: number): Promise<MPlayableEngine | null> {
    return this._delete(`${this.PATH}delete/${id}`);
  }

  async tags(): Promise<MTag[] | null> {
    return this._view(`${this.PATH}tags`).then((res: any) => res.tags as MTag[]);
  }

  async isRelease(id: number, isRelease: boolean): Promise<MPlayableEngine[]> {
    return await this._view(`${this.PATH}is-release/${id}/${isRelease}`) || [];
  }
}

export const playableEngineService = new PlayableEngineService();
