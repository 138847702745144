import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';

import { useEffect, useState } from 'react';
import { usePlayable } from '../../../../context/playable.context';
import { findAllParentsId, findElem } from '../../../../utils/playable.utils';
import { IGameObjectJson } from '../../../../../../modeles/MP_Interfaces';

// import './comp-game-object-ref.scss';

export interface IGameObject {
  name: string;
  type: string;
  value: string | { componentId: string, value: string } | null;
  isRef: boolean;
}

export function SettingCompGameObjectRef(props: {
  gameObject: IGameObject,
}) {
  const uPlayable = usePlayable();

  const [node, _setNode] = useState<IGameObjectJson>();
  const [value, _setValue] = useState<string | null>(null);

  useEffect(() => {
    if (uPlayable.playableJson) {
      if (uPlayable.playableJson) {
        const { value: gameObjectValue } = props.gameObject;
        if (gameObjectValue) {
          const resolvedValue = typeof gameObjectValue === 'string' ? gameObjectValue : gameObjectValue.value;
          _setValue(resolvedValue);
          _setNode(findElem(uPlayable.playableJson, resolvedValue)!);
        }
      }
    }
  }, [props.gameObject, uPlayable.playableJson]);

  const onFocusGameObject = () => {
    if (node && value) {
      uPlayable.setNodeEdit(node);
      uPlayable.setNodeOpened(findAllParentsId(uPlayable.getRuntimeGameObject(value).gameObject));
    }
  };

  const naming = () => {
    if (props.gameObject.value) {
      return node ? node.name : value;
    }
    return 'null';
  };

  return (
    <div className="row mb-1 d-flex align-items-center">
      <div className="col-5">
        <label>{props.gameObject.name}</label>
      </div>
      <div className="col-7">
        <Button
          onClick={() => { onFocusGameObject(); }}
          size="small"
          block
          icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
          iconPosition="end"
          disabled={!value}
        >
          {
            naming()
          }
        </Button>
      </div>
    </div>
  );
}
