import { useEffect, useState } from 'react';

import { usePlayable } from '../../../../context/playable.context';
import { YcCard } from '../../../../../../components';
import { IMeshRendererJson as JsonType } from '../../../../../../modeles/MP_Interfaces';
import {
  SettingCompInputBoolean, SettingCompSelect,
  SubSettingCompMaterials, SettingCompInputCheckbox,
} from '../../sub-settings';

import './setting-comp-mesh-renderer.scss';

const shadowsCastingOptions: { label: string, value: number }[] = [
  { label: 'Off', value: 0 },
  { label: 'On', value: 1 },
  { label: 'Two Sided', value: 2 },
  { label: 'Shadows Only', value: 3 },
];

const motionVectorsOptions: { label: string, value: number }[] = [
  { label: 'Camera Motion Only', value: 0 },
  { label: 'Per Object Motion', value: 1 },
  { label: 'Force no motion', value: 2 },
];

export function SettingCompMeshRenderer(props: { initialData: any, parentID: string, icon?: string }) {
  const childType: string = props.initialData.type;
  const childID: string = props.initialData.instanceID;
  const parentID: string = props.parentID;

  const uPlayable = usePlayable();

  const [compTypeIndex, _setCompTypeIndex] = useState<number>();
  const [component, _setComponent] = useState<JsonType>();

  useEffect(() => {
    if (compTypeIndex === undefined) {
      _setCompTypeIndex(uPlayable.getCompTypeIndex(childType, parentID, childID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (props !== undefined && compTypeIndex !== undefined) {
      _setComponent(uPlayable.getRunTimeComponent(`${childType}[${compTypeIndex}]`) as JsonType);
    }
  }, [childType, compTypeIndex, props, uPlayable]);

  const editComponent = (propertyPath: string, value: any, update: boolean = true) => {
    if (compTypeIndex !== undefined) {
      uPlayable.updateGameObject(value, `${childType}[${compTypeIndex}]/${propertyPath}`, childID, update);
    }
  };

  const sharedMaterialsIds: string[] = props.initialData.sharedMaterials || [];

  return (
    <YcCard
      title="Mesh Renderer"
      icon={props.icon}
      light
      open
      warning={compTypeIndex === undefined}
      titleExtra={(
        <SettingCompInputCheckbox
          checked={component?.enabled ?? true}
          onChange={(e) => editComponent('enabled', e)}
        />
      )}
    >
      <div className="row">
        <div className="col-12">
          <div className="material-area">
            {
              sharedMaterialsIds?.map((materialId: string, index: number) => (
                <SubSettingCompMaterials
                  key={index}
                  index={index}
                  materialId={materialId}
                  childID={childID}
                />
              ))
            }
          </div>
          <SettingCompSelect
            disabled
            label="Cast Shadows"
            value={component?.shadowCastingMode ?? 1}
            options={shadowsCastingOptions}
            onChange={(value) => editComponent('shadowCastingMode', value)}
          />
          <SettingCompSelect
            disabled
            label="Motion Vectors"
            value={component?.motionVectorGenerationMode ?? 1}
            options={motionVectorsOptions}
            onChange={(value) => editComponent('motionVectorGenerationMode', value)}
          />
          <SettingCompInputBoolean
            label="Receive Shadows"
            checked={component?.receiveShadows ?? true}
            onChange={(e) => editComponent('receiveShadows', e)}
          />
          <SettingCompInputBoolean
            label="Dynamic Occlusion"
            checked={component?.allowOcclusionWhenDynamic ?? true}
            onChange={(e) => editComponent('allowOcclusionWhenDynamic', e)}
          />
        </div>
      </div>
    </YcCard>
  );
}
