import {
  RefObject, useEffect,
  useRef, useState,
} from 'react';
import { InputNumber } from 'antd';

import { IVector2Json, IVector3Json } from '../../../../../../modeles/MP_Interfaces';
import { usePlayable } from '../../../../context/playable.context';

import './comp-vectors.scss';

type vectorItem = {
  label: string,
  ref?: RefObject<HTMLInputElement>,
  field: 'x' | 'y' | 'z',
  onChange: (e: number) => void,
};

export function SettingCompVector2(props: {
  defaultType?: string,
  vector?: IVector2Json,
  label: string,
  onChange: (vector: IVector2Json) => void,
  min?: number,
  max?: number,
  step?: number,
  disabled?: boolean,
}) {
  const uPlayable = usePlayable();

  const defaultTypes: { [key: string]: IVector2Json } = {
    '0s': new uPlayable.playableClasses.Vector3(0, 0),
    '0.5s': new uPlayable.playableClasses.Vector3(0.5, 0.5),
    '300s': new uPlayable.playableClasses.Vector3(300, 300),
  };

  const [vector, _setVector] = useState<IVector2Json>(defaultTypes[props.defaultType ?? 0]);

  useEffect(() => {
    if (props.vector) {
      _setVector(props.vector);
    }
  }, [props.vector]);

  const editTransform = (newVector: IVector2Json) => {
    props.onChange(newVector);
  };

  const newVector2 = (value: IVector2Json): IVector2Json => {
    return new uPlayable.playableClasses.Vector3(value.x ?? 0, value.y ?? 0) as IVector2Json;
  };

  const vectors2Map: vectorItem[] = [
    {
      label: 'X',
      field: 'x',
      onChange: (e: number) => {
        _setVector({ x: e, y: vector.y } as IVector2Json);
        editTransform(newVector2({ x: e, y: vector.y } as IVector2Json));
      },
    },
    {
      label: 'Y',
      field: 'y',
      onChange: (e: number) => {
        _setVector({ x: vector.x, y: e } as IVector2Json);
        editTransform(newVector2({ x: vector.x, y: e } as IVector2Json));
      },
    },
  ];

  return (
    <div className="row mb-1">
      {
        vector && (
          <div className="comp-vector row align-items-center">
            <div className="col-5">
              <label className="me-1">{props.label}</label>
            </div>
            <div className="row col-7">
              {vectors2Map.map((elem: any, i: number) => (
                <div key={i} className="col-4 d-flex align-items-center" style={{ paddingRight: 0 }}>
                  <div>
                    <label className="me-1" style={{ userSelect: 'none', cursor: 'pointer' }}>{elem.label}</label>
                  </div>
                  <InputNumber
                    disabled={props?.disabled}
                    // @ts-ignore
                    value={vector[elem.field]?.toFixed(3)}
                    size="small"
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                    onChange={(e) => e != null && elem.onChange(e)}
                    min={props.min}
                    max={props.max}
                    step={props.step}
                  />
                </div>
              ))}
            </div>
          </div>
        )
      }
    </div>
  );
}

export function SettingCompVector3(props: {
  defaultType?: string,
  vector?: IVector3Json,
  label: string,
  onChange: (vector: IVector3Json, updateJson: boolean) => void,
  disabled?: boolean,
}) {
  const uPlayable = usePlayable();

  const defaultTypes: { [key: string]: IVector3Json } = {
    '0s': new uPlayable.playableClasses.Vector3(0, 0, 0),
    '1s': new uPlayable.playableClasses.Vector3(1, 1, 1),
  };

  const [vector, _setVector] = useState<IVector3Json>(defaultTypes[props.defaultType ?? 0]);

  const fieldXRef = useRef<HTMLInputElement>(null);
  const fieldYRef = useRef<HTMLInputElement>(null);
  const fieldZRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.vector) {
      _setVector(props.vector);
    }
  }, [props.vector]);

  const editTransform = (newVector: IVector3Json, updateJson: boolean = true) => {
    props.onChange(newVector, updateJson);
  };

  const newVector3 = (value: IVector3Json): IVector3Json => {
    return new uPlayable.playableClasses.Vector3(value.x ?? 0, value.y ?? 0, value.z ?? 0) as IVector3Json;
  };

  const handleSelectContent = (ref: RefObject<HTMLInputElement>) => {
    if (ref.current !== null) {
      ref.current.select();
    }
  };

  const vectors3Map: vectorItem[] = [
    {
      label: 'X',
      field: 'x',
      ref: fieldXRef,
      onChange: (e: number) => {
        _setVector({ x: e, y: vector.y, z: vector.z } as IVector3Json);
        editTransform(newVector3({ x: e, y: vector.y, z: vector.z } as IVector3Json));
      },
    },
    {
      label: 'Y',
      field: 'y',
      ref: fieldYRef,
      onChange: (e: number) => {
        _setVector({ x: vector.x, y: e, z: vector.z } as IVector3Json);
        editTransform(newVector3({ x: vector.x, y: e, z: vector.z } as IVector3Json));
      },
    },
    {
      label: 'Z',
      field: 'z',
      ref: fieldZRef,
      onChange: (e: number) => {
        _setVector({ x: vector.x, y: vector.y, z: e } as IVector3Json);
        editTransform(newVector3({ x: vector.x, y: vector.y, z: e } as IVector3Json));
      },
    },
  ];

  return (
    <div className="comp-vector row mb-1 align-items-center">
      <div className="col-5">
        <label>{props.label}</label>
      </div>
      <div className="row col-7" style={{ padding: 0 }}>
        {vector && vectors3Map.map((elem: any, i: number) => (
          <div key={i} className="col-4 d-flex align-items-center" style={{ paddingRight: 0 }}>
            <div>
              <label className="me-1" style={{ userSelect: 'none', cursor: 'pointer' }}>{elem.label}</label>
            </div>
            <InputNumber
              ref={elem?.ref}
              disabled={props?.disabled}
              // @ts-ignore
              value={vector[elem.field]?.toFixed(3)}
              size="small"
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              onChange={(e) => e != null && elem.onChange(e)}
              onFocus={() => handleSelectContent(elem?.ref as RefObject<HTMLInputElement>)}
              controls={false}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
