import {
  Button, Popconfirm, Spin, Tag, Tooltip,
} from 'antd';
import { useEffect, useState } from 'react';
import { ColumnType } from 'antd/lib/table';
import { CloudDownloadOutlined, DeleteOutlined } from '@ant-design/icons';

import { useAuths } from '../../../../contexts/auths.context';
import { MCreativeExport, MCreative, MNetwork } from '../../../../modeles';
import { creativeExportsService } from '../../../../services';
import { MpTable } from '../../../../components';

// styles
import './creatives-exported.scss';

export function CreativesExported(props:{
  creative: MCreative,
}) {
  const uAuths = useAuths();

  const [loading, _setLoading] = useState(false);
  const [creativesExported, _setCreativesExported] = useState<MCreativeExport[]>([]);

  useEffect(() => {
    if (props.creative) {
      _setLoading(true);
      creativeExportsService.exportsByIec(props.creative.id).then((data) => {
        _setCreativesExported(data || []);
        _setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function downloadFile(id: number) {
    const url = await creativeExportsService.downloadFile(id);
    const link = document.createElement('a');
    link.href = url!;
    link.click();
  }

  async function deleteExport(id: number) {
    const dataSource = [...creativesExported];
    _setCreativesExported(dataSource.filter((item) => item.id !== id));
    await creativeExportsService.remove(id);
  }

  const columns: ColumnType<MCreativeExport>[] = [
    {
      title: 'Name',
      key: 'name',
      render: (text: string, record: MCreativeExport) => (
        <label>{record.name}</label>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name || '') || -1,
    },
    {
      title: 'Networks',
      key: 'networks',
      fixed: 'right',
      align: 'center',
      render: (text: string, record: MCreativeExport) => (
        <div className="networks">
          {record.networks.map((network: MNetwork) => <Tag key={network.value}>{network.name}</Tag>)}
        </div>
      ),
    },
    {
      title: 'Date',
      key: 'date',
      fixed: 'right',
      align: 'center',
      render: (text: Date, record: MCreativeExport) => (
        <label>{new Date(record.createdAt!).toLocaleString() }</label>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      width: '100px',
      align: 'center',
      render: (text: string, record: MCreativeExport) => (
        <div className="actions">
          <Tooltip placement="top" title="Download">
            <Button type="primary" shape="circle" icon={<CloudDownloadOutlined />} size="small" onClick={() => { downloadFile(record.id); }} />
          </Tooltip>
          {uAuths.isAdmin()
            && (
            <Popconfirm title="Sure to delete?" onConfirm={() => { deleteExport(record.id); }}>
              <Button type="primary" shape="circle" icon={<DeleteOutlined />} size="small" danger />
            </Popconfirm>
            )}
        </div>
      ),
    },
  ];

  return (
    <div className="creatives-exported">
      <Spin spinning={loading}>
        <MpTable bordered size="small" dataSource={creativesExported} columns={columns} rowKey="id" mpTableKey="creative-exported" urlParamsEnabled />
      </Spin>
    </div>
  );
}
