import { useEffect, useState } from 'react';
import { ColumnType } from 'antd/lib/table';
import {
  Checkbox, Dropdown, Button,
} from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';

interface Props<T extends object = any> {
  tableKey: string,
  onChange(hidedFields: string[]): void,
  columns: (ColumnType<T> & {
    mpCanNotHide?: boolean,
  })[],
}

function setLocalStorage(key: string, hidedFields: string[]) {
  localStorage.setItem(`yctable-${key}`, JSON.stringify(hidedFields));
}

function getLocalStorage(key: string): string[] {
  return JSON.parse(localStorage.getItem(`yctable-${key}`) as string || '[]') as string[];
}

export function MpTableHideMenu<T extends object = any>({ columns, tableKey, onChange }: Props<T>) {
  const [hiddenFields, _setHiddenFields] = useState(getLocalStorage(tableKey));
  const [isOpen, _setIsOpen] = useState(false);

  useEffect(() => {
    onChange(hiddenFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onToggleField(item: ColumnType<T>) {
    const index = hiddenFields.indexOf(item.key as string);
    if (index !== -1) {
      hiddenFields.splice(index, 1);
    } else if (item.key) {
      hiddenFields.push(item.key as string);
    }
    const dup = [...hiddenFields];
    _setHiddenFields(dup);
    onChange(dup);
    setLocalStorage(tableKey, hiddenFields);
  }

  const onOpenChange = (visible: boolean) => {
    _setIsOpen(visible);
  };

  const items = columns.map((item: any) => {
    return {
      key: item.key,
      label: (
        <Checkbox
          disabled={item.mpCanNotHide}
          checked={hiddenFields.indexOf(item.key as string) === -1}
          onChange={(/* e */) => onToggleField(item as ColumnType<T>)}
        >
          {item.title}
        </Checkbox>
      ),
    };
  });

  return (
    <Dropdown
      onOpenChange={onOpenChange}
      open={isOpen}
      trigger={['click']}
      menu={{ items }}
    >
      <Button
        type="default"
        size="small"
        icon={<UnorderedListOutlined />}
      >
        Columns
      </Button>
    </Dropdown>
  );
}
