import { MCreativeVideo, MNodeBase } from '../../../../modeles';

export const varVideo = (video: MCreativeVideo | string | null) => {
  if (video !== null) {
    if (typeof video === 'string') {
      return `video${video}`;
    }
    return `video${video._key}`;
  }
  return '';
};

export const varElement = (element: MNodeBase, prefix?: string) => {
  return `${prefix}${element._key}`;
};
