/* eslint-disable @typescript-eslint/no-unsafe-argument */
import JSZip from 'jszip';
import { MApplication, MCreative, MCreativeExport } from '../../../../modeles';
import { creativeExportsService, creativesService } from '../../../../services';
import { addCreativeExports, exportPlatform, generateExportName } from '../../../../tools/export.tool';
import { Events, getNetwork, setNetwork } from '../../../../networks/network-integration';
import GoogleAdsIntegration from '../../../../networks/googleAds-integration';
import MolocoIntegration from '../../../../networks/moloco-integration';
import { FilesTools } from '../../../../tools';

export async function exportPlayable(
  playable: MCreative,
  application: MApplication,
  selectedNetwork: any[],
  platform: string,
  exportFormatString: string,
) {
  if (!playable.PlayableRelease?.url) {
    console.error('No playable url');
  } else {
    const zip = new JSZip();
    const response = await fetch(playable.PlayableRelease.url);
    const data = await response.text();

    let logoBase64 = '';

    if (application.Icon && application.Icon.url) {
      const resp = await creativesService.urlPublic(application.Icon.url);
      if (resp) {
        const value = await FilesTools.getBase64FromUrl(resp);
        if (value) {
          logoBase64 = value;
        }
      }
    }

    for (let i = 0; i < selectedNetwork.length; i += 1) {
      const network = selectedNetwork[i];
      setNetwork(network.value as string);
      const NETWORK = getNetwork();

      try {
        let modifiedData = data.replace('var NETWORK = \'default\';', `var NETWORK = '${network.value}';`);
        modifiedData = modifiedData.replace('<!-- [##_MP_META_##] -->', NETWORK.MP_META);
        modifiedData = modifiedData.replace('<!-- [##_MP_SCRIPT_##] -->', NETWORK.MP_SCRIPT);
        modifiedData = modifiedData.replace('<!-- [##_MP_FUNCTION_##] -->', `
        <script>
        var __iecEnd = false;
        function iecApiCall(path, message = 'Call') {
          if(path) {
            let ApiCall = new XMLHttpRequest();
            ApiCall.open("POST", "https://api-analytics.monsterplayable.com/iec-entries/" + path);
            ApiCall.setRequestHeader("Accept", "application/json");
            ApiCall.setRequestHeader("Content-Type", "application/json");
            ApiCall.send(JSON.stringify(dataToSend));
          } else {
            newLog('Event: ', message);
          }
        }

        let dataToSend = {
          device: {
            language: window.navigator.language,
            languages: window.navigator.languages,
            platform: window.navigator.platform,
            userAgent: window.navigator.userAgent,
          },
          iec: {
            iecId: '${playable.id}',
            userId: '${playable.userId}',
            creativeType: '${playable.type}',
            accountId: '${playable.accountId}',
            applicationId: '${application.id}',
            network: '${NETWORK ? NETWORK.name : 'null'}',
          },
        };
          

        class EventEmitter {
          _events = {};
        
          emit(event) {
            if (this._events[event]) {
              this._events[event].forEach((e) => e());
            }
          }
        
          addListener(event, callback) {
            if (!this._events[event]) {
              this._events[event] = [callback];
            } else if (!this._events[event].includes(callback)) {
              this._events[event].push(callback);
            }
          }
        
          removeListener(event, callback) {
            if (this._events[event]) {
              const index = this._events[event].indexOf(callback);
              if (index >= 0) {
                this._events[event].splice(index, 1);
              }
            }
          }
        }

        var _networkSDK = null;
        var _appInfo = ${JSON.stringify(playable)};
        var _events = new EventEmitter();
        var Events = ${JSON.stringify(Events)}
        
        function isIos() {
          return /iPhone|iPad|iPod|Macintosh|MacIntel|Mac/i.test(navigator.userAgent);
        }

        function defineStoreUrl() {
          if (isIos()) {
            _appInfo.store = {
              url: _appInfo.appStore,
            }
          } else {
            _appInfo.store = {
              url: _appInfo.playStore,
            }
          }
        }
        defineStoreUrl();

        if('${NETWORK.name}' === 'tiktok') {
          function iecDisplayApiCallOneShot() {
            window.removeEventListener('click', iecDisplayApiCallOneShot);
            iecApiCall('iec-displayed', null);
          }
          window.addEventListener('click', iecDisplayApiCallOneShot);
        } else {
          iecApiCall('iec-displayed', null);
        }

        function onReady(){
          ${NETWORK && NETWORK.onReady.toString().replace(/^[^{]*{\s*/, '').replace(/\s*}[^}]*$/, '')}
        }

        function onResize(){
          ${NETWORK && NETWORK.onResize.toString().replace(/^[^{]*{\s*/, '').replace(/\s*}[^}]*$/, '')}
        }

        function onVolumeChange(){
          ${NETWORK && NETWORK.onVolumeChange.toString().replace(/^[^{]*{\s*/, '').replace(/\s*}[^}]*$/, '')}
        }

        window.monsterplayable.injected.network.onWindowLoad = function () {
          ${NETWORK && NETWORK.onWindowLoad.toString().replace(/^[^{]*{\s*/, '').replace(/\s*}[^}]*$/, '')}
        };

        window.monsterplayable.injected.network.install = function () {
          if(!__iecEnd){
            __iecEnd = true;
            iecApiCall('iec-end', null);
          }
          ${NETWORK && NETWORK.install.toString().replace(/^[^{]*{\s*/, '').replace(/\s*}[^}]*$/, '')}
        };

        window.monsterplayable.injected.network.gameReady = function () {
          ${NETWORK && NETWORK.gameReady.toString().replace(/^[^{]*{\s*/, '').replace(/\s*}[^}]*$/, '')}
        };

        window.monsterplayable.injected.network.gameEnd = function () {
          ${NETWORK && NETWORK.gameEnd.toString().replace(/^[^{]*{\s*/, '').replace(/\s*}[^}]*$/, '')}
        };

        window.monsterplayable.injected.network.sendMsg = function (msg) {
          console.warn(msg);
        };

        window.monsterplayable.injected.network.getScreenSize = function () {
          ${NETWORK && NETWORK.getScreenSize.toString().replace(/^[^{]*{\s*/, '').replace(/\s*}[^}]*$/, '')}
        };

        window.monsterplayable.injected.network.getAudioVolume = function () {
          ${NETWORK && NETWORK.getAudioVolume.toString().replace(/^[^{]*{\s*/, '').replace(/\s*}[^}]*$/, '')}
        };
          
        </script>
        `);
        if (NETWORK && NETWORK.preloader) {
          modifiedData = modifiedData.replace('</head>', `<style>
              :root {
                --base64-image: url('${logoBase64}');
              }

              #preloader {
                z-index: 1000;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                height: 100vh;
                background-image: var(--base64-image);
                background-size: cover;
                background-position: center;
              }

              #blurScreen {
                backdrop-filter: blur(45px);
                width: 100vw;
                height: 100vh;
                position: fixed;
                z-index: -1;
                background-color: rgba(255, 255, 255, 0.2);
              }

              .loader {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                top: 20%;
              }

              #gameIcon {
                width: 116px;
                height: 116px;
                background-color: orangered;
                border-radius: 26px;
                border: 7px solid #FFFFFF;
                animation-name: spaceboots;
                -webkit-animation-name: spaceboots;
                animation-duration: 1.4s;
                -webkit-animation-duration: 1.4s;
                transform-origin: 50% 50%;
                -webkit-transform-origin: 50% 50%;
                animation-iteration-count: infinite;
                -webkit-animation-iteration-count: infinite;
                animation-timing-function: alternate;
                -webkit-animation-timing-function: linear;
                background-image: var(--base64-image);
                background-size: cover;
                background-position: center;
              }

              #gameTitle {
                margin-top: 25px;
                text-align: center;
                font-family: "Abel", sans-serif;
                font-size: 25px;
                font-weight: bold;
                letter-spacing: 2px;
                color: white;
              }

              @-webkit-keyframes spaceboots {
                0% {
                  -webkit-transform: translate(0px, 0px) rotate(-1deg);
                }

                50% {
                  -webkit-transform: translate(0px, 4px) rotate(1deg);
                }

                100% {
                  -webkit-transform: translate(0px, 0px) rotate(-1deg);
                }
              }

              #loader {
                border: 8px solid rgba(200, 200, 200, 0.4);
                border-top: 8px solid #ffffff;
                border-radius: 60%;
                width: 80px;
                height: 80px;
                animation: spin 2s linear infinite;
                margin-top: 25px;
              }

              @keyframes spin {
                0% {
                  transform: rotate(0deg);
                }

                100% {
                  transform: rotate(360deg);
                }
              }
            </style>
  </head>`);
          modifiedData = modifiedData.replace('<!-- [##_MP_PRELOADER_##] -->', `
            <div id="preloader">
              <div id="blurScreen"></div>
              <div class="loader">
                <div id="gameIcon"></div>
                <span id="gameTitle">${application.name}</span>
              </div>
              <div id="loader"></div>
            </div>
          `);
        }

        const file = new Blob([modifiedData], { type: 'text/plain' });

        if (network.value === 'mintegral') {
          const MintegralJSZip = new JSZip();
          MintegralJSZip.file(`${generateExportName(
            {
              crea_name: playable.name.toLocaleUpperCase().replaceAll(' ', '_'),
              app_name: application.name.toLocaleUpperCase().replaceAll(' ', '_'),
              key: application.key.toUpperCase(),
              type: 'P',
              version: playable.version?.toString() || '0.0.0',
              network: network.value.toUpperCase(),
              platform: exportPlatform(platform),
            },
            exportFormatString,
          )}.html`, file);
          zip.file(`${generateExportName(
            {
              crea_name: playable.name.toLocaleUpperCase().replaceAll(' ', '_'),
              app_name: application.name.toLocaleUpperCase().replaceAll(' ', '_'),
              key: application.key.toUpperCase(),
              type: 'P',
              version: playable.version?.toString() || '0.0.0',
              network: network.value.toUpperCase(),
              platform: exportPlatform(platform),
            },
            exportFormatString,
          )}.zip`, MintegralJSZip.generateAsync({ type: 'blob' }));
        } else if (network.value === 'meta') {
          if (selectedNetwork.length === 1) {
            zip.file('index.html', file);
          } else {
            const MintegralJSZip = new JSZip();
            MintegralJSZip.file('index.html', file);
            zip.file(`${generateExportName(
              {
                crea_name: playable.name.toLocaleUpperCase().replaceAll(' ', '_'),
                app_name: application.name.toLocaleUpperCase().replaceAll(' ', '_'),
                key: application.key.toUpperCase(),
                type: 'P',
                version: playable.version?.toString() || '0.0.0',
                network: network.value.toUpperCase(),
                platform: exportPlatform(platform),
              },
              exportFormatString,
            )}.zip`, MintegralJSZip.generateAsync({ type: 'blob' }));
          }
        } else if (network.value === 'tiktok') {
          const jsonContent = '{"playable_orientation": 0}';
          if (selectedNetwork.length === 1) {
            zip.file('index.html', file);
            zip.file('config.json', jsonContent);
          } else {
            const MintegralJSZip = new JSZip();
            MintegralJSZip.file('index.html', file);
            MintegralJSZip.file('config.json', jsonContent);
            zip.file(`${generateExportName(
              {
                crea_name: playable.name.toLocaleUpperCase().replaceAll(' ', '_'),
                app_name: application.name.toLocaleUpperCase().replaceAll(' ', '_'),
                key: application.key.toUpperCase(),
                type: 'P',
                version: playable.version?.toString() || '0.0.0',
                network: network.value.toUpperCase(),
                platform: exportPlatform(platform),
              },
              exportFormatString,
            )}.zip`, MintegralJSZip.generateAsync({ type: 'blob' }));
          }
        } else {
          const fileName = `${generateExportName(
            {
              crea_name: playable.name.toLocaleUpperCase().replaceAll(' ', '_'),
              app_name: application.name.toLocaleUpperCase().replaceAll(' ', '_'),
              key: application.key.toUpperCase(),
              type: 'P',
              version: playable.version?.toString() || '0.0.0',
              network: network.value.toUpperCase(),
              platform: exportPlatform(platform),
            },
            exportFormatString,
          )}.html`;
          zip.file(fileName, file);
        }
      } catch (error) {
        console.error('Error downloading or modifying the file:', error);
      }
    }
    await zip.generateAsync({ type: 'blob' })
      .then(async (content) => {
        const formData = new FormData();
        formData.append('export', content);
        const file = await creativeExportsService.uploadExport(formData);
        const name = generateExportName(
          {
            crea_name: playable.name.toLocaleUpperCase().replaceAll(' ', '_'),
            app_name: application.name.toLocaleUpperCase().replaceAll(' ', '_'),
            key: application.key.toUpperCase(),
            type: 'P',
            version: playable.version?.toString() || '0.0.0',
            platform: exportPlatform(platform),
            network: selectedNetwork.length === 1 ? selectedNetwork[0].value.toUpperCase() : '',
          },
          exportFormatString,
        );
        const newCreativeExport: MCreativeExport = {
          ...new MCreativeExport(),
          name,
          creativeId: playable.id,
          networks: selectedNetwork,
          Export: file.data,
          platform: exportPlatform(platform),
        };
        await addCreativeExports(newCreativeExport, content, platform, name);
      });
  }
}
