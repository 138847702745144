import { Image } from 'antd';

import './step-two.scss';
import {
  Installation1, Installation2, Installation3,
} from '../../../../../../assets/images/installation-images';

export function StepTwo() {
  const steps = [
    {
      title: '1) Open package Manager',
      image: Installation1,
    },
    {
      title: '2) Select from disc',
      image: Installation2,
    },
    {
      title: '3) Open the Monster Playable folder, Select the package.json',
      image: Installation3,
    },
  ];

  return (
    <div id="step-two">
      <p>To add the Monster Playable package to your game:</p>
      <div className="d-flex justify-content-around">
        {
          steps.map((step, index) => (
            <div key={index} className="d-block text-center">
              <p>{step.title}</p>
              <Image
                width={200}
                height={200}
                style={{ objectFit: 'contain' }}
                src={step.image}
              />
            </div>
          ))
        }
      </div>
    </div>
  );
}
