import { Slider } from 'antd';

import { MCreativeLoop } from '../../../../../../modeles';
import { useIec } from '../../../../context/iec.context';
import { YcCard } from '../../../../../../components';
import { SettingCompBackground } from '../setting-comp-background';
import { getIconByType } from '../../../../utils/iec.utils';
import { IecInputNumber } from '../../../input-number';

import './setting-loop.scss';

export function SettingLoop(props: {
  loop: MCreativeLoop
}) {
  const uIec = useIec();

  const getDuration = () => {
    for (const v of uIec.iec.iecData.videos) {
      if (v.loops.indexOf(props.loop) !== -1) {
        return v.videoDuration;
      }
    }
    return 0;
  };

  const sliderOnChange = (value: number[]) => {
    if (!(props.loop.timeStart === +value[0].toFixed(2))) {
      props.loop.displayedTime = +value[0].toFixed(2);
    } else {
      props.loop.displayedTime = +value[1].toFixed(2);
    }
    props.loop.timeStart = +value[0].toFixed(2);
    props.loop.timeEnd = +value[1].toFixed(2);
    uIec.refreshIec(false);
  };

  const sliderOnAfterChange = (value: number[]) => {
    props.loop.displayedTime = null;
    props.loop.timeStart = +value[0].toFixed(2);
    props.loop.timeEnd = +value[1].toFixed(2);
    uIec.refreshIec(true);
  };

  return (
    <YcCard title="Loop" icon={getIconByType('loop')} open canOpenAndClose={false}>
      <div className="setting-loop">
        <SettingCompBackground background={props.loop.background} isDeletable={false} />
        <YcCard title="Loop" open light>
          <div className="row">
            <div className="col-2">
              <IecInputNumber label="Start" field="timeStart" obj={props.loop} step={0.01} />
            </div>
            <div className="col-8">
              <Slider
                className="mt-4"
                range
                value={[props.loop.timeStart, props.loop.timeEnd]}
                step={0.01}
                max={getDuration()}
                onChange={(t) => { sliderOnChange(t); }}
                onAfterChange={(t) => { sliderOnAfterChange(t); }}
              />
            </div>
            <div className="col-2">
              <IecInputNumber label="End" field="timeEnd" obj={props.loop} step={0.01} />
            </div>
          </div>
        </YcCard>
      </div>
    </YcCard>
  );
}
