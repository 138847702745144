import { MAccount } from './account.m';
import { MDocument } from './document.m';
import { MUser } from './user.m';

export class MCustomFont {
  id: number = 0;
  accountId?: number;
  userId?: number;
  documentId?: number;
  isPrivate: boolean = true;
  name: string = '';
  type: string = '';
  createdAt: Date = new Date();
  updatedAt: Date = new Date();

  Account?: MAccount;
  User?: MUser;
  Document?: MDocument;
}
