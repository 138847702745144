/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import { MCustomFont } from '../../../modeles';
import { customFontService } from '../../../services';

export interface FormatedFontType {
  key: string,
  value: string,
}

export interface iecSettingsContextType {
  fonts: MCustomFont[],

  getFormatFonts: () => FormatedFontType[] | undefined;
  addNewFont: (customFont: MCustomFont) => void;
}

const IecSettingsContext = React.createContext<iecSettingsContextType>({
  fonts: [new MCustomFont()],

  getFormatFonts: () => { return undefined; },
  addNewFont: () => { },
});

export function IecSettingsContextProvider({ children }: { children: React.ReactNode }) {
  const [fonts, _setFonts] = useState<MCustomFont[]>([new MCustomFont()]);

  useEffect(() => {
    customFontService.listing().then((listing) => {
      _setFonts(listing || []);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fonts?.forEach((font) => {
      if (font.Document?.url) {
        const fontFace = new FontFace(font.name, `url(${font.Document.url})`);
        document.fonts.add(fontFace);
        fontFace.load();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fonts]);

  // GETTER //
  const getFormatFonts = useCallback((): FormatedFontType[] => {
    return fonts.map((font) => {
      return { key: font.name, value: font.id.toString() };
    });
  }, [fonts]);

  // Functions //
  const addNewFont = useCallback(async (customFont: MCustomFont): Promise<void> => {
    await customFontService.add(customFont).then((data) => {
      if (data) {
        _setFonts([...fonts, data]);
      }
    });
  }, [fonts]);

  const value = useMemo(() => ({
    fonts,
    getFormatFonts,
    addNewFont,
  }), [
    fonts,
    getFormatFonts,
    addNewFont,
  ]);

  return (
    <IecSettingsContext.Provider value={value}>
      {children}
    </IecSettingsContext.Provider>
  );
}

export function useIecSettings() {
  const context = React.useContext(IecSettingsContext);
  if (!context) throw new Error('No IecSettingsContext provider found!');
  return context;
}
