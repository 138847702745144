import { MCreativeEndcard } from '../../../../../../modeles';
import { SettingCompBackground } from '../setting-comp-background';
import { YcCard } from '../../../../../../components';

import './setting-endcard.scss';
import { getIconByType } from '../../../../utils/iec.utils';

export function SettingEndcard(props: {
  endcard: MCreativeEndcard
}) {
  return (
    <YcCard title="Endcard" icon={getIconByType('endcard')} open canOpenAndClose={false}>
      <div className="setting-endcard">
        <SettingCompBackground background={props.endcard.background} isDeletable={false} />
      </div>
    </YcCard>
  );
}
