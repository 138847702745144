import {
  Button, Form, Input, Switch, Table, Tabs, Tooltip, Tree,
} from 'antd';
import { DataNode, TreeProps } from 'antd/es/tree';
import { ColumnType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  CheckOutlined, CloseOutlined, EditOutlined, UserSwitchOutlined,
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCrown } from '@fortawesome/pro-regular-svg-icons';
import TextArea from 'antd/lib/input/TextArea';

import {
  MpPermissions, YcCard, YcTitle, YcUserModal,
  FormAddress,
} from '../../../../components';
import { useAuths, useUserEdited } from '../../../../contexts';
import { MAccount, IPermission, MUser } from '../../../../modeles';
import { adminAccountsService, adminUsersService, permissionService } from '../../../../services';
import { AccountStatsPage } from '../../components';

import './account.page.scss';

export function AccountPage() {
  const uAuths = useAuths();
  const uUserEdited = useUserEdited();
  const uParams = useParams<{ id: string }>();

  const [account, _setAccount] = useState<MAccount | null>(null);
  const [customerPermissions, _setCustomerPermissions] = useState<IPermission>({});

  useEffect(() => {
    adminAccountsService.view(parseInt(uParams.id, 10)).then((data) => {
      _setAccount(data as MAccount);
    });

    permissionService.customerPermissions().then((permissions) => {
      _setCustomerPermissions(permissions || {});
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listFormObj = (perms: IPermission, parentKey?: string): DataNode[] => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return Object.keys(perms).map((permission) => {
      const key = parentKey ? `${parentKey}/${permission}` : permission;
      return {
        title: permission,
        key,
        // @ts-ignore
        children: perms[permission].children ? listFormObj(perms[permission].children, key) : [],
      };
    });
  };

  const columns: ColumnType<any>[] = [
    {
      title: 'Active',
      key: 'isActive',
      render: (user: MUser) => (
        <Switch
          disabled={user.id === uAuths.user?.id}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          defaultChecked={user.isActive}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={async (e) => {
            user.isActive = e;
            await adminUsersService.isActive(user.id, e);
          }}
        />
      ),
    }, {
      title: 'Email',
      key: 'email',
      render: (user: MUser) => (
        <label>{user.email}</label>
      ),
    }, {
      title: 'Creation date',
      key: 'createdAt',
      render: (user: MUser) => (
        <label>{new Date(user.createdAt!).toLocaleString()}</label>
      ),
    }, {
      title: 'Admin',
      key: 'isAdmin',
      render: (user: MUser) => (
        <Switch
          disabled={user.id === uAuths.user?.id}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          defaultChecked={user.isAdmin}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={async (e) => {
            user.isAdmin = e;
            await adminUsersService.isAdmin(user.id, e);
          }}
        />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (user: MUser) => (
        <div className="d-flex justify-content-around">
          <Button type="primary" icon={<EditOutlined />} size="middle" shape="circle" onClick={() => { uUserEdited.setUser(user.id); }} />
          <Tooltip title="Log as user">
            <Button
              danger
              icon={<UserSwitchOutlined />}
              size="middle"
              shape="circle"
              onClick={() => { uAuths.logAs(user.id); }}
            />
          </Tooltip>
          <Tooltip title="Log as S-Admin">
            <Button
              danger
              type="primary"
              icon={<FontAwesomeIcon icon={faUserCrown} title="Font weight" />}
              size="middle"
              shape="circle"
              onClick={() => { uAuths.logAs(user.id, true); }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const accountPermissionsEdited = (permissions: IPermission) => {
    _setAccount({ ...account!, permissions });
  };

  return (
    <div id="account">
      {account
        && (
          <Form
            layout="vertical"
            onFinish={(e) => {
              adminAccountsService.edit(parseInt(uParams.id, 10), { ...e, permissions: account.permissions } as MAccount);
            }}
          >
            <YcTitle
              links={[{
                link: '/admin/accounts',
                label: 'Accounts',
              }]}
              label={`Account (${account.name})`}
            />
            <Tabs items={[
              {
                key: '1',
                label: 'Infos',
                children:
                  // eslint-disable-next-line react/jsx-indent
                  <div className="row">
                    <div className="col-xl-4 col-lg-6">
                      <YcCard title="Informations" open canOpenAndClose={false}>
                        <Form.Item label="Description" name="description" initialValue={account?.description}>
                          <TextArea rows={10} />
                        </Form.Item>
                      </YcCard>
                    </div>
                    <div className="col-xl-4 col-lg-6">
                      <YcCard title="General" open canOpenAndClose={false} canEdit>
                        <Form.Item label="Name" name="name" initialValue={account?.name}>
                          <Input />
                        </Form.Item>
                        <Form.Item label="Export Format" name="exportFormat" initialValue={account?.exportFormat}>
                          <Input />
                        </Form.Item>
                        <Form.Item label="Is email vaidated" name="mailValidated" valuePropName="checked" initialValue={account.mailValidated}>
                          <Switch />
                        </Form.Item>
                      </YcCard>
                    </div>
                    <div className="col-xl-4 col-lg-6">
                      <YcCard title="Address" open canOpenAndClose={false} canEdit>
                        <FormAddress address={account?.Address} />
                      </YcCard>
                    </div>
                    <div className="col-xl-4 col-lg-6">
                      <YcCard title="Permissions" open canOpenAndClose={false} canEdit>
                        <div className="col-12">
                          <MpPermissions
                            allowedPermissions={customerPermissions}
                            editedPermissions={account.permissions || {}}
                            permissionsChanged={accountPermissionsEdited}
                          />
                        </div>
                      </YcCard>
                    </div>
                    <Button type="primary" htmlType="submit" style={{ width: '120px' }}>Update</Button>
                  </div>,
              },
              {
                key: '2',
                label: 'Users',
                children: <Table columns={columns} dataSource={account.Users} pagination={false} rowKey="id" />,
              },
              {
                key: '3',
                label: 'Stats',
                children: <AccountStatsPage accountId={account.id} />,
              },
            ]}
            />
          </Form>
        )}
      <YcUserModal />
    </div>
  );
}
