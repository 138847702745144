import { Link } from 'react-router-dom';

import * as ImagesFooter from '../../assets/images/footer';

import './footer.scss';

export function Footer() {
  return (
    <div id="footer">
      <img src={ImagesFooter.BACKGROUND} className="background" alt="background footer" />
      <img src={ImagesFooter.MONSTER} className="monster" alt="left monster" />
      <div className="content">
        <div>
          <img className="logo" title="Monster Playable" alt="Logo Monster Playable" src="/logo-Monster-Playable-white.svg" />
          <br />
          <div className="copy-text">Copyright ©2013-2024 Monster Playable | All rights reserved</div>
          <Link to="/privacy-policies">Privacy policies</Link>
          <a href="mailto:contact@monsterplayable.com">Contact Us</a>
          {/* DO NOT DELETE */}
          {/* <a href="https://discord.gg/MbrcV84y" target="_blank" rel="noreferrer">
            <img src={ImagesFooter.DISCORD} width={32} alt="discord" />
          </a> */}
        </div>
      </div>
    </div>
  );
}
