import { Form, Input } from 'antd';
import { MAddress } from '../../modeles';

import './form-address.scss';

export function FormAddress(props: {
  address?: MAddress,
}) {
  return (
    <div className="form-address">
      <div className="row">
        <div className="col-12">
          <Form.Item label="Address" name={['address', 'address']} initialValue={props.address?.address}>
            <Input />
          </Form.Item>
        </div>
        <div className="col-12">
          <Form.Item label="Additional information" name={['address', 'additional']} initialValue={props.address?.additional}>
            <Input />
          </Form.Item>
        </div>
        <div className="col-2">
          <Form.Item label="Zip Code" name={['address', 'zipcode']} initialValue={props.address?.zipcode}>
            <Input />
          </Form.Item>
        </div>
        <div className="col-5">
          <Form.Item label="City" name={['address', 'city']} initialValue={props.address?.city}>
            <Input />
          </Form.Item>
        </div>
        <div className="col-5">
          <Form.Item label="Country" name={['address', 'country']} initialValue={props.address?.country}>
            <Input />
          </Form.Item>
        </div>
      </div>
    </div>
  );
}
