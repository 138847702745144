import { Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { MAccount } from '../../../../modeles';
import { adminAccountsService } from '../../../../services';

import './invoices-generator.page.scss';

export function InvoicesGeneratorPage() {
  const [accounts, _setAccounts] = useState<MAccount[]>([]);

  useEffect(() => {
    adminAccountsService.listing().then((data) => {
      _setAccounts(data || []);
    });
  }, []);

  const columns: ColumnType<MAccount>[] = [
    {
      title: 'ID',
      key: 'id',
      render: (account: MAccount) => (
        <Link to={`#${account.id}`}>
          #
          {account.id}
        </Link>
      ),
    },
    {
      title: 'Name',
      key: 'name',
      render: (account: MAccount) => (
        <Link to={`#${account.id}`}>{account.name}</Link>
      ),
    },
    {
      title: 'Creation date',
      key: 'createdAt',
      render: (account: MAccount) => (
        <label>{new Date(account.createdAt!).toLocaleString()}</label>
      ),
    },
  ];

  return (
    <div id="accounts">
      <Table
        bordered
        size="small"
        dataSource={accounts}
        columns={columns}
        rowKey="id"
      />
    </div>
  );
}
