import { useEffect, useState } from 'react';
import { DataNode, TreeProps } from 'antd/es/tree';
import { Tree } from 'antd';

import { IPermission } from '../../modeles';

import './permissions.scss';

export function MpPermissions(props: {
  allowedPermissions: IPermission,
  editedPermissions: IPermission,
  permissionsChanged: (permissions: IPermission) => void,
}) {
  const [allowedPermissions, _setAllowedPermissions] = useState<DataNode[]>([]);
  const [editedPermissionsKey, _setEditedPermissionsKey] = useState<string[]>([]);

  useEffect(() => {
    _setEditedPermissionsKey(getListPermissions(props.editedPermissions));
    _setAllowedPermissions(listFormObj(props.allowedPermissions));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.allowedPermissions, props.editedPermissions]);

  const getListPermissions = (permissions: IPermission): string[] => {
    const list: string[] = [];
    loopPermission(permissions, list);
    return list || [];
  };

  const loopPermission = (permissions: IPermission, list: string[], parentKey?: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    Object.keys(permissions).forEach((permission) => {
      const key = parentKey ? `${parentKey}/${permission}` : permission;
      if (permissions[permission].active) {
        list.push(key);
      }
      if (permissions[permission].children) {
        loopPermission(permissions[permission].children!, list, key);
      }
    });
  };

  const listFormObj = (perms: IPermission, parentKey: string = ''): DataNode[] => {
    return Object.entries(perms).map(([permission, permissionData]) => {
      const key = parentKey ? `${parentKey}/${permission}` : permission;
      const children = permissionData.children ? listFormObj(permissionData.children, key) : [];
      return {
        title: permission,
        key,
        children,
      };
    });
  };

  const onCheckPermission: TreeProps['onCheck'] = (checkedKeys, info) => {
    const key = info.node.key as string;
    if (editedPermissionsKey.includes(key)) {
      _setEditedPermissionsKey(editedPermissionsKey.filter((perm) => perm !== info.node.key));
    } else {
      _setEditedPermissionsKey([...editedPermissionsKey, key]);
    }
    updatePermission(props.editedPermissions as IPermission, key.split('/') || []);
  };

  const updatePermission = (permissions: IPermission, access: string[]) => {
    let permission = permissions;
    for (let index = 0; index < access.length - 1; index += 1) {
      permission = permission[access[index]].children as IPermission;
    }

    const perm = access.pop() as string;
    permission[perm].active = !permission[perm].active;

    props.permissionsChanged(permissions);
  };

  return (
    <div className="mp-permissions">
      <Tree
        checkable
        defaultExpandAll
        checkStrictly
        treeData={allowedPermissions}
        onCheck={onCheckPermission}
        checkedKeys={editedPermissionsKey}
      />
    </div>
  );
}
