import { useIec } from '../../../../context/iec.context';
import { MPLibrary, YcCard } from '../../../../../../components';
import {
  MCompImage, MDocument, ObjectFitTypeValues, ObjectFlipTypeValues,
} from '../../../../../../modeles';
import { IecSelect } from '../../../select';

import './setting-comp-image.scss';

export function SettingCompImage(props: {
  image: MCompImage
}) {
  const uIec = useIec();

  const getDefault = (key: string): any => {
    return (props.image as any)[key] || undefined;
  };

  return (
    <YcCard
      title="image"
      light
      open={props.image._open}
      onClickTitle={(val) => {
        props.image._open = val;
        uIec.refreshIec(true);
      }}
      onDelete={() => {
        uIec.removeComponentNodeEdite(props.image);
      }}
    >
      <div className="row">
        <div className="col-3">
          <MPLibrary
            type="image"
            label="Image"
            typeName="assets"
            folder="icon"
            path="/library/upload-icon"
            defaultValue={getDefault('file')}
            width={54}
            height={54}
            onUpload={(doc: MDocument) => {
              props.image.file = doc;
              uIec.refreshIec(true);
            }}
            maxWeight={2}
          />
        </div>
        <div className="col-3">
          <IecSelect label="Object Fit" field="objectFit" obj={props.image} values={ObjectFitTypeValues} />
        </div>
        <div className="col-3">
          <IecSelect label="Object Flip" field="objectFlip" obj={props.image} values={ObjectFlipTypeValues} />
        </div>
      </div>
    </YcCard>
  );
}
