import React, { useState, useEffect } from 'react';
import {
  Button, Collapse, CollapseProps, Upload,
} from 'antd';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';

import {
  SettingCompInputNumber,
  SettingCompInputBoolean,
  SettingCompSelect,
  SettingCompInputText,
  SettingCompInputCheckbox,
  SettingCompColor,
  SettingCompVector3,
  SettingCompVector2,
  SettingCompImage,
  SettingCompArrayInputText,
  SettingCompArrayInputNumber,
  SettingCompGameObjectRef,
  IGameObject,
  SettingCompSelectLayerMask,
} from '../../sub-settings';
import { usePlayable } from '../../../../context/playable.context';
import { IColorJson } from '../../../../../../modeles/MP_Interfaces';
import { SettingCompAudioSource } from '../setting-comp-audio-source';
import { SettingCompTransform } from '../setting-comp-transform';
import { IVariable } from './setting-comp-script';

import './setting-comp-script.scss';

const componentsMap: { [key: string]: { Component: any, valueField: string } } = {
  boolean: { Component: SettingCompInputBoolean, valueField: 'checked' },
  number: { Component: SettingCompInputNumber, valueField: 'value' },
  string: { Component: SettingCompInputText, valueField: 'value' },
  Vector3: { Component: SettingCompVector3, valueField: 'vector' },
  Vector2: { Component: SettingCompVector2, valueField: 'vector' },
  Color: { Component: SettingCompColor, valueField: 'color' },
  LayerMask: { Component: SettingCompSelectLayerMask, valueField: 'value' },
  // Transform: { Component: SettingCompTransform, valueField: '' },
  // AudioSource: { Component: SettingCompAudioSource, valueField: '' },
  // 'Image[]': { Component: SettingCompImage, valueField: '' },
  'string[]': { Component: SettingCompArrayInputText, valueField: 'value' },
  'number[]': { Component: SettingCompArrayInputNumber, valueField: 'value' },
  'List<number>': { Component: SettingCompArrayInputNumber, valueField: 'value' },
  'List<string>': { Component: SettingCompArrayInputText, valueField: 'value' },
};

const notSupported = [
  'UnityEvent',
  'Material',
  'SpriteRenderer[]',
  'TextMeshPro[]',
  'Texture2D',
  'Light',
  'Mesh',
  'CameraClearFlags[]',
  'List<CameraClearFlags>',
  'Sprite',
];

export function SettingInputScript(props: {
  componentRunTime: any,
  variable: IVariable,
  index: number
  editComponent: (path: string, value: any, isColor: boolean) => void
  parentPath?: string[];
}) {
  const uPlayable = usePlayable();

  const formatVariableName = (name: string): string => {
    const bracketsClean = name.replaceAll('_', '');
    const firstUpper = (bracketsClean[0].toUpperCase() + bracketsClean.slice(1));
    return firstUpper.replace(/([A-Z])/g, ' $1');
  };

  const editComponent = (value: any) => {
    let path = props.variable.name;
    if (props.parentPath) {
      path = `${props.parentPath}/${path}`;
    }
    props.editComponent(path, value, props.variable.type === 'Color');
  };

  function getEnumIndices(e: any): number[] {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument
    return Object.keys(e)
      .filter((key) => typeof e[key] === 'number')
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      .map((key) => e[key]);
  }

  const displayComponent = (): React.ReactNode => {
    const variableComponent = componentsMap[props.variable.type];
    let variableValue = props.componentRunTime;

    props.parentPath?.forEach((path) => {
      variableValue = variableValue[path];
    });
    variableValue = variableValue[props.variable.name];

    if (variableComponent) {
      return (
        <variableComponent.Component
          key={props.index}
          label={formatVariableName(props.variable.name)}
          onChange={(value: any) => editComponent(value)}
          {...{ [variableComponent.valueField]: variableValue }}
        />
      );
    }

    if (notSupported.includes(props.variable.type)) {
      return (
        <SettingCompInputText
          key={props.index}
          disabled
          label={props.variable.name ? formatVariableName(props.variable.name) : props.variable.componentId || 'null'}
          value={props.variable.type || props.variable.value}
          onChange={(value: any) => editComponent(value)}
        />
      );
    }

    if (Array.isArray(props.variable.value)) {
      const tab = [{
        key: 1,
        label: props.variable.name,
        children: props.variable.value.map((item, index) => {
          const newParentPath = [...(props.parentPath || []), props.variable.name];
          return (
            <SettingInputScript
              key={index}
              componentRunTime={props.componentRunTime}
              variable={item}
              index={index}
              editComponent={(path: string, value: any) => { props.editComponent(path, value, item.type === 'Color'); }}
              parentPath={newParentPath}
            />
          );
        }),
      }];
      return (
        <Collapse ghost items={tab} />
      );
    }

    if (props.variable.type !== 'GameObject' && !props.variable.isRef && !!props.variable.type) {
      let pathArray = [];
      if (props.variable.type?.includes('.')) {
        pathArray = props.variable.type.split('.');
      } else {
        pathArray = [props.variable.type];
      }

      const scripts = uPlayable.playableWindow.monsterplayable.scripts;
      let objectType: string[] = [];
      if (scripts) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        objectType = pathArray.reduce((obj, key) => obj && obj[key], scripts);
      }

      if (!objectType) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        objectType = pathArray.reduce((obj, key) => obj && obj[key], uPlayable.playableWindow.monsterplayable.classes);
      }

      const indices = getEnumIndices(objectType);
      return (
        <SettingCompSelect
          key={props.index}
          options={indices.map((item) => {
            return { label: objectType[item], value: item };
          })}
          value={variableValue}
          label={props.variable.name ? formatVariableName(props.variable.name) : 'undefined'}
          onChange={(value: any) => {
            return editComponent(value);
          }}
          disabled={objectType.length > 0}
        />
      );
    }
    if (props.variable.type === 'GameObject' || props.variable.isRef) {
      return (
        <SettingCompGameObjectRef gameObject={props.variable as IGameObject} />
      );
    }
    return (
      <SettingCompInputText
        key={props.index}
        disabled
        label={props.variable.name ? formatVariableName(props.variable.name) : props.variable.componentId || 'null'}
        value={props.variable.type || props.variable.value}
        onChange={(value: any) => editComponent(value)}
      />
    );
  };

  return (
    <div>
      {
        displayComponent()
      }
    </div>
  );
}
