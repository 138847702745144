import qs from 'qs';
import dayjs from 'dayjs';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Form, Spin } from 'antd';
import { useEffect, useState } from 'react';

import { YcFieldCheckPage, FCItem, YcDatePicker } from '../../../../components';
import { useAuths } from '../../../../contexts/auths.context';

import './reporting-app-filter.component.scss';

interface Filters {
  applications: FCItem[];
  users: FCItem[];
  creativeTypes: FCItem[];
  granularities: FCItem[];

  isGranularity: boolean;
  isApplication: boolean;
  isUser: boolean;
  isCreativeType: boolean;
}

export function ReportingAppFilter(props: {
  onSearch: (data: any) => void;
  service: any,
  accountId: number,
}) {
  const uLocation = useLocation();
  const uHistory = useHistory();
  const uAuths = useAuths();

  const [filters, _setFilters] = useState<Filters | null>(null);
  const [dates, _setDates] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([dayjs(), dayjs()]);
  const [loading, _setLoading] = useState<boolean>(false);

  const SetParamsFilter = (items: FCItem[], paramsString: string) => {
    if (paramsString) {
      const params = paramsString.split(',');
      items.forEach((item) => {
        if (params.includes(`${item.value}-Y`)) {
          item.checked = true;
        } else if (params.includes(`${item.value}-N`)) {
          item.checked = false;
        }
      });
    }
  };

  useEffect(() => {
    _setLoading(true);
    const search = new URLSearchParams(uLocation.search);
    if (search.has('dateStart')) {
      dates[0] = dayjs(search.get('dateStart'));
    }
    if (search.has('dateEnd')) {
      dates[1] = dayjs(search.get('dateEnd'));
    }
    props.service.filtersApplication(props.accountId).then((data: Filters) => {
      _setLoading(false);
      if (data) {
        SetParamsFilter(data.granularities, search.get('granularities')!);
        SetParamsFilter(data.applications, search.get('applications')!);
        SetParamsFilter(data.users, search.get('users')!);
        SetParamsFilter(data.creativeTypes, search.get('creativeTypes')!);
        if (search.has('is')) {
          const is = search.get('is')!.split(',');
          data.isGranularity = is.includes('granularity');
          data.isApplication = is.includes('applicationId');
          data.isUser = is.includes('userId');
          data.isCreativeType = is.includes('creativeType');
        }
        _setFilters(data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getParamsFilter = (items: FCItem[]) => {
    const results = items.filter((item) => item.checked !== undefined);
    return results.map((item) => `${item.value}-${item.checked ? 'Y' : 'N'}`).join() || undefined;
  };
  const getParamsIs = () => {
    const is: string[] = [];
    if (filters?.isGranularity) { is.push('granularity'); }
    if (filters?.isApplication) { is.push('applicationId'); }
    if (filters?.isUser) { is.push('userId'); }
    if (filters?.isCreativeType) { is.push('creativeType'); }
    if (is.length) {
      return is.join(',');
    }
    return undefined;
  };

  const getParams = (stringify: boolean) => {
    const b = {
      dateStart: dayjs(dates[0]).startOf('day'),
      dateEnd: dayjs(dates[1]).endOf('day'),
      granularity: filters?.isGranularity ? filters.granularities.find((g) => g.checked === true)?.value : undefined,
      applications: getParamsFilter(filters!.applications),
      users: getParamsFilter(filters!.users),
      creativeTypes: getParamsFilter(filters!.creativeTypes),
      is: getParamsIs(),
    };
    if (stringify) {
      return qs.stringify(b, { encode: false });
    }
    return b;
  };

  return (
    <div id="reporting-app-filter">
      <Spin spinning={loading}>
        {!!filters
          && (
          <Form>
            <Form.Item>
              <YcDatePicker
                value={dates}
                onChange={(e) => {
                  if (e && e.length >= 2) {
                    _setDates([e[0]!, e[1]!]);
                  }
                }}
              />
            </Form.Item>
            <Form.Item>
              <YcFieldCheckPage
                is={filters.isGranularity}
                label="Granularities"
                items={filters.granularities}
                onChange={(granularities, isGranularity): void => {
                  _setFilters({ ...filters, granularities, isGranularity });
                }}
              />
            </Form.Item>
            <Form.Item>
              <YcFieldCheckPage
                is={filters.isApplication}
                label="Applications"
                items={filters.applications}
                mode="multiple"
                onChange={(applications, isApplication): void => {
                  _setFilters({ ...filters, applications, isApplication });
                }}
              />
            </Form.Item>
            <Form.Item>
              <YcFieldCheckPage
                is={filters.isUser}
                label="Users"
                items={filters.users}
                mode="multiple"
                onChange={(users, isUser): void => {
                  _setFilters({ ...filters, users, isUser });
                }}
              />
            </Form.Item>
            { (uAuths.canAccess('applications/playable') && uAuths.canAccess('applications/iec'))
              && (
              <Form.Item>
                <YcFieldCheckPage
                  is={filters.isCreativeType}
                  label="Creative Types"
                  items={filters.creativeTypes}
                  mode="multiple"
                  onChange={(creativeTypes, isCreativeType): void => {
                    _setFilters({ ...filters, creativeTypes, isCreativeType });
                  }}
                />
              </Form.Item>
              )}
            <Form.Item>
              <Button
                type="primary"
                onClick={() => {
                  uHistory.push({
                    search: getParams(true) as string,
                  });
                  props.onSearch(getParams(false));
                }}
              >
                Search
              </Button>
            </Form.Item>
          </Form>
          )}
      </Spin>
    </div>
  );
}
