import { ColumnsType } from 'antd/es/table';
import { Button, Input, Select } from 'antd';
import { useEffect, useState } from 'react';
import type { SelectProps } from 'antd';

import { LANGUAGES } from '../../../../tools';
import { MpTable } from '../../../../components';

import './translation-grid.scss';

export function TranslateGrid(props: {
  translations: any[]
  saveTranslate: (translationsCopy: any) => void,
  cancelTranslate: () => void,
}) {
  const [columns, _setColumns] = useState<ColumnsType<any>>([]);
  const [LanguagesCodes, _setLanguagesCodes] = useState<string[]>([]);
  const [translationsCopy, _setTranslationsCopy] = useState<{ [code: string]: string }[]>([]);

  useEffect(() => {
    if (props.translations) {
      _setTranslationsCopy(props.translations);
      const uniqueArrayKey:string[] = ['DEFAULT'];

      props.translations.forEach((translation: object) => {
        Object.keys(translation).forEach((key) => {
          if (!uniqueArrayKey.includes(key)) {
            uniqueArrayKey.push(key);
          }
        });
      });
      _setLanguagesCodes(uniqueArrayKey.filter((code) => code !== 'DEFAULT'));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.translations]);

  useEffect(() => {
    const newColums:ColumnsType<any> = [{
      title: 'DEFAULT',
      dataIndex: 'DEFAULT',
      key: 'DEFAULT',
      fixed: 'left',
      width: 180,
      render: (text: string, record: any) => {
        return (<label>{record.DEFAULT}</label>);
      },
    }];
    LanguagesCodes.forEach((key) => {
      newColums.push({
        title: LANGUAGES[key] ? `${LANGUAGES[key]} (${key})` : key,
        dataIndex: key,
        key,
        width: 180,
        render: (text: string, record: any) => {
          return (<Input value={record[key]} onChange={(e) => { editTranslation(record.DEFAULT as string, key, e.target.value as string); }} />);
        },
      });
    });

    _setColumns(newColums);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LanguagesCodes]);

  const editTranslation = (defaultValue: string, key: string, value: string) => {
    translationsCopy.find((elem) => elem.DEFAULT === defaultValue)![key] = value;
    _setTranslationsCopy([...translationsCopy]);
  };

  const getOptionsLANGUAGES = (): SelectProps['options'] => {
    const options: SelectProps['options'] = [];
    const countryCodes = Object.keys(LANGUAGES);
    for (const code of countryCodes) {
      const countryName = LANGUAGES[code];
      options.push({
        label: `${countryName} (${code})`,
        value: code,
      });
    }
    return options;
  };

  const handleChange = (value: string[]) => {
    _setLanguagesCodes(value);
  };

  const cleanBeforeSave = () => {
    const filteredTranslations = translationsCopy.map((translation) => {
      const filteredTranslation = {};
      for (const languageCode of [...LanguagesCodes, 'DEFAULT']) {
        if (translation[languageCode]) {
          // @ts-ignore
          filteredTranslation[languageCode] = translation[languageCode];
        }
      }
      return filteredTranslation;
    });
    props.saveTranslate(filteredTranslations);
  };

  return (
    <div className="translate-grid">
      <Select
        mode="multiple"
        allowClear
        style={{ width: '100%', marginBottom: '10px' }}
        placeholder="Select languages"
        value={LanguagesCodes}
        onChange={handleChange}
        options={getOptionsLANGUAGES()}
      />
      <MpTable
        bordered
        size="small"
        dataSource={translationsCopy}
        columns={columns}
        rowKey="DEFAULT"
        mpTableKey="translations"
        scroll={{ x: 100, y: 500 }}
        urlParamsEnabled={false}
      />
      <div className="footer">
        <label className="alert-message">Dont forget to Save!</label>
        <Button danger onClick={() => { props.cancelTranslate(); }}>cancel</Button>
        <Button type="primary" onClick={() => { cleanBeforeSave(); }}>Validate</Button>
      </div>
    </div>
  );
}
