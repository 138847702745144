import { MCompTemplatedBackground } from '../../../../../modeles';

export const PreviewCompTemplatedBackground_CSS = `
.templated-full-height { height: 100%; }

.templated-btn-1 {
  padding: 20px 32px;
  font-size: 24px;
  color: white;
  background-color: #00ca67;
  border-radius: 12px;
  box-shadow: 0px 8px 0px #00a353;
  border-bottom: solid 2px #48ffa5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all ease 0.2s;
}

.templated-btn-1:hover {
  background-color: #00E53E;
  box-shadow: 0px 8px 0px #00b21b;
  border-bottom: solid 2px #2eff27;
  color: white;
  transition: all ease 0.2s;
}

.templated-btn-1-shd {
  box-shadow: 0px 16px 0px #00000029;
  border-radius: 12px;
}


.templated-btn-2 {
  padding: 24px 32px;
  font-size: 26px;
  color: white;
  background-color: #00ca67;
  border-radius: 80px;
  box-shadow: 0px 8px 0px #00a353;
  border-bottom: solid 2px #48ffa5;
  text-shadow: 0px 2px 0px #00000029;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all ease 0.2s;
}

.templated-btn-2:hover {
  background-color: #00E53E;
  box-shadow: 0px 8px 0px #00b21b;
  border-bottom: solid 2px #2eff27;
  color: white;
  transition: all ease 0.2s;
}

.templated-btn-2-shd {
  box-shadow: 0px 16px 0px #00000029;
  border-radius: 80px;
}


.templated-btn-3 {
  padding: 24px 32px;
  font-size: 26px;
  color: white;
  background-color: #00D529;
  border-radius: 24px;
  border-bottom: 6px solid #008648;
  text-shadow: 0px 2px 2px #00000020;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all ease 0.2s;
}

.templated-btn-3:hover {
  background-color: #009b4b;
  border-radius: 24px;
  border-bottom: 6px solid #007237;
  color: white;
  transition: all ease 0.2s;
}

.templated-btn-3-shd {
  box-shadow: 0px 0px 10px #00000029;
  border-radius: 28.5px;
  padding: 4px;
  background: white;
}


.templated-btn-4 {
  padding: 24px 32px;
  font-size: 26px;
  color: white;
  background-color: #0095ff;
  border-radius: 24px;
  border-bottom: 8px solid #0065d0;
  text-shadow: 0px 2px 0px #00000029;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all ease 0.2s;
}

.templated-btn-4:hover {
  background-color: #23a4ff;
  border-radius: 24px;
  border-bottom: 8px solid #007bff;
  color: white;
  transition: all ease 0.2s;
}

.templated-btn-4-shd {
  box-shadow: 0px 0px 16px #00000029;
  border-radius: 32.5px;
  padding: 8px;
  background: white;
 }
`;

export function PreviewCompTemplatedBackground(props: {
  templatedBackground: MCompTemplatedBackground,
}) {
  return (
    <div
      style={{ height: '100%' }}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: `
          <div class="templated-btn-${props.templatedBackground.templateNb}-shd templated-full-height">
            <div class="templated-btn-${props.templatedBackground.templateNb} templated-full-height" />
          </div>
        `,
      }}
    />
  );
}
