import { MCreative } from '../../../../modeles';
import { EmptyExport, EmptyExportFont } from './empty';

/* eslint-disable jsx-a11y/html-has-lang */
export function Meta(props: {
  iec: MCreative,
  body: string,
  fonts: EmptyExportFont[],
  appInfo: any,
  NETWORK: any,
}) {
  return (
    <EmptyExport
      iec={props.iec}
      body={props.body}
      fonts={props.fonts}
      appInfo={props.appInfo}
      NETWORK={props.NETWORK}
      script={`
        try {
          _networkSDK = FbPlayableAd;
        } catch (e) {
          console.warn('FbPlayableAd not found')
        }
        iecDisplayApiCall();
      `}
    />
  );
}
