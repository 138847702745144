import {
  Form, Button, Input, Modal,
} from 'antd';
import { useState } from 'react';

import { usersService } from '../../services/users.service';
import { MUser } from '../../modeles';

import './add-user-modal.page.scss';

export function YcAddUserModalPage(props: {
  isOpen: boolean,
  close: () => void,
}) {
  const [form] = Form.useForm();
  const [isLoading, _setIsLoading] = useState<boolean>(false);

  const onFinish = () => {
    _setIsLoading(true);
    const newUser = form.getFieldsValue() as MUser;
    usersService.add(newUser).then((data) => {
      if (data) {
        props.close();
        form.resetFields();
      }
      _setIsLoading(false);
    });
  };

  const cancel = () => {
    form.resetFields();
    props.close();
  };

  return (
    <Modal
      title="New User"
      open={props.isOpen}
      onCancel={() => cancel()}
      footer={[
        <Button type="primary" ghost onClick={() => { cancel(); }}>
          Cancel
        </Button>,
        <Button type="primary" onClick={() => { onFinish(); }} loading={isLoading}>
          Create User
        </Button>,
      ]}
    >
      <Form layout="vertical" form={form}>
        <div className="row">
          <Form.Item
            className="col-12"
            label="Email"
            name="email"
            rules={[{ required: true }]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            className="col-6"
            label="Last name"
            name="lastName"
            rules={[{ required: true }]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            className="col-6"
            label="First name"
            name="firstName"
            rules={[{ required: true }]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            className="col-12"
            label="Password"
            name="password"
            rules={[{ required: true }]}
          >
            <Input.Password type="password" />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
}
