import {
  PieChart as PieC, Pie, Cell, Tooltip,
} from 'recharts';

import './pie-chart.scss';

export function PieChart(props: {
  label: string,
  data: { data: { name: string, value: number }[], total: number },
}) {
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, index,
  }: {
    cx: number;
    cy: number;
    midAngle: number;
    innerRadius: number;
    outerRadius: number;
    index: number;
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {props.data.data[index].name}
      </text>
    );
  };

  const customTooltip = (e: any) => {
    if (e.active) {
      return (
        <div className="custom-tooltip">
          <span>{`${((e.payload[0].value / props.data.total) * 100).toFixed(2)}%`}</span>
        </div>
      );
    }

    return undefined;
  };

  const COLORS = ['#943126', '#21618C', '#117864', '#1D8348', '#9A7D0A', '#212F3C'];

  return (
    <div id="pie-chart">
      <label>{props.label}</label>
      <PieC width={250} height={250}>
        <Pie
          data={props.data.data}
          dataKey="value"
          cx="50%"
          cy="50%"
          outerRadius={80}
          fill="#8884d8"
          labelLine={false}
          label={renderCustomizedLabel}
        >
          {props.data.data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]}
            />
          ))}
        </Pie>
        <Tooltip content={customTooltip} />
      </PieC>
    </div>
  );
}
