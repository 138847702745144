import {
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Spin,
  Switch,
  Upload,
  message,
} from 'antd';
import {
  RcFile, UploadChangeParam, UploadFile, UploadProps,
} from 'antd/es/upload';
import { ColumnType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import {
  CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, LoadingOutlined, PlusOutlined,
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/pro-regular-svg-icons';

import { useAuths } from '../../../../contexts';
import { MpTable } from '../../../../components';
import { MAsset, MDocument } from '../../../../modeles';
import { assetService } from '../../../../services/asset.service';
import { getUniqueList } from '../../../../tools';

import './assets.page.scss';

export function AssetsPage() {
  const uAuths = useAuths();
  const [loading, _setLoading] = useState(false);
  const [assets, _setAssets] = useState<MAsset[]>([]);
  const [asset, _setAsset] = useState<MAsset | null>(null);

  useEffect(() => {
    _setLoading(true);
    assetService.superListing().then((data) => {
      _setAssets(data || []);
      _setLoading(false);
    });
  }, []);

  const columns: ColumnType<MAsset>[] = [
    {
      title: 'Id',
      key: 'id',
      dataIndex: 'id',
      render: (text: string, record: MAsset) => (
        <label>{record.id}</label>
      ),
      sorter: {
        compare: (a: MAsset, b: MAsset) => a.id - b.id,
        multiple: 1,
      },
      filters: getUniqueList(assets, 'id').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MAsset) => record.id === (value as number) || false,
      filterSearch: true,
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (text: string, record: MAsset) => (
        <label>{record.name}</label>
      ),
      sorter: (a: MAsset, b: MAsset) => a.name.localeCompare(b.name || '') || -1,
      filters: getUniqueList(assets, 'name').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MAsset) => record.name === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      render: (text: string, record: MAsset) => (
        <label>{record.type}</label>
      ),
      sorter: (a: MAsset, b: MAsset) => a.type.localeCompare(b.type || '') || -1,
      filters: getUniqueList(assets, 'type').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MAsset) => record.type === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'Is Free',
      key: 'isFree',
      dataIndex: 'isFree',
      render: (text: string, record: MAsset) => (
        record.isFree
          ? (
            <FontAwesomeIcon icon={faCheck} color="green" />
          )
          : (
            <FontAwesomeIcon icon={faXmark} color="red" />
          )
      ),
      sorter: (a: MAsset, b: MAsset) => (a.isFree || b.isFree ? 1 : 0),
    },
    {
      title: 'Is Private',
      key: 'isPrivate',
      dataIndex: 'isPrivate',
      render: (text: string, record: MAsset) => (
        record.isPrivate
          ? (
            <FontAwesomeIcon icon={faCheck} color="green" />
          )
          : (
            <FontAwesomeIcon icon={faXmark} color="red" />
          )
      ),
      sorter: (a: MAsset, b: MAsset) => (a.isPrivate || b.isPrivate ? 1 : 0),
    },
    {
      title: 'Size',
      key: 'size',
      dataIndex: 'size',
      render: (text: string, record: MAsset) => (
        <label>{record.Document?.size?.toLocaleString() || 0} kB</label>
      ),
      sorter: {
        compare: (a: MAsset, b: MAsset) => (a.Document?.size || 0) - (b.Document?.size || 0),
        multiple: 1,
      },
    },
    {
      title: 'Asset',
      key: 'asset',
      dataIndex: 'Document',
      render: (text: string, record: MAsset) => (
        <img src={record.Document?.url} alt={record.name} style={{ width: '32px' }} />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      width: '100px',
      align: 'center',
      render: (text: string, record: MAsset) => (
        uAuths.isSuperAdmin()
            && (
              <>
                <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={() => { _setAsset(record); }} size="small" />
                <Popconfirm title="Sure to delete?" onConfirm={() => { deleteAsset(record.id); }}>
                  <Button type="primary" shape="circle" icon={<DeleteOutlined />} size="small" danger />
                </Popconfirm>
              </>
            )
      ),
    },
  ];

  const deleteAsset = async (id: number) => {
    const dataSource = [...assets];
    _setAssets(dataSource.filter((item) => item.id !== id));
    await assetService.SuperRemove(id);
  };

  const onCancel = () => {
    _setAsset(null);
  };

  return (
    <>
      <div id="exports">
        <div className="mb-2">
          <Button
            type="primary"
            onClick={() => { _setAsset(new MAsset()); }}
          >
            Add assets
          </Button>
        </div>
        <Spin spinning={loading}>
          <MpTable
            bordered
            urlParamsEnabled
            mpDisableTotal
            size="small"
            dataSource={assets}
            columns={columns}
            rowKey="id"
            mpTableKey="documentsTable"
          />
        </Spin>
      </div>
      {
        asset && (
          <AssetsPageModal asset={asset} onCancel={onCancel} />
        )
      }
    </>
  );
}

export function AssetsPageModal(props:{
  asset: MAsset,
  onCancel: () => void
}) {
  const [loading, _setLoading] = useState(false);
  const [assetDocument, _setAssetDocument] = useState<MDocument | undefined>();
  const [form] = Form.useForm();

  const onSave = (values: any) => {
    if (props.asset.id === 0) {
      assetService.superAdd({ ...values as MAsset, documentId: assetDocument!.id });
    } else {
      assetService.SuperEdit({
        ...props.asset,
        type: values.type,
        isFree: values.isFree,
        isPrivate: values.isPrivate,
        documentId: assetDocument!.id,
      });
    }
    props.onCancel();
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG/GIF file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      _setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      _setLoading(false);
      _setAssetDocument(info.file.response.data as MDocument);
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Modal
      title="Create Asset"
      open
      okText={<>submit</>}
      okButtonProps={{ form: 'formCreate', htmlType: 'submit' }}
      onCancel={() => { props.onCancel(); }}
    >
      <Form className="row" id="formCreate" onFinish={onSave} form={form} layout="vertical">
        <Form.Item label="Name" name="name" rules={[{ required: true }]} initialValue={props.asset.name}>
          <Input disabled={props.asset.id !== 0} type="text" placeholder="click" />
        </Form.Item>
        <Form.Item label="Type" name="type" rules={[{ required: true }]} initialValue={props.asset.type}>
          <Input type="text" />
        </Form.Item>
        <Form.Item
          valuePropName="checked"
          className="col-6"
          label="Is Free"
          name="isFree"
          rules={[{ required: true }]}
          initialValue={props.asset.isFree}
        >
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
        <Form.Item
          valuePropName="checked"
          className="col-6"
          label="Is Private"
          name="isPrivate"
          rules={[{ required: true }]}
          initialValue={props.asset.isPrivate}
        >
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
        <Form.Item label="Asset" name="asset" rules={[{ required: true }]} initialValue={props.asset.Document?.url}>
          <Upload
            name="asset"
            listType="picture-card"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            headers={{
              authorization: localStorage.getItem('user-token') || '',
            }}
            action={`${process.env.REACT_APP_API_URL}/asset/upload-asset`}
            maxCount={1}
          >
            {
             (props.asset.id === 0 || assetDocument?.originalFileName)
             && (assetDocument?.originalFileName ? <img src={assetDocument.url} alt="asset" style={{ width: '100%' }} /> : uploadButton)
            }
            {
              (props.asset.id !== 0 && !assetDocument?.originalFileName)
              && (<img src={props.asset.Document?.url} alt="asset" style={{ width: '100%' }} />)
            }
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
}
