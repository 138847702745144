import { MCreative } from '../../../../modeles';
import { EmptyExport, EmptyExportFont } from './empty';

/* eslint-disable jsx-a11y/html-has-lang */
export function GoogleAds(props: {
  iec: MCreative,
  body: string,
  fonts: EmptyExportFont[],
  appInfo: any,
  NETWORK: any,
}) {
  return (
    <EmptyExport
      iec={props.iec}
      body={props.body}
      fonts={props.fonts}
      appInfo={props.appInfo}
      NETWORK={props.NETWORK}
      scripts={[
        { type: 'text/javascript', src: 'https://tpc.googlesyndication.com/pagead/gadgets/html5/api/exitapi.js' },
      ]}
      metadatas={[
        { name: 'ad.orientation', content: 'portrait,landscape' },
      ]}
      script={`
        try {
          _networkSDK = ExitApi;
        } catch (e) {
          console.warn('ExitApi not found')
        }
      `}
    />
  );
}
