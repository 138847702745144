import { useMemo, useState } from 'react';
import { Spin } from 'antd';

import { LineChart, MpColumnType, MpTable } from '../../../../components';
import { ReportingAppFilter } from '../reporting-app-filters';
import { reportingService } from '../../../../services';

import './account-stats.page.scss';

interface Total {
  creatives: number,
}

interface ResultLine {
  id: number;
  createdAt?: string;
  platform?: string;
  applicationKey?: string;
  creative?: string;
  user?: string;
  creativeType?: string;
  adName?: string;
  creatives: number,
}

interface Result {
  is: string[],
  total: Total,
  results: ResultLine[],
}

export function AccountStatsPage(props: {
  accountId: number
}) {
  const [results, _setResults] = useState<Result | null>(null);
  const [loading, _setLoading] = useState<boolean>(false);
  const [chartData, _setChartData] = useState<Array<any>>([]);
  const [chartDataKey, _setChartDataKey] = useState<Array<any>>([]);

  const onSearch = (data: any) => {
    _setLoading(true);
    _setChartData([]);
    reportingService.searchApplication(props.accountId, data as object).then((res) => {
      _setLoading(false);
      if (res) {
        res.results = res.results.map((e: ResultLine) => ({
          ...e,
        }));
        _setResults({
          ...res,
          is: data.is ? data.is.split(',') : [],
        } as unknown as Result);
        if (data.is?.split(',').length >= 1 && data.is?.split(',').length < 3 && data.is.includes('granularity')) {
          const newList: any[] = [];
          const newListKey: any[] = [];
          let breakpoint = data.is?.split(',')[0] === 'granularity' ? data.is?.split(',')[1] : data.is?.split(',')[0];
          if (breakpoint === 'applicationId') breakpoint = 'applicationKey';
          if (breakpoint === 'userId') breakpoint = 'userName';
          res.results.forEach((element: any) => {
            if (!newListKey?.includes(element[breakpoint])) {
              newListKey.push(element[breakpoint]);
            }
            if (newList.find((item: any) => item.createdAt === element.createdAt)) {
              const e = newList.find((item: any) => item.createdAt === element.createdAt);
              // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
              e[element[breakpoint]] = (newList.find((item: any) => item.createdAt === element.createdAt)[breakpoint] || 0) + element.displayed;
            } else {
              newList.push({ createdAt: element.createdAt, [element[breakpoint] || 'creatives']: element.creatives });
            }
          });
          if (newListKey.length === 1 && newListKey[0] === undefined) {
            newListKey[0] = 'creatives';
          }
          _setChartData(newList.sort((a: any, b: any) => a.createdAt - b.createdAt));
          _setChartDataKey(newListKey);
        }
      }
      _setLoading(false);
    });
  };

  const columDate: MpColumnType<ResultLine> = {
    title: 'Date',
    key: 'createdAt',
    dataIndex: 'createdAt',
    mpSort: 'string',
    mpCanNotHide: true,
  };

  const columApplication: MpColumnType<ResultLine> = {
    title: 'Application',
    key: 'applicationKey',
    dataIndex: 'applicationKey',
    mpSort: 'string',
    mpCanNotHide: true,
  };

  const columUser: MpColumnType<ResultLine> = {
    title: 'User',
    key: 'userName',
    dataIndex: 'userName',
    mpSort: 'string',
    mpCanNotHide: true,
  };

  const columCreativeType: MpColumnType<ResultLine> = {
    title: 'Creative Type',
    key: 'creativeType',
    dataIndex: 'creativeType',
    mpSort: 'string',
    mpCanNotHide: true,
  };

  const columPlatform: MpColumnType<ResultLine> = {
    title: 'Platform',
    key: 'platform',
    dataIndex: 'platform',
    mpSort: 'string',
    mpCanNotHide: true,
  };

  const columns: MpColumnType<ResultLine>[] = [
    {
      title: 'Creatives',
      dataIndex: 'creatives',
      key: 'creatives',
      mpSort: 'number',
      render: (text: string, record: any) => (
        <label>{record.creatives.toLocaleString()}</label>
      ),
    },
  ];

  const columnsMemo = useMemo(() => {
    let cols: MpColumnType<ResultLine>[] = [...columns];
    let bCols: MpColumnType<ResultLine>[] = [];

    if (results?.is.includes('creativeType')) {
      bCols = [columCreativeType, ...bCols];
    }
    if (results?.is.includes('applicationId')) {
      bCols = [columApplication, ...bCols];
    }
    if (results?.is.includes('userId')) {
      bCols = [columUser, ...bCols];
    }
    if (results?.is.includes('platform')) {
      bCols = [columPlatform, ...bCols];
    }
    if (results?.is.includes('granularity')) {
      bCols = [columDate, ...bCols];
    }
    if (bCols.length) {
      cols = [{
        title: 'Breakdown',
        dataIndex: 'breakdown',
        key: 'breakdown',
        mpCanNotHide: true,
        children: bCols,
      }, ...cols];
    }
    return cols;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results?.is]);

  const summarys = results ? [
    { key: 'generique', value: undefined, colSpan: results.is.length },
    { key: 'Creatives', value: results.total.creatives.toLocaleString() || undefined },
  ] : undefined;

  return (
    <div id="account-stats" className="raw">
      <div className="col-6">
        <Spin spinning={loading}>
          <div className="mb-3">
            <ReportingAppFilter accountId={props.accountId} service={reportingService} onSearch={onSearch} />
          </div>
          {!!chartData[0]
          && <LineChart data={chartData} dataKey="createdAt" barDataKey={chartDataKey} />}
          {!!results && (
          <MpTable
            bordered
            size="small"
            dataSource={results.results}
            columns={columnsMemo}
            mpSummarys={summarys}
            mpTableKey="Creatives"
            rowKey="id"
            horizontalScrollEnabled
          />
          )}
        </Spin>
      </div>
    </div>
  );
}
