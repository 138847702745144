import { Slider } from 'antd';

import { MCreativeBreak } from '../../../../../../modeles';
import { useIec } from '../../../../context/iec.context';
import { YcCard } from '../../../../../../components';

import './setting-break.scss';
import { SettingCompBackground } from '../setting-comp-background';
import { getIconByType } from '../../../../utils/iec.utils';
import { IecInputNumber } from '../../../input-number';

export function SettingBreak(props: {
  br: MCreativeBreak
}) {
  const uIec = useIec();

  const getDuration = () => {
    for (const v of uIec.iec.iecData.videos) {
      if (v.breaks.indexOf(props.br) !== -1) {
        return v.videoDuration;
      }
    }
    return 0;
  };

  return (
    <YcCard title="Break" icon={getIconByType('break')} open canOpenAndClose={false}>
      <div className="setting-break">
        <SettingCompBackground background={props.br.background} isDeletable={false} />
        <YcCard title="Break" open light>
          <div className="row">
            <div className="col-3">
              <IecInputNumber label="Time" field="time" obj={props.br} step={0.01} />
            </div>
            <div className="col-9">
              <Slider
                className="mt-4"
                value={props.br.time}
                step={0.01}
                max={getDuration()}
                onChange={(t) => {
                  props.br.time = t;
                  uIec.refreshIec(false);
                }}
                onAfterChange={(t) => {
                  props.br.time = t;
                  uIec.refreshIec(true);
                }}
              />
            </div>
          </div>
        </YcCard>
      </div>
    </YcCard>
  );
}
