import { BaseService } from '../base.service';
import { MAccount } from '../../modeles';

class ClientManagerAccountService extends BaseService {
  PATH = 'client-manager/account/';

  async accountsManaged(): Promise<MAccount[] | null> {
    return this._listing(`${this.PATH}accounts-managed`);
  }
}

export const clientManagerAccountService = new ClientManagerAccountService();
