import { useState } from 'react';
import { Button, Card, Steps } from 'antd';

import { YcTitle } from '../../../../components';
import {
  StepFour, StepOne, StepThree, StepTwo,
} from './steps';

import './setup.page.scss';

export function SetupPage() {
  const [currentStep, setCurrentStep] = useState(0);
  const steps = [
    {
      title: 'Download',
      description: 'Download the Monster Playable.',
    },
    {
      title: 'Installation',
      description: 'Add the Monster Playable package to your game.',
    },
    {
      title: 'Setup',
      description: 'Setup your first game',
    },
    {
      title: 'Use',
      description: 'Global/Export settings',
    },
  ];

  const onStepChange = (value: number) => {
    setCurrentStep(value);
  };

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <div id="setup">
      <YcTitle label="Setup" />
      <div className="row">
        <div className="col-3">
          <Steps current={currentStep} onChange={onStepChange} direction="vertical" items={steps} />
        </div>
        <div className="col-9">
          <Card className="mb-2" title={steps[currentStep].title}>
            { currentStep === 0 && <StepOne />}
            { currentStep === 1 && <StepTwo />}
            { currentStep === 2 && <StepThree />}
            { currentStep === 3 && <StepFour />}
          </Card>
          <div className="steps-action">
            {currentStep < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Next
              </Button>
            )}
            {currentStep > 0 && (
              <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                Previous
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
