import { useEffect, useState } from 'react';
import {
  Button, Form, Input, Popconfirm, Popover, Spin, Table, Upload,
} from 'antd';
import { ColumnType } from 'antd/lib/table';
import { DeleteOutlined, CloudDownloadOutlined, UploadOutlined } from '@ant-design/icons';

import { useAuths } from '../../../../../../contexts/auths.context';
import { MDocumentation } from '../../../../../../modeles';
import { documentationService } from '../../../../../../services';
import { antFormNormFile } from '../../../../../../tools';

import './documentation-list.scss';

export function DocumentationList() {
  const uAuths = useAuths();
  const [documentations, _setDocumentations] = useState<MDocumentation[]>([]);
  const [loading, _setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    _setLoading(true);
    documentationService.listing().then((data) => {
      _setDocumentations(data || []);
      _setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function downloadDocumentation(url: string) {
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const documentationsColumns: ColumnType<MDocumentation>[] = [
    {
      title: 'Version',
      key: 'version',
      render: (text: number, record: MDocumentation) => (
        <label>{record.version}</label>
      ),
      sorter: (a, b) => a.version.localeCompare(b.version || '') || -1,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text: string, record: MDocumentation) => (
        <div className="actions d-flex justify-content-around">
          <Popover content="Download">
            <Button
              type="primary"
              shape="circle"
              icon={<CloudDownloadOutlined />}
              size="small"
              onClick={() => downloadDocumentation(record.Documentation!.url)}
            />
          </Popover>
          {uAuths.isAdmin()
            && (
              <Popconfirm title="Sure to delete?" onConfirm={() => { deleteDocumentation(record.id); }}>
                <Button type="primary" shape="circle" icon={<DeleteOutlined />} size="small" danger />
              </Popconfirm>
            )}
        </div>
      ),
    },
    {
      title: 'Date',
      key: 'date',
      render: (text: number, record: MDocumentation) => (
        <label>{new Date(record.createdAt!).toLocaleString()}</label>
      ),
    },
  ];

  const onFinish = (values: any) => {
    const documentation = {
      ...(new MDocumentation()),
      version: values.version,
      Documentation: values.documentation[0].response.data,
    };
    documentationService.add(documentation).then((data) => {
      if (data) {
        _setDocumentations([...documentations, data]);
        // @ts-ignore
        form.resetFields();
      }
    });
  };

  const deleteDocumentation = async (id: number) => {
    const dataSource = [...documentations];
    _setDocumentations(dataSource.filter((item) => item.id !== id));
    await documentationService.remove(id);
  };

  return (
    <div id="documentation-list">
      <div className="row">
        <div className="col-6">
          <Spin spinning={loading}>
            <Table bordered size="small" dataSource={documentations} columns={documentationsColumns} rowKey="id" />
          </Spin>
        </div>
        {uAuths.isAdmin()
          && (
            <div className="col-6">
              <Form onFinish={onFinish} form={form} layout="vertical">
                <div className="row">
                  <div className="row">
                    <div className="col-12">
                      <Form.Item label="Version" name="version" rules={[{ required: true }]}>
                        <Input type="text" />
                      </Form.Item>
                    </div>
                    <div className="col-12">
                      <Form.Item
                        label="Documentation"
                        name="documentation"
                        rules={[{ required: true }]}
                        valuePropName="fileList"
                        getValueFromEvent={antFormNormFile}
                      >
                        <Upload
                          name="documentation"
                          headers={{
                            authorization: localStorage.getItem('user-token') || '',
                          }}
                          action={`${process.env.REACT_APP_API_URL}/documentation/upload-documentation`}
                          maxCount={1}
                        >
                          <Button icon={<UploadOutlined />}>Upload file</Button>
                        </Upload>
                      </Form.Item>
                    </div>
                  </div>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" className="mt-4">
                      Upload documentation
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          )}
      </div>
    </div>
  );
}
