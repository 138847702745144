/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  Alert,
  Button,
  Input, InputNumber, message, Select, Slider, Spin, UploadProps,
} from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { RcFile } from 'antd/es/upload';
import { PlaySquareOutlined } from '@ant-design/icons';
import {
  useEffect, useRef, useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faVolume } from '@fortawesome/pro-regular-svg-icons';
import { YcCard } from '../card';
import { libraryService } from '../../services/library.service';

import './video-uploader.scss';
import { MDocument } from '../../modeles';

interface VideoDocument {
  id: number;
  originalFileName: string;
  name: string;
  url: string;
  awsKey: string;
  size: number;
  type: string;
  duration: number;
  lastModified: Date;
}

export function VideoUploader(props: {
  onUpload: (doc: MDocument) => void
  modalOk: () => Promise<void>,
  modalCancel: () => void,
}) {
  const maxSizeInMB = 50;

  const [video, _setVideo] = useState<HTMLVideoElement | null>();
  const [videoInfo, _setVideoInfo] = useState<VideoDocument | null>();
  const [originalVideoInfo, _setOriginalVideoInfo] = useState<VideoDocument>();
  const [videoTimeRange, _setVideoTimeRange] = useState<[number, number]>([0, 0]);
  const [qualitySelected, _setQualitySelected] = useState<number>(1000000);
  const [loading, _setLoading] = useState<boolean>(false);

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('loadedmetadata', () => {
        _setVideo(videoRef.current as HTMLVideoElement);
        _setVideoTimeRange([0, videoRef.current?.duration || 0]);
      });
    }
    props.onUpload(videoInfo!);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoInfo?.lastModified]);

  const cleanData = () => {
    _setVideo(null);
    _setVideoInfo(null);
  };

  const cancel = () => {
    props.modalCancel();
    cleanData();
  };

  const validate = () => {
    props.modalOk();
    cleanData();
  };

  const draggerParams: UploadProps = {
    name: 'video',
    multiple: false,
    maxCount: 1,
    onChange(info) {
      if (info.file.status === 'done') {
        message.success(`${info.file.name} video uploaded successfully`);
        const newVideoInfo = {
          ...info.file.response.data,
          size: info.file.size ? info.file.size / 1000 / 1000 : undefined,
          type: info.file.type,
          name: info.file.name,
          lastModified: new Date(),
        };
        _setVideoInfo(newVideoInfo);
        _setOriginalVideoInfo(newVideoInfo);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} video upload failed.`);
      }
    },
    beforeUpload(file: RcFile) {
      const isVideo = file.type === 'video/mp4';
      if (!isVideo) {
        message.error(`${file.name} is not a png file`);
      }
      const isLessThanLimit = file.size / 1000 / 1000 < maxSizeInMB;
      if (!isLessThanLimit) {
        message.error(`Video must be smaller than ${maxSizeInMB}MB!`);
      }
      return isLessThanLimit && isVideo;
    },
    action: `${process.env.REACT_APP_API_URL}/creatives/upload-video`,
    headers: {
      authorization: localStorage.getItem('user-token') || '',
    },
  };

  const getFrame = (num: number) => {
    const int_part = Math.trunc(num);
    const float_part = Number((num - int_part).toFixed(2));
    return `${Math.trunc(float_part * 30)}`.padStart(2, '0');
  };

  const calculateSize = async () => {
    _setLoading(true);
    const startAt = `00:00:${`${Math.trunc(videoTimeRange[0])}`.padStart(2, '0')}:${getFrame(videoTimeRange[0])}`;
    const endAt = videoTimeRange[1] !== video?.duration
      ? `00:00:${`${Math.trunc(videoTimeRange[1])}`.padStart(2, '0')}:${getFrame(videoTimeRange[1])}`
      : undefined;
    const videoConverted = await libraryService.convertVideo({
      videoId: originalVideoInfo?.id,
      name: videoInfo?.name,
      startAt,
      endAt,
      bitrate: qualitySelected,
    });
    _setVideoInfo({
      ...videoConverted.data,
    });
    _setLoading(false);
  };

  const checkSize = () => {
    if (videoInfo?.size) {
      if (videoInfo?.size < 1.5) {
        return <Alert message={`Good: ${videoInfo?.size.toFixed(2)}Mb`} type="success" showIcon />;
      }
      if (videoInfo?.size > 1.5 && videoInfo?.size < maxSizeInMB) {
        return <Alert message={`Warning: ${videoInfo?.size.toFixed(2)}Mb`} type="warning" showIcon />;
      }
      return <Alert message={`To heavy: ${videoInfo?.size.toFixed(2)}Mb`} type="error" showIcon />;
    }
    return <Alert message="Size error" type="error" showIcon />;
  };

  const editSliderRange = (e: [number, number]) => {
    if (videoTimeRange[0] !== e[0]) {
      video!.currentTime = e[0];
    } else {
      video!.currentTime = e[1];
    }
    _setVideoTimeRange(e);
  };

  const resetOrginalVideo = () => {
    _setVideoInfo(originalVideoInfo);
  };

  return (
    <div className="video-uploader">
      {
        !videoInfo?.id
          ? (
            <Dragger {...draggerParams}>
              <p className="ant-upload-drag-icon">
                <PlaySquareOutlined />
              </p>
              <p className="ant-upload-text">Click or drag video to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single MP4 video.
              </p>
            </Dragger>
          )
          : (
            <div className="row h-100">
              <div className="col-4 row h-100">
                <YcCard title="Settings" open canOpenAndClose={false} onBack={resetOrginalVideo}>
                  <div className="row">
                    <div className="col-12 input-separate">
                      <label>Name</label>
                      <Input
                        placeholder="Text"
                        value={videoInfo?.name}
                        onChange={(e) => { _setVideoInfo({ ...videoInfo, name: e.target.value }); }}
                      />
                    </div>
                    <div className="col-12 input-separate">
                      <label>Format</label>
                      <Input
                        placeholder="Text"
                        disabled
                        value={videoInfo.type}
                      />
                    </div>
                    <div className="col-6 input-separate">
                      <label>Time start</label>
                      <InputNumber
                        min={0}
                        max={video?.duration}
                        step={0.01}
                        placeholder="0.00"
                        value={videoTimeRange[0]}
                        onChange={(e) => _setVideoTimeRange([e || 0, videoTimeRange[1]])}
                      />
                    </div>
                    <div className="col-6 input-separate">
                      <label>Time end</label>
                      <InputNumber
                        min={0}
                        max={video?.duration}
                        step={0.01}
                        placeholder="0.00"
                        value={videoTimeRange[1]}
                        onChange={(e) => _setVideoTimeRange([videoTimeRange[0], e || 0])}
                      />
                    </div>
                    <div className="col-12 input-separate">
                      <label>Quality</label>
                      <Select
                        defaultValue={qualitySelected}
                        style={{ width: '100%' }}
                        options={[
                          // { label: 'Keep original', value: 0 },
                          { label: 'Very low', value: 100000 },
                          { label: 'Low', value: 500000 },
                          { label: 'Medium', value: 1000000 },
                          { label: 'High', value: 5000000 },
                          { label: 'Very High', value: 10000000 },
                        ]}
                        onChange={(e) => { _setQualitySelected(e); }}
                      />
                    </div>
                    <div className="col-6 input-separate">
                      <Button
                        type="primary"
                        onClick={() => { calculateSize(); }}
                        loading={loading}
                      >Apply settings
                      </Button>
                    </div>
                    <div className="col-6 input-separate">
                      {
                        checkSize()
                      }
                    </div>
                  </div>
                </YcCard>
                <div className="d-flex justify-content-center gap-3">
                  <Button loading={loading} onClick={() => { cancel(); }}>Cancel</Button>
                  <Button loading={loading} type="primary" onClick={() => { validate(); }}>Validate</Button>
                </div>
              </div>
              <div className="col-8 h-100 video-zone" key={videoInfo?.lastModified.toString()}>
                <Spin spinning={loading} size="large">
                  <div className="col-12 h-100 video-container">
                    <video ref={videoRef} className="video-preview">
                      <source src={videoInfo?.url} />
                      Your browser does not support the video tag.
                    </video>
                    <div className="video-slider">
                      <Slider
                        min={0}
                        max={video?.duration}
                        onChange={(e) => { editSliderRange([e[0], e[1]]); }}
                        range
                        value={[videoTimeRange[0], videoTimeRange[1]]}
                        step={0.01}
                      />
                    </div>
                    <div className="video-actions">
                      <Button type="primary" shape="circle" icon={<FontAwesomeIcon icon={faPlay} />} onClick={() => { video?.play(); }} />
                      <Button disabled type="primary" shape="circle" icon={<FontAwesomeIcon icon={faVolume} />} />
                    </div>
                  </div>
                </Spin>
              </div>
            </div>
          )
      }
    </div>
  );
}
