import * as HomeImages from '../../../../../../assets/images/home';

import './resume.scss';

export function Resume() {
  return (
    <div id="resume">
      <img className="fd-rond" src={HomeImages.FdRond} alt="Fd Rond" />
      <img className="fd-rond-pink" src={HomeImages.FdRondPink} alt="Fd Rond" />
      <div className="content row">
        <div className="col-12 col-md-6">
          <a href="#playable-preview">
            <div className="short-card">
              <h2 className="title">Playable Ads</h2>
              <h3 className="sub-title">Directly from unity</h3>
              <p className="description">
                Create truly playable game ads by <strong>importing your game directly from Unity</strong>.
              </p>
              <div className="button">Try Playables</div>
              <img src={HomeImages.ImgPlayable} alt="Img playable" />
              <div className="tag">SOON</div>
            </div>
          </a>
        </div>
        <div className="col-12 col-md-6">
          <a href="#iec-preview">
            <div className="short-card">

              <h2 className="title">I.E.C</h2>
              <h3 className="sub-title">Interactive End Card</h3>
              <p className="description">
                Create your own ads thanks to our <strong>easy-to-use interface with lots of options</strong>.
              </p>
              <div className="button">Try I E C</div>
              <img src={HomeImages.ImgIec} alt="Img iec" />
            </div>
          </a>
        </div>
        <div className="col-12">
          <div className="long-card">
            <div className="card-content">
              <img src={HomeImages.Gaming} alt="Img Gaming" />
              <h2 className="title">Advantages of both: Playable ads and IEC</h2>
              <p className="description">
                Playable ads not only increase the user acquisition, it allows to decrease the Cost Per Install, increase{' '}
                <strong>the click rate, the retention rate and then the Return On Ad Spend.</strong>
                Your UA team will be glad to learn all of those advantages.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
