import {
  Button, Spin, Select, Modal,
  DatePicker,
} from 'antd';
import { ColumnType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faPlus,
  faSave,
  faDollarSign,
  faGift,
} from '@fortawesome/pro-regular-svg-icons';

import dayjs from 'dayjs';
import { MpTable, MpTabDateFilter } from '../../../../components';
import { MCreativeListing } from '../../../../modeles';
import { getUniqueList } from '../../../../tools';
import { creativesListingService } from '../../../../services/creativesListing.service';
import { adminAccountsService, adminApplicationsService, adminCreativesService } from '../../../../services';

import './creatives-listing.page.scss';

export function CreativesListingPage() {
  const [loading, _setLoading] = useState(false);
  const [creativesList, _setCreativesList] = useState<MCreativeListing[]>([]);
  const [modalOpen, _setModalOpen] = useState<boolean>(false);
  const [itemEdited, _setItemEdited] = useState<number | null>(null);

  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = () => {
    _setLoading(true);
    creativesListingService.listing().then((data) => {
      _setCreativesList(data || []);
      _setLoading(false);
    });
  };

  const columns: ColumnType<MCreativeListing>[] = [
    {
      title: 'Account',
      key: 'account',
      dataIndex: 'account',
      render: (text: string, record: MCreativeListing) => (
        <Link to={`/admin/accounts/account/${record.accountId}`}>
          {record.Account?.name} ({record.Account?.id})
        </Link>
      ),
      sorter: (a: MCreativeListing, b: MCreativeListing) => a.Account?.name.localeCompare(b.Account?.name || '') || -1,
      filters: getUniqueList(creativesList, 'Account.name').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MCreativeListing) => record.Account?.name === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'Application',
      key: 'application',
      dataIndex: 'application',
      render: (text: string, record: MCreativeListing) => (
        <Link to={`/applications/${record.applicationId}/creatives`}>
          {record.Application?.name} ({record.Application?.id})
        </Link>
      ),
      sorter: (a: MCreativeListing, b: MCreativeListing) => a.Application?.name.localeCompare(b.Application?.name || '') || -1,
      filters: getUniqueList(creativesList, 'Application.name').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MCreativeListing) => record.Application?.name === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'Creative',
      key: 'creative',
      dataIndex: 'creative',
      render: (text: string, record: MCreativeListing) => (
        <Link to={`/applications/${record.applicationId}/creatives`}>
          {record.Creative?.name} ({record.Creative?.id})
        </Link>
      ),
      sorter: (a: MCreativeListing, b: MCreativeListing) => a.Creative?.name.localeCompare(b.Creative?.name || '') || -1,
      filters: getUniqueList(creativesList, 'Creative.name').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MCreativeListing) => record.Creative?.name === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      render: (text: string, record: MCreativeListing) => (
        <label>{record.Creative.type}</label>
      ),
      sorter: (a: MCreativeListing, b: MCreativeListing) => a.Creative.type?.localeCompare(b.Creative.type || '') || -1,
      filters: getUniqueList(creativesList, 'Creative.type').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MCreativeListing) => record.Creative.type === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'Is Free',
      key: 'isFree',
      dataIndex: 'isFree',
      align: 'center',
      render: (text: string, record: MCreativeListing) => {
        if (record.isFree) {
          return <FontAwesomeIcon icon={faGift} size="2xl" color="red" />;
        }
        return <FontAwesomeIcon icon={faDollarSign} size="2xl" color="green" />;
      },
      sorter: (a: MCreativeListing, b: MCreativeListing) => (a.isFree || b.isFree ? 1 : 0),
      filters: getUniqueList(creativesList, 'isFree').map((data) => ({
        text: data.toString(),
        value: data,
      })),
      onFilter: (value: any, record: MCreativeListing) => record.isFree === (value as boolean) || false,
      filterSearch: true,
    },
    {
      title: 'Created',
      key: 'createdAt',
      dataIndex: 'createdAt',
      fixed: 'right',
      align: 'center',
      render: (text: string, record: MCreativeListing) => (
        <>
          {record.createdAt || record.id === itemEdited ? (
            <DatePicker
              defaultValue={record.createdAt ? dayjs(record.createdAt) : dayjs()}
              onChange={(e) => { record.createdAt = e ? e.toDate() : undefined; }}
              disabled={record.id !== itemEdited}
            />
          ) : (
            <label>Null</label>
          )}
          <Button
            className={record.id !== itemEdited ? 'visible button-icon' : 'button-icon'}
            type="primary"
            shape="circle"
            size="small"
            icon={<FontAwesomeIcon icon={faEdit} size="sm" />}
            onClick={() => _setItemEdited(record.id)}
            disabled={!!itemEdited}
          />
          <Button
            className={record.id === itemEdited ? 'visible button-icon' : 'button-icon'}
            type="primary"
            shape="circle"
            size="small"
            icon={<FontAwesomeIcon icon={faSave} size="sm" />}
            onClick={() => {
              _setItemEdited(null);
              creativesListingService.edit(record.id, record);
            }}
          />
        </>
      ),
      sorter: (a: MCreativeListing, b: MCreativeListing) => a.createdAt?.toString()?.localeCompare(b.createdAt?.toString() || '') || -1,
      filterDropdown: MpTabDateFilter,
      onFilter: (value: any, record: MCreativeListing) => {
        return new Date(record.createdAt!).getTime() >= value.split('/')[0] && new Date(record.createdAt!).getTime() <= value.split('/')[1];
      },
    },
    {
      title: 'Requested',
      key: 'requestAt',
      dataIndex: 'requestAt',
      fixed: 'right',
      align: 'center',
      render: (text: string, record: MCreativeListing) => (
        <>
          {record.requestAt || record.id === itemEdited ? (
            <DatePicker
              defaultValue={record.requestAt ? dayjs(record.requestAt) : dayjs()}
              onChange={(e) => { record.requestAt = e ? e.toDate() : undefined; }}
              disabled={record.id !== itemEdited}
            />
          ) : (
            <label>Null</label>
          )}
          <Button
            className={record.id !== itemEdited ? 'visible button-icon' : 'button-icon'}
            type="primary"
            shape="circle"
            size="small"
            icon={<FontAwesomeIcon icon={faEdit} size="sm" />}
            onClick={() => _setItemEdited(record.id)}
            disabled={!!itemEdited}
          />
          <Button
            className={record.id === itemEdited ? 'visible button-icon' : 'button-icon'}
            type="primary"
            shape="circle"
            size="small"
            icon={<FontAwesomeIcon icon={faSave} size="sm" />}
            onClick={() => {
              _setItemEdited(null);
              creativesListingService.edit(record.id, record);
            }}
          />
        </>
      ),
      sorter: (a: MCreativeListing, b: MCreativeListing) => a.requestAt?.toString()?.localeCompare(b.requestAt?.toString() || '') || -1,
      filterDropdown: MpTabDateFilter,
      onFilter: (value: any, record: MCreativeListing) => {
        return new Date(record.requestAt!).getTime() >= value.split('/')[0] && new Date(record.requestAt!).getTime() <= value.split('/')[1];
      },
    },
    {
      title: 'Sent',
      key: 'sendAt',
      dataIndex: 'sendAt',
      fixed: 'right',
      align: 'center',
      render: (text: string, record: MCreativeListing) => (
        <>
          {record.sendAt || record.id === itemEdited ? (
            <DatePicker
              defaultValue={record.sendAt ? dayjs(record.sendAt) : dayjs()}
              onChange={(e) => { record.sendAt = e ? e.toDate() : undefined; }}
              disabled={record.id !== itemEdited}
            />
          ) : (
            <label>Null</label>
          )}
          <Button
            className={record.id !== itemEdited ? 'visible button-icon' : 'button-icon'}
            type="primary"
            shape="circle"
            size="small"
            icon={<FontAwesomeIcon icon={faEdit} size="sm" />}
            onClick={() => _setItemEdited(record.id)}
            disabled={!!itemEdited}
          />
          <Button
            className={record.id === itemEdited ? 'visible button-icon' : 'button-icon'}
            type="primary"
            shape="circle"
            size="small"
            icon={<FontAwesomeIcon icon={faSave} size="sm" />}
            onClick={() => {
              _setItemEdited(null);
              creativesListingService.edit(record.id, record);
            }}
          />
        </>
      ),
      sorter: (a: MCreativeListing, b: MCreativeListing) => a.sendAt?.toString()?.localeCompare(b.sendAt?.toString() || '') || -1,
      filterDropdown: MpTabDateFilter,
      onFilter: (value: any, record: MCreativeListing) => {
        return new Date(record.sendAt!).getTime() >= value.split('/')[0] && new Date(record.sendAt!).getTime() <= value.split('/')[1];
      },
    },
  ];

  return (
    <div id="creatives-listing">
      <Spin spinning={loading}>
        <Button
          type="primary"
          onClick={() => { _setModalOpen(true); }}
          icon={<FontAwesomeIcon icon={faPlus} className="icon" />}
        />
        <MpTable
          bordered
          urlParamsEnabled
          mpDisableTotal
          size="small"
          dataSource={creativesList}
          columns={columns}
          rowKey="id"
          mpTableKey="creativesTable"
        />
      </Spin>
      <CreativesListingAdd isOpen={modalOpen} onOk={() => { refreshData(); _setModalOpen(false); }} onCancel={() => { _setModalOpen(false); }} />
    </div>
  );
}

export function CreativesListingAdd(props: {
  isOpen: boolean,
  onOk: () => void,
  onCancel: () => void,
}) {
  const [newCreative, _setNewCreative] = useState<MCreativeListing>(new MCreativeListing());
  const [accounts, _setAccounts] = useState<{ value: string, label: string }[]>([]);
  const [applications, _setApplications] = useState<{ value: string, label: string }[]>([]);
  const [creatives, _setCreatives] = useState<{ value: string, label: string }[]>([]);

  useEffect(() => {
    adminAccountsService.listingForListing().then((data) => {
      _setAccounts(data?.map((account) => ({ value: account.id.toString(), label: account.name })) || []);
    });
  }, []);

  useEffect(() => {
    _setApplications([]);
    if (newCreative?.accountId) {
      adminApplicationsService.listingByAccount(newCreative.accountId).then((data) => {
        _setApplications(data?.map((application) => ({ value: application.id.toString(), label: application.name })) || []);
      });
    }
  }, [newCreative?.accountId]);

  useEffect(() => {
    _setCreatives([]);
    if (newCreative?.applicationId) {
      adminCreativesService.listingByApplication(newCreative.applicationId).then((data) => {
        _setCreatives(data?.map((creative) => ({ value: creative.id.toString(), label: creative.name })) || []);
      });
    }
  }, [newCreative?.applicationId]);

  const createCreativeList = () => {
    creativesListingService.add(newCreative);
    props.onOk();
  };

  return (
    <div id="creatives-listing-add">
      <Modal title="Add new element" open={props.isOpen} onOk={() => { createCreativeList(); }} onCancel={() => { props.onCancel(); }}>
        <div className="content-modal">
          <Select
            disabled={!accounts}
            onChange={(e) => { _setNewCreative({ ...newCreative, accountId: e }); }}
            optionFilterProp="label"
            showSearch
            placeholder="Account"
            style={{ width: '140px' }}
            options={accounts}
          />
          <Select
            disabled={!newCreative?.accountId}
            onChange={(e) => { _setNewCreative({ ...newCreative, applicationId: e }); }}
            showSearch
            optionFilterProp="label"
            placeholder="Application"
            style={{ width: '140px' }}
            options={applications}
          />
          <Select
            disabled={!newCreative?.applicationId}
            onChange={(e) => { _setNewCreative({ ...newCreative, creativeId: e }); }}
            showSearch
            optionFilterProp="label"
            placeholder="Creative"
            style={{ width: '140px' }}
            options={creatives}
          />
          <DatePicker
            style={{ width: '140px' }}
            onChange={(e) => { _setNewCreative({ ...newCreative, createdAt: e.toDate() }); }}
            placeholder="Created"
          />
          <DatePicker
            style={{ width: '140px' }}
            onChange={(e) => { _setNewCreative({ ...newCreative, requestAt: e.toDate() }); }}
            placeholder="Requested"
          />
          <DatePicker
            style={{ width: '140px' }}
            onChange={(e) => { _setNewCreative({ ...newCreative, sendAt: e.toDate() }); }}
            placeholder="Sent"
          />
        </div>
      </Modal>
    </div>
  );
}
