import {
  Button, Card, Form, Input, Popover, Select, Tag,
} from 'antd';
import { useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import { useHistory } from 'react-router-dom';
import { FormAddress } from '../../../../components/form-address';
import { useAuths } from '../../../../contexts/auths.context';
import {
  MAccount, MApplication, MCreative, MCreativeExport, MTag,
} from '../../../../modeles';
import { accountService } from '../../../../services';
import './account.page.scss';
import { isMonthly } from '../../../../tools';

export function AccountPage() {
  const DEFAULT_EXPORT_FORMAT = '{KEY}_{TYPE}{VERSION}_{NETWORK}_{PLATFORM}';

  const uAuths = useAuths();
  const uHistory = useHistory();

  const [exportFormat, _setExportFormat] = useState<string>(DEFAULT_EXPORT_FORMAT);
  const [isReady, _setIsReady] = useState<boolean>(false);
  const [account, _setAccount] = useState<MAccount>(new MAccount());
  const [tags, _setTags] = useState<MTag[]>();
  const [selectedTag, _setSelectedTag] = useState<string>();

  useEffect(() => {
    if (uAuths.isAdmin()) {
      accountService.view().then((data) => {
        if (data) {
          _setAccount(data);
          _setTags(data.tags);
          _setIsReady(true);
        } else {
          uHistory.push('/');
        }
      });
    } else {
      uHistory.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values: any) => {
    const c : MAccount = { ...account, ...values, tags };
    accountService.edit(account.id, c).then((data) => {
      if (data) {
        _setAccount(data);
        _setTags(data.tags);
      }
    });
  };

  function showColorPicker(label: string) {
    _setSelectedTag(label);
  }

  function editColorTag(label: string, color: string) {
    const tagsChanged = [...tags!];
    tagsChanged.find((t) => t.text.toUpperCase() === label.toUpperCase())!.color = color;
    _setTags(tagsChanged);
  }

  const tagRender = (props: any) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const color = tags?.find((tag) => tag.text.toUpperCase() === label.toUpperCase())?.color;
    return (
      <Tag
        onClick={() => showColorPicker(label.toUpperCase() as string)}
        color={tags?.find((tag) => tag.text === label.toUpperCase())?.color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          cursor: 'pointer', marginRight: 3, color, border: `1px solid ${color}`, backgroundColor: `${color}15`,
        }}
      >
        {label.toUpperCase()}
        <Popover
          content={(
            <ChromePicker
              color={tags?.find((tag) => tag.text.toUpperCase() === label.toUpperCase())?.color}
              onChange={(e) => editColorTag(label.toUpperCase() as string, e.hex)}
              disableAlpha
            />
          )}
          trigger="click"
          open={selectedTag === label.toUpperCase()}
          onOpenChange={() => { _setSelectedTag(''); }}
        />
      </Tag>
    );
  };

  const handleChange = (value: string[]) => {
    const newTags = value?.map((tag) => {
      const oldTag = account.tags?.find((t) => t.text.toUpperCase() === tag.toUpperCase());
      if (oldTag) {
        return { text: oldTag.text.toUpperCase(), color: oldTag.color };
      }
      return { text: tag.toUpperCase(), color: '' };
    });
    _setTags(newTags);
  };

  return (
    <div className="account-page">
      {isReady
        && (
        <Form
          onFinish={onFinish}
          layout="vertical"
        >
          <div className="row">
            <div className="col-xl-4 col-lg-6">
              <Card title="Infos">
                <Form.Item label="Name" name="name" rules={[{ required: true }]} initialValue={account.name}>
                  <Input type="text" />
                </Form.Item>
                <hr />
                <FormAddress address={account.Address} />
              </Card>
            </div>
            <div className="col-xl-4 col-lg-6">
              <Card title="Tags">
                <Form.Item
                  label="Tags"
                  name="tags"
                  rules={[{ required: false }]}
                  initialValue={account.tags?.map((tag) => ({ label: tag.text, value: tag.text }))}
                >
                  <Select
                    mode="tags"
                    tagRender={tagRender}
                    fieldNames={{ label: 'text', value: 'text', options: 'options' }}
                    options={account.tags}
                    onChange={handleChange}
                  />
                </Form.Item>
                <table className="table table-sm table-bordered">
                  <tbody>
                    <tr>
                      <th>Add</th>
                      <td>Enter your name and clic on ENTER of the keybord</td>
                    </tr>
                    <tr>
                      <th>Change color</th>
                      <td>Clic on tag and change the color</td>
                    </tr>
                    <tr>
                      <th>Delete</th>
                      <td>Clic on the cross (x)</td>
                    </tr>
                    <tr>
                      <th>Save</th>
                      <td>Don&apos;t forget to clic on [Save]</td>
                    </tr>
                  </tbody>
                </table>
              </Card>
            </div>
            <div className="col-xl-4 col-lg-6">
              <Card title="Config">
                <Form.Item
                  label="Export format"
                  name="exportFormat"
                  rules={[{ required: false }]}
                  initialValue={account.exportFormat || DEFAULT_EXPORT_FORMAT}
                >
                  <Input
                    type="text"
                    onChange={(e) => {
                      _setExportFormat(e.target.value as string);
                    }}
                    placeholder={DEFAULT_EXPORT_FORMAT}
                  />
                </Form.Item>
                <p className="m-t-subinput">
                  {
                    exportFormat
                      .replace('{CREA_NAME}', 'ORANGE')
                      .replace('{APP_NAME}', 'MONSTER')
                      .replace('{KEY}', 'STG')
                      .replace('{TYPE}', 'I')
                      .replace('{VERSION}', '8')
                      .replace('{NETWORK}', 'MINTEGRAL')
                      .replace('{PLATFORM}', 'AND')
                  }
                  &nbsp;&nbsp;<i>(Result example)</i>
                </p>
                <table className="table table-sm table-bordered">
                  <tbody>
                    <tr>
                      <th>{'{CREA_NAME}'}</th>
                      <td>Creative NAME (example: Orange)</td>
                    </tr>
                    <tr>
                      <th>{'{APP_NAME}'}</th>
                      <td>Application NAME (example: MONSTER)</td>
                    </tr>
                    <tr>
                      <th>{'{KEY}'}</th>
                      <td>Application key (example: STG)</td>
                    </tr>
                    <tr>
                      <th>{'{TYPE}'}</th>
                      <td>Is IEC|PLEC or PLAYABLE (example: I|P)</td>
                    </tr>
                    <tr>
                      <th>{'{VERSION}'}</th>
                      <td>Version creative (example: 1|2|3|...)</td>
                    </tr>
                    <tr>
                      <th>{'{NETWORK}'}</th>
                      <td>Network name (example: GOOGLE|APPLOVIN|MINTEGRAL|...)</td>
                    </tr>
                    <tr>
                      <th>{'{PLATFORM}'}</th>
                      <td>Platform name (example: IOS|AND)</td>
                    </tr>
                  </tbody>
                </table>
              </Card>
            </div>
            <div className="col-xl-4 col-lg-6">
              <Card title="Stats">
                <table className="table table-sm table-bordered">
                  <tbody>
                    <tr>
                      <th> </th>
                      <th>Current Month</th>
                      <th>Total</th>
                    </tr>
                    <tr>
                      <th>Applications</th>
                      <td>{account.Applications?.filter((c: MApplication) => isMonthly(new Date(c.createdAt))).length}</td>
                      <td>{account.Applications?.filter((c: MApplication) => !c.deletedAt).length}</td>
                    </tr>
                    <tr>
                      <th>IECs</th>
                      <td>{account.Iec?.filter((c: MCreative) => isMonthly(new Date(c.createdAt!))).length}</td>
                      <td>{account.Iec?.filter((c: MCreative) => !c.deletedAt).length}</td>
                    </tr>
                    <tr>
                      <th>Exports</th>
                      <td>{account.CreativeExport?.filter((c: MCreativeExport) => isMonthly(new Date(c.createdAt!))).length}</td>
                      <td>{account.CreativeExport?.filter((c: MCreativeExport) => !c.deletedAt).length}</td>
                    </tr>
                  </tbody>
                </table>
              </Card>
            </div>
          </div>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="mt-4">
              Save
            </Button>
          </Form.Item>
        </Form>
        )}
    </div>
  );
}
