import { useEffect, useState } from 'react';
import { Button, Switch } from 'antd';
import { ColumnType } from 'antd/lib/table';
import {
  CheckOutlined, CloseOutlined, EditOutlined,
} from '@ant-design/icons';

import { MUser } from '../../../../modeles';
import { MpTable, YcTitle, YcUserModal } from '../../../../components';
import { usersService } from '../../../../services/users.service';
import { useAuths } from '../../../../contexts/auths.context';
import { useUserEdited } from '../../../../contexts/user-edited.context';
import { getUniqueList } from '../../../../tools';

import './users.page.scss';
import { YcAddUserModalPage } from '../../../../components/add-user-modal';

export function UsersPage() {
  const uAuths = useAuths();
  const uUserEdited = useUserEdited();

  const [users, _setUsers] = useState<MUser[]>([]);
  const [isOpen, _setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    usersService.listing().then((data) => {
      _setUsers(data || []);
    }).catch(() => {});
  }, []);

  const refreshUsers = () => {
    usersService.listing().then((data) => {
      _setUsers(data || []);
    }).catch(() => {});
  };

  const columns: ColumnType<MUser>[] = [
    {
      title: 'ID',
      key: 'id',
      render: (value: number, user: MUser) => (
        user.id
      ),
      sorter: (a: MUser, b: MUser) => (a.id || 0) - (b.id || 0),
    },
    {
      title: 'Email',
      key: 'email',
      render: (value: string, user: MUser) => (
        user.email
      ),
      sorter: {
        compare: (a: MUser, b: MUser) => a.email.localeCompare(b.email || '') || -1,
        multiple: 1,
      },
      filters: getUniqueList(users, 'email').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MUser) => record.email === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'First Name',
      key: 'firstName',
      render: (value: string, user: MUser) => (
        user.firstName
      ),
      sorter: {
        compare: (a: MUser, b: MUser) => a.firstName.localeCompare(b.firstName || '') || -1,
        multiple: 1,
      },
      filters: getUniqueList(users, 'firstName').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MUser) => record.firstName === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'Last Name',
      key: 'lastName',
      render: (value: string, user: MUser) => (
        user.lastName
      ),
      sorter: {
        compare: (a: MUser, b: MUser) => a.lastName.localeCompare(b.lastName || '') || -1,
        multiple: 1,
      },
      filters: getUniqueList(users, 'lastName').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MUser) => record.lastName === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'Admin',
      key: 'isAdmin',
      dataIndex: 'isAdmin',
      render: (value: boolean, user: MUser) => (
        <Switch
          disabled={user.id === uAuths.user?.id}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          defaultChecked={user.isAdmin}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={async (e) => {
            user.isAdmin = e;
            await usersService.isAdmin(user.id, e);
          }}
        />
      ),
      sorter: (a: MUser, b: MUser) => (a.isAdmin || b.isAdmin ? 1 : 0),
    },
    {
      title: 'Active',
      key: 'isActive',
      render: (value: boolean, user: MUser) => (
        <Switch
          disabled={user.id === uAuths.user?.id || user.isAdmin}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          defaultChecked={user.isActive}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={async (e) => {
            user.isActive = e;
            await usersService.isActive(user.id, e);
          }}
        />
      ),
      sorter: (a: MUser, b: MUser) => (a.isActive || b.isActive ? 1 : 0),
      filters: [{ text: 'Active', value: true }, { text: 'Inactive', value: false }],
      onFilter: (value, record) => record.isActive === value,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (value: boolean, user: MUser) => (
        <Button
          type="primary"
          icon={<EditOutlined />}
          size="middle"
          shape="circle"
          onClick={() => { uUserEdited.setUser(user.id); }}
        />
      ),
    },
  ];

  return (
    <div id="root-users">
      <YcTitle label="Users" />
      <Button type="primary" onClick={() => { _setIsOpen(true); }}>Add User</Button>
      <MpTable bordered size="small" dataSource={users} columns={columns} rowKey="id" mpTableKey="users" urlParamsEnabled />
      <YcUserModal />
      <YcAddUserModalPage isOpen={isOpen} close={() => { refreshUsers(); _setIsOpen(false); }} />
    </div>
  );
}
