import {
  Switch, Route, Redirect,
  RouteProps, useHistory, useLocation,
} from 'react-router-dom';
import { useEffect } from 'react';

import { useAuths, UserEditedContextProvider } from '../contexts';
import {
  PrivacyPolicies, YcHeader, SideApplications, SideApplicationsManaged,
} from '../components';

import './app.scss';

// Modules
import {
  ApplicationModule,
  ReportingModule,
  SetupModule,
  AdminModule,
  AccountModule,
  PerformanceModule,
} from '.';

// Pages
import {
  ForgotPasswordPage,
  HomePage,
  LoginPage,
  ResetPasswordPage,
  SignUpPage,
  ActiveAccountPage,
  PlayablePresentation,
  IecPresentation,
} from './root/pages';
import packageJson from '../../package.json';

const frontVersion = packageJson.version;

function YcRoute({
  YcComponent, ycIsLogin, canAccess = true, ...rest
}: RouteProps<string> & { YcComponent: any, ycIsLogin?: boolean, canAccess?: boolean }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {(ycIsLogin && canAccess) && <YcComponent {...props} />}
          {(!ycIsLogin || !canAccess) && <Redirect to={{ pathname: '/', state: { from: props.location } }} />}
        </>
      )}
    />
  );
}

const connectedBlockedPaths = ['/', '/iec-presentation', '/playable-presentation'];

export function App() {
  const uAuths = useAuths();
  const ycIsLogin = uAuths.isLogin();
  const ycIsSuperAdmin = uAuths.isSuperAdmin();
  const uHistory = useHistory();
  const uLocation = useLocation();

  useEffect(() => {
    if (uAuths.isLogin() && connectedBlockedPaths.includes(uLocation.pathname)) {
      uHistory.push('/applications');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uAuths, uLocation.pathname]);

  if (uHistory.location.search.includes('gclid')) {
    localStorage.setItem('isGoogleAd', 'true');
  }

  const checkPage = (): boolean => {
    return !!window.location.pathname.includes('/applications/') && !window.location.pathname.includes('admin');
  };

  return (
    <div id="app">
      <label className="app-version">V{frontVersion}</label>
      <YcHeader />
      {uAuths.isReady()
        && (
          <div className="main">
            {(window.location.pathname !== '/applications' && checkPage()) && (
              <div className="games">
                {
                  uAuths.isClientManager()
                    ? <SideApplicationsManaged />
                    : <SideApplications />
                }
              </div>
            )}
            <div className={`content ${checkPage() ? 'full-size' : ''}`}>
              <UserEditedContextProvider>
                <Switch>
                  {/* Logout route */}
                  {/* <YcRoute path="/pricing" ycIsLogin={!ycIsLogin} YcComponent={PricingPage} /> */}

                  <YcRoute path="/login" ycIsLogin={!ycIsLogin} YcComponent={LoginPage} />
                  <YcRoute path="/iec-presentation" ycIsLogin={!ycIsLogin} YcComponent={IecPresentation} />
                  <YcRoute path="/playable-presentation" ycIsLogin={!ycIsLogin} YcComponent={PlayablePresentation} />
                  <YcRoute path="/sign-up" ycIsLogin={!ycIsLogin} YcComponent={SignUpPage} />
                  <YcRoute path="/reset-password/:token" ycIsLogin={!ycIsLogin} YcComponent={ResetPasswordPage} />
                  <YcRoute path="/forgot-password" ycIsLogin={!ycIsLogin} YcComponent={ForgotPasswordPage} />
                  <YcRoute path="/active-account/:token" ycIsLogin={!ycIsLogin} YcComponent={ActiveAccountPage} />

                  {/* Modules */}
                  <YcRoute path="/applications" ycIsLogin={ycIsLogin} YcComponent={ApplicationModule} />
                  <YcRoute path="/reporting" ycIsLogin={ycIsLogin} YcComponent={ReportingModule} />
                  <YcRoute path="/setup" ycIsLogin={ycIsLogin} canAccess={uAuths.canAccess('applications/playable')} YcComponent={SetupModule} />
                  <YcRoute
                    path="/admin"
                    ycIsLogin={ycIsLogin}
                    canAccess={ycIsSuperAdmin || uAuths.canAccess('mpAdmin')}
                    YcComponent={AdminModule}
                  />
                  <YcRoute path="/account" ycIsLogin={ycIsLogin} YcComponent={AccountModule} />
                  <YcRoute path="/performance" ycIsLogin={ycIsSuperAdmin} YcComponent={PerformanceModule} />

                  <Route exact path="/"><HomePage /></Route>
                  <YcRoute path="/privacy-policies" ycIsLogin YcComponent={PrivacyPolicies} />

                </Switch>
              </UserEditedContextProvider>
            </div>
          </div>
        )}
    </div>
  );
}
