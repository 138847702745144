/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import { MCreative } from '../../../modeles';
import { creativesService } from '../../../services';

export type OrientationType = 'portrait' | 'landscape';

export interface CreativesContextType {
  creatives: MCreative[],

  getCreatives: (type: 'IEC' | 'PLA' | null) => MCreative[] | undefined;
  getIsReady: () => boolean;
  setCreatives: (creatives: MCreative[] | null) => void;
  setIsReady: (ready: boolean) => void;
  adminDelete: (id: number) => void;
  superAdminDelete: (id: number) => void;
}

const CreativesContext = React.createContext<CreativesContextType>({
  creatives: [new MCreative()],

  getCreatives: () => { return undefined; },
  getIsReady: () => { return false; },
  setCreatives: (/* iec: MCreative[] | null */) => { },
  setIsReady: () => { },
  adminDelete: () => { },
  superAdminDelete: () => { },
});

export function CreativesContextProvider({ children }: { children: React.ReactNode }) {
  const [creatives, _setCreatives] = useState<MCreative[]>([new MCreative()]);
  const [isReady, _setIsReady] = useState<boolean>(false);
  const [refreshInterval, _setRefreshInterval] = useState<string | number | NodeJS.Timeout | undefined>();

  // GETTER //
  const getCreatives = useCallback((type: 'IEC' | 'PLA' | null = null) => {
    if (type) {
      return creatives.filter((creative) => creative.type === type);
    }
    return creatives;
  }, [creatives]);

  const getIsReady = useCallback((): boolean => {
    return isReady;
  }, [isReady]);

  // SETTER //
  const setCreatives = useCallback((data: MCreative[] | null) => {
    const newData = data || [new MCreative()];
    _setCreatives(JSON.parse(JSON.stringify(newData)) as MCreative[]);
  }, []);

  const setIsReady = useCallback((ready: boolean) => {
    _setIsReady(ready);
  }, [_setIsReady]);

  // REFRESH //
  useEffect(() => {
    if (creatives.find((creative) => creative.status === 'pending')) {
      if (!refreshInterval) {
        _setRefreshInterval(setInterval(() => {
          creativesService.listingByApplication(creatives[0].applicationId as number).then((data) => {
            _setCreatives(data as MCreative[]);
          });
        }, 3000));
      }
    } else {
      clearInterval(refreshInterval);
    }
  }, [creatives, refreshInterval]);

  // FUNCTION //
  const adminDelete = useCallback(async (id: number) => {
    const dataSource = [...creatives];
    _setCreatives(dataSource.filter((item) => item.id !== id));
    await creativesService.deleteByDate(id);
  }, [creatives]);

  const superAdminDelete = useCallback(async (id: number) => {
    const dataSource = [...creatives];
    _setCreatives(dataSource.filter((item) => item.id !== id));
    await creativesService.remove(id);
  }, [creatives]);

  const value = useMemo(() => ({
    creatives,
    getCreatives,
    getIsReady,
    setCreatives,
    setIsReady,
    adminDelete,
    superAdminDelete,
  }), [
    creatives,
    getCreatives,
    getIsReady,
    setCreatives,
    setIsReady,
    adminDelete,
    superAdminDelete,
  ]);

  return (
    <CreativesContext.Provider value={value}>
      {children}
    </CreativesContext.Provider>
  );
}

export function useCreatives() {
  const context = React.useContext(CreativesContext);
  if (!context) throw new Error('No CreativesContext provider found!');
  return context;
}
