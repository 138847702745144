/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  useMemo, useState, useEffect, useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';

import { applicationsService } from '../../../services';
import { MApplication, MNodeBase } from '../../../modeles';

export interface ApplicationContextType {
  app?: MApplication,
  isReady: boolean,
  saveApp: (c: MApplication) => void,
  updateApp: (c: MApplication) => void,
}

const ApplicationContext = React.createContext<ApplicationContextType>({
  app: undefined,
  isReady: false,
  saveApp: (/* c: MApplication */) => {},
  updateApp: (/* c: MApplication */) => {},
});

export type LoopElem = MNodeBase & { videos?: MNodeBase[], breaks?: MNodeBase[], elements?: MNodeBase[] };
export type LoopCallbackParams = { node: LoopElem, index: number, parentList: MNodeBase[], parentNode: MNodeBase, parentKey: string, pathNode: LoopElem[] };

export function ApplicationContextProvider({ children }: { children: React.ReactNode }) {
  const [app, _setApplication] = useState<MApplication>();
  const [isReady, _setIsReady] = useState<boolean>(false);

  const uHistory = useHistory();

  useEffect(() => {
    const matchs = uHistory.location.pathname.match(/\/applications\/(\d+)/) || [];
    // eslint-disable-next-line radix
    const appId = parseInt(matchs[1] || '-1');
    if (appId !== -1 && (!app || app.id !== appId)) {
      _setIsReady(false);
      applicationsService.view(appId as number).then((data) => {
        if (data) {
          _setApplication(data);
          _setIsReady(true);
        } else {
          uHistory.push('/applications');
        }
      });
      _setIsReady(true);
    } else if (appId === -1 && app) {
      _setApplication(undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uHistory.location.pathname]);

  const saveApp = useCallback((c: MApplication) => {
    applicationsService.edit(app!.id, c).then((data) => {
      if (data) {
        _setApplication(data);
      }
    });
  }, [app]);

  const updateApp = useCallback((c: MApplication) => {
    _setApplication(c);
  }, []);

  const value = useMemo(() => ({
    app,
    isReady,

    saveApp,
    updateApp,
  }), [app, isReady, saveApp, updateApp]);

  return (
    <ApplicationContext.Provider value={value}>
      {children}
    </ApplicationContext.Provider>
  );
}

export function useApplication() {
  const context = React.useContext(ApplicationContext);
  if (!context) throw new Error('No ApplicationContext provider found!');
  return context;
}
