/* eslint-disable @typescript-eslint/no-use-before-define */
import Axios, {
  AxiosError, AxiosRequestConfig, AxiosResponse, RawAxiosRequestHeaders,
} from 'axios';
import { message } from 'antd';
import { MUser } from '../modeles';

export interface IToken {
  token: string;
  id: number;
  accountId: number;
  firstName: string;
  lastName: string;
  account: string;
}

export interface ITokens {
  tokens: IToken[];
}

export class BaseService {
  setToken(token: string) {
    localStorage.setItem('user-token', token);
  }

  addToken(token: string, user: MUser) {
    let tokens = JSON.parse(localStorage.getItem('user-tokens') as string) as ITokens || { tokens: [] };
    const current = tokens.tokens?.find((sToken) => sToken.id === user.id && sToken.accountId === user.accountId);
    if (current) {
      current.token = token;
    } else {
      if (!tokens.tokens) {
        tokens = { tokens: [] };
      }
      tokens.tokens.push({
        token,
        id: user.id,
        accountId: user.accountId,
        firstName: user.firstName,
        lastName: user.lastName,
        account: user.Account?.name || '',
      });
    }
    localStorage.setItem('user-tokens', JSON.stringify(tokens));
  }

  getToken(): string {
    return localStorage.getItem('user-token') as string || '';
  }

  getTokens(): ITokens {
    return JSON.parse(localStorage.getItem('user-tokens') as string) as ITokens || {};
  }

  delToken() {
    localStorage.removeItem('user-token');
  }

  removeToken(token: IToken) {
    const tokens = JSON.parse(localStorage.getItem('user-tokens') as string) as ITokens || {};
    tokens.tokens = tokens.tokens.filter((sToken) => (sToken.id !== token.id || sToken.accountId !== token.accountId));
    localStorage.setItem('user-tokens', JSON.stringify(tokens));
  }

  setLocalData(label: string, value: string) {
    localStorage.setItem(label, value);
  }

  getLocalData(label: string): string {
    return localStorage.getItem(label) as string || '';
  }

  delLocalData(label: string) {
    localStorage.removeItem(label);
  }

  private getCleanUrl(...strings: string[]) {
    return strings.join('/').replace(/\/+/g, '/');
  }

  public _getMessage(mes: any) {
    if (typeof mes === 'string') {
      return mes;
    } if (typeof mes === 'object') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const obj = Object.entries(mes);
      let msg = '';
      for (const [key, value] of obj) {
        msg += `${key} ${(value as { msg: string }).msg}\n`;
      }
      return msg;
    }
    return 'Error';
  }

  private async request(path: string, config: AxiosRequestConfig): Promise<any | null> {
    try {
      if (!config.baseURL) {
        config.baseURL = process.env.REACT_APP_API_URL;
      }
      if (!config.headers || !config.headers.authorization) {
        config.headers = {
          ...config.headers,
          authorization: this.getToken(),
        } as unknown as RawAxiosRequestHeaders;
      }
      const res: AxiosResponse = await Axios(this.getCleanUrl(path), config);
      if (res.data.message || res.data.messages) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success(this._getMessage(res.data.message || res.data.messages));
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return res.data;
    } catch (e) {
      const res: AxiosResponse | undefined = (e as AxiosError).response;
      if (res?.data.message || res?.data.messages) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(this._getMessage(res.data.message || res?.data.messages));
      }
      return null;
    }
    return null;
  }

  public get<ParamsType>(path: string, params: ParamsType, config?: AxiosRequestConfig): any {
    return this.request(path, {
      ...(config || {}),
      method: 'GET',
      params,
    });
  }

  public post<BodyType, ParamsType>(path: string, data: BodyType, params?: ParamsType, config?: AxiosRequestConfig): any {
    return this.request(path, {
      ...(config || {}),
      method: 'POST',
      data,
      params,
    });
  }

  public put<BodyType>(path: string, data: BodyType, config?: AxiosRequestConfig): any {
    return this.request(path, {
      ...(config || {}),
      method: 'PUT',
      data,
    });
  }

  public delete<ParamsType>(path: string, params: ParamsType, config?: AxiosRequestConfig): any {
    return this.request(path, {
      ...(config || {}),
      method: 'DELETE',
      params,
    });
  }

  public async _add<T>(path: string, app: T): Promise<T | null> {
    const p = await bc.post(path, app);
    if (p) {
      return p.data as T;
    }
    return null;
  }

  public async _edit<T, ParamsType>(path: string, data: T, params?: ParamsType): Promise<T | null> {
    const p = await bc.post(path, data, params);
    if (p) {
      return p.data as T;
    }
    return null;
  }

  public async _delete<T, ParamsType>(path: string, params?: ParamsType): Promise<T | null> {
    const p = await bc.delete(path, params);
    if (p) {
      return p.data as T;
    }
    return null;
  }

  public async _view<T>(path: string, data = {}): Promise<T | null> {
    const p = await bc.get(path, data);
    if (p) {
      return p.data as T;
    }
    return null;
  }

  public async _getSuccess(path: string, data = {}): Promise<boolean> {
    const p = await bc.get(path, data);
    if (p) {
      return true;
    }
    return false;
  }

  public async _listing<T>(path: string, params = {}): Promise<T[]> {
    const apps = await bc.get(path, params);
    if (apps) {
      return apps.data as T[];
    }
    return [];
  }

  public async _listingPost<T>(path: string, data = {}): Promise<T[]> {
    const apps = await bc.post(path, data);
    if (apps) {
      return apps.data as T[];
    }
    return [];
  }
}

export const bc = new BaseService();
