import { Tooltip } from 'antd';
import { useState, useEffect } from 'react';

import './button-groups.scss';

type GroupButton = {
  hoverText: string,
  value: number,
  label?: string,
  icon?: string,
};

export const defineSelected = (nb: number, btns: GroupButton[]): boolean[] => {
  const result: boolean[] = [];

  for (let i = 0; i < btns.length; i += 1) {
    const btn = btns[i];

    // eslint-disable-next-line no-bitwise
    result.push((nb & btn.value) !== 0);
  }

  return result;
};

export function SettingButtonGroup(props: {
  onClick: (data: number) => void,
  value: number,
  btns: GroupButton[],
  label: string,
  canMultiple?: boolean,
  disabled?: boolean,
}) {
  const [selected, _setSelected] = useState<boolean[]>([]);

  useEffect(() => {
    if (props.btns === undefined) { return; }

    _setSelected(defineSelected(props.value, props.btns));
  }, [props]);

  const handleClick = (index: number) => {
    let newSelected = [...selected];
    let total: number = 0;

    if (props.canMultiple) {
      newSelected[index] = !newSelected[index];
    } else {
      newSelected = newSelected.map((_, i) => i === index);
    }

    props.btns.forEach((btn: any, i: number) => {
      total += (newSelected[i] && btn.value);
    });
    _setSelected(newSelected);
    props.onClick(total);
  };

  return (
    <div className="mb-1 button-group-container row">
      <div className="col-5">
        <label>{props.label}</label>
      </div>
      <div className="button-group">
        <div className="button-group-again">
          {props?.btns?.map((btn, i) => (
            <Tooltip key={i} title={props.disabled ? '' : btn.hoverText}>
              <div
                className={`
                  group-button
                  ${i + 1 < props.btns.length && 'left-border'}
                  ${props.disabled && 'disabled'}
                  ${selected[i] && 'chosen'}
                `}
                onClick={() => (props.disabled ? null : handleClick(i))}
              >
                {btn?.label && <span>{btn.label}</span>}
                {btn?.icon && <img src={btn.icon} alt="group-btn-icon" />}
              </div>
            </Tooltip>
          ))}
        </div>
      </div>
    </div>
  );
}

type GroupButtonStr = {
  value: string,
  label?: string,
  icon?: string,
};

export function SettingButtonGroupStr(props: {
  onClick: (data: string) => void,
  btns: GroupButtonStr[],
  label: string,
  value: string,
  disabled?: boolean,
}) {
  return (
    <div className="mb-1 button-group-container row">
      <div className="col-5">
        <label>{props.label}</label>
      </div>
      <div className="button-group">
        <div className="button-group-again">
          {props?.btns?.map((btn, i) => (
            <Tooltip key={i} title={props.disabled ? '' : btn.value}>
              <div
                className={`
                  group-button
                  ${i + 1 < props.btns.length && 'left-border'}
                  ${props.disabled && 'disabled'}
                  ${props.value === btn.value && 'chosen'}
                `}
                onClick={() => (props.disabled ? null : props.onClick(btn.value))}
              >
                {btn?.label && <span>{btn.label}</span>}
                {btn?.icon && <img src={btn.icon} alt="group-btn-icon" />}
              </div>
            </Tooltip>
          ))}
        </div>
      </div>
    </div>
  );
}
