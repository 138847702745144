import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { Button, Popconfirm } from 'antd';

import {
  PreviewBreak, PreviewElement, PreviewEndcard, PreviewHover, PreviewLoop,
} from '../previews';
import {
  MCreativeBreak, MCreativeElementBase, MCreativeEndcard, MCreativeHover, MCreativeLoop, MCustomTemplate,
} from '../../../../../modeles';

import './templates-modal.scss';

export function TemplatesModal(props: {
  templates: MCustomTemplate[],
  selectedTemplate: (template: MCustomTemplate) => void,
  removeTemplate: (template: MCustomTemplate) => void,
}) {
  const [templateSelected, setTemplateSelected] = useState<MCustomTemplate>();

  return (
    <div className="templates-modal">
      <div className="templates">
        {
          props.templates.map((template, index) => {
            const node = template.node;
            node._edite = false;

            return (
              <div
                className={`template-card ${templateSelected?.id === template.id ? 'active' : ''}`}
                key={index}
                onClick={() => {
                  setTemplateSelected(template);
                  props.selectedTemplate(template);
                }}
              >
                <div className="template">
                  <div className="element-block" />
                  <div className="element">
                    {
                      node._type === 'element' && (
                      <PreviewElement
                        element={node as MCreativeElementBase}
                      />
                      )
                    }
                    {node._type === 'break' ? <PreviewBreak br={node as MCreativeBreak} /> : ''}
                    {node._type === 'loop' ? <PreviewLoop loop={node as MCreativeLoop} /> : ''}
                    {node._type === 'hover' ? <PreviewHover hover={node as MCreativeHover} /> : ''}
                    {node._type === 'endcard' ? <PreviewEndcard endcard={node as MCreativeEndcard} /> : ''}
                  </div>
                </div>
                <div className="details">
                  {template.name}
                  <Popconfirm
                    title="Sure to delete?"
                    onConfirm={() => { props.removeTemplate(template); }}
                  >
                    <Button
                      className="remove-button"
                      icon={<FontAwesomeIcon icon={faTrash} title="remove" />}
                      type="primary"
                      danger
                      shape="circle"
                    />
                  </Popconfirm>

                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
}
