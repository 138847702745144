import { MDocument } from './document.m';

export class MMppSdk {
  id: number = 0;
  version: string = '';
  createdAt: Date | undefined;
  sdkId?: number;
  Sdk?: MDocument;
  engineCompatibleVersion: string = '';
  isRelease: boolean = false;
  changeLog: string = '';
}
