import { MAccount, MUser } from '../modeles';
import { BaseService } from './base.service';

class ProfileService extends BaseService {
  PATH = 'profile/';

  async view(): Promise<MAccount | null> {
    return this._view(`${this.PATH}view`);
  }

  async updateInformations(data: MUser) {
    await this.post(`${this.PATH}update-informations`, data);
  }

  async updatePassword(data: any) {
    await this.post(`${this.PATH}update-password`, data);
  }
}

export const profileService = new ProfileService();
