import { MCreativeHover } from '../../../../../../modeles';
import { SettingCompBackground } from '../setting-comp-background';
import { YcCard } from '../../../../../../components';
import { getIconByType } from '../../../../utils/iec.utils';

import './setting-hover.scss';

export function SettingHover(props: {
  hover: MCreativeHover
}) {
  return (
    <YcCard title="Hover" icon={getIconByType('hover')} open canOpenAndClose={false}>
      <div className="setting-hover">
        <SettingCompBackground background={props.hover.background} isDeletable={false} />
      </div>
    </YcCard>
  );
}
