/* eslint-disable react/no-danger */
import {
  ExportType, MCreative, MCreativeElementBase, MCreativeHover,
} from '../../../../../modeles';
import { varElement } from '../../exports/functions';
import { PreviewCompBackground } from './preview-comp-background';
import { PreviewElement } from './preview-element';

export const PreviewHover_CSS = `
.preview-hover { position: absolute; top: 0; left: 0; right: 0; bottom: 0; }
`;

export function PreviewHover(props: {
  hover: MCreativeHover,
  base64?: { [key:string]: string },
  isEditable?: boolean,
  videoId?: string,
  exportType?: ExportType,
  language?: string,
  iec?: MCreative,
}) {
  const getVar = () => {
    return varElement(props.hover, 'hover');
  };

  return (
    <div
      className={`preview-hover ${props.videoId ? `hover-video hover-video${props.videoId}` : ''}`}
      id={getVar()}
      style={
        { pointerEvents: props.isEditable ? 'auto' : 'none', display: (props.base64 && props.videoId) ? 'none' : 'block' }
      }
    >
      <PreviewCompBackground background={props.hover.background} />
      {props.hover.elements.map(
        (elem: MCreativeElementBase) => (
          <PreviewElement
            exportType={props.exportType}
            element={elem}
            key={elem._key}
            base64={props.base64}
            language={props.language}
            iec={props.iec}
          />
        ),
      )}
    </div>
  );
}
