import { BaseService } from '../base.service';
import { MAccount, MApplication } from '../../modeles';

class ClientManagerApplicationsService extends BaseService {
  PATH = 'client-manager/applications/';

  async listing(): Promise<MAccount[] | null> {
    return this._listing(`${this.PATH}listing`);
  }

  async sideListingManaged(): Promise<MAccount[] | null> {
    return this._listing(`${this.PATH}side-listing-managed`);
  }

  async add(iec: MApplication): Promise<MApplication | null> {
    return this._add(`${this.PATH}add`, iec);
  }
}

export const clientManagerApplicationsService = new ClientManagerApplicationsService();
