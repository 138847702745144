import { Checkbox, Select, Tag } from 'antd';
import { useState } from 'react';

import './field-check.scss';

export interface FCItem {
  value: string | number | boolean,
  label: string,
  checked?: boolean,
  appKey?: string,
}

export function YcFieldCheckPage(props: {
  label: string,
  items: FCItem[],
  is: boolean,
  disabledSelect?: boolean,
  disabledCheck?: boolean,
  mode?: 'multiple' | 'tags',
  maxTagCount?: number | 'responsive',
  onChange?: (items: FCItem[], is: boolean) => void,
}) {
  const [search, _setSearch] = useState('');

  const getValue = () => props.items.filter((item) => item.checked !== undefined).map((item) => item.value);

  const tagRender = (propsTag: any) => {
    const { label, value, closable } = propsTag;

    const isActive = () => props.items.find((item) => value === item.value)?.checked;

    return (
      <Tag
        color={isActive() ? '#2db7f5' : '#ff5500'}
        closable={closable}
        onClose={(/* e */) => {
          if (props.onChange) {
            const items = [...props.items];
            items.forEach((item) => {
              if (value === item.value) {
                item.checked = undefined;
              }
            });
            props.onChange(items, props.is);
          }
        }}
        onClick={(/* e */) => {
          if (props.onChange) {
            const items = [...props.items];
            items.forEach((item) => {
              if (value === item.value) {
                item.checked = !item.checked;
              }
            });
            props.onChange(items, props.is);
          }
        }}
      >
        {isActive() ? '+' : '-'}
&nbsp;
        {label}
      </Tag>
    );
  };

  const getItems = (): FCItem[] => {
    if (search) {
      const item = props.items.find((e) => e.checked !== true && e.value.toString().toLocaleLowerCase().trim() === search.toLocaleLowerCase());
      if (item) {
        return [{
          value: item.value,
          label: `[${item.value as string}] ${item.label}`,
        }, ...props.items];
      }
    }
    return props.items;
  };

  return (
    <div className="c-field-check">
      <div className="left">
        <Checkbox
          disabled={props.disabledCheck}
          checked={props.is}
          onClick={() => {
            if (props.onChange) {
              props.onChange(props.items, !props.is);
            }
          }}
        />
&nbsp;&nbsp;
        {props.label}
      </div>
      <Select
        mode={props.mode}
        maxTagCount={props.maxTagCount}
        onBlur={() => _setSearch('')}
        value={getValue()}
        filterOption={
        (input, option) => {
          _setSearch(input);
          return !!option?.label.toLowerCase().includes(input.toLocaleLowerCase()) || !!option?.value.toString().includes(input.toString());
        }
      }
        tagRender={tagRender}
        disabled={props.disabledSelect}
        allowClear
        onChange={(values) => {
          _setSearch('');
          if (props.onChange) {
            const items = [...props.items];
            items.forEach((item) => {
              if (values.includes(item.value)) {
                if (item.checked === undefined) {
                  item.checked = values.includes(item.value);
                }
              } else {
                item.checked = undefined;
              }
            });
            props.onChange(items, props.is);
          }
        }}
        options={getItems()}
      />
    </div>
  );
}
