import { Button, Select } from 'antd';
import { useRef, useEffect } from 'react';
import { RetweetOutlined } from '@ant-design/icons';

import { useIec } from '../../context/iec.context';
import { PreviewIec } from '../iec';
import { IecInputNumber } from '../input-number';
import { FLAGS, LANGUAGES } from '../../../../tools';
import { useCreative } from '../../context/creative.context';

import './preview.scss';

export function Preview() {
  const rVideo = useRef<HTMLVideoElement>(null);

  const uIec = useIec();
  const uCreative = useCreative();

  useEffect(() => {
    if (rVideo.current) {
      if (uIec.getBreakActive()) {
        rVideo.current!.currentTime = uIec.getBreakActive()!.time || 0;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uIec.iec]);

  const updateSize = () => {
    const tmp = uIec.iec.iecData._phoneWidth;
    uIec.iec.iecData._phoneWidth = uIec.iec.iecData._phoneHeight;
    uIec.iec.iecData._phoneHeight = tmp;
    uIec.refreshIec(true);
  };

  return (
    <div className="iec-preview">
      <span className="iec-size">
        <IecInputNumber addonBefore="W" field="_phoneWidth" obj={uIec.iec.iecData} />
        <Button
          type="primary"
          shape="circle"
          icon={<RetweetOutlined />}
          onClick={() => {
            updateSize();
            uIec.refreshIec(true);
          }}
        />
        <IecInputNumber addonBefore="H" field="_phoneHeight" obj={uIec.iec.iecData} />
        <Select
          value={uCreative.currentLanguage}
          onChange={(e) => { uCreative.setCurrentLanguage(e); }}
          options={Object.keys(uIec.getEnabledLanguages()).map((flag) => {
            return { value: flag, label: FLAGS[flag] };
          })}
        />
      </span>
      <div
        className="phone"
        style={{
          width: `${uIec.iec.iecData._phoneWidth + 16}px`,
          height: `${uIec.iec.iecData._phoneHeight + 16}px`,
        }}
        key={uIec.getPhoneOrientation()}
      >
        <PreviewIec />
      </div>
    </div>
  );
}
