import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Spin, Button, Input, Modal, Form, Select,
} from 'antd';

import { MAccount, MApplication } from '../../../../modeles';
import { clientManagerApplicationsService, applicationsService, clientManagerAccountService } from '../../../../services';
import { YcTitle } from '../../../../components';
import { useAuths } from '../../../../contexts/auths.context';

import './applications.page.scss';
import { ApplicationsListing } from './application-listing';
import { ApplicationsManagedListing } from './application-managed-listing';

export function ApplicationsPage() {
  const uAuths = useAuths();
  const uHistory = useHistory();

  const [loading, _setLoading] = useState(false);
  const [isCreateApp, _setIsCreateApp] = useState<boolean>(false);
  const [accountsManaged, _setAccountManaged] = useState<MAccount[]>([]);
  const [searchValue, _setSearchValue] = useState<string>('');

  useEffect(() => {
    _setLoading(true);
    clientManagerAccountService.accountsManaged().then((data) => {
      _setAccountManaged(data || []);
      _setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCreate = (values: MApplication) => {
    const promise = uAuths.isClientManager()
      ? clientManagerApplicationsService.add(values)
      : applicationsService.add(values);

    promise.then((result) => {
      if (result) {
        uHistory.push(`/applications/${result.id}/settings`);
      }
    });
  };

  return (
    <div id="applications">
      <YcTitle label="Applications" />
      <Spin spinning={loading}>
        <div className="row mb-3">
          <div className="col-lg-4">
            <Input placeholder="Search" onChange={(e) => { _setSearchValue(e.target.value as string); }} />
          </div>
          <div className="col-lg-8">
            <Button
              type="primary"
              className="bt-add"
              loading={loading}
              onClick={() => { _setIsCreateApp(true); }}
            >Add Application
            </Button>
          </div>
        </div>
      </Spin>
      {
        uAuths.isClientManager()
          ? <ApplicationsManagedListing searchValue={searchValue} />
          : <ApplicationsListing searchValue={searchValue} />
      }
      <Modal
        title="Create Application"
        open={isCreateApp}
        okText={<>submit</>}
        okButtonProps={{ form: 'formCreate', htmlType: 'submit' }}
        onCancel={() => {
          _setIsCreateApp(false);
        }}
      >
        <Form id="formCreate" onFinish={onCreate} layout="vertical">
          {
            uAuths.isClientManager() && (
              <Form.Item label="Account" name="accountId" rules={[{ required: true }]}>
                <Select
                  showSearch
                  placeholder="Select a account"
                  options={accountsManaged.map((account) => ({
                    value: account.id,
                    label: account.name,
                  }))}
                />
              </Form.Item>
            )
          }
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input type="text" placeholder="My App" />
          </Form.Item>
          <Form.Item label="Key (Your own key)" name="key" rules={[{ required: true }]}>
            <Input type="text" className="key" placeholder="MAP" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
