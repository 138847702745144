import { Spin } from 'antd';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal } from '@fortawesome/pro-regular-svg-icons';

import { MpColumnType, MpTable, YcTags } from '../../../../components';
import { Result, ResultLine } from '../../pages/performance/performance.page';

import './creative-list.scss';

export function CreativeList(props: {
  loading: boolean;
  results: Result | null
}) {
  const columPlatform: MpColumnType<ResultLine> = {
    title: 'Platform',
    key: 'platform',
    dataIndex: 'platform',
    mpSort: 'string',
    mpCanNotHide: true,
  };

  const columNetwork: MpColumnType<ResultLine> = {
    title: 'Network',
    key: 'network',
    dataIndex: 'network',
    mpSort: 'string',
    mpCanNotHide: true,
  };

  const columApplication: MpColumnType<ResultLine> = {
    title: 'Application',
    key: 'applicationKey',
    dataIndex: 'applicationKey',
    mpSort: 'string',
    mpCanNotHide: true,
    render: (text: string, record: any) => (
      <Link to={`/applications/${record.applicationId}/creatives`}>
        {record.applicationKey}
      </Link>
    ),
  };

  const columCreative: MpColumnType<ResultLine> = {
    title: 'Creative',
    key: 'creativeName',
    dataIndex: 'creativeName',
    mpSort: 'string',
    mpCanNotHide: true,
    render: (text: string, record: any) => (
      <Link to={`/applications/${record.applicationId}/creatives/iec/${record.creativeId}`}>
        {record.creativeName}
      </Link>
    ),
  };

  const columUser: MpColumnType<ResultLine> = {
    title: 'User',
    key: 'userName',
    dataIndex: 'userName',
    mpSort: 'string',
    mpCanNotHide: true,
  };

  const columCountry: MpColumnType<ResultLine> = {
    title: 'Country',
    key: 'countryCode',
    dataIndex: 'countryCode',
    mpSort: 'string',
    mpCanNotHide: true,
  };

  const columns: MpColumnType<ResultLine>[] = [
    {
      title: 'TOP',
      dataIndex: 'isTop',
      key: 'isTop',
      mpSort: 'string',
      align: 'center',
      width: 75,
      filters: [
        {
          text: 'Is Top',
          value: true,
        },
        {
          text: 'Is Not',
          value: false,
        },
      ],
      onFilter: (value: any, record: any) => record.isTop === value || false,
      render: (text: string, record: any) => (
        <label>{record.isTop
          ? <FontAwesomeIcon className="icon-link" icon={faMedal} color="red" />
          : undefined}
        </label>
      ),
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      render: (text: string, record: any) => (
        <YcTags tags={record.tags} />
      ),
    },
    {
      title: 'Videos',
      dataIndex: 'videos',
      key: 'videos',
      sorter: (a: any, b: any) => +(a.schema.raw?.videos || 0) - +(b.schema.raw?.videos || 0),
      render: (text: string, record: any) => (
        <label>{record.schema.raw?.videos || 'null'}</label>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      sorter: (a: any, b: any) => +(a.schema.raw?.breaks || 0) - +(b.schema.raw?.breaks || 0),
      render: (text: string, record: any) => (
        <label>{record.schema.raw ? +record.schema.raw.breaks + +record.schema.raw.loops : 'null'}</label>
      ),
    },
    {
      title: 'Global Hover',
      dataIndex: 'globalHover',
      key: 'globalHover',
      sorter: (a: any, b: any) => (a.schema.raw?.globalHover || 0) - (b.schema.raw?.globalHover || 0),
      filters: [
        {
          text: 'True',
          value: true,
        },
        {
          text: 'False',
          value: false,
        },
      ],
      onFilter: (value: any, record: any) => record.schema.raw?.globalHover === value || false,
      render: (text: string, record: any) => (
        <label>{record.schema.raw?.globalHover ? 'true' : 'false'}</label>
      ),
    },
    {
      title: 'End Card',
      dataIndex: 'endcard',
      key: 'endcard',
      sorter: (a: any, b: any) => (a.schema.raw?.endcard || 0) - (b.schema.raw?.endcard || 0),
      filters: [
        {
          text: 'True',
          value: true,
        },
        {
          text: 'False',
          value: false,
        },
      ],
      onFilter: (value: any, record: any) => record.schema.raw?.endcard === value || false,
      render: (text: string, record: any) => (
        <label>{record.schema.raw?.endcard ? 'true' : 'false'}</label>
      ),
    },
    {
      title: 'Impression',
      dataIndex: 'displayed',
      key: 'displayed',
      mpSort: 'number',
      render: (text: string, record: any) => (
        <label>{record.displayed.toLocaleString()}</label>
      ),
    },
    {
      title: 'Redirect',
      dataIndex: 'redirect',
      key: 'redirect',
      mpSort: 'number',
      render: (text: string, record: any) => (
        <label>{record.redirect.toLocaleString()}</label>
      ),
    },
    {
      title: 'Conversion',
      dataIndex: 'conversion',
      key: 'conversion',
      mpSort: 'number',
      render: (text: string, record: any) => (
        <label>{`${((+record.redirect * 100) / +record.displayed).toFixed(3)}%`}</label>
      ),
    },
  ];

  const columnsMemo = useMemo(() => {
    let cols: MpColumnType<ResultLine>[] = [...columns];
    let bCols: MpColumnType<ResultLine>[] = [];
    if (props.results?.is.includes('network')) {
      bCols = [columNetwork, ...bCols];
    }
    if (props.results?.is.includes('applicationId')) {
      bCols = [columApplication, ...bCols];
    }
    if (props.results?.is.includes('creativeId')) {
      bCols = [columCreative, ...bCols];
    }
    if (props.results?.is.includes('platform')) {
      bCols = [columPlatform, ...bCols];
    }
    if (props.results?.is.includes('country')) {
      bCols = [columCountry, ...bCols];
    }
    if (props.results?.is.includes('userId')) {
      bCols = [columUser, ...bCols];
    }
    if (bCols.length) {
      cols = [{
        title: 'Breakdown',
        dataIndex: 'breakdown',
        key: 'breakdown',
        mpCanNotHide: true,
        children: bCols,
      }, ...cols];
    }
    return cols;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.results?.is]);

  const summarys = props.results ? [
    { key: 'generique', value: undefined, colSpan: props.results.is.length },
    { key: 'isTop', value: undefined },
    { key: 'tags', value: undefined },
    { key: 'videos', value: undefined },
    { key: 'actions', value: undefined },
    { key: 'globalHover', value: undefined },
    { key: 'endcard', value: undefined },
    { key: 'displayed', value: props.results.total.displayed.toLocaleString() || undefined },
    { key: 'redirect', value: props.results.total.redirect.toLocaleString() || undefined },
    { key: 'conversion', value: `${((+props.results.total.redirect * 100) / +props.results.total.displayed).toFixed(3)}%` },
  ] : undefined;

  return (
    <div id="creative-list">
      <Spin spinning={props.loading}>
        {!!props.results && (
        <MpTable
          bordered
          size="small"
          dataSource={props.results.results}
          columns={columnsMemo}
          mpSummarys={summarys}
          mpTableKey="reporting-advertising"
          rowKey="id"
          horizontalScrollEnabled
        />
        )}
      </Spin>
    </div>
  );
}
