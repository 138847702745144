import { MBuildError } from '../modeles';
import { BaseService } from './base.service';

class BuildErrorService extends BaseService {
  PATH = 'build-error/';

  async listing(): Promise<MBuildError[] | null> {
    return this._listing(`${this.PATH}listing`);
  }
}

export const buildErrorService = new BuildErrorService();
