import type { Color } from 'antd/es/color-picker';
import { useEffect, useState } from 'react';
import { ColorPicker } from 'antd';

import { usePlayable } from '../../../context/playable.context';
import { IColorJson } from '../../../../../modeles/MP_Interfaces';
import { ColorTools } from '../../../../../tools';

export function SettingCompColor(props: {
  color?: IColorJson,
  label: string,
  onChange: (color: IColorJson, updateJson: boolean) => void,
  disabled?: boolean,
  defaultType?: string,
  noAlpha?: boolean,
}) {
  const uPlayable = usePlayable();

  const defaultTypes: { [key: string]: IColorJson } = {
    black: new uPlayable.playableClasses.Color(0, 0, 0, 1),
    white: new uPlayable.playableClasses.Color(255, 255, 255, 1),
    sky: new uPlayable.playableClasses.Color(19, 30, 45, 1),
    grey: new uPlayable.playableClasses.Color(50, 50, 50, 1),
  };

  const [color, _setColor] = useState<any>(defaultTypes[props.defaultType ?? 0]);

  useEffect(() => {
    if (props.color !== undefined) {
      _setColor(ColorTools.mToWebRgba({ ...props.color, a: props.noAlpha ? 1 : props.color.a }));
    }
  }, [props.color, props.noAlpha]);

  const editColor = (newValue: IColorJson, updateJson: boolean = true) => {
    props.onChange(newValue, updateJson);
  };

  const getColor = (colorSelected: Color): IColorJson => {
    const newColor = colorSelected.toRgb();
    _setColor(newColor);

    return {
      r: newColor.r / 255,
      g: newColor.g / 255,
      b: newColor.b / 255,
      a: newColor.a,
    };
  };

  const newColor = (value: IColorJson): IColorJson => {
    return new uPlayable.playableClasses.Color(
      value.r,
      value.g,
      value.b,
      value.a,
    ) as IColorJson;
  };

  return (
    <div className="row d-flex mb-1 align-items-center">
      <div className="col-5">
        <label>{props.label}</label>
      </div>
      <div className="col-7">
        <ColorPicker
          disabledAlpha={props.noAlpha}
          disabled={props.disabled}
          value={color as Color}
          onChangeComplete={(e: Color) => editColor(newColor(getColor(e)))}
          onChange={(e: Color) => editColor(newColor(getColor(e)), false)}
          defaultFormat="rgb"
        />
      </div>
    </div>
  );
}
