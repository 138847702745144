/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import JSZip from 'jszip';
import ReactDOMServer from 'react-dom/server';

import {
  Mintegral,
  IronSource,
  YsoNetwork,
  GoogleAds,
  Meta as MetaNetwork,
  AppLovin,
  UnityAds,
  DigitalTurbine,
  Tiktok,
  Moloco,
} from '../../components';
import { getFontsUsed } from '../../utils/iec.utils';
import {
  MApplication, MCreative, MCreativeExport, MCustomFont,
} from '../../../../modeles';
import { FilesTools } from '../../../../tools';
import { ExportHtml } from '../../components/export';
import {
  addCreativeExports,
  exportPlatform, generateExportName, getZipLanguageFile, htmlDecode,
} from '../../../../tools/export.tool';
import { setNetwork, getNetwork } from '../../../../networks/network-integration';
import { creativeExportsService } from '../../../../services';

export async function exportIec(
  platform: string,
  iec: MCreative,
  application: MApplication,
  exportFormatString: string,
  enableTranslation: boolean,
  selectedTranslation: string[],
  settingsFonts: MCustomFont[],
  selectedNetwork: any[],
) {
  const zip = new JSZip();
  const fontsName = getFontsUsed(iec);
  const fonts = await FilesTools.setBase64Fonts(
    fontsName.map((fontName) => settingsFonts.find((f) => f.name.toLocaleLowerCase() === fontName.toLocaleLowerCase())!),
  );
  const translations = enableTranslation ? selectedTranslation : ['ZZ'];
  for (let st = 0; st < translations.length; st += 1) {
    for (let i = 0; i < selectedNetwork.length; i += 1) {
      const network = selectedNetwork[i];
      setNetwork(network.value as string);
      const NETWORK = getNetwork();

      let finalIec = '<!DOCTYPE html>';
      const content = await ExportHtml(iec, 'Export', translations[st]);

      switch (network.value) {
        case 'mintegral':
          finalIec += htmlDecode(ReactDOMServer.renderToString(
            <Mintegral iec={iec} NETWORK={NETWORK} appInfo={iec} fonts={fonts} body={content} />,
          ));
          break;
        case 'applovin':
          finalIec += htmlDecode(ReactDOMServer.renderToString(
            <AppLovin iec={iec} NETWORK={NETWORK} appInfo={iec} fonts={fonts} body={content} />,
          ));
          break;
        case 'unityads':
          finalIec += htmlDecode(ReactDOMServer.renderToString(
            <UnityAds iec={iec} NETWORK={NETWORK} appInfo={iec} fonts={fonts} body={content} />,
          ));
          break;
        case 'digitalturbine':
          finalIec += htmlDecode(ReactDOMServer.renderToString(
            <DigitalTurbine iec={iec} NETWORK={NETWORK} appInfo={iec} fonts={fonts} body={content} />,
          ));
          break;
        case 'ironsource':
          finalIec += htmlDecode(ReactDOMServer.renderToString(
            <IronSource iec={iec} NETWORK={NETWORK} appInfo={iec} fonts={fonts} body={content} />,
          ));
          break;
        case 'ysonetwork':
          finalIec += htmlDecode(ReactDOMServer.renderToString(
            <YsoNetwork iec={iec} NETWORK={NETWORK} appInfo={iec} fonts={fonts} body={content} />,
          ));
          break;
        case 'googleads':
          finalIec += htmlDecode(ReactDOMServer.renderToString(
            <GoogleAds iec={iec} NETWORK={NETWORK} appInfo={iec} fonts={fonts} body={content} />,
          ));
          break;
        case 'meta':
          finalIec += htmlDecode(ReactDOMServer.renderToString(
            <MetaNetwork iec={iec} NETWORK={NETWORK} appInfo={iec} fonts={fonts} body={content} />,
          ));
          break;
        case 'tiktok':
          finalIec += htmlDecode(ReactDOMServer.renderToString(
            <Tiktok iec={iec} NETWORK={NETWORK} appInfo={iec} fonts={fonts} body={content} />,
          ));
          break;
        case 'moloco_html':
        case 'moloco_txt':
          finalIec += htmlDecode(ReactDOMServer.renderToString(
            <Moloco iec={iec} NETWORK={NETWORK} appInfo={iec} fonts={fonts} body={content} />,
          ));
          break;
        default:
          console.error(`Export of ${network.value} failed`);
          break;
      }
      if (finalIec) {
        const file = new Blob([finalIec], {
          type: 'text/plain',
        });
        if (network.value === 'mintegral') {
          const MintegralJSZip = new JSZip();
          MintegralJSZip.file(`${generateExportName(
            {
              crea_name: iec.name.toLocaleUpperCase().replaceAll(' ', '_'),
              app_name: application.name.toLocaleUpperCase().replaceAll(' ', '_'),
              key: application.key.toUpperCase(),
              type: 'I',
              version: iec.version?.toString() || '0.0.0',
              network: network.value.toUpperCase(),
              platform: exportPlatform(platform),
            },
            exportFormatString,
          )}.html`, file);
          zip.file(`${getZipLanguageFile(translations, st)}${generateExportName(
            {
              crea_name: iec.name.toLocaleUpperCase().replaceAll(' ', '_'),
              app_name: application.name.toLocaleUpperCase().replaceAll(' ', '_'),
              key: application.key.toUpperCase(),
              type: 'I',
              version: iec.version?.toString() || '0.0.0',
              network: network.value.toUpperCase(),
              platform: exportPlatform(platform),
            },
            exportFormatString,
          )}.zip`, MintegralJSZip.generateAsync({ type: 'blob' }));
        } else if (network.value === 'meta') {
          if (selectedNetwork.length === 1) {
            zip.file('index.html', file);
          } else {
            const MintegralJSZip = new JSZip();
            MintegralJSZip.file('index.html', file);
            zip.file(`${getZipLanguageFile(translations, st)}${generateExportName(
              {
                crea_name: iec.name.toLocaleUpperCase().replaceAll(' ', '_'),
                app_name: application.name.toLocaleUpperCase().replaceAll(' ', '_'),
                key: application.key.toUpperCase(),
                type: 'I',
                version: iec.version?.toString() || '0.0.0',
                network: network.value.toUpperCase(),
                platform: exportPlatform(platform),
              },
              exportFormatString,
            )}.zip`, MintegralJSZip.generateAsync({ type: 'blob' }));
          }
        } else if (network.value === 'tiktok') {
          const jsonContent = '{"playable_orientation": 0}';
          if (selectedNetwork.length === 1) {
            zip.file('index.html', file);
            zip.file('config.json', jsonContent);
          } else {
            const MintegralJSZip = new JSZip();
            MintegralJSZip.file('index.html', file);
            MintegralJSZip.file('config.json', jsonContent);
            zip.file(`${getZipLanguageFile(translations, st)}${generateExportName(
              {
                crea_name: iec.name.toLocaleUpperCase().replaceAll(' ', '_'),
                app_name: application.name.toLocaleUpperCase().replaceAll(' ', '_'),
                key: application.key.toUpperCase(),
                type: 'I',
                version: iec.version?.toString() || '0.0.0',
                network: network.value.toUpperCase(),
                platform: exportPlatform(platform),
              },
              exportFormatString,
            )}.zip`, MintegralJSZip.generateAsync({ type: 'blob' }));
          }
        } else if (network.value === 'moloco_txt') {
          const moloco = new FormData();
          moloco.append('export', file, 'moloco.html');
          const molocoFile = await creativeExportsService.uploadExportMoloco(moloco);

          let txtContent = '<script src="mraid.js"></script>';
          // eslint-disable-next-line max-len
          txtContent += '\n<script src="https://api.monsterplayable.com/inject/moloco?ad={ad-id}&mraid_viewable=#IMP_TRACE_MRAID_VIEWABLE_ESC#&game_viewable=#IMP_TRACE_GAME_VIEWABLE_ESC#&engagement=#IMP_TRACE_ENGAGEMENT_ESC#&complete=#IMP_TRACE_COMPLETE_ESC#&click=#CLICK_TEMPLATE_ESC#"></script>';
          txtContent += '\n%{IMP_BEACON}';
          txtContent += `\n<script src="https://api.monsterplayable.com/inject/moloco/html/${molocoFile.data.id}"></script>`;

          const fileName = `${getZipLanguageFile(translations, st)}${generateExportName(
            {
              crea_name: iec.name.toLocaleUpperCase().replaceAll(' ', '_'),
              app_name: application.name.toLocaleUpperCase().replaceAll(' ', '_'),
              key: application.key.toUpperCase(),
              type: 'I',
              version: iec.version?.toString() || '0.0.0',
              network: network.value.toUpperCase(),
              platform: exportPlatform(platform),
            },
            exportFormatString,
          )}.txt`;

          zip.file(fileName, txtContent);
        } else {
          const fileName = `${getZipLanguageFile(translations, st)}${generateExportName(
            {
              crea_name: iec.name.toLocaleUpperCase().replaceAll(' ', '_'),
              app_name: application.name.toLocaleUpperCase().replaceAll(' ', '_'),
              key: application.key.toUpperCase(),
              type: 'I',
              version: iec.version?.toString() || '0.0.0',
              network: network.value.toUpperCase(),
              platform: exportPlatform(platform),
            },
            exportFormatString,
          )}.html`;
          zip.file(fileName, file);
        }
      }
    }
  }
  await zip.generateAsync({ type: 'blob' })
    .then(async (content) => {
      const formData = new FormData();
      formData.append('export', content);
      const file = await creativeExportsService.uploadExport(formData);
      const name = generateExportName(
        {
          crea_name: iec.name.toLocaleUpperCase().replaceAll(' ', '_'),
          app_name: application.name.toLocaleUpperCase().replaceAll(' ', '_'),
          key: application.key.toUpperCase(),
          type: 'I',
          version: iec.version?.toString() || '0.0.0',
          platform: exportPlatform(platform),
          network: selectedNetwork.length === 1 ? selectedNetwork[0].value.toUpperCase() : '',
        },
        exportFormatString,
      );
      const newCreativeExport: MCreativeExport = {
        ...new MCreativeExport(),
        name,
        creativeId: iec.id,
        networks: selectedNetwork,
        Export: file.data,
        platform: exportPlatform(platform),
      };
      await addCreativeExports(newCreativeExport, content, platform, name);
    });
}
