import { useEffect, useState } from 'react';
import { Button, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft, faArrowsUpDownLeftRight, faArrowLeftToLine, faCircleSmall,
} from '@fortawesome/pro-regular-svg-icons';
import { InfoCircleOutlined } from '@ant-design/icons';

import { MCompTransform, MCompTransformBase, MCreativeElementBase } from '../../../../../../modeles';
import { useIec } from '../../../../context/iec.context';
import { YcCard } from '../../../../../../components';
import { IecInputNumber } from '../../../input-number';
import { IecSwitch } from '../../../iec-switch';

import './setting-comp-transform.scss';

function SettingCompTransformT(props: {
  transform: MCompTransformBase,
  element: MCreativeElementBase,
  label?: string,
}) {
  const uIec = useIec();
  const [shift, _setShift] = useState(false);

  const parentWidth = () => {
    return document.getElementById(props.element._key)?.parentElement?.clientWidth as number || props.transform.width;
  };

  const parentHeight = () => {
    return document.getElementById(props.element._key)?.parentElement?.clientHeight as number || props.transform.height;
  };

  const isLeft = () => { return props.transform.x === 0; };
  const isRight = () => { return props.transform.x + props.transform.width === parentWidth(); };
  const isTop = () => { return props.transform.y === 0; };
  const isBottom = () => { return props.transform.y + props.transform.height === parentHeight(); };
  const isFull = () => { return isLeft() && isRight() && isTop() && isBottom(); };
  const isCenterX = () => { return props.transform.x === Math.ceil((parentWidth() - props.transform.width) / 2); };
  const isCenterY = () => { return props.transform.y === Math.ceil((parentHeight() - props.transform.height) / 2); };
  const isCenter = () => { return isCenterX() && isCenterY(); };

  useEffect(() => {
    const keydown = (event: KeyboardEvent) => {
      _setShift(event.shiftKey as boolean);
    };
    const keyup = (event: KeyboardEvent) => {
      _setShift(event.shiftKey as boolean);
    };
    document.addEventListener('keydown', keydown);
    document.addEventListener('keyup', keyup);
    return () => {
      document.removeEventListener('keydown', keydown);
      document.removeEventListener('keyup', keyup);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.element]);
  return (
    <div className="setting-comp-transform">
      {props.label && <h6>{props.label}</h6>}
      <div className="row">
        <div className="col-2 input-separate">
          {!shift
          && (
            <>
              <Tooltip title='Press "Shift" to get more options!'>
                <InfoCircleOutlined className="badge-information" />
              </Tooltip>
              <div className="block-bts">
                <Button
                  type="text"
                  className={isTop() ? 'yc-active' : ''}
                  onClick={() => {
                    props.transform.height += props.transform.y;
                    props.transform.y = 0;
                    uIec.refreshIec(true);
                  }}
                  icon={<FontAwesomeIcon rotation={90} icon={faArrowLeft} />}
                />
                <div />
                <Button
                  type="text"
                  className={isLeft() ? 'yc-active' : ''}
                  onClick={() => {
                    props.transform.width += props.transform.x;
                    props.transform.x = 0;
                    uIec.refreshIec(true);
                  }}
                  icon={<FontAwesomeIcon icon={faArrowLeft} />}
                />
                <Button
                  type="text"
                  className={isFull() ? 'yc-active' : ''}
                  onClick={() => {
                    props.transform.x = 0;
                    props.transform.y = 0;
                    props.transform.width = parentWidth();
                    props.transform.height = parentHeight();
                    uIec.refreshIec(true);
                  }}
                  icon={<FontAwesomeIcon lightingColor="white" rotation={90} icon={faArrowsUpDownLeftRight} />}
                />
                <Button
                  type="text"
                  className={isRight() ? 'yc-active' : ''}
                  onClick={() => {
                    props.transform.width = parentWidth() - props.transform.x;
                    uIec.refreshIec(true);
                  }}
                  icon={<FontAwesomeIcon rotation={180} icon={faArrowLeft} />}
                />
                <div />
                <Button
                  type="text"
                  className={isBottom() ? 'yc-active' : ''}
                  onClick={() => {
                    props.transform.height = parentHeight() - props.transform.y;
                    uIec.refreshIec(true);
                  }}
                  icon={<FontAwesomeIcon rotation={270} icon={faArrowLeft} />}
                />
              </div>
            </>
          )}
          {shift
          && (
          <div className="block-bts">
            <Button
              type="text"
              className={isTop() ? 'yc-active' : ''}
              onClick={() => {
                props.transform.y = 0;
                uIec.refreshIec(true);
              }}
              icon={<FontAwesomeIcon rotation={90} icon={faArrowLeftToLine} />}
            />
            <div />
            <Button
              type="text"
              className={isLeft() ? 'yc-active' : ''}
              onClick={() => {
                props.transform.x = 0;
                uIec.refreshIec(true);
              }}
              icon={<FontAwesomeIcon icon={faArrowLeftToLine} />}
            />
            <Button
              type="text"
              className={isCenter() ? 'yc-active' : ''}
              onClick={() => {
                props.transform.x = Math.ceil((parentWidth() - props.transform.width) / 2);
                props.transform.y = Math.ceil((parentHeight() - props.transform.height) / 2);
                uIec.refreshIec(true);
              }}
              icon={<FontAwesomeIcon lightingColor="white" rotation={90} icon={faCircleSmall} />}
            />
            <Button
              type="text"
              className={isRight() ? 'yc-active' : ''}
              onClick={() => {
                props.transform.x = parentWidth() - props.transform.width;
                uIec.refreshIec(true);
              }}
              icon={<FontAwesomeIcon rotation={180} icon={faArrowLeftToLine} />}
            />
            <div />
            <Button
              type="text"
              className={isBottom() ? 'yc-active' : ''}
              onClick={() => {
                props.transform.y = parentHeight() - props.transform.height;
                uIec.refreshIec(true);
              }}
              icon={<FontAwesomeIcon rotation={270} icon={faArrowLeftToLine} />}
            />
          </div>
          )}
        </div>
        <div className="col-2 input-separate">
          <IecInputNumber label="Width" field="width" obj={props.transform} />
        </div>
        <div className="col-2 input-separate">
          <IecInputNumber label="Height" field="height" obj={props.transform} />
        </div>
        <div className="col-2 input-separate">
          <IecInputNumber label="X" field="x" obj={props.transform} />
        </div>
        <div className="col-2 input-separate">
          <IecInputNumber label="Y" field="y" obj={props.transform} />
        </div>
        <div className="col-2 input-separate">
          <IecInputNumber label="Rotate" field="rotate" obj={props.transform} />
        </div>
      </div>
    </div>
  );
}

export function SettingCompTransform(props: {
  transform: MCompTransform,
  element: MCreativeElementBase,
}) {
  const uIec = useIec();

  return (
    <YcCard
      title="Transform"
      light
      open={props.transform._open}
      onClickTitle={(val) => {
        props.transform._open = val;
        uIec.refreshIec(true);
      }}
    >
      <div className="setting-comp-transform">
        {!props.transform.isTransformDifferent && <SettingCompTransformT transform={props.transform} element={props.element} />}
        {props.transform.isTransformDifferent && uIec.getPhoneOrientation() === 'portrait' && (
          <SettingCompTransformT transform={props.transform.portrait} element={props.element} label="Portrait" />
        ) }
        {props.transform.isTransformDifferent && uIec.getPhoneOrientation() === 'landscape' && (
          <SettingCompTransformT transform={props.transform.landscape} element={props.element} label="Landscape" />
        ) }
        <IecSwitch label="Transform Different between Portrait and Lanscape" field="isTransformDifferent" obj={props.transform} />
      </div>
    </YcCard>
  );
}
