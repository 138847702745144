import { Link, useHistory, useParams } from 'react-router-dom';
import { ColumnType } from 'antd/lib/table';
import {
  Table, Spin, Button, Tooltip, Popover,
  Modal, Form, Input, UploadProps, Upload, message,
} from 'antd';
import {
  AndroidFilled, AppleFilled, InboxOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck, faGear, faTriangleExclamation, faXmark,
} from '@fortawesome/pro-regular-svg-icons';

import { MCreative } from '../../../../../../modeles';
import packageJson from '../../../../../../../package.json';
import { useAuths } from '../../../../../../contexts/auths.context';
import { CreativesContextType, useCreatives } from '../../../../context/creatives.context';
import { applicationsService, creativesService } from '../../../../../../services';
import { antFormNormFile, getUniqueList } from '../../../../../../tools';
import { PlayablesListActions } from './playables-list-actions';

import './playables-list.page.scss';

const frontVersion = packageJson.version;
const { Dragger } = Upload;

const statusIconsMap = {
  null: { icon: faXmark, hoverText: 'No status specified', color: 'black' },
  failed: { icon: faXmark, hoverText: 'Error building', color: 'red' },
  succeeded: { icon: faCheck, color: 'green' },
  pending: {
    icon: faGear,
    hoverText: 'Building',
    color: 'orange',
    className: 'rotate',
  },
  running: {
    icon: faGear,
    hoverText: 'Building',
    color: 'orange',
    className: 'rotate',
  },
};

export function PlayablesListPage(props: {
  filter: string;
}) {
  const uCreatives: CreativesContextType = useCreatives();
  const uAuths = useAuths();
  const uHistory = useHistory();
  const uParams: any = useParams();

  const [playables, _setPlayables] = useState<MCreative[]>([]);
  const [nextVersion, _setNextVersion] = useState<number>();
  const [isImportPlayable, _setIsImportPlayable] = useState<boolean>(false);
  const [playableFileId, _setPlayableFileId] = useState<number>();

  useEffect(() => {
    _setPlayables(uCreatives.getCreatives('PLA') || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uCreatives.getIsReady(), uCreatives.creatives]);

  useEffect(() => {
    _setPlayables((uCreatives.getCreatives('PLA') || [])
      .filter((iec) => iec.name.toLowerCase().includes(props.filter)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filter]);

  const versionWarning = (
    <div className="warning-popup">
      <p>The creation tool has been updated.</p>
      <p>Please check your creative before export</p>
    </div>
  );

  const playablesColumns: ColumnType<MCreative>[] = [
    {
      title: 'Playables',
      key: 'name',
      render: (text: string, record: MCreative) => (
        <Link to={`creatives/playable/${record.id}`}>{record.name}</Link>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name || '') || -1,
    },
    {
      title: 'User',
      key: 'user',
      render: (text: string, record: MCreative) => {
        return (record.User?.name)
          ? <label>{record.User?.name}</label>
          : <label>{record.User?.email}</label>;
      },
      sorter: (a: MCreative, b: MCreative) => a.User?.email.localeCompare(b.User?.email || '') || -1,
      onFilter: (value: any, record: MCreative) => record.User?.name === (value as string) || false,
      filters: getUniqueList(playables, 'User.name').map((data) => ({
        text: data,
        value: data,
      })),
    },
    {
      title: 'Status',
      key: 'status',
      align: 'center',
      render: (text: string, record: MCreative) => {
        const status = record.status;

        return (
          // @ts-ignore
          <Tooltip title={statusIconsMap[status]?.hoverText}>
            <FontAwesomeIcon
              icon={statusIconsMap[status].icon}
              color={statusIconsMap[status].color}
              // @ts-ignore
              className={statusIconsMap[status]?.className}
              size="xl"
            />
          </Tooltip>
        );
      },
      sorter: (a: MCreative, b: MCreative) => a?.status?.localeCompare(b?.status || '') || -1,
    },
    {
      title: 'Version',
      key: 'version',
      fixed: 'right',
      width: '100px',
      align: 'center',
      render: (text: number, record: MCreative) => (
        <label>{record.version}</label>
      ),
      sorter: (a: MCreative, b: MCreative) => (
        a.version.toString().localeCompare(b.version.toString() || '') || -1
      ),
    },
    {
      title: 'Sub version',
      key: 'subVersion',
      fixed: 'right',
      width: '130px',
      align: 'center',
      render: (text: number, record: MCreative) => (
        <label>{record.subVersion}</label>
      ),
      sorter: (a: MCreative, b: MCreative) => (
        a.subVersion.toString().localeCompare(b.subVersion.toString() || '') || -1
      ),
    },
    {
      title: 'Exported',
      key: 'exported',
      fixed: 'right',
      width: '100px',
      align: 'center',
      render: (text: string, record: MCreative) => (
        <div className="exported">
          {record.androidIsExported && <AndroidFilled />}
          {record.iosIsExported && <AppleFilled />}
        </div>
      ),
    },
    {
      title: 'Info',
      key: 'information',
      fixed: 'right',
      width: '50px',
      align: 'center',
      render: (text: number, record: MCreative) => (
        (frontVersion > record.iecEngineVersion! && record.type === 'IEC') && (
          <Popover content={versionWarning} title="Warning!">
            <FontAwesomeIcon icon={faTriangleExclamation} color="orange" size="2xl" />
          </Popover>
        )
      ),
    },
    {
      title: 'Creation',
      key: 'creaion',
      fixed: 'right',
      width: '100px',
      align: 'center',
      render: (text: number, record: MCreative) => (
        <label>{new Date(record.createdAt!).toLocaleString()}</label>
      ),
    },
    {
      key: 'actions',
      fixed: 'right',
      align: 'center',
      render: (text: string, record: MCreative) => (
        <div className="actions">
          <PlayablesListActions record={record} />
        </div>
      ),
    },
  ];

  const draggerParams: UploadProps = {
    name: 'playable',
    multiple: false,
    action: `${process.env.REACT_APP_API_URL}/creatives/import-playable`,
    maxCount: 1,
    headers: {
      authorization: localStorage.getItem('user-token') || '',
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        message.success(`${info.file.name} Playable uploading.`);
      }
      if (status === 'done') {
        message.success(`${info.file.name} Playable uploaded successfully.`);
        _setPlayableFileId(info.file.response.data.id as number);
      } else if (status === 'error') {
        message.error(`${info.file.name} Playable upload failed.`);
      }
    },
  };

  const openImportPlayableModal = async () => {
    _setNextVersion(await creativesService.nextCreative({ applicationId: uParams.appId, type: 'PLA' }) as number);
    _setIsImportPlayable(true);
  };

  const onImportPlayable = (values: MCreative) => {
    applicationsService.view(uParams.appId as number).then((application) => {
      if (application) {
        const newPlayable = {
          ...new MCreative(),
          name: values.name,
          applicationId: application.id,
          version: values.version,
          type: 'PLA',
          appStore: application.appStore,
          playStore: application.playStore,
          playableEngineVersion: '0.0.0',
          playableId: playableFileId,
        } as MCreative;
        creativesService.add(newPlayable).then((data) => {
          if (data) {
            uHistory.push(`/applications/${uParams.appId}/creatives/playable/${data.id}`);
          }
        });
      }
    });
  };

  return (
    <div id="playables-list">
      <Spin spinning={!uCreatives.getIsReady()}>
        <Table
          bordered
          size="small"
          dataSource={playables}
          columns={playablesColumns}
          rowKey="id"
          pagination={{ defaultPageSize: 15, pageSizeOptions: [10, 15, 20, 50, 100], showSizeChanger: true }}
        />
      </Spin>
      {
        uAuths.isSuperAdmin() && (
          <Button
            type="primary"
            className="bt-add"
            onClick={() => { openImportPlayableModal(); }}
          >
            Import Playable
          </Button>
        )
      }
      <Modal
        width="720px"
        title="Import Playable"
        open={isImportPlayable}
        okText={<>Validate</>}
        okButtonProps={{ form: 'formCreate', htmlType: 'submit' }}
        onCancel={() => {
          _setIsImportPlayable(false);
        }}
      >
        <Form id="formCreate" onFinish={onImportPlayable} layout="vertical">
          <div className="row">
            <Form.Item className="col-6" label="Name" name="name" rules={[{ required: true }]} initialValue={`P${nextVersion}`}>
              <Input type="text" />
            </Form.Item>
            <Form.Item className="col-6" label="Version" name="version" rules={[{ required: true }]} initialValue={nextVersion}>
              <Input type="number" className="version" min={nextVersion} />
            </Form.Item>
            <Form.Item
              valuePropName="fileList"
              getValueFromEvent={antFormNormFile}
              className="col-12"
              label="Playable"
              name="playable"
              rules={[{ required: true }]}
            >
              <Dragger {...draggerParams}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag a playable to this area to upload</p>
                <p className="ant-upload-hint">
                  Upload a single playable. Your playable must weigh less than 5 MB!
                </p>
              </Dragger>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
