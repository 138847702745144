import { MColor } from './color.m';
import { APP_WIDTH } from './creative.m';
import { MDocument } from './document.m';

export type ComponentType =
  ''
  | 'text'
  | 'image'
  | 'button'
  | 'background'
  | 'border'
  | 'transform'
  | 'overlay'
  | 'blur'
  | 'box-shadow'
  | 'text-shadow'
  | 'text-stroke'
  | 'flex'
  | 'animation'
  | 'templated-background';

export const AnimationTypeValues = [
  { key: 'Pulse', value: 'pulse' },
  { key: 'Hithere', value: 'hithere' },
  { key: 'Bounce', value: 'bounce' },
  { key: 'Spine', value: 'spine' },
  { key: 'Circular', value: 'circular' },
  { key: 'To left', value: 'animation-to-left' },
  { key: 'To right', value: 'animation-to-right' },
] as const;
export type AnimationType = typeof AnimationTypeValues[number]['value'];

export const ButtonActionTypeValues = [
  { key: 'Video continue', value: 'video-continue', endcard: false },
  { key: 'App download', value: 'app-download', endcard: true },
  { key: 'Video change', value: 'video-change', endcard: false },
  { key: 'Endcard display', value: 'endcard-display', endcard: false },
  { key: 'Redirect URL', value: 'redirect-url', endcard: false },
] as const;
export type ButtonActionType = typeof ButtonActionTypeValues[number]['value'];

export const OverflowTypeValues = [
  { key: 'Hidden', value: 'hidden' },
  { key: 'Visible', value: 'visible' },
] as const;
export type OverflowType = typeof OverflowTypeValues[number]['value'];

export const ObjectFitTypeValues = [
  { key: 'None', value: 'none' },
  { key: 'Fill', value: 'fill' },
  { key: 'Contain', value: 'contain' },
  { key: 'Cover', value: 'cover' },
  { key: 'Scale down', value: 'scale-down' },
] as const;
export type ObjectFitType = typeof ObjectFitTypeValues[number]['value'];

export const ObjectFlipTypeValues = [
  { key: 'None', value: 'none' },
  { key: 'Horizontal', value: 'scale(-1, 1)' },
  { key: 'Vertical', value: 'scale(1, -1)' },
  { key: 'Both', value: 'scale(-1, -1)' },
] as const;
export type ObjectFlipType = typeof ObjectFlipTypeValues[number]['value'];

export const FlexDirectionTypeValues = [
  { key: 'Row', value: 'row' },
  { key: 'Column', value: 'column' },
] as const;
export type FlexDirectionType = typeof FlexDirectionTypeValues[number]['value'];

export const AlignItemsTypeValues = [
  { key: 'Center', value: 'center' },
  { key: 'Start', value: 'start' },
  { key: 'End', value: 'end' },
] as const;
export type AlignItemsType = typeof AlignItemsTypeValues[number]['value'];

export const JustifyContentTypeValues = [
  { key: 'Center', value: 'center' },
  { key: 'Start', value: 'start' },
  { key: 'End', value: 'end' },
  { key: 'Space between', value: 'space-between' },
  { key: 'Space around', value: 'space-around' },
  { key: 'Space evenly', value: 'space-evenly' },
] as const;
export type JustifyContentType = typeof JustifyContentTypeValues[number]['value'];

export const TimingFunctionTypeValues = [
  { key: 'Ease', value: 'ease' },
  { key: 'Ease in', value: 'ease-in' },
  { key: 'Ease out', value: 'ease-out' },
  { key: 'Ease in out', value: 'ease-in-out' },
  { key: 'Linear', value: 'linear' },
] as const;
export type TimingFunctionType = typeof TimingFunctionTypeValues[number]['value'];

export class MCompBase {
  _type: ComponentType = '';
  _open: boolean = true;
  _visible: boolean = true;
}

export class MCompTemplatedBackground extends MCompBase {
  _type: ComponentType = 'templated-background';
  templateNb?: number;
}

export class MCompText extends MCompBase {
  constructor(text?: string, fontSize?: number, fontColor?: MColor) {
    super();
    if (text) { this.text = text; }
    if (fontSize) { this.fontSize = Math.floor(fontSize); }
    if (fontColor) { this.fontColor = fontColor; }
  }
  _type: ComponentType = 'text';
  text: string = 'Text';
  fontSize: number = Math.floor(APP_WIDTH / 10);
  fontPolice: string = 'arial';
  fontWeight: string = 'normal';
  fontColor = new MColor(0, 0, 0, 1);
  fontStyle: string = '';
  textDecoration: string = '';
  textAlign: 'left' | 'right' | 'center' | 'justify' = 'center';
  verticalAlign: 'top' | 'middle' | 'bottom' = 'middle';
}

export class MCompImage extends MCompBase {
  _type: ComponentType = 'image';
  file: MDocument = new MDocument();
  objectFit: ObjectFitType = 'contain';
  objectFlip: ObjectFlipType = 'none';
}

export class MCompBackground extends MCompBase {
  constructor(color?: MColor) {
    super();
    if (color) { this.color = color; }
  }
  _type: ComponentType = 'background';
  color = new MColor(255, 255, 255, 0.5);
}

export class MCompBorder extends MCompBase {
  constructor(size?: number, radius?: number, color?: MColor, overflow?: OverflowType) {
    super();
    if (size) { this.size = Math.floor(size); }
    if (radius) { this.radius = Math.floor(radius); }
    if (color) { this.color = color; }
    if (overflow) { this.overflow = overflow; }
  }
  _type: ComponentType = 'border';
  color = new MColor(0, 0, 0, 1);
  size = 10;
  radius = 20;
  overflow: OverflowType = 'hidden';
  radiusUnit: 'percentage' | 'pixel' = 'percentage';
}

export class MCompBoxShadow extends MCompBase {
  constructor(x?: number, y?: number, blurRadius?: number, spreadRadius?: number, color?: MColor) {
    super();
    if (x) { this.x = Math.floor(x); }
    if (y) { this.y = Math.floor(y); }
    if (blurRadius) { this.blurRadius = Math.floor(blurRadius); }
    if (spreadRadius) { this.spreadRadius = Math.floor(spreadRadius); }
    if (color) { this.color = color; }
  }
  _type: ComponentType = 'box-shadow';
  x = 0;
  y = 0;
  blurRadius = 10;
  spreadRadius = 10;
  color = new MColor(0, 0, 0, 1);
}

export class MCompTextShadow extends MCompBase {
  constructor(x?: number, y?: number, blurRadius?: number, color?: MColor) {
    super();
    if (x) { this.x = Math.floor(x); }
    if (y) { this.y = Math.floor(y); }
    if (blurRadius) { this.blurRadius = Math.floor(blurRadius); }
    if (color) { this.color = color; }
  }
  _type: ComponentType = 'text-shadow';
  x = 0;
  y = 0;
  blurRadius = 10;
  color = new MColor(0, 0, 0, 1);
}

export class MCompTextStroke extends MCompBase {
  constructor(size?: number, color?: MColor) {
    super();
    if (size) { this.size = Math.floor(size); }
    if (color) { this.color = color; }
  }
  _type: ComponentType = 'text-stroke';
  size = 0;
  color = new MColor(0, 0, 0, 1);
}

export class MCompAnimation extends MCompBase {
  constructor(animation?: AnimationType) {
    super();
    if (animation) { this.animation = animation; }
  }
  _type: ComponentType = 'animation';
  _openAdvance: boolean = false;
  animation: AnimationType = 'pulse';
  scaleStart: number = 0.9;
  scaleEnd: number = 1.1;
  rotateStart: number = -10;
  rotateEnd: number = 10;
  translateY: number = -30;
  duration: number = 1;
  intensity: number = 1;
  timingFunction: TimingFunctionType = 'ease';
}

export class MCompButton extends MCompBase {
  constructor(action?: ButtonActionType) {
    super();
    if (action) { this.action = action; }
  }
  _type: ComponentType = 'button';
  action: ButtonActionType = 'video-continue';
  displayEndCardRedirect: boolean = true;
  appDownloadDisplayEndCard: boolean = false;
  videoKey: string | null = null;
  url: string | null = null;
}

export class MCompOverlay extends MCompBase {
  _type: ComponentType = 'overlay';
  layoutColor = new MColor(0, 0, 0, 0.4);
  borderRadius = 50;
  blur = 0;
}

export class MCompBlur extends MCompBase {
  _type: ComponentType = 'blur';
  blur = 1;
}

export class MCompFlex extends MCompBase {
  constructor(flexDirection?: FlexDirectionType) {
    super();
    if (flexDirection) { this.flexDirection = flexDirection; }
  }
  _type: ComponentType = 'flex';
  flexDirection: FlexDirectionType = 'column';
  alignItems: AlignItemsType = 'center';
  justifyContent: JustifyContentType = 'space-evenly';
}

export class MCompTransformBase extends MCompBase {
  constructor(width?: number, height?: number, x?: number, y?: number, rotate?: number) {
    super();
    if (width) { this.width = Math.floor(width); }
    if (height) { this.height = Math.floor(height); }
    if (x) { this.x = Math.floor(x); }
    if (y) { this.y = Math.floor(y); }
    if (rotate) { this.rotate = Math.floor(rotate); }
  }
  x: number = 0;
  y: number = 0;
  width: number = APP_WIDTH / 2;
  height: number = APP_WIDTH / 2;
  rotate: number = 0;
}

export class MCompTransform extends MCompTransformBase {
  _type: ComponentType = 'transform';
  isTransformDifferent = false;
  portrait = new MCompTransformBase();
  landscape = new MCompTransformBase();
}
