import { ReactNode, useState, useEffect } from 'react';
import { PlusOutlined as PlusIcon } from '@ant-design/icons';
import { Button } from 'antd';

import { YcCard } from '../../../../../../components';
import { IGameObjectJson } from '../../../../../../modeles/MP_Interfaces';
import { SettingCompMeshRenderer } from '../setting-comp-mesh-renderer';
import { SettingCompTransform } from '../setting-comp-transform';
import { SettingCompRectTransform } from '../setting-comp-rect-transform';
import { SettingCompText } from '../setting-comp-text';
import { SettingCompTextMeshPro } from '../setting-comp-text-mesh-pro';
import { SettingCompImage } from '../setting-comp-image';
import { SettingCompCapsuleCollider } from '../setting-comp-capsule-collider';
import { SettingCompRigidbody } from '../setting-comp-rigidbody';
import { SettingCompScript } from '../setting-comp-script';
import { SettingCompLight } from '../setting-comp-light';
import { SettingCompCanvas } from '../setting-comp-canvas';
import { SettingCompSphereCollider } from '../setting-comp-sphere-collider';
import { SettingCompBoxCollider } from '../setting-comp-box-collider';
import { SettingCompGraphicRaycaster } from '../setting-comp-graphic-raycaster';
import { SettingCompCanvasScaler } from '../setting-comp-canvas-scaler';
import { SettingCompCamera } from '../setting-comp-camera';
import { SettingCompButton } from '../setting-comp-button';
import { SettingCompMeshCollider } from '../setting-comp-mesh-collider';
import { SettingCompAudioSource } from '../setting-comp-audio-source';
import { SettingCompAnimator } from '../setting-comp-animator';
import { SettingCompParticleSystem } from '../setting-comp-particle-system';
import { SettingCompInputCheckbox } from '../../sub-settings';
import { SettingCompCanvasRenderer } from '../setting-comp-canvas-renderer';
import { SettingCompMeshFilter } from '../setting-comp-mesh-filter';
import { usePlayable } from '../../../../context/playable.context';
import * as icons from '../../../../../../assets/images/playable';

import './setting-base.scss';

const componentsMap: { [key: string]: { icon: string, Component: any } } = {
  Animator: { icon: icons.IcoAnimator, Component: SettingCompAnimator },
  AudioSource: { icon: icons.IcoAudioSource, Component: SettingCompAudioSource },
  BoxCollider: { icon: icons.IcoBoxCollider, Component: SettingCompBoxCollider },
  Button: { icon: icons.IcoButton, Component: SettingCompButton },
  Camera: { icon: icons.IcoCamera, Component: SettingCompCamera },
  Canvas: { icon: icons.IcoCanvas, Component: SettingCompCanvas },
  CanvasRenderer: { icon: icons.IcoCanvas, Component: SettingCompCanvasRenderer }, // Can't be deactivated
  CanvasScaler: { icon: icons.IcoCanvasScaler, Component: SettingCompCanvasScaler },
  CapsuleCollider: { icon: icons.IcoCapsuleCollider, Component: SettingCompCapsuleCollider },
  GraphicRaycaster: { icon: icons.IcoGraphicRaycaster, Component: SettingCompGraphicRaycaster },
  Image: { icon: icons.IcoImage, Component: SettingCompImage },
  Light: { icon: icons.IcoLight, Component: SettingCompLight },
  MeshCollider: { icon: icons.IcoMeshCollider, Component: SettingCompMeshCollider },
  MeshFilter: { icon: icons.IcoMeshRenderer, Component: SettingCompMeshFilter },
  MeshRenderer: { icon: icons.IcoMeshRenderer, Component: SettingCompMeshRenderer },
  ParticleSystem: { icon: icons.IcoParticlesSystem, Component: SettingCompParticleSystem }, // Can't be deactivated
  RectTransform: { icon: icons.IcoRectTransform, Component: SettingCompRectTransform }, // Can't be deactivated
  Rigidbody: { icon: icons.IcoRigidbody, Component: SettingCompRigidbody }, // Can't be deactivated
  Script: { icon: icons.IcoScript, Component: SettingCompScript }, // Can't be deactivated
  SphereCollider: { icon: icons.IcoSphereCollider, Component: SettingCompSphereCollider },
  Text: { icon: icons.IcoText, Component: SettingCompText },
  TextMeshProUGUI: { icon: icons.IcoTextMeshProGUI, Component: SettingCompTextMeshPro },
  Transform: { icon: icons.IcoTransform, Component: SettingCompTransform }, // Can't be deactivated
};

export function SettingBase(props: { title: ReactNode, element?: IGameObjectJson }) {
  const uPlayable = usePlayable();

  const [localEnabled, _setLocalEnabled] = useState<boolean>();

  const instanceID: string = props.element?.instanceID || '';

  useEffect(() => {
    if (props !== undefined) {
      const gameObjects = uPlayable?.playableClasses?.MP_GameManager?.scene?.gameObjects;
      const localGameObject = gameObjects?.find((e: any) => e.instanceID === props.element?.instanceID);

      _setLocalEnabled(localGameObject?.activeSelf as boolean);
    }
  }, [props, uPlayable?.playableClasses?.MP_GameManager?.scene?.gameObjects]);

  const handleToggleEnabled = (bool: boolean) => {
    uPlayable.toggleEnabledStateGameObject(instanceID, bool);
    _setLocalEnabled((old) => !old);
  };

  const handleAddComponent = () => {};

  return (
    <YcCard
      title={props.title}
      icon={<img src={icons.IcoGeneric} height={18} className="yc-icon" alt="setting-icon" />}
      open
      canOpenAndClose={false}
      titleExtra={
        props?.title !== 'EMPTY'
          ? (
            <SettingCompInputCheckbox
              checked={localEnabled}
              onChange={(e) => handleToggleEnabled(e as boolean)}
            />
          ) : null
      }
    >
      <div className="setting-base">
        {props.element?.components?.map((treeElem, i) => {
          const Curr = componentsMap[treeElem.type];

          if (Curr !== undefined) {
            return (
              <Curr.Component
                key={i}
                initialData={treeElem}
                icon={<img src={Curr.icon} height={18} className="yc-icon" alt="component-icon" />}
                parentID={props.element?.instanceID}
              />
            );
          }
          return null;
        })}
        {
          props?.title !== 'EMPTY' && (
            <div className="bottom-line">
              <Button className="bottom-btn" onClick={() => handleAddComponent()} disabled>
                <PlusIcon className="add-btn" />
                <span>Add a component</span>
              </Button>
            </div>
          )
        }
      </div>
    </YcCard>
  );
}
