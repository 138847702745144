import { Link } from 'react-router-dom';
import {
  CardCreation, CardTry, Footer, ICardCreation, ICardTry,
} from '../../../../components';
import * as HomeImages from '../../../../assets/images/home';

import './iec-presentation.page.scss';

export function IecPresentation() {
  const cards: ICardCreation[] = [
    {
      title: 'Upload your videos',
      description: 'Import your gameplay videos directly to Monster Playable',
      icon: HomeImages.IcoUploadVideos,
      image: HomeImages.ImgUploadVideos,
      bgColor: '#ffc581',
    },

    {
      title: 'Generate unlimited customization creatives',
      description: 'Manage your Interactive Ads directly on Monster Playable. Benefit from a range of diverse assets and styles.',
      icon: HomeImages.IcoCustom,
      image: HomeImages.ImgCustom,
      bgColor: '#51bbff',
    },

    {
      title: 'Export it to all famous networks: Meta, Applovin, Mintegral…',
      description: 'Export your Playable to a multitude of platforms!',
      icon: HomeImages.IcoExport,
      image: HomeImages.ImgExport,
      bgColor: '#e4a3ff',
    },

    {
      title: 'Check the performances',
      description: 'Check the results / performances Iterate it as much as you want.',
      icon: HomeImages.IcoPerf,
      image: HomeImages.ImgPerf,
      bgColor: '#02adac',
    },
  ];

  const cardstry: ICardTry[] = [
    {
      title: 'Ninja hands',
      description: 'Use your ninja powers !',
      icon: 'https://yso-playable-prod-front.s3.eu-west-3.amazonaws.com/home_assets/iec_game_1_icon.png',
      url: 'https://yso-playable-prod-front.s3.eu-west-3.amazonaws.com/home_assets/game_1_iec.html',
    },

    {
      title: 'Retro Battle',
      description: 'Discover many games !',
      icon: 'https://yso-playable-prod-front.s3.eu-west-3.amazonaws.com/home_assets/iec_game_2_icon.png',
      url: 'https://yso-playable-prod-front.s3.eu-west-3.amazonaws.com/home_assets/game_2_iec.html',
    },

    {
      title: 'Hide N Seek 3D!',
      description: 'Find the hidden kids !',
      icon: 'https://yso-playable-prod-front.s3.eu-west-3.amazonaws.com/home_assets/iec_game_3_icon.png',
      url: 'https://yso-playable-prod-front.s3.eu-west-3.amazonaws.com/home_assets/game_3_iec.html',
    },

    {
      title: 'Dino Domination',
      description: 'Help your dinosaur survive',
      icon: 'https://yso-games-prod-front.s3.amazonaws.com/apps/dino-domination/icons/2ooSnp2t0yFE.png',
      url: 'https://yso-playable-prod-front.s3.amazonaws.com/tmp/IecQrCode/d7u8edPsEfU9.html',
    },

    {
      title: 'Monster Box',
      description: 'Catch them all !',
      icon: 'https://yso-games-prod-front.s3.amazonaws.com/apps/monster-box/icons/GGUo70PLHFww.png',
      url: 'https://yso-playable-prod-front.s3.amazonaws.com/tmp/IecQrCode/XaCOWgYCelys.html',
    },

    {
      title: 'Whooo ?',
      description: 'Does he have a mustache?',
      icon: 'https://yso-games-prod-front.s3.amazonaws.com/apps/whooo/icons/FDJvzvEpVrFh.png',
      url: 'https://yso-playable-prod-front.s3.amazonaws.com/tmp/IecQrCode/SPQlg7EZRP8C.html',
    },

  ];

  return (

    <div id="root-home">
      <div id="iec-presentation">

        <div className="iec-header-contain">

          <div className="iec-header">

            <div className="content row">
              <div className="col-12 col-md-6 align-self-center">

                <img className="icon" src={HomeImages.IcoVideo} alt="iec icon" />
                <h1 className="title">I.E.C</h1>
                <p className="sub-title mb-5">Interactive End Card</p>

                <div className="content-header">
                  <h2 className="market-title">Transform your gameplay videos into playable ads</h2>
                  <p><Link className="button-a" to="/login">Request a demo</Link></p>
                  <p><a className="button-b" href="#try">Try I E C</a></p>
                </div>

              </div>
              <div className="col-12 col-sm-6 align-self-center">
                <img className="iec-img" src={HomeImages.IecVisual} alt="iec img" />
              </div>
            </div>
          </div>
        </div>

        <div className="iec-contain">

          <img className="fd-rond" src={HomeImages.FdRond} alt="Fd Rond" />
          <img className="fd-rond-pink" src={HomeImages.FdRondPink} alt="Fd Rond" />
          <img className="fd-rond-pink-b" src={HomeImages.FdRondPink} alt="Fd Rond" />

          <div className="create-playable">
            <div className="content row">
              <div className="col-12 align-self-center">
                <h2 className="title">How to create a Interactive End Cards ?</h2>
                <p className="sub-title mb-5">with Monster Playable</p>
                {cards.map((card, index) => (
                  <CardCreation number={index + 1} card={card} />
                ))}
              </div>
            </div>
          </div>

          <div id="try">
            <div className="try-playable">
              <h2 className="title mb-4">Try our Interactive End Cards</h2>
              <div className="content row">
                {cardstry.map((card) => (
                  <CardTry cardtry={card} />
                ))}
              </div>
            </div>
          </div>
        </div>

        <Footer />

      </div>
    </div>
  );
}
