import { useIec } from '../../../../context/iec.context';
import { MColor, MCompBoxShadow } from '../../../../../../modeles';
import { YcCard, YcColorPicker } from '../../../../../../components';
import { IecInputNumber } from '../../../input-number';

import './setting-comp-box-shadow.scss';

export function SettingCompBoxShadow(props: {
  boxShadow: MCompBoxShadow
}) {
  const uIec = useIec();

  return (
    <YcCard
      title="Box Shadow"
      light
      open={props.boxShadow._open}
      onClickTitle={(val) => {
        props.boxShadow._open = val;
        uIec.refreshIec(true);
      }}
      onDelete={() => {
        uIec.removeComponentNodeEdite(props.boxShadow);
      }}
    >
      <div className="row">
        <div className="col-2">
          <IecInputNumber label="X" field="x" obj={props.boxShadow} />
        </div>
        <div className="col-2">
          <IecInputNumber label="Y" field="y" obj={props.boxShadow} />
        </div>
        <div className="col-3">
          <IecInputNumber label="Blur" field="blurRadius" obj={props.boxShadow} />
        </div>
        <div className="col-3">
          <IecInputNumber label="Spread" field="spreadRadius" obj={props.boxShadow} />
        </div>
        <div className="col-2">
          <label>Color</label>
          <YcColorPicker
            color={props.boxShadow.color}
            onColorChange={(color: MColor) => {
              props.boxShadow.color = color;
              uIec.refreshIec(true);
            }}
          />
        </div>
      </div>
    </YcCard>
  );
}
