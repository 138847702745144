import type { TreeProps, DataNode } from 'antd/es/tree';
import { Tree, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { useIec } from '../../../context/iec.context';
import {
  MCreative, MCreativeElementBase, MCreativeHover, MCreativeVideo,
} from '../../../../../modeles';
import { ElemTitle } from './elem-list-title';
import { YcCard } from '../../../../../components';
import { CAN_INCLUDES, findElem, getTypeElement } from '../../../utils/iec.utils';
import { IcoTree } from '../../../../../assets/images/icons';

import './elem-list.scss';

export function IecListElem(props: TreeProps) {
  const uIec = useIec();

  const treeList = iecToTreeList(uIec.iec);
  const nodeSelected = uIec.getNodeEdite();
  const selectedKeys = nodeSelected ? [nodeSelected._key] : [];
  const expandedKeys = uIec.getAllOpenKeys();

  const onDrop: TreeProps['onDrop'] = (info) => {
    const fromKey = info.dragNode.key;
    const toKey = info.node.key;

    // Find elem to move
    const elemToMove = findElem(uIec.iec, fromKey);
    const dragObj = elemToMove.node;
    // Put element
    const elemWhereToPut = findElem(uIec.iec, toKey);

    // @ts-ignore
    if (CAN_INCLUDES(elemWhereToPut.node).includes(getTypeElement(dragObj))) {
      // remove elem
      elemToMove.parentList.splice(elemToMove.index, 1);

      // add elem
      if (info.dropToGap) {
        elemWhereToPut.parentList.splice(info.dropPosition, 0, dragObj);
      } else if (elemWhereToPut.node.breaks) {
        elemWhereToPut.node.breaks.splice(info.dropPosition, 0, dragObj);
      } else if (elemWhereToPut.node.elements) {
        elemWhereToPut.node.elements.splice(info.dropPosition, 0, dragObj);
      }
      uIec.refreshIec(true);
    } else {
      message.error(`Can not put a ${dragObj._type} into a ${elemWhereToPut.node._type}`);
    }
  };

  const onExpand = (expandedK: React.Key[], info: any) => {
    const node = findElem(uIec.iec, info.node.key as string);
    node.node._open = info.expanded;
    uIec.refreshIec(true);
  };

  const onSelect = (keys: React.Key[]/* , info: any */) => {
    if (keys && keys.length) {
      const node = findElem(uIec.iec, keys[0]);
      uIec.setNodeEdite(node.node);
    }
  };

  return (
    <YcCard
      title="Tree"
      icon={<img src={IcoTree} alt="Icon Tree" className="yc-icon" />}
      open
      canOpenAndClose={false}
      warning={uIec.warning}
      onBack={() => {
        uIec.historyBack();
      }}
    >
      <div className="elem-list">
        <Tree
          className="iec-elem-list"
          draggable
          blockNode
          defaultExpandAll
          expandedKeys={expandedKeys}
          onDrop={onDrop}
          onExpand={onExpand}
          onSelect={onSelect}
          treeData={treeList}
          selectedKeys={selectedKeys}
          {...props}
        />
        <div className="global-video-items">
          {uIec.iec.iecData.videos.find((v) => v.video.url !== '') && (
            <div
              className="video-item"
              onClick={() => {
                const video = new MCreativeVideo();
                video._open = true;
                video._edite = true;
                uIec.iec.iecData.videos.push(video);
                uIec.setNodeEdite(video);
                uIec.refreshIec(true);
              }}
            >
              <PlusOutlined /> Video
            </div>
          )}
          {(uIec.iec.iecData.videos.find((v) => v.video.url !== '') && !uIec.iec.iecData.hover)
        && (
        <div
          className="hover-item"
          onClick={() => {
            const hover = new MCreativeHover();
            uIec.iec.iecData.hover = hover;
            uIec.setNodeEdite(hover);
            uIec.refreshIec(true);
          }}
        >
          <PlusOutlined /> Global Hover
        </div>
        )}
        </div>
      </div>
    </YcCard>
  );
}

const iecToTreeList = (iec: MCreative) => {
  const parseElems = (elements: MCreativeElementBase[]): DataNode[] => {
    const ret: DataNode[] = [];
    for (let i = 0; i < elements.length; i += 1) {
      const e = elements[i];
      const elemP: DataNode = { title: <ElemTitle node={e} key={e._key} />, key: e._key };
      if (e.elements && e.elements.length) {
        elemP.children = parseElems(e.elements);
      }
      ret.push(elemP);
    }
    return ret;
  };

  const treeList: DataNode[] = [];
  for (const v of iec.iecData.videos) {
    const parent: DataNode = { title: <ElemTitle node={v} key={v._key} />, key: v._key };
    if (v.video.url) {
      for (const b of v.breaks) {
        const breakC: DataNode = { title: <ElemTitle node={b} key={b._key} />, key: b._key };
        if (b.elements && b.elements.length) {
          breakC.children = parseElems(b.elements);
        }
        parent.children = parent.children || [];
        parent.children.push(breakC);
      }
      for (const l of v.loops) {
        const loopC: DataNode = { title: <ElemTitle node={l} key={l._key} />, key: l._key };
        if (l.elements && l.elements.length) {
          loopC.children = parseElems(l.elements);
        }
        parent.children = parent.children || [];
        parent.children.push(loopC);
      }
      if (v.hover) {
        const hoverV = v.hover;
        const hoverC: DataNode = { title: <ElemTitle node={hoverV} key={hoverV._key} />, key: hoverV._key };
        if (hoverV.elements && hoverV.elements.length) {
          hoverC.children = parseElems(hoverV.elements);
        }
        parent.children = parent.children || [];
        parent.children.push(hoverC);
      }
    }
    treeList.push(parent);
  }
  if (iec.iecData.videos.length > 0 && iec.iecData.videos[0].video.url) {
    const endcard: DataNode = { title: <ElemTitle node={iec.iecData.endcard} key={iec.iecData.endcard._key} />, key: iec.iecData.endcard._key };
    endcard.children = parseElems(iec.iecData.endcard.elements);
    treeList.push(endcard);

    if (iec.iecData.hover) {
      const hover: DataNode = { title: <ElemTitle node={iec.iecData.hover} key={iec.iecData.hover._key} />, key: iec.iecData.hover._key };
      hover.children = parseElems(iec.iecData.hover.elements);
      treeList.push(hover);
    }
  }
  return treeList;
};
