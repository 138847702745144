import THREE, { PerspectiveCamera } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

export const MPPerspectiveCamera = (PLAYABLE_THREE: typeof THREE, containerSize: { width: number, height: number }): PerspectiveCamera => {
  const camera = new PLAYABLE_THREE.PerspectiveCamera(75, containerSize.width / containerSize.height, 0.1, 1000);
  camera.position.set(0, 20, 15);
  camera.lookAt(0, 0, 0);
  return camera;
};

export const MPControls = (camera: THREE.PerspectiveCamera, domElement: HTMLCanvasElement): OrbitControls => {
  const controls = new OrbitControls(camera, domElement);
  controls.target.set(0, 0, 0);
  controls.enableRotate = false;
  return controls;
};
