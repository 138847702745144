import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AndroidFilled, AppleFilled } from '@ant-design/icons';
import { Spin, Table } from 'antd';

import { MAccount, MApplication, MTag } from '../../../../modeles';
import { applicationsService, clientManagerApplicationsService } from '../../../../services';
import { YcTags, MpTable, MpColumnType } from '../../../../components';
import { accountColor } from '../../../../tools';

import './applications.page.scss';

export function ApplicationsManagedListing(props: {
  searchValue: string,
}) {
  const [applications, _setApplications] = useState<MAccount[]>([]);
  const [applicationsDisplayed, _setApplicationsDisplayed] = useState<MAccount[]>([]);
  const [loading, _setLoading] = useState(false);
  const [tags, _setTags] = useState<MTag[]>([]);

  useEffect(() => {
    _setLoading(true);
    clientManagerApplicationsService.listing().then((data) => {
      _setApplications(data || []);
      _setApplicationsDisplayed(data || []);
      _setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    _setApplicationsDisplayed(
      applications.map((acc) => ({
        ...acc,
        Applications: acc.Applications!.filter(
          (appF) => appF.name.toLowerCase().includes(props.searchValue.toLowerCase())
            || appF.key.toUpperCase().includes(props.searchValue.toUpperCase()),
        ),
      })),
    );
  }, [applications, props.searchValue]);

  const columns: MpColumnType<MAccount>[] = [
    {
      title: 'Name',
      key: 'name',
      mpCanNotHide: true,
      mpSort: 'string',
      render: (text: string, record: MAccount) => (
        record.name
      ),
    },
  ];

  const subColumns: MpColumnType<MApplication>[] = [
    {
      title: 'Name',
      key: 'name',
      mpCanNotHide: true,
      mpSort: 'string',
      render: (text: string, record: MApplication) => (
        <Link className="link-to-iecs" to={`/applications/${record.id}/creatives`}>
          {
            record.Icon?.url
              ? <img className="application-icon" src={record.Icon?.url} alt={record.name} />
              : <div className="no-application-icon">{record.name[0]}</div>
          }
          {record.name}
        </Link>
      ),
    },
    {
      title: 'Key',
      key: 'key',
      mpCanNotHide: true,
      mpSort: 'string',
    },
    {
      title: 'Store',
      key: 'store',
      fixed: 'right',
      width: '100px',
      align: 'center',
      render: (text: string, record: MApplication) => (
        <div className="d-flex justify-content-around">
          {record.playStore && <AndroidFilled />}
          {record.appStore && <AppleFilled />}
        </div>
      ),
      sorter: (a, b) => (a.Iecs?.filter((iec) => iec.type === 'IEC').length || 0) - (b.Iecs?.filter((iec) => iec.type === 'IEC').length || 0),
    },
    {
      title: 'Tags',
      key: 'tags',
      fixed: 'right',
      width: '100px',
      align: 'center',
      render: (text: string, record: MApplication) => (
        <YcTags tags={record.tags} />
      ),
      filters: tags.map((element) => ({
        text: element.text,
        value: element.text,
      })),
      onFilter: (value: any, record) => record.tags?.some((e) => e.text === value) || false,
      filterSearch: true,
    },
    {
      title: 'IECs',
      key: 'iecs',
      fixed: 'right',
      width: '100px',
      align: 'center',
      render: (text: string, record: MApplication) => (
        <label>{record?.Iecs?.filter((iec) => iec.type === 'IEC').length}</label>
      ),
      sorter: (a, b) => (a.Iecs?.filter((iec) => iec.type === 'IEC').length || 0) - (b.Iecs?.filter((iec) => iec.type === 'IEC').length || 0),
    },
    {
      title: 'Playables',
      key: 'playables',
      fixed: 'right',
      width: '100px',
      align: 'center',
      render: (text: string, record: MApplication) => (
        <label>{record?.Iecs?.filter((iec) => iec.type === 'PLA').length}</label>
      ),
      sorter: (a, b) => (a.Iecs?.filter((iec) => iec.type === 'PLA').length || 0) - (b.Iecs?.filter((iec) => iec.type === 'PLA').length || 0),
    },
  ];

  const adminDelete = async (id: number) => {
    const dataSource = [...applicationsDisplayed];
    _setApplications(dataSource.filter((item) => item.id !== id));
    _setApplicationsDisplayed(dataSource.filter((item) => item.id !== id));
    await applicationsService.deleteByDate(id);
  };

  const superAdminDelete = async (id: number) => {
    const dataSource = [...applicationsDisplayed];
    _setApplications(dataSource.filter((item) => item.id !== id));
    _setApplicationsDisplayed(dataSource.filter((item) => item.id !== id));
    await applicationsService.remove(id);
  };

  const getRowBackgroundColor = (record: MAccount, id: number) => {
    const color = accountColor[id];
    const opacity = !!record?.Applications && record.Applications.length > 0 ? '1' : '0.3';
    return color ? `rgba(${color}, ${opacity})` : 'white';
  };

  const expandedRowRender = (record: any) => (
    <MpTable
      dataSource={record.Applications}
      columns={subColumns}
      rowKey="id"
      mpTableKey="applications"
      urlParamsEnabled={false}
      pagination={false}
      mpShowTitle={false}
    />
  );

  return (
    <Spin spinning={loading}>
      <Table
        columns={columns}
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys: ['0'],
          rowExpandable: (record) => !!record.Applications && record.Applications.length > 0,
        }}
        dataSource={applicationsDisplayed}
        size="small"
        rowKey="id"
        bordered
        onRow={(record: MAccount, index) => ({
          style: {
            backgroundColor: getRowBackgroundColor(record, (index as number) + 1),
          },
        })}
      />
    </Spin>
  );
}
