import {
  Button, Result, Skeleton, Spin,
} from 'antd';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { authsService } from '../../../../services';

import * as ImagesSignup from '../../../../assets/images/sign-up';
import './active-account.page.scss';

export function ActiveAccountPage() {
  const uHistory = useHistory();
  const uParams: any = useParams();

  const [result, _setResult] = useState<boolean>(false);
  const [ready, _setReady] = useState<boolean>(false);
  const [token, _setToken] = useState<string>('');

  useEffect(() => {
    if (uParams.token) {
      _setToken(uParams.token as string);
      authsService.validateAccount(uParams.token as string).then((data) => {
        _setResult(data);
        _setReady(true);
      });
    } else {
      uHistory.push('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onResendMail = () => {
    authsService.newVerificationMail(token);
  };

  return (
    <div id="active-account">
      <img src={ImagesSignup.Bkgcontent} className="bkgcontent" alt="background" />
      <div className="block-bkg">
        <img src={ImagesSignup.Monster} className="monster" alt="monster" />
        <div className="block-auth">
          <h1>Active Account</h1>
          {
            !ready && (
              <Result
                status="info"
                title={<Skeleton.Button />}
                subTitle={<Skeleton.Input />}
                extra={[
                  <Spin size="large" />,
                ]}
              />
            )
          }
          {
            (ready && result) && (
              <Result
                status="success"
                title="Account successfull validated!"
                subTitle="Welcome to Monster Playable!"
                extra={[
                  <Link className="pl-3" to="/login">
                    <Button type="primary">
                      Login
                    </Button>
                  </Link>,
                ]}
              />
            )
          }
          {
            (ready && !result) && (
              <Result
                status="error"
                title="Account validated failed!"
                subTitle="Please contact us or retry with new verification email!"
                extra={[
                  <a href="mailto:contact@monsterplayable.com">
                    <Button type="primary">
                      Contact us!
                    </Button>
                  </a>,
                  <Button type="primary" onClick={onResendMail}>
                    Resend verification link
                  </Button>,
                ]}
              />
            )
          }
        </div>
      </div>
    </div>
  );
}
