/* eslint-disable react/jsx-no-constructed-context-values */
import { TourProps } from 'antd';
import React, {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import { useAuths } from '../../../contexts';
import { usersService } from '../../../services';

export interface FormatedRefType {
  [step: number]: any | null;
}

export interface tourContextType {
  tourName: string | null,
  runTour: boolean,
  steps: TourProps['steps'],

  setTourName: (name: string) => void
  setSteps: (stepsProps: any[]) => void
  setRunTour: (isRunning: boolean) => void
}

const TourContext = React.createContext<tourContextType>({
  tourName: null,
  runTour: false,
  steps: [],

  setTourName: () => {},
  setSteps: () => {},
  setRunTour: () => {},
});

export function TourContextProvider({ children }: { children: React.ReactNode }) {
  const uAuths = useAuths();

  const [tourName, _setTourName] = useState<string | null>(null);
  const [runTour, _setRunTour] = useState<boolean>(false);
  const [steps, _setSteps] = useState<TourProps['steps']>([]);

  useEffect(() => {
    // _setRunTour(true);
    if (tourName) {
      _setRunTour(!uAuths.tourDone(tourName));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourName]);

  // SETTERS //
  const setTourName = useCallback((name: string) => {
    _setTourName(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSteps = useCallback((stepsProps: any[]) => {
    _setSteps(stepsProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setRunTour = useCallback((isRunning: boolean) => {
    const newTours = { ...uAuths.user?.tours };
    newTours[tourName!].finished = !isRunning;
    usersService.updateTours(newTours);
    _setRunTour(isRunning);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourName]);

  const value = useMemo(() => ({
    tourName,
    runTour,
    steps,
    setTourName,
    setSteps,
    setRunTour,
  }), [
    tourName,
    runTour,
    steps,
    setTourName,
    setSteps,
    setRunTour,
  ]);

  return (
    <TourContext.Provider value={value}>
      {children}
    </TourContext.Provider>
  );
}

export function useTour() {
  const context = React.useContext(TourContext);
  if (!context) throw new Error('No TourContext provider found!');
  return context;
}
