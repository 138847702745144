import { Button } from 'antd';
import { useEffect, useState, ReactNode } from 'react';

import {
  MCompBackground,
  MCompBase,
  MCompBorder,
  MCompBoxShadow,
  MCompButton,
  MCompImage,
  MCompOverlay,
  MCompBlur,
  MCompText,
  MCompTextShadow,
  MCreativeElementBase,
  MCompAnimation,
  MCompFlex,
  MCompTextStroke,
  MCompTemplatedBackground,
  ComponentType,
} from '../../../../../../modeles';
import { YcCard } from '../../../../../../components';
import { SettingCompTransform } from '../setting-comp-transform';
import { SettingCompBackground } from '../setting-comp-background';
import { SettingCompBorder } from '../setting-comp-border';
import { SettingCompText } from '../setting-comp-text';
import { SettingCompImage } from '../setting-comp-image';
import { SettingCompButton } from '../setting-comp-button';
import { SettingCompOverlay } from '../setting-comp-overlay';
import { SettingCompBlur } from '../setting-comp-blur';
import { SettingCompAnimation } from '../setting-comp-animation';
import { SettingCompTemplatedBackground } from '../setting-comp-templated-background';

import { useIec } from '../../../../context/iec.context';
import { SettingCompBoxShadow } from '../setting-comp-box-shadow';
import { SettingCompTextShadow } from '../setting-comp-text-shadow';
import { SettingCompFlex } from '../setting-comp-flex';
import { getComponent } from '../../../../utils/iec.utils';
import { SettingCompTextStroke } from '../setting-comp-text-stroke';

import './setting-base.scss';
import './changeButtonStyleBtns.scss';

const componentsBtnsMap: { elementType: ComponentType, label: string, comp: MCompBase }[] = [
  { elementType: 'templated-background', label: 'Templated Background', comp: new MCompTemplatedBackground() },
  { elementType: 'background', label: 'Background', comp: new MCompBackground() },
  { elementType: 'border', label: 'Border', comp: new MCompBorder() },
  { elementType: 'image', label: 'Image', comp: new MCompImage() },
  { elementType: 'text', label: 'Text', comp: new MCompText() },
  { elementType: 'button', label: 'Button', comp: new MCompButton() },
  { elementType: 'overlay', label: 'Overlay', comp: new MCompOverlay() },
  { elementType: 'blur', label: 'Blur', comp: new MCompBlur() },
  { elementType: 'box-shadow', label: 'Box Shadow', comp: new MCompBoxShadow() },
  { elementType: 'text-shadow', label: 'Text Shadow', comp: new MCompTextShadow() },
  { elementType: 'text-stroke', label: 'Text Outline', comp: new MCompTextStroke() },
  { elementType: 'animation', label: 'Animation', comp: new MCompAnimation() },
  { elementType: 'flex', label: 'Flex', comp: new MCompFlex() },
];

const staysIfTemplate = ['templated-background', 'text', 'button'];

export function SettingBase(props: {
  title: ReactNode,
  icon?: React.ReactNode,
  element: MCreativeElementBase,
  children: React.ReactNode,
}) {
  const uIec = useIec();

  const [isThereTemplate, _setIsThereTemplate] = useState(false);

  useEffect(() => {
    if (props?.element?.components) {
      _setIsThereTemplate(props.element.components.some((comp: any) => comp._type === 'templated-background'));
    }
  }, [props?.element?.components]);

  useEffect(() => {
    if (isThereTemplate && props?.element?.components) {
      props.element.components.forEach((comp: any) => {
        if (!staysIfTemplate.includes(comp._type as string)) {
          uIec.removeComponentNodeEdite(comp as MCompBase);
        }
      });
    }
  }, [isThereTemplate, props?.element?.components, uIec]);

  const AddComponent = (comp: MCompBase) => {
    if (!getComponent(props.element, comp._type)) {
      props.element.components.push(comp);
      uIec.refreshIec(true);
    }
  };

  return (
    <YcCard
      title={props.title}
      icon={props.icon}
      open
      canOpenAndClose={false}
    >
      <div className="setting-base">
        <SettingCompTransform transform={props.element.transform} element={props.element} />
        {props.element.components.map((comp) => {
          if (comp._type === 'templated-background') {
            return (<SettingCompTemplatedBackground templatedBackground={comp as MCompTemplatedBackground} key={comp._type} />);
          }
          if (comp._type === 'background') { return <SettingCompBackground background={comp as MCompBackground} key={comp._type} isDeletable />; }
          if (comp._type === 'border') { return <SettingCompBorder border={comp as MCompBorder} key={comp._type} />; }
          if (comp._type === 'text') { return <SettingCompText text={comp as MCompText} key={comp._type} />; }
          if (comp._type === 'image') { return <SettingCompImage image={comp as MCompImage} key={comp._type} />; }
          if (comp._type === 'button') { return <SettingCompButton button={comp as MCompButton} key={comp._type} />; }
          if (comp._type === 'overlay') { return <SettingCompOverlay overlay={comp as MCompOverlay} key={comp._type} />; }
          if (comp._type === 'blur') { return <SettingCompBlur blur={comp as MCompBlur} key={comp._type} />; }
          if (comp._type === 'box-shadow') { return <SettingCompBoxShadow boxShadow={comp as MCompBoxShadow} key={comp._type} />; }
          if (comp._type === 'text-shadow') { return <SettingCompTextShadow textShadow={comp as MCompTextShadow} key={comp._type} />; }
          if (comp._type === 'text-stroke') { return <SettingCompTextStroke textStroke={comp as MCompTextStroke} key={comp._type} />; }
          if (comp._type === 'animation') { return <SettingCompAnimation animation={comp as MCompAnimation} key={comp._type} />; }
          if (comp._type === 'flex') { return <SettingCompFlex flex={comp as MCompFlex} key={comp._type} />; }
          return <div key={comp._type}>{comp._type}</div>;
        })}
        {props.children}
      </div>
      <div className="settings-effect">
        {componentsBtnsMap.map(({ elementType, label, comp }, i: number) => {
          if (!getComponent(props.element, elementType)) {
            return (
              <Button
                key={i}
                disabled={isThereTemplate && !staysIfTemplate.includes(elementType)}
                size="small"
                onClick={() => AddComponent(comp)}
              >
                {label}
              </Button>
            );
          }
          return null;
        })}
      </div>
    </YcCard>
  );
}
