/* eslint-disable react/no-danger */
import {
  ExportType, MCreative, MCreativeElementBase, MCreativeEndcard,
} from '../../../../../modeles';
import { PreviewCompBackground } from './preview-comp-background';
import { PreviewElement } from './preview-element';

export const PreviewEndcard_CSS = `
.preview-endcard { position: absolute; top: 0; left: 0; right: 0; bottom: 0; display: none; }
.preview-endcard-a { position: absolute; top: 0; left: 0; right: 0; bottom: 0; }
`;

export function PreviewEndcard(props: {
  endcard: MCreativeEndcard,
  base64?: { [key:string]: string },
  exportType?: ExportType,
  language?: string,
  iec?: MCreative,
}) {
  return (
    <div className="preview-endcard" id="endcard">
      <PreviewCompBackground background={props.endcard.background} />
      {props.endcard.elements.map(
        (elem: MCreativeElementBase) => (
          <PreviewElement
            exportType={props.exportType}
            element={elem}
            key={elem._key}
            base64={props.base64}
            language={props.language}
            iec={props.iec}
          />
        ),
      )}
      {props.base64 && (
      <div dangerouslySetInnerHTML={{
        __html: '<a class="preview-endcard-a" href="javascript:void(0);" onClick="monsterplayable.install()">&nbsp;</a>',
      }}
      />
      )}
    </div>
  );
}
