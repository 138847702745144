/* eslint-disable jsx-a11y/control-has-associated-label */
import Avatar from 'antd/lib/avatar/avatar';
import { Link, NavLink } from 'react-router-dom';
import { Button, Popover } from 'antd';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserCrown, faGaugeHigh, faWrench, faArrowTrendUp,
  faGrid2, faCrown, faPowerOff, faRightLeft, faGear,
  faUsers, faUser, faFileInvoiceDollar,
} from '@fortawesome/pro-regular-svg-icons';

import { useAuths } from '../../contexts/auths.context';

import './header.scss';

export function YcHeader() {
  const uAuths = useAuths();

  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const toggleIsNavCollapsed = () => {
    setIsNavCollapsed((old) => !old);
  };

  const onLogout = () => {
    uAuths.logout();
  };

  return (
    <nav
      id="header-component"
      className={`
        ${uAuths.isSuperAdminLogAs() ? 'log-as-s-admin' : ''}
        navbar
        navbar-expand-lg
      `}
    >
      <div className="header-background" />
      <div className="header-container">
        <Link to={uAuths.isLogin() ? '/applications' : '/'}>
          <img className="logo" title="Monster Playable" alt="Logo Monster Playable" src="/logo-Monster-Playable.svg" />
        </Link>
        <div className="links">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={!isNavCollapsed}
            aria-label="Toggle navigation"
            onClick={toggleIsNavCollapsed}
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div id="navbarSupportedContent" className={`${isNavCollapsed ? 'collapse' : ''} mobilenav navbar-collapse`}>
            <ul className="navbar-nav">
              {uAuths.isLogin() === true && (
                <>
                  <li className="nav-item">
                    <NavLink to="/applications" activeClassName="active">
                      <FontAwesomeIcon className="icon-link" icon={faGrid2} />
                      Applications
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/reporting" activeClassName="active">
                      <FontAwesomeIcon className="icon-link" icon={faArrowTrendUp} />
                      Reporting
                    </NavLink>
                  </li>
                  {(uAuths.isSuperAdmin() || uAuths.isSuperAdminLogAs()) && (
                    <li className="nav-item">
                      <NavLink to="/performance" activeClassName="active">
                        <FontAwesomeIcon className="icon-link" icon={faGaugeHigh} />
                        Performance
                      </NavLink>
                    </li>
                  )}
                  {uAuths.canAccess('setup') && (
                    <li className="nav-item">
                      <NavLink to="/setup" activeClassName="active">
                        <FontAwesomeIcon className="icon-link" icon={faWrench} />
                        Setup
                      </NavLink>
                    </li>
                  )}
                  {(uAuths.isSuperAdmin() || uAuths.canAccess('mpAdmin')) && (
                    <NavLink to="/admin" activeClassName="active" className="hide">
                      <FontAwesomeIcon icon={faCrown} />
                    </NavLink>
                  )}
                  <li className="nav-item">
                    <Popover
                      placement="bottomRight"
                      arrow={{ pointAtCenter: true }}
                      title={(
                        <>
                          <div><b>{uAuths.user?.Account.name}</b></div>
                          {uAuths.user?.email}
                        </>
                      )}
                      content={(
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item">
                            <NavLink activeClassName="active" className="d-flex align-items-center gap-2" to="/account/profile">
                              <FontAwesomeIcon className="icon-link" icon={faUser} />
                              <span>Profile</span>
                            </NavLink>
                          </li>
                          <li className="list-group-item">
                            <NavLink activeClassName="active" className="d-flex align-items-center gap-2" to="/account/invoices">
                              <FontAwesomeIcon className="icon-link" icon={faFileInvoiceDollar} />
                              <span>Invoices</span>
                            </NavLink>
                          </li>
                          {uAuths.isAdmin() && (
                            <li className="list-group-item">
                              <NavLink activeClassName="active" className="d-flex align-items-center gap-2" to="/account/account">
                                <FontAwesomeIcon className="icon-link" icon={faGear} />
                                <span>Account</span>
                              </NavLink>
                            </li>
                          )}
                          {uAuths.isAdmin() && (
                            <li className="list-group-item">
                              <NavLink activeClassName="active" className="d-flex align-items-center gap-2" to="/account/users">
                                <FontAwesomeIcon className="icon-link" icon={faUsers} />
                                <span>Users</span>
                              </NavLink>
                            </li>
                          )}
                          <li className="list-group-item">
                            <NavLink className="d-flex align-items-center gap-2 text-danger" to="/login" onClick={onLogout}>
                              <FontAwesomeIcon className="icon-link" icon={faPowerOff} />
                              <span>Logout</span>
                            </NavLink>
                          </li>
                          {uAuths.isSuperAdminLogAs() && (
                            <li className="list-group-item">
                              <NavLink
                                className="d-flex align-items-center gap-2 text-danger"
                                to={window.location.pathname}
                                onClick={() => { uAuths.logAs(uAuths.user!.adminLogged!.id); }}
                              >
                                <FontAwesomeIcon className="icon-link" icon={faRightLeft} />
                                <span>Switch Back</span>
                              </NavLink>
                            </li>
                          )}
                        </ul>
                      )}
                      trigger="click"
                    >
                      <a href="#/">
                        <Avatar
                          className="avatar"
                          icon={
                            uAuths.isSuperAdminLogAs()
                              ? <FontAwesomeIcon icon={faUserCrown} />
                              : <FontAwesomeIcon icon={faUser} />
                          }
                        />
                      </a>
                    </Popover>
                  </li>
                </>
              )}
              {uAuths.isLogin() === false && (
                <>
                  <li className="nav-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/iec-presentation">Interactive End Card</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/playable-presentation">Playable</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/login">
                      <Button className="login-button">
                        <FontAwesomeIcon className="icon-link" icon={faUser} />
                        Login
                      </Button>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}
