import { SettingBase } from '../settings/setting-base';
import { IGameObjectJson } from '../../../../../modeles/MP_Interfaces';
import { usePlayable } from '../../../context/playable.context';

import './setting.scss';

export function Setting() {
  const uPlayable = usePlayable();
  const node = uPlayable.getNodeEdit();

  if (node) {
    return (
      <SettingBase title={`${node.name}`} element={node as IGameObjectJson} />
    );
  }

  return <SettingBase title="EMPTY" />;
}
