import { BaseService } from './base.service';
import { MReporting } from '../modeles';

class ReportingService extends BaseService {
  PATH = 'iec-entries/';
  PATH_REPORTING = 'reporting/creative/';
  PATH_APPLICATION = 'reporting/application/';

  async entriesByApplication(applicationId: number): Promise<MReporting[] | null> {
    return this._listing(`${this.PATH}entries/${applicationId}`);
  }

  async analyticsByApplication(applicationId: number): Promise<any | null> {
    return this._listing(`${this.PATH}analytics/${applicationId}`);
  }

  async analyticsByIec(applicationId: number, iecId: number): Promise<any | null> {
    return this._listing(`${this.PATH}analytics/${applicationId}/${iecId}`);
  }

  // PATH_REPORTING
  async filters(): Promise<any | null> {
    return this._view(`${this.PATH_REPORTING}filters/`);
  }

  async search(data: object): Promise<any | null> {
    return this._view(`${this.PATH_REPORTING}search`, data);
  }

  // PATH_ACCOUNT_REPORTING
  async filtersApplication(accountId: number): Promise<any | null> {
    return this._view(`${this.PATH_APPLICATION}filters/${accountId}`);
  }

  async searchApplication(accountId: number, data: object): Promise<any | null> {
    return this._view(`${this.PATH_APPLICATION}search/${accountId}`, data);
  }
}

export const reportingService = new ReportingService();
