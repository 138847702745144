import { gererateRandomString } from '../tools';
import { MCompBackground, MCompBase, MCompTransform } from './creativeComponent.m';
import { MDocument } from './document.m';
import { MColor } from './color.m';
import { MApplication } from './application.m';
import { MAccount } from './account.m';
import { MUser } from './user.m';

export type ExportType = 'Try' | 'Download' | 'Export';
export type NodeType = '' | 'element' | 'video' | 'break' | 'endcard' | 'hover' | 'loop';
export type CreativeType = 'IEC' | 'PLA';

export const EndVideoActionTypeValues = [
  { key: 'Video change', value: 'video-change' },
  { key: 'Endcard display', value: 'endcard-display' },
] as const;
export type EndVideoActionType = typeof EndVideoActionTypeValues[number]['value'];

export const APP_WIDTH = 640;
export const PHONE_WIDTH = 360;
export const PHONE_HEIGHT = 640;

export const AppScale = () => {
  return APP_WIDTH / PHONE_WIDTH;
};

export function generateCreativeKey() {
  return gererateRandomString(6);
}

export class MNodeBase {
  _key: string = generateCreativeKey();
  _type: NodeType = '';
  _open: boolean = true;
  _edite: boolean = false;
  _isLocked: boolean = false;
  _isHidden: boolean = false;
  _displayName: string = '';
}

export class MCreativeBreak extends MNodeBase {
  constructor(time?: number) {
    super();
    if (time) { this.time = time; }
  }
  _type: NodeType = 'break';
  background = new MCompBackground(new MColor(255, 255, 255, 0.0));
  time: number = 0;
  elements: MCreativeElementBase[] = [];
}

export class MCreativeLoop extends MNodeBase {
  constructor(time?: number) {
    super();
    if (time) { this.timeStart = time; }
    if (time) { this.timeEnd = time + 1; }
  }
  _type: NodeType = 'loop';
  background = new MCompBackground(new MColor(255, 255, 255, 0.0));
  displayedTime?: number | null = null;
  timeStart: number = 0;
  timeEnd: number = 0;
  elements: MCreativeElementBase[] = [];
}

export class MCreativeHover extends MNodeBase {
  _type: NodeType = 'hover';
  background = new MCompBackground(new MColor(255, 255, 255, 0.0));
  elements: MCreativeElementBase[] = [];
}

export class MCreativeEndcard extends MNodeBase {
  _type: NodeType = 'endcard';
  background = new MCompBackground(new MColor(255, 255, 255, 0.0));
  elements: MCreativeElementBase[] = [];
}

export class MCreativeVideo extends MNodeBase {
  _type: NodeType = 'video';
  background = new MCompBackground(new MColor(255, 255, 255, 0.0));
  video: MDocument = new MDocument();
  videoName: string = '';
  videoDuration: number = 0;
  loop: boolean = false;
  muted: boolean = true;
  endVideoAction: EndVideoActionType = 'endcard-display';
  displayEndCardRedirect: boolean = true;
  nextVideoKey: string | null = null;
  breaks: MCreativeBreak[] = [];
  loops: MCreativeLoop[] = [];
  hover?: MCreativeHover;
}
export class MCreativeIecData {
  constructor() {
    this.videos[0]._edite = true;
  }
  _phoneWidth = PHONE_WIDTH;
  _phoneHeight = PHONE_HEIGHT;
  endcard: MCreativeEndcard = new MCreativeEndcard();
  hover?: MCreativeHover;
  videos: MCreativeVideo[] = [new MCreativeVideo()];
  width: number = 0;
  height: number = 0;
  zoomPortrait: number = 1;
  zoomLandscape: number = 1;
}

type CreativeStatus = 'null' | 'failed' | 'pending' | 'running' | 'succeeded';

export class MCreative {
  id: number = 0;
  accountId: number = 0;
  userId: number = 0;
  applicationId: number = 0;
  name: string = '';
  type?: CreativeType;
  version: number = 0;
  subVersion: number = 0;
  iecData = new MCreativeIecData();
  appStore?: string;
  playStore?: string;
  androidIsExported: boolean = false;
  iosIsExported: boolean = false;
  stats?: any;
  iecEngineVersion?: string;
  playableEngineVersion: string = '0.0.1';
  apiVersion?: string;
  playableId?: number;
  playableReleaseId?: number;
  playableDataId?: number;
  Playable?: MDocument;
  PlayableRelease?: MDocument;
  PlayableData?: MDocument;
  Application?: MApplication;
  Account?: MAccount;
  User?: MUser;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  schema: any;
  translations: any[] = [];
  status: CreativeStatus = 'null';
}

// ELEMENTS
export class MCreativeElementBase extends MNodeBase {
  _type: NodeType = 'element';
  transform = new MCompTransform();
  components: MCompBase[] = [];
  elements: MCreativeElementBase[] = [];
}

export class MCreativeListing {
  id: number = 0;
  accountId: number = 0;
  applicationId: number = 0;
  creativeId: number = 0;
  info: string = '';
  createdAt?: Date;
  updatedAt?: Date;
  sendAt?: Date;
  requestAt?: Date;
  Account: MAccount = new MAccount();
  Application: MApplication = new MApplication();
  Creative: MCreative = new MCreative();
  isFree: boolean = false;
}
