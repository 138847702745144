/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useCallback, useMemo, useState } from 'react';
import { MCreative } from '../../../modeles';

export type OrientationType = 'portrait' | 'landscape';

export interface CreativeContextType {
  creative: MCreative,
  showTranslation: boolean,
  currentLanguage: string,
  // phoneOrientation: { width: number, height: number },

  refreshCreative: (save: boolean) => void;

  setCreative: (creative: MCreative | null) => void;
  setShowTranslation: (show: boolean) => void
  setCurrentLanguage: (language: string) => void
  // getPlayableFile: () => Promise<string>;
  // getPhoneOrientation: () => OrientationType,
  // getTourVisibility: () => boolean,
  // setTourVisibility: (visibility: boolean) => void,
}

const CreativeContext = React.createContext<CreativeContextType>({
  creative: new MCreative(),
  showTranslation: false,
  currentLanguage: 'ZZ',
  // phoneOrientation: { width: 420, height: 640 },

  refreshCreative: (/* save: boolean */) => {},

  setCreative: (/* playable: MCreative | null */) => {},
  setShowTranslation: (/* show: boolean */) => { },
  setCurrentLanguage: (/* show: boolean */) => { },
  // getPhoneOrientation: () => { return 'portrait'; },
  // // eslint-disable-next-line @typescript-eslint/require-await
  // getPlayableFile: async () => { return ''; },
  // getTourVisibility: () => { return false; },
  // setTourVisibility: () => {},
});

export function CreativeContextProvider({ children }: { children: React.ReactNode }) {
  const [creative, _setCreative] = useState<MCreative>(new MCreative());
  const [showTranslation, _setShowTranslation] = useState<boolean>(false);
  const [currentLanguage, _setCurrentLanguage] = useState<string>('ZZ');
  // const [phoneOrientation] = useState<{ width: number, height: number }>({ width: 420, height: 640 });
  // const [tourIsVisible, _setTourIsVisible] = useState<boolean>(true);

  // const _duplicate = (i: MCreative) => {
  //   return JSON.parse(JSON.stringify(i)) as MCreative;
  // };

  const setCreative = useCallback((data: MCreative | null) => {
    const newData = data || new MCreative();
    _setCreative(JSON.parse(JSON.stringify(newData)) as MCreative);
  }, []);

  const refreshCreative = useCallback((save: boolean) => {
    save as boolean;
  }, []);

  // const getPhoneOrientation = useCallback(() : OrientationType => {
  //   if (playable.iecData._phoneWidth > playable.iecData._phoneHeight) {
  //     return 'landscape';
  //   }
  //   return 'portrait';
  // }, [playable]);

  // const getPlayableFile = useCallback(async () : Promise<string> => {
  //   return await (await fetch(playable.Playable?.url || '')).text() as string;
  // }, [playable]);

  // const getTourVisibility = useCallback(() : boolean => {
  //   return tourIsVisible;
  // }, [tourIsVisible]);

  // const setTourVisibility = useCallback((visibility: boolean) => {
  //   _setTourIsVisible(visibility);
  // }, [_setTourIsVisible]);

  const setShowTranslation = useCallback((show: boolean) => {
    _setShowTranslation(show);
  }, []);

  const setCurrentLanguage = useCallback((language: string) => {
    _setCurrentLanguage(language);
  }, []);

  const value = useMemo(() => ({
    creative,
    showTranslation,
    currentLanguage,
    refreshCreative,
    // phoneOrientation,
    // getPlayableFile,
    // getPhoneOrientation,
    // getTourVisibility,
    setCreative,
    setShowTranslation,
    setCurrentLanguage,
    // setTourVisibility,
  }), [
    creative,
    showTranslation,
    currentLanguage,
    refreshCreative,
    // phoneOrientation,
    // getPlayableFile,
    // getPhoneOrientation,
    // getTourVisibility,
    setCreative,
    setShowTranslation,
    setCurrentLanguage,
    // setTourVisibility,
  ]);

  return (
    <CreativeContext.Provider value={value}>
      {children}
    </CreativeContext.Provider>
  );
}

export function useCreative() {
  const context = React.useContext(CreativeContext);
  if (!context) throw new Error('No Creative Shared Informations provider found!');
  return context;
}
