import { MCompBlur } from '../../../../../../modeles';
import { useIec } from '../../../../context/iec.context';
import { YcCard } from '../../../../../../components';
import { IecInputNumber } from '../../../input-number';

import './setting-comp-blur.scss';

export function SettingCompBlur(props: {
  blur: MCompBlur
}) {
  const uIec = useIec();

  return (
    <YcCard
      title="blur"
      light
      open={props.blur._open}
      onClickTitle={(val) => {
        props.blur._open = val;
        uIec.refreshIec(true);
      }}
      onDelete={() => {
        uIec.removeComponentNodeEdite(props.blur);
      }}
    >
      <div className="row">
        <div className="col-4">
          <IecInputNumber label="Blur" field="blur" obj={props.blur} step={0.1} />
        </div>
      </div>

    </YcCard>
  );
}
