import { useHistory, useParams, Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { ColumnType } from 'antd/lib/table';
import {
  Spin, Button, Tooltip, Popconfirm, Popover, Modal, Form, Input, Card,
  Alert,
} from 'antd';
import {
  AndroidFilled, AppleFilled, WarningOutlined,
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileExport, faCopy, faTrash, faPlay,
} from '@fortawesome/pro-regular-svg-icons';

import { MCreative } from '../../../../../../modeles';
import { deepCopy, iecIsEditable } from '../../../../../../tools/utils.tool';
import { applicationsService, creativesService, clientManagerCreativesService } from '../../../../../../services';
import packageJson from '../../../../../../../package.json';
import { useAuths } from '../../../../../../contexts/auths.context';
import { CreativesContextType, useCreatives } from '../../../../context/creatives.context';
import { setSelectedTemplate, TEMPLATES } from './creative-default-template';
import { MpTable } from '../../../../../../components';

import './iecs-list.page.scss';
import { ApplicationContextType, useApplication } from '../../../../context';

const { Meta } = Card;

const frontVersion = packageJson.version;

export function IecsListPage(props: {
  filter: string;
}) {
  const uCreatives: CreativesContextType = useCreatives();
  const uApplication: ApplicationContextType = useApplication();
  const uAuths = useAuths();
  const uHistory = useHistory();
  const uParams: any = useParams();

  const [iecs, _setIecs] = useState<MCreative[]>([]);
  const [isCreateIec, _setIsCreateIec] = useState<boolean>(false);
  const [templateTested, _setTemplateTested] = useState<any | null>(null);
  const [isCopyIec, _setIsCopyIec] = useState<boolean>(false);
  const [copyIec, _setCopyIec] = useState<MCreative>();
  const [nextVersion, _setNextVersion] = useState<number>();
  const [templateSelected, _setTemplateSelected] = useState<any>('empty');
  const [iframeKey, setIframeKey] = useState(0);

  const templatesContainerOuter = useRef(null);

  useEffect(() => {
    _setIecs(uCreatives.getCreatives('IEC') || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uCreatives.getIsReady()]);

  useEffect(() => {
    _setIecs((uCreatives.getCreatives('IEC') || [])
      .filter((iec) => iec.name.toLowerCase().includes(props.filter)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filter, uCreatives.creatives]);

  const versionWarning = (
    <div className="warning-popup">
      <p>The creation tool has been updated.</p>
      <p>Please check your creative before export</p>
    </div>
  );

  const versionError = (
    <div className="warning-popup">
      <p>The creation tool has got a major update.</p>
      <p>This IEC is no longer editable</p>
    </div>
  );

  const iecsColumns: ColumnType<MCreative>[] = [
    {
      title: 'IEC',
      key: 'name',
      render: (text: string, record: MCreative) => (
        iecIsEditable(record)
          ? <Link to={`creatives/iec/${record.id}`}>{record.name}</Link>
          : <label>{record.name}</label>
      ),
      sorter: (a: MCreative, b: MCreative) => a.name.localeCompare(b.name || '') || -1,
    },
    {
      title: 'User',
      key: 'user',
      render: (text: string, record: MCreative) => {
        return (record.User?.name)
          ? <label>{record.User?.name}</label>
          : <label>{record.User?.email}</label>;
      },
      sorter: (a: MCreative, b: MCreative) => a.User?.name.localeCompare(b.User?.name || '') || -1,
    },
    {
      title: 'Version',
      key: 'version',
      fixed: 'right',
      width: '100px',
      align: 'center',
      render: (text: number, record: MCreative) => (
        <label>{record.version}</label>
      ),
      sorter: (a: MCreative, b: MCreative) => (a.version || 0) - (b.version || 0),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Exported',
      key: 'exported',
      fixed: 'right',
      width: '100px',
      align: 'center',
      render: (text: string, record: MCreative) => (
        <div className="exported">
          {record.androidIsExported && <AndroidFilled />}
          {record.iosIsExported && <AppleFilled />}
        </div>
      ),
    },
    {
      title: 'Info',
      key: 'information',
      fixed: 'right',
      width: '50px',
      align: 'center',
      render: (text: number, record: MCreative) => (
        checkIecVersion(record)
      ),
    },
    {
      title: 'Creation',
      key: 'creaion',
      fixed: 'right',
      width: '100px',
      align: 'center',
      render: (text: number, record: MCreative) => (
        <label>{new Date(record.createdAt!).toLocaleString()}</label>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      width: '100px',
      align: 'center',
      render: (text: string, record: MCreative) => (
        <div className="actions">
          {
            !uAuths.isFreeAccount()
              ? (
                <Link className="export" to={`/applications/${uParams.appId}/export/${record.id}`}>
                  <Tooltip placement="top" title="Export creative">
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<FontAwesomeIcon icon={faFileExport} title="Text align left" />}
                      size="small"
                    />
                  </Tooltip>
                </Link>
              )
              : (
                <Tooltip title="Your account is on FREE mode, contact us at: contact@monsterplayable.com!">
                  <Button
                    type="primary"
                    shape="circle"
                    disabled={uAuths.isFreeAccount()}
                    icon={<FontAwesomeIcon icon={faFileExport} title="Text align left" />}
                    size="small"
                  />
                </Tooltip>
              )
          }
          {record.type === 'IEC'
            && (
              <Tooltip placement="top" title="Copy creative">
                <Button
                  type="primary"
                  shape="circle"
                  onClick={() => { openCopyIecModal(record); }}
                  icon={<FontAwesomeIcon icon={faCopy} title="Text align left" />}
                  size="small"
                />
              </Tooltip>
            )}
          {uAuths.isAdmin()
            && (
              <Tooltip placement="left" title="Remove creative">
                <>
                  <Popconfirm title="Sure to delete?" onConfirm={() => { uCreatives.adminDelete(record.id); }}>
                    <Button type="primary" shape="circle" icon={<FontAwesomeIcon icon={faTrash} title="Text align left" />} size="small" danger />
                  </Popconfirm>
                </>
              </Tooltip>
            )}
          {uAuths.isSuperAdmin()
            && (
              <Tooltip placement="left" title="! Admin delete !">
                <>
                  <Popconfirm title="Sure to delete?" onConfirm={() => { uCreatives.superAdminDelete(record.id); }}>
                    <Button type="dashed" shape="circle" icon={<FontAwesomeIcon icon={faTrash} title="Text align left" />} size="small" danger />
                  </Popconfirm>
                </>
              </Tooltip>
            )}
        </div>
      ),
    },
  ];

  const checkIecVersion = (record: MCreative) => {
    if (record.iecEngineVersion !== frontVersion) {
      if (!iecIsEditable(record)) {
        return (
          <Popover content={versionError} title="Alert!">
            <WarningOutlined style={{ color: 'red', fontSize: '24px' }} />
          </Popover>
        );
      }
      return (
        <Popover content={versionWarning} title="Warning!">
          <WarningOutlined style={{ color: 'orange', fontSize: '24px' }} />
        </Popover>
      );
    }
    return '';
  };

  const openNewIecModal = async () => {
    _setNextVersion(await creativesService.nextCreative({ applicationId: uParams.appId, type: 'IEC' }) as number);
    _setIsCreateIec(true);
  };

  const openCopyIecModal = async (iec: MCreative) => {
    _setCopyIec(deepCopy(iec));
    _setNextVersion(await creativesService.nextCreative({ applicationId: uParams.appId, type: 'IEC' }) as number);
    _setIsCopyIec(true);
  };

  const onCreate = (values: MCreative) => {
    if (uAuths.isClientManager()) {
      const newIec = {
        ...new MCreative(),
        name: values.name,
        applicationId: uApplication.app!.id,
        accountId: uApplication.app!.accountId,
        version: values.version,
        type: 'IEC',
        appStore: uApplication.app!.appStore,
        playStore: uApplication.app!.playStore,
        iecEngineVersion: frontVersion,
      } as MCreative;
      setSelectedTemplate(newIec, templateSelected.key as string);
      clientManagerCreativesService.addIec(newIec).then((data) => {
        if (data) {
          uHistory.push(`/applications/${uParams.appId}/creatives/iec/${data.id}`);
        }
      });
    } else {
      applicationsService.view(uParams.appId as number).then((application) => {
        if (application) {
          const newIec = {
            ...new MCreative(),
            name: values.name,
            applicationId: application.id,
            version: values.version,
            type: 'IEC',
            appStore: application.appStore,
            playStore: application.playStore,
            iecEngineVersion: frontVersion,
          } as MCreative;
          setSelectedTemplate(newIec, templateSelected.key as string);
          creativesService.add(newIec).then((data) => {
            if (data) {
              uHistory.push(`/applications/${uParams.appId}/creatives/iec/${data.id}`);
            }
          });
        }
      });
    }
  };

  const onCopy = (values: MCreative) => {
    applicationsService.view(uParams.appId as number).then((application) => {
      if (application) {
        const newIec = {
          ...new MCreative(),
          name: values.name,
          applicationId: application.id,
          version: values.version,
          type: 'IEC',
          appStore: copyIec?.appStore,
          playStore: copyIec?.playStore,
          iecEngineVersion: frontVersion,
          iecData: copyIec?.iecData,
        } as MCreative;
        creativesService.add(newIec).then((data) => {
          if (data) {
            uHistory.push(`/applications/${uParams.appId}/creatives/iec/${data.id}`);
          }
        });
      }
    });
  };

  return (
    <div id="creatives-creatives">
      <Spin spinning={!uCreatives.getIsReady()}>
        <MpTable
          bordered
          size="small"
          dataSource={iecs}
          columns={iecsColumns}
          rowKey="id"
          urlParamsEnabled
          mpTableKey="iecs"
          defaultPageSize={10}
        />
      </Spin>
      <Button type="primary" className="bt-add" onClick={() => { openNewIecModal(); }}>Add IEC</Button>
      <Modal
        width="900px"
        title="Create IEC"
        open={isCreateIec}
        onCancel={() => {
          _setIsCreateIec(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              _setIsCreateIec(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            type="primary"
            key="submit"
            form="formCreate"
            htmlType="submit"
          >
            Validate
          </Button>,
        ]}
      >
        <Form id="formCreate" onFinish={onCreate} layout="vertical">
          <div className="row">

            <Form.Item className="col-6" label="Name" name="name" rules={[{ required: true }]} initialValue={`I${nextVersion}`}>
              <Input type="text" />
            </Form.Item>
            <Form.Item className="col-6" label="Version" name="version" rules={[{ required: true }]} initialValue={nextVersion}>
              <Input type="number" className="version" min={nextVersion} />
            </Form.Item>
            <Form.Item label="Choose a template" name="template">

              <div ref={templatesContainerOuter} className="templates-container-outer">
                <div className="templates-container">

                  <div className="row">
                    {TEMPLATES?.map((template, index) => (
                      <div className="col-md-3 col-sm-6 mb-3 template-card" key={index}>
                        {
                          template.key !== 'empty' && (
                            <Button
                              onClick={(e) => {
                                e.preventDefault();
                                _setTemplateTested(template);
                              }}
                              className="button-try"
                              shape="circle"
                              icon={<FontAwesomeIcon icon={faPlay} title="Try It" />}
                            />
                          )
                        }
                        <Card
                          key={index}
                          className={`template ${template.key === templateSelected.key ? 'selected' : ''}`}
                          onClick={() => _setTemplateSelected(template)}
                          hoverable
                          cover={<img className="image" alt={template.title} src={template.src} />}
                        >
                          <Meta title={template.title} description={template.description} />
                        </Card>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      <Modal
        title="Copy IEC"
        open={isCopyIec}
        okText={<>Validate</>}
        okButtonProps={{ form: 'formCopy', htmlType: 'submit' }}
        onCancel={() => {
          _setIsCopyIec(false);
        }}
      >
        <Form id="formCopy" onFinish={onCopy} layout="vertical">
          <Form.Item label="Name" name="name" rules={[{ required: true }]} initialValue={`I${nextVersion}`}>
            <Input type="text" />
          </Form.Item>
          <Form.Item label="Version" name="version" rules={[{ required: true }]} initialValue={nextVersion}>
            <Input type="number" className="version" min={nextVersion} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={templateTested?.title}
        open={templateTested}
        onCancel={() => {
          _setTemplateTested(null);
        }}
        footer={(
          <Button
            type="primary"
            onClick={() => { setIframeKey((prevKey) => prevKey + 1); }}
          >Retry
          </Button>
        )}
      >
        <iframe
          key={iframeKey}
          className="Type"
          src={templateTested?.iec}
          width="100%"
          height="500px"
          title={templateTested?.title}
        />
      </Modal>
    </div>
  );
}
