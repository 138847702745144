import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight } from '@fortawesome/pro-regular-svg-icons';
import { Button, Tooltip } from 'antd';

import { IconMonsterPlayable } from '../../../../../../../assets/images/home';
import { IGame } from '../../../home.page';

import './games-card.scss';

export function GamesCard(props: {
  games: IGame[],
  selectedGame: IGame | null,
  selectGame: (game: IGame) => void,
  isSmallScreen: boolean,
  refresh: () => void,
}) {
  const selectGame = (game: IGame) => {
    props.selectGame(game);
    if (props.isSmallScreen) {
      window.open(game.url, '_blank');
    }
  };

  const refresh = () => {
    props.refresh();
  };

  return (
    <div id="games-card">
      <div className="title">
        <h3>Choose game</h3>
        <Tooltip title="search">
          <Button
            type="primary"
            shape="circle"
            onClick={refresh}
            icon={<FontAwesomeIcon icon={faRotateRight} title="Font weight" />}
          />
        </Tooltip>
      </div>
      <div className="games row">
        {
          props.games.map((game, index) => (
            <div key={index} className="col-4" onClick={() => { selectGame(game); }}>
              <GameIcon game={game} selected={props.selectedGame?.name === game.name} />
            </div>
          ))
        }
      </div>
    </div>
  );
}

export function GameIcon(props: {
  game: IGame,
  selected : boolean,
}) {
  return (
    <div id="game-icon" className={props.selected ? 'selected' : ''}>
      <div className="icon">
        {
          props.game.icon
            ? (<img src={props.game.icon} alt="Game Icon" className="ico" />)
            : (<img src={IconMonsterPlayable} alt="Monster Playable Icon" className="empty" />)
        }
      </div>
      <label className="game-name">{props.game.name}</label>
    </div>
  );
}
