import { Button, Select } from 'antd';

import { useIec } from '../../context/iec.context';
import { useIecSettings } from '../../context/iec-settings.context';

import './iec-select.scss';

const SelectOption = Select.Option;

export function IecSelect(props: {
  obj: any,
  field: string,
  values: readonly { value: string, key:string }[],
  label?: string,
  disabled?: boolean,
  extraButtonFunction?: () => void,
  extraButtonLabel?: string,
  isFont?: boolean,
}) {
  const uIec = useIec();
  const uIecSettings = useIecSettings();

  function onChange(value: string) {
    if (props.isFont) {
      props.obj[props.field] = uIecSettings.fonts.find((font) => font.id === +value)?.name;
    } else {
      props.obj[props.field] = value;
    }
    uIec.refreshIec(true);
  }

  return (
    <div className="iec-select">
      {props.label ? <label>{props.label}</label> : ''}
      <div className="d-flex">
        <Select
          value={props.obj[props.field]}
          onChange={(e) => { onChange(e as string); }}
          style={{ display: 'inline-block' }}
          disabled={props.disabled}
          size="small"
        >
          {props.values.map((val) => {
            return <SelectOption key={val.key} value={val.value}>{val.key}</SelectOption>;
          })}
        </Select>
        {props.extraButtonFunction && (
        <Button
          style={{ display: 'inline-block' }}
          size="small"
          onClick={() => props.extraButtonFunction!()}
        >
          {props.extraButtonLabel || 'Choose'}
        </Button>
        )}
      </div>
    </div>
  );
}
