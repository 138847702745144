import { Button, Modal } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';

import { mpSdkService, documentationService } from '../../../../../../services';
import { MMppSdk } from '../../../../../../modeles';

import './step-one.scss';

export function StepOne() {
  const [modalArchivedLoading, _setModalArchivedLoading] = useState<boolean>(false);
  const [mpSdks, _setMpSdks] = useState<MMppSdk[]>([]);

  useEffect(() => {
    _setModalArchivedLoading(true);
    mpSdkService.listingRelease().then((data) => {
      _setMpSdks(data || []);
      _setModalArchivedLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadLastSdk = () => {
    mpSdkService.lastSdk().then((data) => {
      const link = document.createElement('a');
      link.href = data?.Sdk?.url || '#';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).catch(() => { });
  };

  const downloadLastDocumentation = () => {
    documentationService.lastDocumentation().then((data) => {
      const link = document.createElement('a');
      link.target = '_blank';
      link.href = data?.Documentation?.url || '#';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).catch(() => { });
  };

  function downloadSdk(url: string) {
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const showConfirm = () => {
    Modal.confirm({
      title: 'Archived SDKs',
      content: (
        <div className="sdks">
          {mpSdks.map((sdk) => (
            <Button onClick={() => downloadSdk(sdk.Sdk!.url)}>
              {sdk.version}
            </Button>
          ))}
        </div>
      ),
      cancelButtonProps: {
        style: { display: 'none' },
      },
      icon: null,
    });
  };

  return (
    <div id="step-one">
      <div className="d-flex justify-content-around">
        <div className="text-center">
          <p>Download our SDK!</p>
          <Button style={{ marginRight: '10px' }} type="primary" icon={<DownloadOutlined />} onClick={() => downloadLastSdk()}>
            Last SDK
          </Button>
          <Button icon={<DownloadOutlined />} onClick={showConfirm}>
            Archived SDKs
          </Button>
        </div>
        <div className="text-center">
          <p>{'Don\'t forget to check the documentation !'}</p>
          <Button type="primary" icon={<DownloadOutlined />} onClick={() => downloadLastDocumentation()}>
            Documentation
          </Button>
        </div>
      </div>
    </div>
  );
}
