import {
  Route, Switch, useRouteMatch,
} from 'react-router-dom';
import {
  ProfilePage, AccountPage, UsersPage, InvoicesPage,
} from './pages';

// styles
import './index.scss';

export function AccountModule() {
  const { path } = useRouteMatch();

  return (
    <div id="setup">
      <Switch>
        <Route exact path={`${path}/profile`}><ProfilePage /></Route>
        <Route exact path={`${path}/account`}><AccountPage /></Route>
        <Route exact path={`${path}/users`}><UsersPage /></Route>
        <Route exact path={`${path}/invoices`}><InvoicesPage /></Route>
      </Switch>
    </div>
  );
}
