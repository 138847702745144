import { MColor } from '../modeles';

export class ColorTools {
  static mColorToString(color: MColor) {
    return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
  }

  static mToRgba(newColor: any) {
    return {
      r: newColor.r,
      g: newColor.g,
      b: newColor.b,
      a: newColor.a,
      type: 'Color',
    };
  }

  static mToWebRgba(newColor: any) {
    return {
      r: Math.round(newColor.r * 255),
      g: Math.round(newColor.g * 255),
      b: Math.round(newColor.b * 255),
      a: newColor.a,
    };
  }

  static mToRgbaUnity(newColor: any) {
    return {
      r: Math.round(newColor.r / 255),
      g: Math.round(newColor.g / 255),
      b: Math.round(newColor.b / 255),
      a: newColor.a,
    };
  }
}

export const accountColor: Record<number, string> = {
  1: '255, 104, 0',
  2: '102, 133, 134',
  3: '103, 148, 54',
  4: '179, 60, 134',
  5: '0, 42, 34',
  6: '84, 94, 117',
  7: '48, 77, 109',
  8: '194, 113, 79',
  9: '92, 107, 95',
  10: '137, 125, 107',
  11: '85, 99, 104',
  12: '143, 144, 142',
  13: '113, 120, 92',
  14: '128, 133, 106',
  15: '158, 132, 117',
  16: '108, 97, 82',
  17: '116, 117, 130',
  18: '148, 137, 123',
  19: '108, 118, 126',
  20: '120, 135, 126',
  21: '123, 104, 89',
  22: '139, 130, 126',
  23: '102, 111, 103',
  24: '128, 105, 109',
  25: '110, 129, 112',
  26: '114, 108, 115',
  27: '126, 120, 131',
  28: '101, 93, 80',
};
