import { ReactNode } from 'react';
import './information-card.scss';
import { NavLink } from 'react-router-dom';

export function InformationCard(props: {
  icons : string[]
  content : string | ReactNode,
  left : boolean
  path: string
}) {
  return (
    <div id="information-card" className={`${props.left ? 'left' : 'right'}`}>
      <div className={`icons row ${props.left ? '' : 'justify-content-end'}`}>
        {
          props.icons.map((icon, index) => (
            <div className="icon col-4 col-md-3" key={index}>
              <img src={icon} alt="Icon contact" />
            </div>
          ))
        }
      </div>
      <div className="description">
        {props.content}
      </div>
      <div className="mt-4">
        <NavLink className="button" to={props.path}>Learn more</NavLink>
      </div>
    </div>
  );
}
