import { useEffect, useState } from 'react';
import {
  Button, Collapse, Form, Input, Popconfirm, Popover, Select, Spin, Switch, Table, Upload,
} from 'antd';
import { ColumnType } from 'antd/lib/table';
import {
  DeleteOutlined, CloudDownloadOutlined, UploadOutlined, CheckOutlined, CloseOutlined,
} from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit, faSave,
} from '@fortawesome/pro-regular-svg-icons';

import { useAuths } from '../../../../../../contexts/auths.context';
import { MMppSdk } from '../../../../../../modeles';
import { mpSdkService, playableEngineService } from '../../../../../../services';
import { antFormNormFile } from '../../../../../../tools';

import './playable-sdk.scss';

export function PlayableSdk() {
  const uAuths = useAuths();
  const [mpSdks, _setMpSdks] = useState<MMppSdk[]>([]);
  const [loading, _setLoading] = useState(false);
  const [sdkEdited, _setSdkEdited] = useState<number | null>(null);
  const [engineVersions, _setEngineVersions] = useState<string[]>([]);

  const [form] = Form.useForm();

  useEffect(() => {
    _setLoading(true);
    mpSdkService.listing().then((data) => {
      _setMpSdks(data || []);
      _setLoading(false);
    });
    playableEngineService.listing().then((data) => {
      _setEngineVersions(data ? data.map((elem) => elem.version) : []);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function downloadSdk(url: string) {
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const editChangeLog = (id: number, text: string) => {
    mpSdks.find((sdk) => sdk.id === id)!.changeLog = text;
    _setMpSdks([...mpSdks]);
  };

  const editEngineCompatibleVersionEdited = (id: number, text: string) => {
    console.log();
    mpSdks.find((sdk) => sdk.id === id)!.engineCompatibleVersion = text;
    _setMpSdks([...mpSdks]);
  };

  const ypSdksColumns: ColumnType<MMppSdk>[] = [
    {
      title: 'Version',
      key: 'version',
      render: (text: number, record: MMppSdk) => (
        <label>{record.version}</label>
      ),
      sorter: (a, b) => a.version.localeCompare(b.version || '') || -1,
    },
    {
      title: 'Release',
      key: 'isRelease',
      width: '6%',
      render: (text: string, record: MMppSdk) => (
        <Switch
          disabled={!uAuths.isSuperAdmin() || !record.engineCompatibleVersion || record.id === sdkEdited}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          defaultChecked={record.isRelease}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={(e) => {
            record.isRelease = e;
            mpSdkService.isRelease(record.id, e);
          }}
        />
      ),
      sorter: (a: MMppSdk, b: MMppSdk) => (a.isRelease || b.isRelease ? 1 : 0),
      // filters: getBooleanFilter(),
      // onFilter: (value: any, record: MMppSdk) => record.isRelease === (value as boolean) || false,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text: string, record: MMppSdk) => (
        <div className="actions d-flex justify-content-around">
          <Popover content="Download">
            <Button type="primary" shape="circle" icon={<CloudDownloadOutlined />} size="small" onClick={() => downloadSdk(record.Sdk!.url)} />
          </Popover>
          {uAuths.isAdmin()
            && (
              <Popconfirm title="Sure to delete?" onConfirm={() => deleteSdk(record.id)}>
                <Button type="primary" shape="circle" icon={<DeleteOutlined />} size="small" danger />
              </Popconfirm>
            )}
        </div>
      ),
    },
    {
      title: 'Engine Compatible Version',
      key: 'engineCompatibleVersion',
      render: (text: number, record: MMppSdk) => (
        <div className="change-log">
          <Select
            disabled={record.id !== sdkEdited}
            value={record.engineCompatibleVersion}
            onChange={(e) => { editEngineCompatibleVersionEdited(record.id, e as string); }}
            style={{ width: '100%' }}
            options={
              engineVersions.map((version) => ({ value: version, label: version }))
            }
          />
          <Button
            className={record.id !== sdkEdited ? 'visible button-icon' : 'button-icon'}
            type="primary"
            shape="circle"
            size="small"
            icon={<FontAwesomeIcon icon={faEdit} size="sm" />}
            onClick={() => _setSdkEdited(record.id)}
            disabled={!!sdkEdited}
          />
          <Button
            className={record.id === sdkEdited ? 'visible button-icon' : 'button-icon'}
            type="primary"
            shape="circle"
            size="small"
            icon={<FontAwesomeIcon icon={faSave} size="sm" />}
            onClick={() => {
              _setSdkEdited(null);
              mpSdkService.edit(record.id, record);
            }}
          />
        </div>
      ),
    },
    {
      title: 'Change Logs',
      key: 'changeLog',
      render: (text: number, record: MMppSdk) => (
        <div className="change-log">
          <TextArea
            disabled={record.id !== sdkEdited}
            value={record.changeLog}
            onChange={(e) => { editChangeLog(record.id, e.target.value as string); }}
          />
          <Button
            className={record.id !== sdkEdited ? 'visible button-icon' : 'button-icon'}
            type="primary"
            shape="circle"
            size="small"
            icon={<FontAwesomeIcon icon={faEdit} size="sm" />}
            onClick={() => _setSdkEdited(record.id)}
            disabled={!!sdkEdited}
          />
          <Button
            className={record.id === sdkEdited ? 'visible button-icon' : 'button-icon'}
            type="primary"
            shape="circle"
            size="small"
            icon={<FontAwesomeIcon icon={faSave} size="sm" />}
            onClick={() => {
              _setSdkEdited(null);
              mpSdkService.edit(record.id, record);
            }}
          />
        </div>
      ),
    },
    {
      title: 'Date',
      key: 'date',
      render: (text: number, record: MMppSdk) => (
        <label>{new Date(record.createdAt!).toLocaleString()}</label>
      ),
    },
  ];

  const onFinish = (values: any) => {
    _setLoading(true);
    const sdk = {
      ...(new MMppSdk()),
      version: values.version,
      Sdk: values.sdk[0].response.data,
      engineCompatibleVersion: values.engineCompatibleVersion,
      changeLog: values.changeLog,
    };
    mpSdkService.add(sdk).then((data) => {
      if (data) {
        _setMpSdks([...mpSdks, data]);
        (form as unknown as { resetFields: () => void }).resetFields();
        _setLoading(false);
      }
    });
  };

  const deleteSdk = (id: number) => {
    const dataSource = [...mpSdks];
    _setMpSdks(dataSource.filter((item) => item.id !== id));
    mpSdkService.remove(id);
  };

  return (
    <div id="playable-sdk">
      <div className="row">
        {uAuths.canAccess('mpAdmin/sdkEngine')
          && (
            <>
              <div className="col-6">
                <Collapse
                  ghost
                  items={[{
                    key: '1',
                    label: 'Manuel upload',
                    children:
                      // eslint-disable-next-line react/jsx-indent
                      <Form onFinish={onFinish} form={form} layout="vertical">
                        <div className="row">
                          <div className="row">
                            <div className="col-12">
                              <Form.Item label="Version" name="version" rules={[{ required: true }]}>
                                <Input type="text" />
                              </Form.Item>
                            </div>
                            <div className="col-12">
                              <Form.Item label="Engine Compatible Version" name="engineCompatibleVersion" rules={[{ required: false }]}>
                                <Input type="text" />
                              </Form.Item>
                            </div>
                            <div className="col-12">
                              <Form.Item label="Change Log" name="changeLog" rules={[{ required: false }]}>
                                <TextArea rows={3} />
                              </Form.Item>
                            </div>
                            <div className="col-12">
                              <Form.Item label="SDK" name="sdk" rules={[{ required: true }]} valuePropName="fileList" getValueFromEvent={antFormNormFile}>
                                <Upload
                                  name="sdk"
                                  headers={{
                                    authorization: localStorage.getItem('user-token') || '',
                                  }}
                                  action={`${process.env.REACT_APP_API_URL}/mp-sdk/upload-sdk`}
                                  maxCount={1}
                                >
                                  <Button icon={<UploadOutlined />}>Upload file</Button>
                                </Upload>
                              </Form.Item>
                            </div>
                          </div>
                          <Form.Item>
                            <Button
                              type="primary"
                              htmlType="submit"
                              className="mt-4"
                              loading={loading}
                            >
                              Upload SDK
                            </Button>
                          </Form.Item>
                        </div>
                      </Form>,
                  }]}
                />
              </div>
              <div className="col-12">
                <Spin spinning={loading}>
                  <Table bordered size="small" dataSource={mpSdks} columns={ypSdksColumns} rowKey="id" />
                </Spin>
              </div>
            </>
          )}
      </div>
    </div>
  );
}
