import { useEffect, useState } from 'react';
import {
  SettingCompSelect, SettingCompInputNumber,
  SettingCompVector2, SettingCompSlider, SettingCompInputCheckbox,
} from '../../sub-settings';

import { usePlayable } from '../../../../context/playable.context';
import { YcCard } from '../../../../../../components';
import { ICanvasScalerJson as JsonType } from '../../../../../../modeles/MP_Interfaces';

import './setting-comp-canvas-scaler.scss';

const canvasScalerScaleModes: { label: string, value: number }[] = [
  { label: 'Constant Pixel Size', value: 0 },
  { label: 'Scale With Screen Size', value: 1 },
  { label: 'Constant Physical Size', value: 2 },
];

export function SettingCompCanvasScaler(props: { initialData: any, parentID: string, icon?: string }) {
  const childType: string = props.initialData.type;
  const childID: string = props.initialData.instanceID;
  const parentID: string = props.parentID;

  const uPlayable = usePlayable();

  const [compTypeIndex, _setCompTypeIndex] = useState<number>();
  const [component, _setComponent] = useState<JsonType>();

  useEffect(() => {
    if (compTypeIndex === undefined) {
      _setCompTypeIndex(uPlayable.getCompTypeIndex(childType, parentID, childID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (props !== undefined && compTypeIndex !== undefined) {
      _setComponent(uPlayable.getRunTimeComponent(`${childType}[${compTypeIndex}]`) as JsonType);
    }
  }, [childType, compTypeIndex, props, uPlayable]);

  const editComponent = (propertyPath: string, value: any, update: boolean = true) => {
    if (compTypeIndex !== undefined) {
      uPlayable.updateGameObject(value, `${childType}[${compTypeIndex}]/${propertyPath}`, childID, update);
    }
  };

  return (
    <YcCard
      title="Canvas Scaler"
      icon={props.icon}
      light
      open
      warning={compTypeIndex === undefined}
      titleExtra={(
        <SettingCompInputCheckbox
          checked={component?.enabled ?? true}
          onChange={(e) => editComponent('enabled', e)}
        />
      )}
    >
      <div className="row">
        <div className="col-12">
          <SettingCompSelect
            disabled
            label="UI Scale Mode"
            value={component?.uiScaleMode ?? 1}
            options={canvasScalerScaleModes}
            onChange={(value) => editComponent('uiScaleMode', value)}
          />
          {
            component?.uiScaleMode === 0 && (
              <SettingCompInputNumber
                disabled
                label="Scale Factor"
                value={component?.scaleFactor ?? 0.5}
                onChange={(value) => editComponent('scaleFactor', value)}
              />
            )
          }
          {
            component?.uiScaleMode === 1 && (
              <SettingCompVector2
                disabled
                defaultType="300s"
                label="Reference Resolution"
                vector={component?.referenceResolution}
                onChange={(value) => editComponent('referenceResolution', value)}
              />
            )
          }
          {
            (component?.matchWidthOrHeight !== undefined && component?.uiScaleMode === 1) && (
              <SettingCompSlider
                disabled
                label="Match"
                step={0.01}
                min={0}
                max={1}
                value={component?.matchWidthOrHeight}
                onChange={(e, update) => editComponent('matchWidthOrHeight', e, update)}
              />
            )
          }
          {
            component?.referencePixelsPerUnit !== undefined && (
              <SettingCompInputNumber
                disabled
                label="Reference Pixels Per Unit"
                value={component?.referencePixelsPerUnit}
                onChange={(value) => editComponent('referencePixelsPerUnit', value)}
              />
            )
          }
        </div>
      </div>
    </YcCard>
  );
}
