import { MCreativeListing } from '../modeles';
import { BaseService } from './base.service';

class CreativesListingService extends BaseService {
  PATH = 'admin/creatives-listing/';

  async listing(): Promise<MCreativeListing[] | null> {
    return this._listing(`${this.PATH}listing`);
  }

  async add(data: MCreativeListing) {
    await this.post(`${this.PATH}add`, data);
  }

  async edit(id: number, data: MCreativeListing) {
    await this.post(`${this.PATH}edit/${id}`, data);
  }
}

export const creativesListingService = new CreativesListingService();
