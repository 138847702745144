import {
  Button,
  Form,
  Input,
  Modal,
  Switch,
  Tree,
} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { DataNode, TreeProps } from 'antd/lib/tree';
import { useEffect, useState } from 'react';

import { useAuths, useUserEdited } from '../../contexts';
import { usersService, adminUsersService } from '../../services';
import { IPermission } from '../../modeles';

import './user-modal.scss';

export function YcUserModal() {
  const uUserEdited = useUserEdited();
  const uAuths = useAuths();

  const [allPermissions, _setAllPermissions] = useState<DataNode[]>([]);
  const [userPermissions, _setUserPermissions] = useState<string[]>([]);

  useEffect(() => {
    usersService.defaultPermission().then((permissions) => {
      if (permissions) {
        _setAllPermissions(listFormObj(permissions));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (uUserEdited.user?.permissions) {
      _setUserPermissions(getListPermissions(uUserEdited.user.permissions));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uUserEdited.user]);

  const listFormObj = (perms: IPermission, parentKey?: string): DataNode[] => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return Object.keys(perms).map((permission) => {
      const key = parentKey ? `${parentKey}/${permission}` : permission;
      return {
        title: permission,
        key,
        // @ts-ignore
        children: perms[permission].children ? listFormObj(perms[permission].children, key) : [],
      };
    });
  };

  const getListPermissions = (permissions: IPermission): string[] => {
    const list: string[] = [];
    loopPermission(permissions, list);
    return list || [];
  };

  const loopPermission = (permissions: IPermission, list: string[], parentKey?: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    Object.keys(permissions).forEach((permission) => {
      const key = parentKey ? `${parentKey}/${permission}` : permission;
      if (permissions[permission].active) {
        list.push(key);
      }
      if (permissions[permission].children) {
        loopPermission(permissions[permission].children!, list, key);
      }
    });
  };

  const onCheckPermission: TreeProps['onCheck'] = (checkedKeys, info) => {
    const key = info.node.key as string;
    if (userPermissions.includes(key)) {
      _setUserPermissions(userPermissions.filter((perm) => perm !== info.node.key));
    } else {
      _setUserPermissions([...userPermissions, key]);
    }
    updatePermission(uUserEdited.user?.permissions as IPermission, key.split('/') || []);
  };

  const updatePermission = (permissions: IPermission, access: string[]) => {
    let permission = permissions;
    for (let index = 0; index < access.length - 1; index += 1) {
      permission = permission[access[index]].children as IPermission;
    }
    const perm = access.pop() as string;
    permission[perm].active = !permission[perm].active;

    editUser('permissions', permissions);
  };

  const editUser = (property: string, value: any) => {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    uUserEdited.updateUser({ ...uUserEdited.user, [property]: value });
  };

  const sendNewPasswork = async () => {
    if (uAuths.isSuperAdmin()) {
      await adminUsersService.sendNewPassword(uUserEdited.user!.id);
    } else {
      await usersService.sendNewPassword(uUserEdited.user!.id);
    }
  };

  return (
    <>
      {uUserEdited.isReady
        && (
          <Modal
            title={`User ${uUserEdited.user?.name}`}
            open={!!uUserEdited.user}
            onOk={() => uUserEdited.saveUser()}
            confirmLoading={uUserEdited.isLoading}
            onCancel={() => uUserEdited.cleanUser()}
          >
            <Form layout="vertical">
              <div className="row">
                <div className="col-12">
                  <Form.Item label="Email" name="email" rules={[{ required: true }]} initialValue={uUserEdited.user?.email}>
                    <Input type="text" className="email" onChange={(e) => editUser('email', e.target.value)} />
                  </Form.Item>
                </div>
                <div className="col-6">
                  <Form.Item label="Last Name" name="lastName" initialValue={uUserEdited.user?.lastName}>
                    <Input type="text" className="name" onChange={(e) => editUser('lastName', e.target.value)} />
                  </Form.Item>
                </div>
                <div className="col-6">
                  <Form.Item label="First Name" name="firstName" initialValue={uUserEdited.user?.firstName}>
                    <Input type="text" className="name" onChange={(e) => editUser('firstName', e.target.value)} />
                  </Form.Item>
                </div>
                <div className="col-6">
                  <Form.Item label="Is active" name="isActive" valuePropName="checked" initialValue={uUserEdited.user?.isActive}>
                    <Switch
                      disabled={uUserEdited.user?.id === uAuths.user?.id}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      onChange={(e) => editUser('isActive', e)}
                    />
                  </Form.Item>
                </div>
                <div className="col-6">
                  <Form.Item label="Is admin" name="isAdmin" valuePropName="checked" initialValue={uUserEdited.user?.isAdmin}>
                    <Switch
                      disabled={uUserEdited.user?.id === uAuths.user?.id}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      onChange={(e) => editUser('isAdmin', e)}
                    />
                  </Form.Item>
                </div>
                <div className="col-12">
                  <Button onClick={() => { sendNewPasswork(); }}>
                    Send new password
                  </Button>
                </div>
                {
                  uAuths.isAdmin() && (
                    <div className="col-12">
                      <Tree
                        checkable
                        defaultExpandAll
                        checkStrictly
                        treeData={allPermissions}
                        onCheck={onCheckPermission}
                        checkedKeys={userPermissions}
                      />
                    </div>
                  )
                }
              </div>
            </Form>
          </Modal>
        )}
    </>
  );
}
