import {
  Button, FloatButton, Modal, Result, Spin, Tabs, TabsProps, Tooltip, Tour,
} from 'antd';
import React, {
  ReactNode,
  useEffect, useRef, useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faGamepadModern } from '@fortawesome/pro-regular-svg-icons';

import { creativesService } from '../../../../services';
import { SettingGeneral } from '../../components';
import { useTour } from '../../context/tour.context';
import {
  Preview, Export, PlayableListElem, Setting, SceneView, LogsView,
} from '../../components/playable';
import { usePlayable } from '../../context/playable.context';
import { useCreative } from '../../context/creative.context';
import { getPlayableSize } from '../../utils/playable.utils';
import { PlayableListAssets } from '../../components/playable/assets-list';
import { FilesTools } from '../../../../tools';

import './playable.page.scss';

const defaultTab = '1';

export function PlayablePage() {
  const uHistory = useHistory();
  const uParams: any = useParams();
  const uPlayable = usePlayable();
  const uCreative = useCreative();
  const uTour = useTour();

  const [isReady, _setIsReady] = useState<boolean>(false);
  const [currTab, _setCurrTab] = useState<string>(defaultTab);
  const [extraTab, _setExtraTab] = useState<React.ReactNode>(null);
  const [iconWeight, _setIconWeight] = useState<number>(0);

  const SettingGeneralRef = useRef(null);
  const PlayableListElemRef = useRef(null);
  const PreviewRef = useRef(null);
  const SettingRef = useRef(null);

  const getTabs = (): TabsProps['items'] => {
    const tabs = [
      {
        key: '1',
        label: 'Game',
        children: <Preview />,
      },
      {
        key: '2',
        label: 'Scene',
        children: <SceneView displayed={currTab === '2'} />,
        disabled: uPlayable.isLoading,
      },
      {
        key: '3',
        label: 'Info / Logs',
        children: <LogsView />,
        disabled: uPlayable.isLoading,
      },
    ];
    return tabs;
  };

  const info = () => {
    Modal.info({
      title: 'Help for Playable editor',
      content: (
        <div>
          <p>
            <strong>W: </strong> Translate | <strong>E: </strong> Rotate | <strong>R: </strong> Scale | <strong>+/-: </strong> Adjust size<br />
            <strong>X: </strong> toggle X | <strong>Y: </strong> toggle Y | <strong>Z: </strong> toggle Z<br />
            <strong>Space bar: </strong> toggle enabled<br />
            <strong>Q: </strong>Toggle world/local space<br />
            <strong>Escape: </strong>Deselect item<br />
            <strong>F: </strong>Focus the selected element. In the case of null element, set default focus<br />
            <strong>Alt: </strong>Hold to activate camera controls<br />
            <strong>←↑↓→: </strong>Move the camera<br />
          </p>
        </div>
      ),
      onOk() { },
    });
  };

  const help = (
    <Tooltip title="Help">
      <Button shape="circle" onClick={info} icon={<FontAwesomeIcon icon={faCircleInfo} />} />
    </Tooltip>
  );

  const playingWarning = (
    <Tooltip title="In Play Mode, edits are not saved.">
      <FontAwesomeIcon icon={faGamepadModern} size="lg" color="red" bounce />
    </Tooltip>
  );

  const playableSize = (size: number) => {
    return (
      <Tooltip title="Playable approximation size">
        <div>{(size + iconWeight).toFixed(2)} MB</div>
      </Tooltip>
    );
  };

  useEffect(() => {
    getPlayableSize(uPlayable.playable.Playable?.url || '').then((size) => {
      _setExtraTab(
        <div className="d-flex gap-2">
          {size && playableSize(size)}
          <>{currTab === '2' ? help : null}</>
          <>{uPlayable.playableIsPlaying() ? playingWarning : null}</>
        </div>,
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currTab, uPlayable.playable, uPlayable.playableIsPlaying()]);

  const steps = [
    {
      title: 'General settings',
      description: "You can see here the Playable's general settings",
      target: () => SettingGeneralRef.current as unknown as HTMLElement,
    },
    {
      title: 'Elements',
      description: "Here you will find all the Playable's elements.",
      target: () => PlayableListElemRef.current as unknown as HTMLElement,
    },
    {
      title: 'Preview',
      description: 'This part is the simulation render of the Playable.',
      target: () => PreviewRef.current as unknown as HTMLElement,
      placement: 'right',
    },
    {
      title: 'Components',
      description: 'And finally this part regroup all the componentsa a selected element in the tree.',
      target: () => SettingRef.current as unknown as HTMLElement,
      placement: 'left',
    },
  ];

  useEffect(() => {
    if (uParams.id) {
      creativesService.view(uParams.id as number).then((data) => {
        if (data) {
          uPlayable.setPlayable(data);
          uCreative.setCreative(data);
          if (data.Application?.Icon?.url) {
            creativesService.urlPublic(data.Application?.Icon?.url).then((resp) => {
              if (resp) {
                FilesTools.getSizeFromUrl(resp).then((crea) => {
                  _setIconWeight(+(crea / (1024 * 1024)).toFixed(2));
                });
              }
            });
          }

          _setIsReady(true);

          if (data.status !== 'succeeded' && data.status !== 'null') {
            const interval = setInterval(() => {
              creativesService.view(uParams.id as number).then((subData) => {
                uPlayable.setPlayable(subData);
                uCreative.setCreative(subData);
                if (subData!.status === 'succeeded' || subData!.status === 'failed' || subData!.status === 'null') {
                  clearInterval(interval);
                }
              });
            }, 3000);
          }
        } else {
          uHistory.push('/creatives');
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    uTour.setSteps(steps);
    uTour.setTourName('playable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SettingGeneralRef, PlayableListElemRef, PreviewRef, SettingRef]);

  const playableSucceeded = (): ReactNode => {
    return (
      <div className="zone-preview" ref={PreviewRef}>
        <Tabs
          tabBarExtraContent={extraTab}
          style={{ width: '100%' }}
          defaultActiveKey={defaultTab}
          items={getTabs()}
          onTabClick={(e: string) => _setCurrTab(e)}
        />
        <div className="mt-3" style={currTab === '3' ? { display: 'none' } : {}}>
          <Export />
        </div>
      </div>
    );
  };

  const playableLoading = (): ReactNode => {
    return (
      <div className="d-flex justify-content-around">
        <Spin tip="Playable Building" size="large">
          <div style={{ padding: 50, background: 'rgba(0, 0, 0, 0.05)', borderRadius: 4 }} />
        </Spin>
      </div>
    );
  };

  const playableError = (): ReactNode => {
    return (
      <div className="d-flex justify-content-around">
        <Result
          status="500"
          title="Sorry, something went wrong"
          subTitle="Please contact the support"
        />
      </div>
    );
  };

  const playableStatus = () => {
    console.log('uPlayable.playable.status: ', uPlayable.playable.status);
    switch (uPlayable.playable.status) {
      case 'succeeded':
      case 'null':
        return playableSucceeded();
      case 'pending':
      case 'running':
        return playableLoading();
      case 'failed':
      default:
        return playableError();
    }
  };

  return (
    <div id="playable" key={uPlayable.playable.id}>
      {isReady
        && (
          <div className="row">
            <div className="col-3">
              <div ref={SettingGeneralRef}>
                <SettingGeneral />
              </div>
              <div className="mb-3" ref={PlayableListElemRef}>
                <PlayableListElem />
              </div>
              <div className="mb-3" ref={PlayableListElemRef}>
                <PlayableListAssets />
              </div>
            </div>
            <div className="col-6">
              {
                playableStatus()
              }
            </div>
            <div className="col-3">
              <div className="setting" ref={SettingRef}>
                <Setting />
              </div>
            </div>
            <Tour open={uTour.runTour} onClose={() => uTour.setRunTour(false)} steps={uTour.steps} />
          </div>
        )}
      <FloatButton
        icon={(<FontAwesomeIcon icon={faCircleInfo} />)}
        style={{ top: 90, bottom: 55 }}
        onClick={() => { uTour.setRunTour(true); }}
      />
    </div>
  );
}
