import { useEffect, useState } from 'react';

import { usePlayable } from '../../../../context/playable.context';
import { YcCard } from '../../../../../../components';
import { IRectTransformJson as JsonType } from '../../../../../../modeles/MP_Interfaces';
import { SettingCompVector2, SettingCompVector3 } from '../../sub-settings';

import './setting-comp-rect-transform.scss';

export function SettingCompRectTransform(props: { initialData: any, parentID: string, icon?: string }) {
  const childType: string = props.initialData.type;
  const childID: string = props.initialData.instanceID;
  const parentID: string = props.parentID;

  const uPlayable = usePlayable();

  const [compTypeIndex, _setCompTypeIndex] = useState<number>();
  const [component, _setComponent] = useState<JsonType>();

  useEffect(() => {
    if (compTypeIndex === undefined) {
      _setCompTypeIndex(uPlayable.getCompTypeIndex(childType, parentID, childID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (props !== undefined && compTypeIndex !== undefined) {
      _setComponent(uPlayable.getRunTimeComponent(`${childType}[${compTypeIndex}]`) as JsonType);
    }
  }, [childType, compTypeIndex, props, uPlayable]);

  const editComponent = (propertyPath: string, value: any, update: boolean = true) => {
    if (compTypeIndex !== undefined) {
      uPlayable.updateGameObject(value, `${childType}[${compTypeIndex}]/${propertyPath}`, childID, update);
    }
  };

  return (
    <YcCard title="Rect Transform" icon={props.icon} light open warning={compTypeIndex === undefined}>
      <div className="row">
        <div className="col-12">
          <SettingCompVector3
            defaultType="0s"
            label="Position"
            vector={component?.localPosition}
            onChange={(e) => editComponent('localPosition', e)}
          />
          <SettingCompVector3
            defaultType="0s"
            label="Rotation"
            vector={component?.localEulerAngles}
            onChange={(e) => editComponent('localEulerAngles', e)}
          />
          <SettingCompVector3
            defaultType="1s"
            label="Scale"
            vector={component?.localScale}
            onChange={(e) => editComponent('localScale', e)}
          />
          <SettingCompVector2
            defaultType="1s"
            min={0}
            max={1}
            step={0.1}
            label="Anchor Min"
            vector={component?.anchorMin}
            onChange={(e) => editComponent('anchorMin', e)}
          />
          <SettingCompVector2
            defaultType="1s"
            min={0}
            max={1}
            step={0.1}
            label="Anchor Max"
            vector={component?.anchorMax}
            onChange={(e) => editComponent('anchorMax', e)}
          />
          <SettingCompVector2
            disabled
            defaultType="0s"
            label="Anchored Position"
            vector={component?.anchoredPosition}
            onChange={(e) => editComponent('anchoredPosition', e)}
          />
          <SettingCompVector2
            disabled
            defaultType="0s"
            label="Size Delta"
            vector={component?.sizeDelta}
            onChange={(e) => editComponent('sizeDelta', e)}
          />
          <SettingCompVector2
            disabled
            defaultType="0.5s"
            label="Pivot"
            vector={component?.pivot}
            onChange={(e) => editComponent('pivot', e)}
          />
        </div>
      </div>
    </YcCard>
  );
}
