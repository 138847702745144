import { MCreative, MNodeBase } from '../../../modeles';

export type LoopElem = MNodeBase & { videos?: MNodeBase[], breaks?: MNodeBase[], loops?: MNodeBase[], elements?: MNodeBase[], hover?: MNodeBase };
export type LoopCallbackParams = { node: LoopElem, index: number, parentList: MNodeBase[], parentNode: MNodeBase, parentKey: string, pathNode: LoopElem[] };

const _parseElems = (parent: LoopElem, keyToLoop: string, pathNode: LoopElem[], callback: (param: LoopCallbackParams) => void): void => {
  // @ts-ignore
  let parentList = parent[keyToLoop] as LoopElem[];
  if (parentList && !Array.isArray(parentList)) {
    parentList = [parentList];
  }
  if (parentList) {
    for (let i = 0; i < parentList.length; i += 1) {
      const e = parentList[i];
      pathNode.push(e);
      callback({
        node: e,
        index: i,
        parentList,
        parentNode: parent,
        parentKey: keyToLoop,
        pathNode: [...pathNode],
      });
      if (e.breaks && e.breaks.length) {
        _parseElems(e, 'breaks', pathNode, callback);
      }
      if (e.loops && e.loops.length) {
        _parseElems(e, 'loops', pathNode, callback);
      }
      if (e.elements && e.elements.length) {
        _parseElems(e, 'elements', pathNode, callback);
      }
      if (e.hover) {
        _parseElems(e, 'hover', pathNode, callback);
      }
      pathNode.pop();
    }
  }
};

const loopIecVideosElems = (iec: MCreative, callback: (param: LoopCallbackParams) => void) => {
  _parseElems(iec.iecData as unknown as LoopElem, 'videos', [], callback);
  _parseElems(iec.iecData as unknown as LoopElem, 'endcard', [], callback);
  _parseElems(iec.iecData as unknown as LoopElem, 'hover', [], callback);
};

const loopIecNode = (node: MNodeBase, callback: (param: LoopCallbackParams) => void) => {
  if (node._type === 'video') {
    _parseElems(node as unknown as LoopElem, 'breaks', [], callback);
    _parseElems(node as unknown as LoopElem, 'hover', [], callback);
  } else {
    _parseElems(node as unknown as LoopElem, 'elements', [], callback);
  }
};

export {
  loopIecVideosElems,
  loopIecNode,
};
