export function DescriptionElements() {
  return (
    <div>
      <p>Here you will find all the IEC elements, and their actions.</p>
      <ul>
        <li>Add</li>
        <li>Hide or lock</li>
        <li>Delete</li>
        <li>Save and re-use template</li>
        <li>Change their name by double clicking on it</li>
      </ul>
    </div>
  );
}
