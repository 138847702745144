import { BaseService } from './base.service';
import { MDocument } from '../modeles';

class LibraryService extends BaseService {
  PATH = 'library/';

  async listing(): Promise<MDocument[] | null> {
    return this._listing(`${this.PATH}listing`);
  }

  async add(doc: MDocument): Promise<MDocument | null> {
    return this._add(`${this.PATH}add`, doc);
  }

  async remove(id: number): Promise<MDocument | null> {
    return this._delete(`${this.PATH}delete/${id}`);
  }

  async convertVideo(doc: any): Promise<any | null> {
    return this.post(`${this.PATH}convert-video`, doc) as Promise<any | null>;
  }

  async moveVideo(doc: { videoId: number, applicationId: number, creativeId: number }): Promise<any | null> {
    return this.post(`${this.PATH}move-video`, doc) as Promise<any | null>;
  }
}

export const libraryService = new LibraryService();
