import { Input, Select } from 'antd';

import { MPLibrary, YcCard } from '../../../../../../components';
import { MCreativeVideo, MDocument, EndVideoActionTypeValues } from '../../../../../../modeles';
import { useIec } from '../../../../context/iec.context';
import { SettingCompBackground } from '../setting-comp-background';
import { getIconByType } from '../../../../utils/iec.utils';
import { IecSwitch } from '../../../iec-switch';
import { IecSelect } from '../../../select';
import { NumberTools } from '../../../../../../tools';
import { libraryService } from '../../../../../../services/library.service';

import './setting-video.scss';

const SelectOption = Select.Option;

export function SettingVideo(props: {
  video: MCreativeVideo
}) {
  const uIec = useIec();

  function editValue(param: string, value: any) {
    (props.video as any)[param] = value;
    uIec.refreshIec(true);
  }

  return (
    <YcCard
      title={`Video[${uIec.iec.iecData.videos.indexOf(props.video) + 1}] ${props.video.videoDuration}s`}
      icon={getIconByType('video')}
      canOpenAndClose={false}
      open
    >
      <div className="setting-video">
        <YcCard title="Video" open light>
          <div className="row">
            <div className="col-xl-2 col-lg-4 input-separate">
              <MPLibrary
                type="video"
                label="Video"
                typeName="video"
                folder="video"
                path="/library/upload-video"
                defaultValue={props.video.video}
                width={54}
                height={54}
                onUpload={async (doc: MDocument) => {
                  if (doc.awsKey?.includes('tmp')) {
                    const videoMoved = await libraryService.moveVideo(
                      {
                        videoId: doc.id,
                        creativeId: uIec.iec.id,
                        applicationId: uIec.iec.applicationId,
                      },
                    );
                    doc.awsKey = videoMoved.data.awsKey;
                    doc.url = videoMoved.data.url;
                  }
                  props.video.video = doc;
                  uIec.refreshIec(true);
                }}
              />
            </div>
            <div className="col-4 input-separate">
              <label>Video name</label>
              <Input
                placeholder="Video name"
                value={props.video.videoName}
                onChange={(e) => editValue('videoName', e.target.value)}
                size="small"
              />
            </div>
            <div className="col-3 input-separate">
              <IecSwitch label="Loop" field="loop" obj={props.video} />
            </div>
            <div className="col-3 input-separate">
              <IecSwitch label="Muted" field="muted" obj={props.video} />
            </div>
            {props.video.loop === false
              && (
                <div className="col-4 input-separate">
                  <IecSelect label="Ending Action" field="endVideoAction" obj={props.video} values={EndVideoActionTypeValues} />
                </div>
              )}
            {props.video.endVideoAction === 'video-change'
              && (
                <div className="col-4 input-separate">
                  <label>Next video</label>
                  <Select
                    value={props.video.nextVideoKey}
                    onChange={(val) => {
                      props.video.nextVideoKey = val;
                      uIec.refreshIec(true);
                    }}
                  >
                    {uIec.iec.iecData.videos.map((video) => {
                      const index = uIec.iec.iecData.videos.findIndex((v) => v === video) + 1;
                      return (
                        <SelectOption key={video._key} value={video._key}>
                          Video[{index}] - {NumberTools.round(video.videoDuration, 2)}s
                        </SelectOption>
                      );
                    })}
                  </Select>
                </div>
              )}
            {(props.video.endVideoAction === 'endcard-display' && !props.video.loop)
              && (
                <div className="col-6">
                  <IecSwitch
                    obj={props.video}
                    label="Redirect to store"
                    field="displayEndCardRedirect"
                  />
                </div>
              )}
          </div>
        </YcCard>
        <SettingCompBackground background={props.video.background} isDeletable={false} />
      </div>
    </YcCard>
  );
}
