import { useHistory, useParams } from 'react-router-dom';
import {
  Button, Card, Divider, Spin, Checkbox, Select,
  message,
} from 'antd';
import {
  useEffect, useState,
} from 'react';
import Meta from 'antd/lib/card/Meta';
import _ from 'lodash';

import { iecIsEditable } from '../../../../tools/utils.tool';
import {
  creativesService, playablesService,
} from '../../../../services';
import { useAuths } from '../../../../contexts';
import {
  MCreative, networks, MNetwork, MCreativeExport, MApplication,
} from '../../../../modeles';
import { YcTitle } from '../../../../components';
import { FLAGS, LANGUAGES } from '../../../../tools';
import { CreativesExported, NetworkCard } from '../../components';
import { useIecSettings, useIec } from '../../context';
import { exportIec } from './iec-export';
import { exportPlayable } from './playable-export';

import './export.page.scss';
import { getPlayableSize } from '../../utils/playable.utils';

export function ExportPage() {
  const uAuths = useAuths();
  const uParams: any = useParams();
  const uIec = useIec();
  const uIecSettings = useIecSettings();
  const uHistory = useHistory();

  const [isReady, _setIsReady] = useState<boolean>(false);
  const [loading, _setLoading] = useState(false);
  const [loadingBuildStatus, _setLoadingBuildStatus] = useState<null | 'running' | 'end'>(null);
  const [reload, _setReload] = useState<number>(0);
  const [creative, _setCreative] = useState<MCreative>(new MCreative());
  const [application, _setApplication] = useState<MApplication>(new MApplication());
  const [selectedNetwork, _setSelectedNetwork] = useState<any[]>([]);
  const [exportFormatString, _setExportFormatString] = useState<string>('');
  const [enableTranslation, _setEnableTranslation] = useState(false);
  const [selectedTranslation, _setSelectedTranslation] = useState<string[]>([]);
  const [fileSize, _setFileSize] = useState<number | null>(null);
  const [messageApi, contextHolder] = message.useMessage();

  const restrictedNetworks = ['moloco_txt'];

  useEffect(() => {
    _setLoading(true);
    if (uParams.id) {
      creativesService.exportView(uParams.id as number).then((creativeView) => {
        if (creativeView) {
          _setCreative(creativeView);
          _setApplication(creativeView.Application!);
          _setExportFormatString(creativeView.Account!.exportFormat!);
          if (creativeView.type === 'IEC') {
            uIec.setIec(creativeView);
            _setLoading(false);
          } else if (creativeView.type === 'PLA') {
            playableLoading(creativeView);
          }
          _setIsReady(true);
        } else {
          uHistory.push('/creatives');
        }
      });
    } else {
      uHistory.push('/creatives');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loadingBuildStatus === 'running') {
      messageApi
        .open({
          type: 'loading',
          content: 'Release build in progress..',
          duration: 0,
        });
    } else if (loadingBuildStatus === 'end') {
      messageApi.destroy();
      message.success('Release build finished', 2.5);
    }
  }, [loadingBuildStatus, messageApi]);

  const playableLoading = (creativeView: MCreative) => {
    if (!creativeView.playableReleaseId) {
      _setLoadingBuildStatus('running');
      playablesService.buildPlayableRelease(creativeView.id);
      const intervalID = setInterval(() => {
        playablesService.playableReleaseStatus(creativeView.id).then((playable) => {
          if (playable?.playableReleaseId) {
            _setCreative({
              ...creativeView,
              PlayableRelease: playable.PlayableRelease,
            });
            getPlayableSize(playable.PlayableRelease?.url || '').then((size) => {
              _setFileSize(size);
            });
            _setLoadingBuildStatus('end');
            _setLoading(false);
            clearInterval(intervalID);
          }
        });
      }, 5000);
    } else {
      getPlayableSize(creativeView.PlayableRelease?.url || '').then((size) => {
        _setFileSize(size);
      });
      _setLoading(false);
    }
  };

  const selectNetwork = (selectedN: MNetwork) => {
    if (!selectedNetwork.some((network: MNetwork) => network.id === selectedN.id)) {
      _setSelectedNetwork([...selectedNetwork, selectedN]);
    } else {
      _setSelectedNetwork(selectedNetwork.filter((network: MNetwork) => network.id !== selectedN.id));
    }
  };

  const selectAllNetwork = () => {
    if (!(selectedNetwork.length === getNetworks().filter((n) => n.isActive).length)) {
      _setSelectedNetwork(getNetworks().filter((n) => n.isActive));
    } else {
      _setSelectedNetwork([]);
    }
  };

  const exportCreative = async (platform: string) => {
    _setLoading(true);
    if (creative.type === 'IEC') {
      await exportIec(
        platform,
        uIec.iec,
        application,
        exportFormatString,
        enableTranslation,
        selectedTranslation,
        uIecSettings.fonts,
        selectedNetwork,
      );
    } else if (creative.type === 'PLA') {
      await exportPlayable(
        creative,
        application,
        selectedNetwork,
        platform,
        exportFormatString,
      );
    }
    _setReload(reload + 1);
    _setLoading(false);
  };

  const getNetworks = (): MNetwork[] => {
    if (uAuths.user?.accountId === 1) {
      return networks;
    }
    return networks.filter((network) => !restrictedNetworks.includes(network.value));
  };

  return (
    <div className="export-iec">
      {isReady
        && (
          <>
            <YcTitle
              links={[{ link: `/applications/${uParams.appId as string}/creatives`, label: 'Interactive Endcards' }]}
              label={`Export ${creative.name}`}
            />
            <div className="row">
              {contextHolder}
              {iecIsEditable(creative) && (
                <div className="col-7">
                  <div className="row">
                    <div className="col-4">
                      <Card
                        className="video-card"
                        cover={(
                          <>
                            {creative.type === 'IEC' && creative.iecData?.videos[0]?.video?.url
                              // eslint-disable-next-line jsx-a11y/media-has-caption
                              ? <video src={creative.iecData.videos[0]?.video?.url} />
                              : <div />}
                            <Divider />
                          </>
                        )}
                      >
                        <Meta title={creative.name} description={`APP: ${creative.applicationId} | IEC: ${creative.id} | Version: ${creative.version}`} />
                        <Meta description={`Size: ${fileSize} MB`} />
                      </Card>
                    </div>
                    <div className="col-8 right-side">
                      <Spin spinning={loading}>
                        <Checkbox
                          className="check-all"
                          checked={selectedNetwork.length === getNetworks().filter((n) => n.isActive).length}
                          onChange={() => { selectAllNetwork(); }}
                        >Check all
                        </Checkbox>
                        <div className="networks">
                          {getNetworks().map((network) => (
                            <NetworkCard
                              key={network.id}
                              network={network}
                              selected={selectedNetwork.some((n: MNetwork) => n.id === network.id)}
                              onSelect={() => { selectNetwork(network); }}
                            />
                          ))}
                        </div>
                        <Divider />
                        <div className="translations">
                          <Checkbox
                            className="check-all"
                            checked={enableTranslation}
                            onChange={() => { _setEnableTranslation(!enableTranslation); }}
                          >Enable translation
                          </Checkbox>
                          <Select
                            disabled={!enableTranslation}
                            mode="multiple"
                            allowClear
                            style={{ width: '100%', marginBottom: '10px' }}
                            placeholder="Select Translations"
                            value={selectedTranslation}
                            onChange={(e) => { _setSelectedTranslation(e); }}
                            options={Object.keys(uIec.getEnabledLanguages()).map((flag) => {
                              return { value: flag, label: `${FLAGS[flag]} - ${LANGUAGES[flag]}` };
                            })}
                          />
                        </div>
                      </Spin>
                      <div className="buttons">
                        <Button
                          loading={loading}
                          type="primary"
                          disabled={!selectedNetwork[0] || !creative.playStore}
                          onClick={() => { exportCreative('android'); }}
                        >
                          Export Android
                        </Button>
                        <Button
                          loading={loading}
                          type="primary"
                          disabled={!selectedNetwork[0] || !creative.appStore}
                          onClick={() => { exportCreative('ios'); }}
                        >
                          Export Ios
                        </Button>
                        <Button
                          loading={loading}
                          type="primary"
                          disabled={!selectedNetwork[0] || !creative.playStore || !creative.appStore}
                          onClick={() => { exportCreative('All'); }}
                        >
                          Export All
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className={iecIsEditable(creative) ? 'col-5' : 'col-12'}>
                <CreativesExported key={reload} creative={creative} />
              </div>
            </div>
          </>
        )}
    </div>
  );
}
