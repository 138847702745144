import { MNodeBase } from '../../../modeles';
import { IAssetJson, IData, IGameObjectJson } from '../../../modeles/MP_Interfaces';
import { IecElementEditor, getTypeElement } from './iec.utils';

function getEditorElem(node: MNodeBase) {
  return IecElementEditor[getTypeElement(node)];
}

const findAssetByKey = (assets: IAssetJson, key: string): any => {
  for (const asset in assets) {
    // eslint-disable-next-line no-prototype-builtins
    if (assets.hasOwnProperty(asset)) {
      // @ts-ignore
      if (assets[asset][key]) {
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return { ...assets[asset][key], type: asset };
      }
    }
  }
  return null;
};

const findGaneObjectByFunc = (gameObjects: IGameObjectJson[], func: (node: IGameObjectJson) => boolean): IGameObjectJson | null => {
  for (const gameObject of gameObjects) {
    if (func(gameObject)) {
      return gameObject;
    }
    if (gameObject.children) {
      const foundElement = findGaneObjectByFunc(gameObject.children, func);
      if (foundElement) {
        return foundElement as IGameObjectJson;
      }
    }
  }
  return null;
};

const findElem = (playable: IData, key: React.Key) => {
  return findGaneObjectByFunc(playable.scene.gameObjects, (node) => node.instanceID === key);
};

const findAllParentsId = (gameObject: any): string[] => {
  const openedNode: string[] = [];
  parentsLoop(gameObject, openedNode);
  return openedNode;
};

const parentsLoop = (gameObject: any, nodes: string[]): string[] => {
  nodes.push(gameObject.instanceID as string);
  if (gameObject.mp_parent) {
    parentsLoop(gameObject.mp_parent, nodes);
  }
  return nodes;
};

async function getPlayableSize(url: string): Promise<number | null> {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const size = blob.size;
    const sizeInMB = size / (1024 * 1024);
    return +sizeInMB.toFixed(2);
  } catch (error) {
    console.error('Error fetching file size:', error);
    return null;
  }
}

export {
  findElem,
  findAssetByKey,
  getEditorElem,
  findAllParentsId,
  getPlayableSize,
};
