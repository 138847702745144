import { useEffect, useState } from 'react';
import { InputNumber, Slider } from 'antd';

import './comp-slider.scss';

export function SettingCompSlider(props: {
  label: string,
  value: number,
  max: number,
  min: number,
  step?: number,
  onChange: (value: number | null, updateJson: boolean) => void,
  disabled?: boolean,
}) {
  const [value, _setValue] = useState<number>();

  useEffect(() => {
    _setValue(props.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  const editValue = (newValue: number, updateJson: boolean = true) => {
    _setValue(newValue);
    props.onChange(newValue, updateJson);
  };

  return (
    <div className="row align-items-center">
      <div className="col-5">
        <label>{props?.label}</label>
      </div>
      <div className="col-5">
        <Slider
          disabled={props?.disabled}
          step={props?.step}
          min={props?.min}
          max={props?.max}
          onChange={(e) => editValue(e as number, false)}
          onAfterChange={(e) => editValue(e as number)}
          value={value ?? 0}
        />
      </div>
      <div className="input col-2" style={{ paddingLeft: 0 }}>
        <InputNumber
          disabled={props?.disabled}
          step={props?.step}
          min={props?.min}
          max={props?.max}
          value={value ?? 0}
          onChange={(e) => editValue(e as number)}
        />
      </div>
    </div>
  );
}
