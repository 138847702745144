import {
  Input, InputNumber, Button, Modal, Upload, Form, Popover,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBold, faItalic, faUnderline, faAlignLeft, faAlignCenter, faAlignRight, faAlignJustify, faArrowUpToLine, faArrowDownToLine, faArrowsToLine, faLanguage,
} from '@fortawesome/pro-regular-svg-icons';
import { UploadOutlined } from '@ant-design/icons';

import { useState } from 'react';
import { useIec } from '../../../../context/iec.context';
import { useIecSettings } from '../../../../context/iec-settings.context';
import { MColor, MCompText, MCustomFont } from '../../../../../../modeles';

import './setting-comp-text.scss';
import { YcCard, YcColorPicker } from '../../../../../../components';
import { IecSelect } from '../../../select';
import { useCreative } from '../../../../context/creative.context';

const FORM_DEFAULT_VALUE = {
  title: '',
  fontSize: 18,
  fontPolice: 'arial',
  fontWeight: 'normal',
  fontColor: '#ffffff',
};

export function SettingCompText(props: { text: MCompText }) {
  const uIec = useIec();
  const uCreative = useCreative();
  const uIecSettings = useIecSettings();

  const [isAddingNewFont, _setIsAddingNewFont] = useState<boolean>(false);
  const [form] = Form.useForm();

  function editValue(param: string, value: any) {
    (props.text as any)[param] = value;
    uIec.refreshIec(true);
  }

  const onFinish = (values: any) => {
    const customFont = {
      ...(new MCustomFont()),
      name: values.fontName,
      documentId: values.fontFile.file.response.data.id,
    };
    uIecSettings.addNewFont(customFont);
    _setIsAddingNewFont(false);
    (form as unknown as { resetFields: () => void }).resetFields();
    editValue('fontPolice', values.fontName);
  };

  const openTranslate = () => {
    uIec.refreshAllText();
    uCreative.setShowTranslation(true);
  };

  return (
    <YcCard
      title="Text"
      light
      open={props.text._open}
      onClickTitle={(val) => {
        props.text._open = val;
        uIec.refreshIec(true);
      }}
      onDelete={() => {
        uIec.removeComponentNodeEdite(props.text);
      }}
    >
      <div className="setting-comp-text">
        <label>Text</label>
        <Input
          placeholder="Text"
          value={props.text.text}
          onChange={(e) => editValue('text', e.target.value)}
          size="small"
          addonAfter={(
            <Popover content="Translate">
              <div className="translate-button">
                <FontAwesomeIcon icon={faLanguage} onClick={() => { openTranslate(); }} size="xl" />
              </div>
            </Popover>
          )}
        />
        <div className="params">
          <div className="font-size">
            <InputNumber
              placeholder={`${FORM_DEFAULT_VALUE.fontSize}`}
              value={props.text.fontSize}
              onChange={(value) => editValue('fontSize', value)}
              size="small"
            />
          </div>
          <div className="font-police">
            <IecSelect
              field="fontPolice"
              obj={props.text}
              values={uIecSettings.getFormatFonts() || []}
              extraButtonFunction={() => { _setIsAddingNewFont(true); }}
              extraButtonLabel="Add Font"
              isFont
            />
          </div>
        </div>
        <div className="params">
          <div>
            <YcColorPicker
              color={props.text.fontColor}
              onColorChange={(color: MColor) => {
                props.text.fontColor = color;
                uIec.refreshIec(true);
              }}
            />
          </div>
          <div className="block-bts">
            <Button
              type="text"
              className={props.text.fontWeight === 'bold' ? 'yc-active' : ''}
              onClick={() => editValue('fontWeight', props.text.fontWeight === 'bold' ? '' : 'bold')}
              icon={<FontAwesomeIcon icon={faBold} title="Font weight" />}
            />
            <Button
              type="text"
              className={props.text.fontStyle === 'italic' ? 'yc-active' : ''}
              onClick={() => editValue('fontStyle', props.text.fontStyle === 'italic' ? '' : 'italic')}
              icon={<FontAwesomeIcon icon={faItalic} title="Italic" />}
              title="Italic"
            />
            <Button
              type="text"
              className={props.text.textDecoration === 'underline' ? 'yc-active' : ''}
              onClick={() => editValue('textDecoration', props.text.textDecoration === 'underline' ? '' : 'underline')}
              icon={<FontAwesomeIcon icon={faUnderline} title="Underline" />}
            />
          </div>
          <div className="block-bts">
            <Button
              type="text"
              className={props.text.textAlign === 'left' ? 'yc-active' : ''}
              onClick={() => editValue('textAlign', props.text.textAlign === 'left' ? undefined : 'left')}
              icon={<FontAwesomeIcon icon={faAlignLeft} title="Text align left" />}
            />
            <Button
              type="text"
              className={props.text.textAlign === 'center' ? 'yc-active' : ''}
              onClick={() => editValue('textAlign', props.text.textAlign === 'center' ? undefined : 'center')}
              icon={<FontAwesomeIcon icon={faAlignCenter} title="Text align center" />}
            />
            <Button
              type="text"
              className={props.text.textAlign === 'right' ? 'yc-active' : ''}
              onClick={() => editValue('textAlign', props.text.textAlign === 'right' ? undefined : 'right')}
              icon={<FontAwesomeIcon icon={faAlignRight} title="Text align right" />}
            />
            <Button
              type="text"
              className={props.text.textAlign === 'justify' ? 'yc-active' : ''}
              onClick={() => editValue('textAlign', props.text.textAlign === 'justify' ? undefined : 'justify')}
              icon={<FontAwesomeIcon icon={faAlignJustify} title="Text align justify" />}
            />
          </div>
          <div className="block-bts">
            <Button
              type="text"
              className={props.text.verticalAlign === 'top' ? 'yc-active' : ''}
              onClick={() => editValue('verticalAlign', props.text.verticalAlign === 'top' ? undefined : 'top')}
              icon={<FontAwesomeIcon icon={faArrowUpToLine} title="Text vertical align top" />}
            />
            <Button
              type="text"
              className={props.text.verticalAlign === 'middle' ? 'yc-active' : ''}
              onClick={() => editValue('verticalAlign', props.text.verticalAlign === 'middle' ? undefined : 'middle')}
              icon={<FontAwesomeIcon icon={faArrowsToLine} title="Text vertical align middle" />}
            />
            <Button
              type="text"
              className={props.text.verticalAlign === 'bottom' ? 'yc-active' : ''}
              onClick={() => editValue('verticalAlign', props.text.verticalAlign === 'bottom' ? undefined : 'bottom')}
              icon={<FontAwesomeIcon icon={faArrowDownToLine} title="Text vertical align bottom" />}
            />
          </div>
        </div>
      </div>
      <Modal
        title="Add custom font"
        open={isAddingNewFont}
        okButtonProps={{ form: 'formNewFont', htmlType: 'submit' }}
        onCancel={() => {
          _setIsAddingNewFont(false);
          (form as unknown as { resetFields: () => void }).resetFields();
        }}
      >
        <Form id="formNewFont" onFinish={onFinish} form={form} layout="vertical">
          <div className="row">
            <div className="row">
              <div className="col-12">
                <Form.Item label="Font name" name="fontName" rules={[{ required: true }]}>
                  <Input type="text" placeholder="Font Name" />
                </Form.Item>
              </div>
              <div className="col-12">
                <Form.Item label="Font" name="fontFile" rules={[{ required: true }]}>
                  <Upload
                    name="font"
                    headers={{
                      authorization: localStorage.getItem('user-token') || '',
                    }}
                    action={`${process.env.REACT_APP_API_URL}/custom-font/upload-font`}
                    maxCount={1}
                  >
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                  </Upload>
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </YcCard>
  );
}
