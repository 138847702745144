import { useIec } from '../../../../context/iec.context';
import { MColor, MCompTextStroke } from '../../../../../../modeles';
import { YcCard, YcColorPicker } from '../../../../../../components';
import { IecInputNumber } from '../../../input-number';

import './setting-comp-text-stroke.scss';

export function SettingCompTextStroke(props: {
  textStroke: MCompTextStroke
}) {
  const uIec = useIec();

  return (
    <YcCard
      title="Text Outline"
      light
      open={props.textStroke._open}
      onClickTitle={(val) => {
        props.textStroke._open = val;
        uIec.refreshIec(true);
      }}
      onDelete={() => {
        uIec.removeComponentNodeEdite(props.textStroke);
      }}
    >
      <div className="row">
        <div className="col-2">
          <IecInputNumber label="Size" field="size" obj={props.textStroke} />
        </div>
        <div className="col-2">
          <label>Color</label>
          <YcColorPicker
            color={props.textStroke.color}
            onColorChange={(color: MColor) => {
              props.textStroke.color = color;
              uIec.refreshIec(true);
            }}
          />
        </div>
      </div>
    </YcCard>
  );
}
