import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { FloatButton, Modal, Tour } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleInfo,
} from '@fortawesome/pro-regular-svg-icons';

import { creativesService } from '../../../../services';
import { MCreative } from '../../../../modeles';
import { useIec } from '../../context/iec.context';
import { useCreative } from '../../context/creative.context';
import { useTour } from '../../context/tour.context';
import {
  Preview, Setting, SettingGeneral, TranslateGrid,
} from '../../components';
import { Export } from '../../components/export';
import { IecListElem } from '../../components/iec/elem-list/elem-list';
import {
  DescriptionComponents, DescriptionElements, DescriptionGeneral, DescriptionPreview,
} from '../../components/descriptions-tour';
import { YcTags } from '../../../../components';

import './iec.page.scss';

export function IecPage() {
  const [isReady, _setIsReady] = useState<boolean>(false);
  const uIec = useIec();
  const uCreative = useCreative();
  const uTour = useTour();

  const SettingGeneralRef = useRef(null);
  const IecListElemRef = useRef(null);
  const PreviewRef = useRef(null);
  const SettingRef = useRef(null);

  const uHistory = useHistory();
  const uLocation: any = useLocation();
  const uParams: any = useParams();

  const steps = [
    {
      title: 'General settings',
      description: <DescriptionGeneral />,
      target: () => SettingGeneralRef.current as unknown as HTMLElement || null,
      placement: 'right',
    },
    {
      title: 'Elements',
      description: <DescriptionElements />,
      target: () => IecListElemRef.current as unknown as HTMLElement || null,
      placement: 'right',
    },
    {
      title: 'Preview',
      description: <DescriptionPreview />,
      target: () => PreviewRef.current as unknown as HTMLElement || null,
      placement: 'right',
    },
    {
      title: 'Components',
      description: <DescriptionComponents />,
      target: () => SettingRef.current as unknown as HTMLElement || null,
      placement: 'left',
    },
  ];

  useEffect(() => {
    if (uParams.id) {
      creativesService.view(uParams.id as number).then((data) => {
        if (data) {
          uIec.setIec(data);
          uCreative.setCreative(data);
          _setIsReady(true);
        } else {
          uHistory.push('/creatives');
        }
      });
    } else if (uLocation.state?.copy) {
      const copy : MCreative = JSON.parse(JSON.stringify({ ...uLocation.state.copy, id: 0, version: null }));
      uIec.setIec(copy);
      uCreative.setCreative(copy);
      _setIsReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    uIec.refreshIec(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady]);

  useEffect(() => {
    uTour.setSteps(steps);
    uTour.setTourName('iec');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SettingGeneralRef, IecListElemRef, PreviewRef, SettingRef]);

  const saveTranslations = (newTranslations: any, save?: boolean) => {
    uIec.setIec({ ...uIec.iec, translations: newTranslations });
    uCreative.setShowTranslation(false);
  };

  const cancelTranslations = () => {
    uCreative.setShowTranslation(false);
  };

  return (
    <div id="iecs-iec" key={uIec.iec.id}>
      {isReady
        && (
        <div className="row">
          <div className="col-3">
            <div className="header-title">
              <YcTags tags={[{ text: uCreative.creative.type!, color: '#008e77' }]} />
              <h3 className="iec-title">{uCreative.creative.name}</h3>
            </div>
            <div ref={SettingGeneralRef}>
              <SettingGeneral />
            </div>
            <div className="mb-3" ref={IecListElemRef}>
              <IecListElem />
            </div>
          </div>
          <div className="col-5">
            <div className="zone-preview" ref={PreviewRef}>
              <Preview />
              <div className="mt-4">
                <Export />
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="setting" ref={SettingRef}>
              <Setting />
            </div>
          </div>
          <Tour open={uTour.runTour} onClose={() => uTour.setRunTour(false)} steps={uTour.steps} />
        </div>
        )}
      <FloatButton
        icon={(<FontAwesomeIcon icon={faCircleInfo} />)}
        style={{ top: 90, bottom: 55 }}
        onClick={() => { uTour.setRunTour(true); }}
      />
      <Modal
        title="Translation Grid"
        width="95%"
        open={uCreative.showTranslation}
        footer={null}
        onCancel={() => { cancelTranslations(); }}
      >
        <TranslateGrid
          translations={uIec.iec.translations}
          cancelTranslate={() => { cancelTranslations(); }}
          saveTranslate={(newTranslations) => { saveTranslations(newTranslations); }}
        />
      </Modal>
    </div>
  );
}
