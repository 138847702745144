import { BaseService } from './base.service';
import { MInvoice } from '../modeles';

class InvoicesService extends BaseService {
  PATH = 'invoices/';

  async listing(): Promise<MInvoice[] | null> {
    return this._listing(`${this.PATH}listing`);
  }

  async pay(month: string): Promise<boolean> {
    return (await this._edit(`${this.PATH}pay`, {
      month,
    } as MInvoice)) !== null;
  }
}

export const invoicesService = new InvoicesService();
