import { Switch } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

import { useIec } from '../../context/iec.context';

import './iec-switch.scss';

export function IecSwitch(props: {
  obj: any,
  field: string,
  label?: string,
  disabled?: boolean,
}) {
  const uIec = useIec();

  const onChange = (value: any) => {
    props.obj[props.field] = value;
    uIec.refreshIec(true);
  };

  return (
    <div className="iec-switch">
      <label>{props.label}</label>
      <div>
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={props.obj[props.field]}
          onChange={(e) => onChange(e)}
          disabled={props.disabled}
        />
      </div>
    </div>
  );
}
