/* eslint-disable jsx-a11y/html-has-lang */

import { MCreative } from '../../../../modeles';
import { EmptyExport, EmptyExportFont } from './empty';

/* eslint-disable max-len */
export function IronSource(props: {
  iec: MCreative,
  body: string,
  fonts: EmptyExportFont[],
  appInfo: any,
  NETWORK: any,
}) {
  return (
    <EmptyExport
      iec={props.iec}
      body={props.body}
      fonts={props.fonts}
      appInfo={props.appInfo}
      NETWORK={props.NETWORK}
      script={`
              function getScript(e,i){var n=document.createElement("script");n.type="text/javascript",n.async=!0,i&&(n.onload=i),n.src=e,document.head.appendChild(n)}
              function parseMessage(e){var i=e.data,n=i.indexOf(DOLLAR_PREFIX+RECEIVE_MSG_PREFIX);if(-1!==n){var t=i.slice(n+2);return getMessageParams(t)}return{}}
              function getMessageParams(e){var i,n=[],t=e.split("/"),a=t.length;if(-1===e.indexOf(RECEIVE_MSG_PREFIX)){if(a>=2&&a%2===0)for(i=0;a>i;i+=2)n[t[i]]=t.length<i+1?null:
                decodeURIComponent(t[i+1])}else{var o=e.split(RECEIVE_MSG_PREFIX);void 0!==o[1]&&(n=JSON&&JSON.parse(o[1]))}return n}function getDapi(e)
                {var i=parseMessage(e);if(!i||i.name===GET_DAPI_URL_MSG_NAME){var n=i.data;getScript(n,onDapiReceived)}}function invokeDapiListeners()
                {for(var e in dapiEventsPool)dapiEventsPool.hasOwnProperty(e)&&dapi.addEventListener(e,dapiEventsPool[e])}function onDapiReceived()
                {dapi=window.dapi,window.removeEventListener("message",getDapi),invokeDapiListeners()}function init(){window.dapi.isDemoDapi&&(window.parent.postMessage
                (DOLLAR_PREFIX+SEND_MSG_PREFIX+JSON.stringify({state:"getDapiUrl"}),"*"),window.addEventListener("message",getDapi,!1))}var DOLLAR_PREFIX="$$",
                RECEIVE_MSG_PREFIX="DAPI_SERVICE:",SEND_MSG_PREFIX="DAPI_AD:",GET_DAPI_URL_MSG_NAME="connection.getDapiUrl",dapiEventsPool={},dapi=window.dapi||
                {isReady:function(){return!1},addEventListener:function(e,i){dapiEventsPool[e]=i},removeEventListener:function(e){delete dapiEventsPool[e]},
                isDemoDapi:!0};init();
            // window.onload = function(){
              (dapi.isReady()) ? onReadyCallback() : dapi.addEventListener("ready", onReadyCallback);
              iecDisplayApiCall();
            // };
            function onReadyCallback(){
              _networkSDK = dapi;
              //No need to listen to this event anymore
              _networkSDK.removeEventListener("ready", onReadyCallback);
          
              //If the ad is visible start the game
              _networkSDK.isViewable() && startGame();
              //Use dapi functions
              _networkSDK.addEventListener("viewableChange", viewableChangeCallback);
              _networkSDK.addEventListener("audioVolumeChange", onVolumeChange);
              _networkSDK.addEventListener("adResized", onResize);
            }
            function startGame() {
              //start your game
              var screenSize = _networkSDK.getScreenSize();
              _networkSDK.getAudioVolume();
            }
            function viewableChangeCallback(e){
              e.isViewable ? this.startGame() : pause();
            }
            function onVolumeChange() {
              _networkSDK.getAudioVolume();
            }
            function onResize() {
              _networkSDK.getScreenSize()
            }
          `}
    />
  );
}
