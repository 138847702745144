import type { TreeProps, DataNode } from 'antd/es/tree';
import {
  Spin, Tabs, TabsProps, Tree,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faObjectGroup } from '@fortawesome/pro-regular-svg-icons';

import { findElem } from '../../../utils/playable.utils';
import { YcCard } from '../../../../../components';
import { usePlayable } from '../../../context/playable.context';
import { IData, IGameObjectJson } from '../../../../../modeles/MP_Interfaces';
import { IcoTree } from '../../../../../assets/images/icons';
import {
  AssetsAudioClip,
  AssetsMaterial,
  AssetsModel,
  AssetsPrefab,
  AssetsScriptableObject,
  AssetsSprite,
  AssetsTexture,
  AssetsVideoClip,
} from '../settings-assets';

import './assets-list.scss';

const assetsMap: { [key: string]: { Component: any, valueField: string } } = {
  // Material: { Component: AssetsColor, valueField: 'checked' },
};

export function PlayableListAssets(props: TreeProps) {
  const uPlayable = usePlayable();

  const [assetsList, _setAssetsList] = useState<{ [key: string]: { [key: string]: object } }>({});
  const [assets, _setAssets] = useState<TabsProps['items']>([]);

  const assetsTypeReady = [
    // animationClips, ##
    // animatorControllers, ##
    // 'audioClips',
    // fonts, ##
    // 'materials',
    // 'models',
    // physicsMaterial2Ds, ##
    // physicsMaterials, ##
    // 'prefabs',
    // renderTextures, ##
    // 'scriptableObjects',
    // 'scripts', ##
    // 'sprites',
    // textAssets, ##
    'textures',
    // 'videoClips',
  ];

  useEffect(() => {
    if (uPlayable.playableJson && uPlayable.playableWindow.monsterplayable) {
      _setAssetsList(uPlayable.playableWindow.monsterplayable.workflow.assets as { [key: string]: { [key: string]: object } });
    } else {
      _setAssetsList({});
    }
  }, [uPlayable, uPlayable.playableJson]);

  const assetsElement = (element: string, listAssets: any): React.ReactNode => {
    switch (element) {
      case 'models':
        return '';
      // return (<AssetsModel key={subIndex} model={assetsList[element][subElement]} />);
      case 'textures':
        return (<AssetsTexture textures={listAssets} />);
      case 'sprites':
        return '';
      // return (<AssetsSprite key={subIndex} sprite={assetsList[element][subElement]} />);
      case 'audioClips':
        return '';
      // return (<AssetsAudioClip key={subIndex} audioClip={assetsList[element][subElement]} />);
      case 'prefabs':
        return '';
      // return (<AssetsPrefab key={subIndex} prefab={assetsList[element][subElement]} />);
      case 'materials':
        return '';
      // return (<AssetsMaterial key={subIndex} material={assetsList[element][subElement]} />);
      case 'videoClips':
        return '';
      // return (<AssetsVideoClip key={subIndex} videoClip={assetsList[element][subElement]} />);
      case 'ScriptableObjects':
        return '';
      // return (<AssetsScriptableObject key={subIndex} scriptableObject={assetsList[element][subElement]} />);
      default:
        return '';
    }
  };

  useEffect(() => {
    const newList: TabsProps['items'] = [];
    Object.keys(assetsList).forEach((element, index) => {
      if (assetsTypeReady.includes(element) && Object.values(assetsList[element]).length > 0) {
        newList.push(
          {
            key: index.toString(),
            label: element,
            children: (
              <div className="top">
                {
                  assetsElement(element, assetsList[element])
                }
              </div>
            ),
          },
        );
      }
    });
    _setAssets(newList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetsList]);

  return (
    <YcCard
      title="Assets"
      icon={<FontAwesomeIcon icon={faObjectGroup} />}
      open={false}
    >
      <Spin spinning={Object.keys(assetsList).length < 1}>
        <Tabs defaultActiveKey="1" items={assets} destroyInactiveTabPane />
      </Spin>
    </YcCard>
  );
}
