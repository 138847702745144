/* eslint-disable react/no-danger */
import {
  Button, Input, Popover, Spin, Table,
} from 'antd';
import { ColumnType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCloudArrowDown,
} from '@fortawesome/pro-regular-svg-icons';

import { MBuildError } from '../../../../modeles';
import { buildErrorService } from '../../../../services';
import { getUniqueList } from '../../../../tools';

import './build-error.page.scss';

export function BuildErrorPage() {
  const location = useLocation();

  const [buildErrors, _setBuildErrors] = useState<MBuildError[]>([]);
  const [displayedBuildErrors, _setDisplayBuildErrors] = useState<MBuildError[]>([]);
  const [isReady, _setIsReady] = useState<boolean>(false);
  const [search, _setSearch] = useState<string>('');

  const queryParameters = new URLSearchParams(location.search);

  useEffect(() => {
    buildErrorService.listing().then((data) => {
      _setBuildErrors(data || []);
      _setDisplayBuildErrors(data || []);

      if (queryParameters.has('search')) {
        _setSearch(queryParameters.get('search') || '');
      }

      _setIsReady(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    _setDisplayBuildErrors(
      buildErrors.filter(
        (buildError) => buildError.code
          .toLowerCase()
          .includes(search.toLowerCase()) || buildError.code.toLowerCase() === search.toLowerCase(),
      ),
    );
  }, [buildErrors, search]);

  const columns: ColumnType<MBuildError>[] = [
    {
      title: 'ID',
      key: 'id',
      render: (buildError: MBuildError) => (
        <label>{buildError.id}</label>
      ),
      sorter: (a: MBuildError, b: MBuildError) => a.id - b.id,
      filters: getUniqueList(buildErrors, 'id').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MBuildError) => record.id.toString() === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'Code',
      key: 'code',
      render: (buildError: MBuildError) => (
        <label>{buildError.code}</label>
      ),
      sorter: {
        compare: (a: MBuildError, b: MBuildError) => a.code!.localeCompare(b.code || '') || -1,
      },
      filters: getUniqueList(buildErrors, 'code').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MBuildError) => record.code === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'Step',
      key: 'step',
      render: (buildError: MBuildError) => (
        <label>{buildError.step}</label>
      ),
      sorter: {
        compare: (a: MBuildError, b: MBuildError) => a.step!.localeCompare(b.step || '') || -1,
      },
      filters: getUniqueList(buildErrors, 'step').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MBuildError) => record.step === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'SDK Version',
      key: 'sdkVersion',
      render: (buildError: MBuildError) => (
        <label>{buildError.sdkVersion}</label>
      ),
      sorter: {
        compare: (a: MBuildError, b: MBuildError) => a.sdkVersion!.localeCompare(b.sdkVersion || '') || -1,
      },
      filters: getUniqueList(buildErrors, 'sdkVersion').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MBuildError) => record.sdkVersion === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'Account',
      key: 'account',
      render: (buildError: MBuildError) => (
        <Link to={`/admin/accounts/account/${buildError.accountId}`}>{buildError.Account?.name}</Link>
      ),
      sorter: (a: MBuildError, b: MBuildError) => a.Account?.name.localeCompare(b.Account?.name || '') || -1,
      filters: getUniqueList(buildErrors, 'Account.name').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MBuildError) => record.Account?.name === (value as string) || false,
      filterSearch: true,
    },
    {
      title: 'User',
      key: 'user',
      render: (buildError: MBuildError) => (
        <Link to={`#${buildError.userId}`}>{buildError.User?.email}</Link>
      ),
      sorter: {
        compare: (a: MBuildError, b: MBuildError) => a.User!.id - b.User!.id,
      },
      filters: getUniqueList(buildErrors, 'User.email').map((data) => ({
        text: data,
        value: data,
      })),
      onFilter: (value: any, record: MBuildError) => record.User!.id === (value as number) || false,
      filterSearch: true,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (buildError: MBuildError) => (
        <div className="d-flex justify-content-around">
          <Popover content="Download">
            <Button
              disabled={!buildError.File?.url}
              type="primary"
              shape="circle"
              icon={<FontAwesomeIcon icon={faCloudArrowDown} />}
              size="small"
              onClick={() => download(buildError.File!.url)}
            />
          </Popover>
        </div>
      ),
    },
    {
      title: 'Creation date',
      key: 'createdAt',
      render: (buildError: MBuildError) => (
        <label>{new Date(buildError.createdAt!).toLocaleString()}</label>
      ),
      sorter: (a: MBuildError, b: MBuildError) => a.createdAt?.toString()?.localeCompare(b.createdAt?.toString() || '') || -1,
      defaultSortOrder: 'descend',
    },
  ];

  function download(url: string) {
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const displayLog = (errorMessage: any) => {
    return (
      <div className="logs">
        <pre dangerouslySetInnerHTML={{ __html: errorMessage }} />
      </div>
    );
  };

  return (
    <div id="engine-logs">
      <Spin spinning={!isReady}>
        <Input
          placeholder="Search"
          onChange={(e) => { _setSearch(e.target.value as string); }}
          value={search}
          style={{ width: '30%', marginBottom: '20px' }}
        />
        <Table
          bordered
          size="small"
          dataSource={displayedBuildErrors}
          columns={columns}
          expandable={{
            expandedRowRender: (record) => displayLog(record.errorMessage),
            rowExpandable: (record) => record.errorMessage !== null,
          }}
          rowKey="id"
          pagination={{ defaultPageSize: 20, pageSizeOptions: [10, 20, 50, 100], showSizeChanger: true }}
        />
      </Spin>
    </div>
  );
}
