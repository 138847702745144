import { useIec } from '../../../../context/iec.context';
import {
  AlignItemsTypeValues, FlexDirectionTypeValues, JustifyContentTypeValues, MCompFlex,
} from '../../../../../../modeles';
import { YcCard } from '../../../../../../components';
import { IecSelect } from '../../../select';

import './setting-comp-flex.scss';

export function SettingCompFlex(props: {
  flex: MCompFlex
}) {
  const uIec = useIec();

  return (
    <YcCard
      title="Flex"
      light
      open={props.flex._open}
      onClickTitle={(val) => {
        props.flex._open = val;
        uIec.refreshIec(true);
      }}
      onDelete={() => {
        uIec.removeComponentNodeEdite(props.flex);
      }}
    >
      <div className="row">
        <div className="col-4">
          <IecSelect label="Flex Direction" field="flexDirection" obj={props.flex} values={FlexDirectionTypeValues} />
        </div>
        <div className="col-4">
          <IecSelect label="Align Items" field="alignItems" obj={props.flex} values={AlignItemsTypeValues} />
        </div>
        <div className="col-4">
          <IecSelect label="Justify Content" field="justifyContent" obj={props.flex} values={JustifyContentTypeValues} />
        </div>
      </div>
    </YcCard>
  );
}
