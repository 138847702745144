/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable import/no-cycle */
import { INetworkIntegration, Events, EventEmitter } from './network-integration';

export interface INetworkHandler {
  onresize: () => void,
  gameStart: () => void,
  gameClose: () => void,
  install?: () => void,
  gameEnd?: () => void,
  gameReady?: () => void,
  HttpAPI?: {
    sendPoint: (msg: string) => void
  },
  innerWidth: number,
  innerHeight: number,
}

export default class MolocoIntegration implements INetworkIntegration {
  _networkSDK: INetworkHandler | null = null;

  _events: EventEmitter = new EventEmitter();

  _appInfo: any = null;

  public get name() { return 'moloco'; }

  public preloader = false;

  public get appInfo(): any { return this._appInfo; }

  public onWindowLoad() {
    return `
    if (FbPlayableAd) {
      _networkSDK = FbPlayableAd;
      console.warn('FbPlayableAd found', _networkSDK);
    } else {
      console.warn('FbPlayableAd not found');
    }
    if (ExitApi) {
      _networkSDK = ExitApi;
      console.warn('ExitApi found', _networkSDK);
    } else {
      console.warn('ExitApi not found');
    }
    if (mraid) {
      _networkSDK = mraid;
      console.warn('mraid found', _networkSDK);
    } else {
      console.warn('mraid not found');
    }
    if (playableSDK) {
      _networkSDK = playableSDK;
      console.warn('playableSDK found', _networkSDK);
    } else {
      console.warn('playableSDK not found');
    }
    `;
  }

  public onReady() {
    this._events.emit(Events.gameStart);
  }

  public onResize() {
    this._events.emit(Events.screenResize);
  }

  public onViewChange(isViewable: boolean) {
    if (isViewable) {
      this._events.emit(Events.gamePlay);
    } else {
      this._events.emit(Events.gamePause);
    }
  }

  public onVolumeChange() {
    this._events.emit(Events.soundChange);
  }

  public install() {
    // @ts-ignore
    if (FbPlayableAd) { FbPlayableAd.onCTAClick(); }
    // @ts-ignore
    if (ExitApi) { _networkSDK.exit(); }
    // @ts-ignore
    if (mraid) { mraid.open(_appInfo.store.url); }
    // @ts-ignore
    if (playableSDK) { _networkSDK.openAppStore(); }
  }

  public gameEnd() {
    // @ts-ignore
    if (_networkSDK?.gameEnd) _networkSDK.gameEnd();
  }

  public gameReady() {
    // @ts-ignore
    if (_networkSDK?.gameReady) _networkSDK.gameReady();
  }

  public getScreenSize() {
    return {
      // @ts-ignore
      width: _networkSDK.innerWidth,
      // @ts-ignore
      height: _networkSDK.innerHeight,
    };
  }

  public getAudioVolume() {
    return 1;
  }

  public sendMsg(msg: string) {
    if (this._networkSDK?.HttpAPI && this._networkSDK.HttpAPI.sendPoint) {
      this._networkSDK.HttpAPI.sendPoint(msg);
    }
  }

  addListener(event: string, callback: () => void) {
    this._events.addListener(event, callback);
  }

  removeListener(event: string, callback: () => void) {
    this._events.removeListener(event, callback);
  }

  MP_META = '';
  MP_SCRIPT = '';
}
