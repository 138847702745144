import { useEffect, useState } from 'react';

import { usePlayable } from '../../../../context/playable.context';
import { YcCard } from '../../../../../../components';
import { IAudioSourceJson as JsonType } from '../../../../../../modeles/MP_Interfaces';
import { SettingCompInputBoolean, SettingCompInputCheckbox, SettingCompSlider } from '../../sub-settings';

import './setting-comp-audio-source.scss';

export function SettingCompAudioSource(props: { initialData: any, parentID: string, icon?: string }) {
  const childType: string = props.initialData.type;
  const childID: string = props.initialData.instanceID;
  const parentID: string = props.parentID;

  const uPlayable = usePlayable();

  const [compTypeIndex, _setCompTypeIndex] = useState<number>();
  const [component, _setComponent] = useState<JsonType>();

  useEffect(() => {
    if (compTypeIndex === undefined) {
      _setCompTypeIndex(uPlayable.getCompTypeIndex(childType, parentID, childID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (props !== undefined && compTypeIndex !== undefined) {
      _setComponent(uPlayable.getRunTimeComponent(`${childType}[${compTypeIndex}]`) as JsonType);
    }
  }, [childType, compTypeIndex, props, uPlayable]);

  const editComponent = (propertyPath: string, value: any, update: boolean = true) => {
    if (compTypeIndex !== undefined) {
      uPlayable.updateGameObject(value, `${childType}[${compTypeIndex}]/${propertyPath}`, childID, update);
    }
  };

  return (
    <YcCard
      title="Audio Source"
      icon={props.icon}
      light
      open
      warning={compTypeIndex === undefined}
      titleExtra={(
        <SettingCompInputCheckbox
          checked={component?.enabled ?? true}
          onChange={(e) => editComponent('enabled', e)}
        />
      )}
    >
      <div className="row">
        <div className="col-12">
          <SettingCompInputBoolean
            label="Play On Awake"
            checked={component?.playOnAwake}
            onChange={(value) => editComponent('playOnAwake', value)}
          />
        </div>
        <div className="col-12">
          <SettingCompInputBoolean
            label="Loop"
            checked={component?.loop}
            onChange={(value) => editComponent('loop', value)}
          />
        </div>
        <div className="col-12">
          <SettingCompSlider
            label="Volume"
            min={0}
            max={1}
            step={0.01}
            value={component?.volume ?? 1}
            onChange={(e, update) => editComponent('volume', e, update)}
          />
          <SettingCompSlider
            disabled
            label="Pitch"
            min={0.1}
            max={3}
            step={0.1}
            value={component?.pitch ?? 1}
            onChange={(e, update) => editComponent('pitch', e, update)}
          />
        </div>
      </div>
    </YcCard>
  );
}
