import { useEffect, useState } from 'react';

import {
  SettingCompInputBoolean, SettingCompSelect,
  SettingCompInputCheckbox,
} from '../../sub-settings';
import { usePlayable } from '../../../../context/playable.context';
import { YcCard } from '../../../../../../components';
import { IGraphicRaycaster as JsonType } from '../../../../../../modeles/MP_Interfaces';

import './setting-comp-graphic-raycaster.scss';

const graphicRaycasterBlockingTypes: { label: string, value: number }[] = [
  { label: 'None', value: 0 },
  { label: 'Two D', value: 1 },
  { label: 'Three D', value: 2 },
  { label: 'All', value: 3 },
];

export function SettingCompGraphicRaycaster(props: { initialData: any, parentID: string, icon?: string }) {
  const childType: string = props.initialData.type;
  const childID: string = props.initialData.instanceID;
  const parentID: string = props.parentID;

  const uPlayable = usePlayable();

  const [compTypeIndex, _setCompTypeIndex] = useState<number>();
  const [component, _setComponent] = useState<JsonType>();

  useEffect(() => {
    if (compTypeIndex === undefined) {
      _setCompTypeIndex(uPlayable.getCompTypeIndex(childType, parentID, childID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (props !== undefined && compTypeIndex !== undefined) {
      _setComponent(uPlayable.getRunTimeComponent(`${childType}[${compTypeIndex}]`) as JsonType);
    }
  }, [childType, compTypeIndex, props, uPlayable]);

  const editComponent = (propertyPath: string, value: any, update: boolean = true) => {
    if (compTypeIndex !== undefined) {
      uPlayable.updateGameObject(value, `${childType}[${compTypeIndex}]/${propertyPath}`, childID, update);
    }
  };

  return (
    <YcCard
      title="Graphic Raycaster"
      icon={props.icon}
      light
      open
      warning={compTypeIndex === undefined}
      titleExtra={(
        <SettingCompInputCheckbox
          checked={component?.enabled ?? true}
          onChange={(e) => editComponent('enabled', e)}
        />
      )}
    >
      <div className="row">
        <div className="col-12">
          <SettingCompInputBoolean
            disabled
            label="Ignore Reversed Graphics"
            checked={component?.ignoreReversedGraphics ?? true}
            onChange={(e) => editComponent('ignoreReversedGraphics', e)}
          />
        </div>
        <div className="col-12">
          <SettingCompSelect
            disabled
            label="Blocking Objects"
            value={component?.blockingObjects ?? 0}
            options={graphicRaycasterBlockingTypes}
            onChange={(e) => editComponent('blockingObjects', e)}
          />
        </div>
      </div>
    </YcCard>
  );
}
