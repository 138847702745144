import { SettingCompInputNumber } from '../comp-input';

type Field = {
  label: string,
  value: any,
  field: string,
};

export function SettingCompFieldsGroup(props: {
  label: string,
  fields: Field[],
  type: string,
  onChange: (field: string, value: any) => void,
}) {
  return (
    <div className="row mb-1">
      <div className="col-5">
        <label className="me-1">{props.label}</label>
      </div>
      <div className="col-7">
        {
          props.type === 'number' && (
            props?.fields.map((field, i) => (
              <SettingCompInputNumber
                key={i}
                reduceSpace
                value={field.value}
                label={field.label}
                onChange={(e) => props.onChange(field.field, e)}
              />
            ))
          )
        }
      </div>
    </div>
  );
}
