import { useEffect, useState } from 'react';

import { usePlayable } from '../../../../context/playable.context';
import { YcCard } from '../../../../../../components';
import { ITextJson as JsonType } from '../../../../../../modeles/MP_Interfaces';
import {
  SettingCompColor, SettingCompInputNumber,
  SettingCompInputBoolean, SettingButtonGroup,
  SettingCompInputCheckbox, defineSelected,
  SettingCompTextArea, SettingCompSelect,
} from '../../sub-settings';

import {
  IcoLeft, IcoCenter, IcoRight,
  IcoTop, IcoMiddle, IcoBottom,
} from '../../../../../../assets/images/textAlignments';
import './setting-comp-text-mesh-pro.scss';

enum HorizontalAlignment {
  Left = 1,
  Center = 2,
  Right = 4,
  Justified = 8,
  Flush = 16,
  Geometry = 32,
}

enum VerticalAlignment {
  Top = 256,
  Middle = 512,
  Bottom = 1024,
  Baseline = 2048,
  Geometry = 4096,
  Capline = 8192,
}

type GroupButton = {
  hoverText: string,
  value: number,
  label?: string,
  icon?: string,
};

const fontStylesLeftBtns: GroupButton[] = [
  { hoverText: 'Bold', value: 1, label: 'B' },
  { hoverText: 'Italic', value: 2, label: 'I' },
  { hoverText: 'Underline', value: 4, label: 'U' },
  { hoverText: 'Strikethrough', value: 64, label: 'S' },
];

const fontStylesRightBtns: GroupButton[] = [
  { hoverText: 'Lowercase', value: 8, label: 'ab' },
  { hoverText: 'Uppercase', value: 16, label: 'AB' },
  { hoverText: 'SmallCaps', value: 32, label: 'SC' },
];

const horizontalBtns: GroupButton[] = [
  { hoverText: 'Left', value: 1, icon: IcoLeft },
  { hoverText: 'Center', value: 2, icon: IcoCenter },
  { hoverText: 'Right', value: 4, icon: IcoRight },
];

const verticalBtns: GroupButton[] = [
  { hoverText: 'Top', value: 1024, icon: IcoTop },
  { hoverText: 'Middle', value: 512, icon: IcoMiddle },
  { hoverText: 'Bottom', value: 256, icon: IcoBottom },
];

export function SettingCompTextMeshPro(props: {
  initialData: any,
  parentID: string,
  icon?: string
}) {
  const childType: string = props.initialData.type;
  const childID: string = props.initialData.instanceID;
  const parentID: string = props.parentID;

  const uPlayable = usePlayable();

  const [compTypeIndex, _setCompTypeIndex] = useState<number>();
  const [component, _setComponent] = useState<JsonType>();

  const [styleInfos, _setStyleInfos] = useState({ left: 0, right: 0 });
  const [alignmentInfos, _setAlignmentInfos] = useState({ horizontal: 1, vertical: 256 });

  useEffect(() => {
    if (compTypeIndex === undefined) {
      _setCompTypeIndex(uPlayable.getCompTypeIndex(childType, parentID, childID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (props !== undefined && compTypeIndex !== undefined) {
      _setComponent(uPlayable.getRunTimeComponent(`${childType}[${compTypeIndex}]`) as JsonType);
    }
  }, [childType, compTypeIndex, props, uPlayable]);

  const editComponent = (propertyPath: string, value: any, update: boolean = true) => {
    if (compTypeIndex !== undefined) {
      uPlayable.updateGameObject(value, `${childType}[${compTypeIndex}]/${propertyPath}`, childID, update);
    }
  };

  useEffect(() => {
    if (component !== undefined) {
      instantiateStyleInfos();
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      _setAlignmentInfos(getAlignmentInfos(component.alignment));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [component]);

  const instantiateStyleInfos = () => {
    const leftIndexes = defineSelected(component?.fontStyle as number, fontStylesLeftBtns);
    const rightIndexes = defineSelected(component?.fontStyle as number, fontStylesRightBtns);

    let leftTotal: number = 0;
    fontStylesLeftBtns.forEach((btn, i) => { leftTotal += (leftIndexes[i] ? btn.value : 0); });

    let rightTotal: number = 0;
    fontStylesRightBtns.forEach((btn, i) => { rightTotal += (rightIndexes[i] ? btn.value : 0); });

    _setStyleInfos({ left: leftTotal, right: rightTotal });
  };

  const getAlignmentInfos = (nb: number): { horizontal?: HorizontalAlignment, vertical?: VerticalAlignment } => {
    const result: { horizontal?: HorizontalAlignment, vertical?: VerticalAlignment } = {};

    const horizontalValues = Object.values(HorizontalAlignment).filter((value) => typeof value === 'number') as number[];
    for (const value of horizontalValues) {
      // eslint-disable-next-line no-bitwise
      if ((nb & value) !== 0) {
        result.horizontal = value;
        break;
      }
    }

    const verticalAlignmentValues = Object.values(VerticalAlignment).filter((value) => typeof value === 'number') as number[];
    for (const value of verticalAlignmentValues) {
      // eslint-disable-next-line no-bitwise
      if ((nb & value) !== 0) {
        result.vertical = value;
        break;
      }
    }

    return result;
  };

  const handleAlignmentChange = (nb: number, isHorizontal: boolean = true) => {
    const oldAlignment = alignmentInfos.horizontal + alignmentInfos.vertical;
    const fieldToUpdate = isHorizontal ? 'horizontal' : 'vertical';

    editComponent('alignment', oldAlignment - alignmentInfos[fieldToUpdate] + nb);
    _setAlignmentInfos((old) => ({ ...old, [fieldToUpdate]: nb }));
  };

  const handleStyleChange = (nb: number, isLeft: boolean = true) => {
    const oldStyle = styleInfos.left + styleInfos.right;
    const fieldToUpdate = isLeft ? 'left' : 'right';

    editComponent('fontStyle', oldStyle - styleInfos[fieldToUpdate] + nb);
    _setStyleInfos((old) => ({ ...old, [fieldToUpdate]: nb }));
  };

  return (
    <YcCard
      title="TextMeshPro - Text (UI)"
      icon={props.icon}
      light
      open
      warning={compTypeIndex === undefined}
      titleExtra={(
        <SettingCompInputCheckbox
          checked={component?.enabled ?? true}
          onChange={(e) => editComponent('enabled', e)}
        />
      )}
    >
      <div className="row">
        <div className="col-12">
          <SettingCompTextArea
            label="Text"
            value={component?.text ?? ''}
            onChange={(e: string) => editComponent('text', e)}
            rows={2}
          />
          <SettingButtonGroup
            canMultiple
            label="Font Style"
            btns={fontStylesLeftBtns}
            onClick={(e) => handleStyleChange(e)}
            value={styleInfos.left}
          />
          <SettingButtonGroup
            disabled
            label="Font capitalization"
            btns={fontStylesRightBtns}
            onClick={(e) => handleStyleChange(e)}
            value={styleInfos.right}
          />
          <SettingCompInputNumber
            label="Font Size"
            value={component?.fontSize ?? 36}
            onChange={(e) => editComponent('fontSize', e)}
          />
          <SettingCompInputBoolean
            disabled
            label="Auto Size"
            checked={component?.resizeTextForBestFit ?? false}
            onChange={(e) => editComponent('enableAutoSizing', e)}
          />
          {
            component?.resizeTextForBestFit && (
              <>
                <SettingCompInputNumber
                  disabled
                  label="Min Size"
                  value={component?.resizeTextMinSize ?? 18}
                  onChange={(e) => editComponent('resizeTextMinSize', e)}
                />
                <SettingCompInputNumber
                  disabled
                  label="Max Size"
                  value={component?.resizeTextMaxSize ?? 72}
                  onChange={(e) => editComponent('resizeTextMaxSize', e)}
                />
              </>
            )
          }
          <SettingCompColor
            defaultType="white"
            label="Vertex Color"
            color={component?.color}
            onChange={(color, updateJson) => editComponent('color', color, updateJson)}
          />
          <SettingButtonGroup
            label="Horizontal Alignment"
            btns={horizontalBtns}
            onClick={(e) => handleAlignmentChange(e)}
            value={alignmentInfos?.horizontal}
          />
          <SettingButtonGroup
            disabled
            label="Vertical Alignment"
            btns={verticalBtns}
            onClick={(e) => handleAlignmentChange(e, false)}
            value={alignmentInfos?.vertical}
          />
          <SettingCompSelect
            label="Wrapping"
            value={component?.enableWordWrapping}
            options={[
              { label: 'Enabled', value: true },
              { label: 'Disabled', value: false },
            ]}
            onChange={(e: boolean) => editComponent('enableWordWrapping', e)}
          />
        </div>
      </div>
    </YcCard>
  );
}
