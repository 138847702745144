import qs from 'qs';
import {
  Button, Form, Spin,
} from 'antd';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useHistory, useLocation } from 'react-router-dom';

import { FCItem, YcDatePicker, YcFieldCheckPage } from '../../../../components';

import './performance-filters.scss';
import { YcNumberCheckPage } from '../../../../components/number-check';

interface Filters {
  platforms: FCItem[];
  applications: FCItem[];
  creatives: FCItem[];
  users: FCItem[];
  creativeTypes: FCItem[];
  countries: FCItem[];
  networks: FCItem[];
  tags: FCItem[];
  videos: number | null;
  actions: number | null;
  globalHover: boolean;
  endCard: boolean;

  isPlatform: boolean;
  isApplication: boolean;
  isCreative: boolean;
  isUser: boolean;
  isCreativeType: boolean;
  isCountry: boolean;
  isNetwork: boolean;
  isTags: boolean;
}

export function PerformanceFilters(props: {
  onSearch: (data: any) => void;
  service: any,
}) {
  const uLocation = useLocation();
  const uHistory = useHistory();

  const [loading, _setLoading] = useState<boolean>(false);
  const [filters, _setFilters] = useState<Filters | null>(null);
  const [applicationKeys, _setApplicationKeys] = useState<string[]>([]);
  const [dates, _setDates] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([dayjs(), dayjs()]);

  useEffect(() => {
    _setLoading(true);
    const search = new URLSearchParams(uLocation.search);
    if (search.has('dateStart')) {
      dates[0] = dayjs(search.get('dateStart'));
    }
    if (search.has('dateEnd')) {
      dates[1] = dayjs(search.get('dateEnd'));
    }
    props.service.filters().then((data: Filters) => {
      _setLoading(false);
      if (data) {
        SetParamsFilter(data.applications, search.get('applications')!);
        SetParamsFilter(data.creatives, search.get('creatives')!);
        SetParamsFilter(data.users, search.get('users')!);
        SetParamsFilter(data.countries, search.get('countries')!);
        SetParamsFilter(data.networks, search.get('networks')!);
        SetParamsFilter(data.platforms, search.get('platforms')!);
        SetParamsFilter(data.creativeTypes, search.get('creativeTypes')!);
        SetParamsFilter(data.tags, search.get('tags')!);

        const videosParam = search.get('videos');
        data.videos = videosParam !== null ? +videosParam : null;
        const actionsParam = search.get('actions');
        data.actions = actionsParam !== null ? +actionsParam : null;

        if (search.has('is')) {
          const is = search.get('is')!.split(',');
          data.isApplication = true;
          data.isCreative = true;
          data.isUser = is.includes('userId');
          data.isCountry = is.includes('country');
          data.isNetwork = is.includes('network');
          data.isPlatform = is.includes('platform');
          data.isCreativeType = is.includes('creativeType');
          data.isTags = is.includes('tags');
        }
        _setFilters(data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SetParamsFilter = (items: FCItem[], paramsString: string) => {
    if (paramsString) {
      const params = paramsString.split(',');
      items.forEach((item) => {
        if (params.includes(`${item.value}-Y`)) {
          item.checked = true;
        } else if (params.includes(`${item.value}-N`)) {
          item.checked = false;
        }
      });
    }
  };

  const setApplicationKeys = () => {
    const keys = filters?.applications.filter((app) => app.checked).map((app) => {
      return app.value as string;
    });
    _setApplicationKeys(keys || []);
  };

  const getParamsFilter = (items: FCItem[]) => {
    const results = items.filter((item) => item.checked !== undefined);
    return results.map((item) => `${item.value}-${item.checked ? 'Y' : 'N'}`).join() || undefined;
  };

  const getParamsIs = () => {
    const is: string[] = [];
    is.push('applicationId');
    is.push('creativeId');
    if (filters?.isUser) { is.push('userId'); }
    if (filters?.isCreativeType) { is.push('creativeType'); }
    if (filters?.isCountry) { is.push('country'); }
    if (filters?.isNetwork) { is.push('network'); }
    if (filters?.isPlatform) { is.push('platform'); }
    if (filters?.isTags) { is.push('tag'); }
    if (is.length) {
      return is.join(',');
    }
    return undefined;
  };

  const getParams = (stringify: boolean) => {
    const b = {
      dateStart: dayjs(dates[0]).format('YYYY-MM-DD'),
      dateEnd: dayjs(dates[1]).format('YYYY-MM-DD'),
      applications: getParamsFilter(filters!.applications),
      creatives: getParamsFilter(filters!.creatives),
      users: getParamsFilter(filters!.users),
      creativeTypes: getParamsFilter(filters!.creativeTypes),
      countries: getParamsFilter(filters!.countries),
      networks: getParamsFilter(filters!.networks),
      platforms: getParamsFilter(filters!.platforms),
      tags: getParamsFilter(filters!.tags),
      videos: filters!.videos ? filters!.videos : undefined,
      actions: filters!.actions ? filters!.actions : undefined,
      is: getParamsIs(),
    };
    if (stringify) {
      return qs.stringify(b, { encode: false });
    }
    return b;
  };

  return (
    <div id="performance-filters">
      <Spin spinning={loading}>
        {!!filters
          && (
          <Form className="row position-relative">
            <Form.Item className="col-3">
              <YcDatePicker
                value={dates}
                onChange={(e) => {
                  if (e && e.length >= 2) {
                    _setDates([e[0]!, e[1]!]);
                  }
                }}
              />
            </Form.Item>
            <Form.Item className="col-3">
              <YcFieldCheckPage
                maxTagCount="responsive"
                is
                disabledCheck
                label="Applications"
                items={filters.applications}
                mode="multiple"
                onChange={(applications, isApplication): void => {
                  _setFilters({ ...filters, applications, isApplication });
                  setApplicationKeys();
                }}
              />
            </Form.Item>
            <Form.Item className="col-3">
              <YcFieldCheckPage
                maxTagCount="responsive"
                is
                disabledCheck
                label="Creatives"
                items={filters.creatives}
                mode="multiple"
                onChange={(creatives, isCreative): void => {
                  _setFilters({ ...filters, creatives, isCreative });
                }}
              />
            </Form.Item>
            <Form.Item className="col-3">
              <YcFieldCheckPage
                maxTagCount="responsive"
                is={filters.isPlatform}
                label="Platforms"
                items={filters.platforms}
                mode="multiple"
                onChange={(platforms, isPlatform): void => {
                  _setFilters({ ...filters, platforms, isPlatform });
                }}
              />
            </Form.Item>
            <Form.Item className="col-3">
              <YcFieldCheckPage
                maxTagCount="responsive"
                is={filters.isNetwork}
                label="Networks"
                items={filters.networks}
                mode="multiple"
                onChange={(networks, isNetwork): void => {
                  _setFilters({ ...filters, networks, isNetwork });
                }}
              />
            </Form.Item>
            <Form.Item className="col-3">
              <YcFieldCheckPage
                maxTagCount="responsive"
                is={filters.isUser}
                label="Users"
                items={filters.users}
                mode="multiple"
                onChange={(users, isUser): void => {
                  _setFilters({ ...filters, users, isUser });
                }}
              />
            </Form.Item>
            <Form.Item className="col-3">
              <YcFieldCheckPage
                maxTagCount="responsive"
                is={filters.isCountry}
                label="Countries"
                items={filters.countries}
                mode="multiple"
                onChange={(countries, isCountry): void => {
                  _setFilters({ ...filters, countries, isCountry });
                }}
              />
            </Form.Item>
            <Form.Item className="col-3">
              <YcFieldCheckPage
                maxTagCount="responsive"
                is={filters.isTags}
                label="Tags"
                items={filters.tags}
                mode="multiple"
                onChange={(tags, isTags): void => {
                  _setFilters({ ...filters, tags, isTags });
                }}
              />
            </Form.Item>
            <Form.Item className="col-3">
              <YcNumberCheckPage
                label="Videos"
                value={filters.videos}
                onChange={(value): void => {
                  _setFilters({ ...filters, videos: value });
                }}
              />
            </Form.Item>
            <Form.Item className="col-3">
              <YcNumberCheckPage
                label="Actions"
                value={filters.actions}
                onChange={(value): void => {
                  _setFilters({ ...filters, actions: value });
                }}
              />
            </Form.Item>
            <Form.Item className="col-3">
              <YcNumberCheckPage value={null} label="Global hover" />
            </Form.Item>
            <Form.Item className="col-3">
              <YcNumberCheckPage value={null} label="End card" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                onClick={() => {
                  uHistory.push({
                    search: getParams(true) as string,
                  });
                  props.onSearch(getParams(false));
                }}
              >
                Search
              </Button>
            </Form.Item>
          </Form>
          )}
      </Spin>
    </div>
  );
}
