import { MCompFlex } from '../../../../../modeles';

export const PreviewCompFlex_CSS = `
/* FLEX */
.preview-comp-flex {
  height: 100%;
}
.preview-comp-flex >.preview-element {
  position: inherit !important;
}
`;

export function PreviewCompFlex(props: {
  // eslint-disable-next-line react/no-unused-prop-types
  flex?: MCompFlex,
  children: React.ReactNode,
}) {
  return (
    <>
      {props.flex && (
      <div
        className="preview-comp-flex"
        style={{
          display: 'flex',
          flexDirection: props.flex?.flexDirection,
          alignItems: props.flex?.alignItems,
          justifyContent: props.flex?.justifyContent,
        }}
      >
        {props.children}
      </div>
      )}
      {!props.flex && props.children}
    </>
  );
}
