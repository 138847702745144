/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  useMemo, useState, useCallback,
} from 'react';

import { adminUsersService, usersService } from '../services';
import { MUser } from '../modeles';
import { useAuths } from './auths.context';

export interface UserEditedContextType {
  user?: MUser | null,
  isReady: boolean,
  isLoading: boolean,
  setUser: (id: number) => void,
  saveUser: () => void,
  updateUser: (u: MUser) => void,
  cleanUser: () => void,
}

const UserEditedContext = React.createContext<UserEditedContextType>({
  user: undefined,
  isReady: false,
  isLoading: false,

  setUser: (/* id: MApplication */) => {},
  saveUser: () => {},
  updateUser: (/* u: MUser */) => {},
  cleanUser: (/* u: MUser */) => {},
});

export function UserEditedContextProvider({ children }: { children: React.ReactNode }) {
  const uAuths = useAuths();

  const [user, _setUser] = useState<MUser | null>();
  const [isReady, _setIsReady] = useState<boolean>(false);
  const [isLoading, _setIsLoading] = useState<boolean>(false);

  const setUser = useCallback((id: number) => {
    _setIsLoading(true);
    _setIsReady(false);
    if (uAuths.isSuperAdmin()) {
      adminUsersService.view(id).then((data) => {
        if (data) {
          _setUser(data);
        }
        _setIsLoading(false);
        _setIsReady(true);
      });
    } else {
      usersService.view(id).then((data) => {
        if (data) {
          _setUser(data);
        }
        _setIsLoading(false);
        _setIsReady(true);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveUser = useCallback(() => {
    _setIsLoading(true);
    if (uAuths.isSuperAdmin()) {
      adminUsersService.edit(user!.id, user!).then((data) => {
        if (data) {
          _setUser(null);
        }
        _setIsLoading(false);
      });
    } else if (uAuths.isAdmin()) {
      usersService.edit(user!.id, user!).then((data) => {
        if (data) {
          _setUser(null);
        }
        _setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const updateUser = useCallback((u: MUser) => {
    _setUser(u);
  }, []);

  const cleanUser = useCallback(() => {
    _setUser(null);
  }, []);

  const value = useMemo(() => ({
    user,
    isReady,
    isLoading,

    setUser,
    saveUser,
    updateUser,
    cleanUser,
  }), [user, isReady, isLoading, saveUser, updateUser, cleanUser, setUser]);

  return (
    <UserEditedContext.Provider value={value}>
      {children}
    </UserEditedContext.Provider>
  );
}

export function useUserEdited() {
  const context = React.useContext(UserEditedContext);
  if (!context) throw new Error('No UserEditedContext provider found!');
  return context;
}
