import './card-try.scss';

export interface ICardTry {
  title: string;
  description: string;
  icon: string;
  url: string;
}

export function CardTry(
  props:{
    cardtry: ICardTry;
  },
) {
  return (

    <div className="col-sm-12 col-md-6 col-lg-4 mb-4 align-self-center">

      <a target="_blank" href={props.cardtry.url} rel="noreferrer">
        <div className="card-try">

          <img className="icon-game" src={props.cardtry.icon} alt="iec icon" />

          <div className="content-game">

            <h3 className="title-game">{props.cardtry.title}</h3>
            <p className="description-game">{props.cardtry.description}</p>
            <div className="button">Play</div>
          </div>

        </div>
      </a>
    </div>
  );
}
